import React from 'react';
import propTypes from 'prop-types';

import Modal from 'components/Modal';
import InputGroup from 'components/InputGroup';

import './statics/styles.css';
import { pathOr } from 'ramda';

import { useTranslation } from 'react-i18next';

const ReviseStockProduct = (props) => {
  const {
    formData,
    onParentCategoryChange,
    data,
    parent_categories,
    onChange,
    onCancel,
    onConfirm,
    products,
    brands,
    unitTypes,
    measureNames,
    revisedStatus,
  } = props;
  const { t } = useTranslation();
  const subCategoriesList = pathOr(
    undefined,
    ['parent_category_id', 'sub_categories'],
    formData
  );

  const sub_category = pathOr(
    { value: null, name: null },
    ['category_id'],
    formData
  );
  return (
    <Modal
      className='-modal'
      title={t('modals.revise_product.title')}
      onCancel={onCancel}
      onSave={onConfirm}
      asyncStatus={revisedStatus}
    >
      <div className='-modal-row'>
        <label className='-modal__label'>{data.variant.external_name}</label>
      </div>
      <div className='-modal-row'>
        <InputGroup
          className='-modal-row-item'
          label={t('modals.revise_product.name_placeholder')}
          placeholder={t('modals.revise_product.name_placeholder')}
          type='select-creatable'
          list={products}
          value={formData['product']}
          required
          onChange={onChange}
          name='product'
        />
        <InputGroup
          className='-modal-row-item'
          label={t('modals.revise_product.description_placeholder')}
          placeholder={t('modals.revise_product.description_placeholder')}
          type='text'
          name='description'
          value={formData['description']}
          onChange={onChange}
        />
      </div>
      <div className='-modal-row'>
        <InputGroup
          className='-modal-row-item'
          label={t('modals.revise_product.brand_placeholder')}
          placeholder={t('modals.revise_product.brand_placeholder')}
          type='select-creatable'
          list={brands}
          name='brand'
          value={formData['brand']}
          onChange={onChange}
        />
        <InputGroup
          className='-modal-row-item'
          label={t('modals.revise_product.category')}
          placeholder={t('modals.revise_product.category_placeholder')}
          type='select'
          list={parent_categories}
          name='parent_category_id'
          required
          value={formData['parent_category_id']}
          onChange={onParentCategoryChange}
        />
      </div>
      <div className='-modal-row'>
        <InputGroup
          className='-modal-row-item'
          label={t('modals.revise_product.subcategory')}
          placeholder={t('modals.revise_product.subcategory_placeholder')}
          type='select'
          list={subCategoriesList}
          name='category_id'
          required
          value={sub_category}
          onChange={onChange}
        />
      </div>
      <hr />
      <div className='-modal-row'>
        <InputGroup
          className='-modal-row-item'
          label={t('modals.revise_product.presentation_placeholder')}
          placeholder={t('modals.revise_product.presentation_placeholder')}
          type='select'
          list={unitTypes}
          name='unit_type'
          required
          value={formData['unit_type']}
          onChange={onChange}
        />
        <InputGroup
          className='-modal-row-item'
          label={t('modals.revise_product.units_multiplier_placeholder')}
          placeholder={t('modals.revise_product.units_multiplier_placeholder')}
          type='number'
          name='units_multiplier'
          required
          value={formData['units_multiplier']}
          onChange={onChange}
        />
      </div>
      <div className='-modal-row'>
        <InputGroup
          className='-modal-row-item'
          label={t('modals.revise_product.units_per_pack_placeholder')}
          placeholder={t('modals.revise_product.units_per_pack_placeholder')}
          type='number'
          name='units_per_pack'
          required
          value={formData['units_per_pack']}
          onChange={onChange}
        />
      </div>
      <div className='-modal-row'>
        <InputGroup
          className='-modal-row-item'
          label={t('modals.revise_product.weight_placeholder')}
          placeholder={t('modals.revise_product.weight_placeholder')}
          type='number'
          name='unit_measure'
          required
          value={formData['unit_measure']}
          onChange={onChange}
        />
        <InputGroup
          className='-modal-row-item'
          label={t('modals.revise_product.unit_measure_name_placeholder')}
          placeholder={t('modals.revise_product.unit_measure_name_placeholder')}
          type='select'
          list={measureNames}
          name='unit_measure_name'
          required
          value={formData['unit_measure_name']}
          onChange={onChange}
        />
      </div>
      <hr />
    </Modal>
  );
};

ReviseStockProduct.propTypes = {
  onCancel: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
  data: propTypes.shape({}).isRequired,
  formData: propTypes.shape({}).isRequired,
  products: propTypes.arrayOf([]).isRequired,
  parent_categories: propTypes.arrayOf([]).isRequired,
  brands: propTypes.arrayOf([]).isRequired,
  unitTypes: propTypes.arrayOf([]).isRequired,
  measureNames: propTypes.arrayOf([]).isRequired,
  revisedStatus: propTypes.string.isRequired,
  onParentCategoryChange: propTypes.func.isRequired,
};

export default ReviseStockProduct;
