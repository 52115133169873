import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from './components/DatePicker'
import moment from 'moment'

class DatePickerContainer extends React.Component {
  constructor (props) {
    super(props)
    
    this.state = {
      date: moment()
    }
  }

  onChangeDate = type => {
    const { date } = this.state

    const nextDate = type === 'add'
      ? moment(date).add(1, 'days')
      : moment(date).subtract(1, 'days')

    this.setState({
      date: nextDate
    })

    this.props.onChange({ target: { name: this.props.name, value: nextDate } })
  };

  render () {
    const { date } = this.state
    
    return (
      <DatePicker 
        onChangeDate={this.onChangeDate}
        date={date}
      />
    )
  }
}

DatePickerContainer.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func
}

DatePickerContainer.defaultProps = {
  name: '',
  onChange: () => {}
}

export default DatePickerContainer