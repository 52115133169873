import { call, put, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { get as getMkp } from 'api-connect/dist/marketplace';
import { del, get, post, put as _put } from 'api-connect/dist/user';

import i18n from '../../../app/configs/i18n';
import history from '../../../app/configs/history';

import { onConfirmation } from '../../modals/store/sagas';

import * as actions from './actions';
import * as modalActions from 'modules/modals/store/actions';
import * as alertActions from 'modules/alerts/store/actions';

function* fetchAllUsersWorker() {
  try {
    const { res, error } = yield call(get, 'admin_users');

    if (res?.status > 400 || error) {
      return yield put(actions.fetchAllUsers.failure({ error }));
    }

    yield put(
      actions.fetchAllUsers.success({
        users: res.data,
      })
    );
  } catch (error) {
    yield put(actions.fetchAllUsers.failure({ error: 'Server error' }));
    yield put(
      alertActions.openAlert({
        message: i18n.t('users.sagas.server_error'),
        type: 'error',
      })
    );
  }
}

//FETCH ALL NETWORKS
export function* fetchAllNetworks() {
  try {
    const { res, error } = yield call(getMkp, 'networks');

    if (res?.status > 400 || error) {
      return yield put(actions.getNetworks.failure({ error }));
    }

    yield put(
      actions.getNetworks.success({
        networks: res.data,
      })
    );
  } catch (error) {
    yield put(actions.getNetworks.failure({ error: 'Server error' }));
    yield put(
      alertActions.openAlert({
        message: i18n.t('networks.sagas.server_error'),
        type: 'error',
      })
    );
  }
}

//CREATE USER
export function* createUserWorker(action) {
  history.push('/settings/users');

  const { res, error } = yield call(post, 'admin_users', action.payload);

  if (res?.status > 400 || error) {
    yield put(
      alertActions.openAlert({
        message: 'Uups, ocurrio un error',
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.addUser.failure({ error }));
  }

  yield put(actions.addUser.success());
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: 'Creado correctamente',
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.fetchAllUsers());
}

//UPDATE USER
function* editUserWorker(action) {
  const { id } = action.payload;

  const { res, error } = yield call(_put, `admin_users/${id}`, action.payload);

  if (error || res?.status >= 400) {
    yield put(
      alertActions.openAlert({
        message: 'Ups, ocurrio un error',
        type: 'error',
      })
    );
    return yield put(actions.updateUser.failure({ error }));
  }

  yield put(actions.updateUser.success({ res }));
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: 'Actualizado correctamente',
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.fetchAllUsers());
}

//DELETE USER
function* deleteUserWorker(action) {
  const message = i18n.t('users.sagas.delete_message');
  const operationName = i18n.t('users.sagas.delete_network');
  const confirmed = yield call(onConfirmation, operationName, message);
  if (!confirmed) {
    return;
  }

  const { id } = action.payload;
  const { res, error } = yield call(del, `admin_users/${id}`);

  if (!res || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('users.sagas.delete_error'),
        type: 'error',
        delay: 2000,
      })
    );
    yield put(modalActions.closeModal());
    return yield put(actions.deleteUser.failure({ error }));
  }

  yield put(
    alertActions.openAlert({
      message: i18n.t('users.sagas.delete_success'),
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.deleteUser.success());

  yield delay(2000);
  yield put(modalActions.closeModal());

  yield put(actions.fetchAllUsers());
}

export function* fetchAllWatcher() {
  yield takeEvery(actions.fetchAllUsers.type, fetchAllUsersWorker);
}

export function* fetchAllNetworksWatcher() {
  yield takeEvery(actions.getNetworks.type, fetchAllNetworks);
}

export function* createUserWatcher() {
  yield takeEvery(actions.addUser.type, createUserWorker);
}

export function* deleteUserWatcher() {
  yield takeEvery(actions.deleteUser.type, deleteUserWorker);
}

export function* editUserWatcher() {
  yield takeEvery(actions.updateUser.type, editUserWorker);
}

//EXPORT WATCHERS
export default [
  fetchAllWatcher,
  createUserWatcher,
  deleteUserWatcher,
  editUserWatcher,
  fetchAllNetworksWatcher,
];
