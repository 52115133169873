import { createSelector } from 'reselect';

import { NAMESPACE } from '../config/constants';

import * as modalsSelectors from '../../modals/store/selectors';

export const bankAccounts = (state) => state[NAMESPACE].items;
export const status = (state) => state[NAMESPACE].status;
export const error = (state) => state[NAMESPACE].error;

export const getBankAccount = createSelector(
  bankAccounts,
  modalsSelectors.data,
  (list, modalData) => list[modalData.bankAccountId]
);
