import React from 'react';
import propTypes from 'prop-types';
import RadioButton from '../../../../../components/RadioButton/RadioButton';
import TimePicker from '../../../../../components/TimePicker/TimePicker';
import DatePicker from '../../../../../components/DatePicker/components/DatePicker';
import errorStyles from '../css/Error.module.css';
import i18n from '../../../../../app/configs/i18n';
import { ErrorMessage, Field } from 'formik';
import { Input } from '../../../../../components/Input';
import { Select } from '../../../../../components/Select';

export const InputField = ({ name, ...rest }) => (
  <Field name={name}>
    {({ field }) => (
      <div>
        <Input {...field} {...rest} />
        <ErrorMessage
          name={name}
          component='span'
          className={errorStyles.error}
        />
      </div>
    )}
  </Field>
);

export const DateField = ({ name, onChange = () => {} }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const handleChange = (date) => {
          form.setFieldValue(name, date);
          form.setFieldTouched(name);
          onChange(date);
        };
        return (
          <div>
            <DatePicker
              onChange={handleChange}
              value={field.value}
              name={name}
              isFuture={undefined}
              required
              timeFormat={false}
              input={false}
            />
            <ErrorMessage
              name={name}
              component='span'
              className={errorStyles.error}
            />
          </div>
        );
      }}
    </Field>
  );
};

export const TimeField = ({ name }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const onChange = (date) => {
          form.setFieldValue(name, date);
          form.setFieldTouched(name);
        };
        return (
          <div>
            <TimePicker time={field.value} onChange={onChange} />
            <ErrorMessage
              name={name}
              component='span'
              className={errorStyles.error}
            />
          </div>
        );
      }}
    </Field>
  );
};

export const CheckboxField = ({ name, onChangeWithForm }) => (
  <Field name={name}>
    {({ field, form }) => {
      const onChange = (val) => {
        form.setFieldValue(name, val);
        form.setFieldTouched(name, val);
        if (onChangeWithForm) onChangeWithForm(form);
      };
      return (
        <>
          <RadioButton
            value
            checked={field.value}
            onChange={onChange}
            text={i18n.t('stock.create_offer.inside')}
          />
          <RadioButton
            value={false}
            checked={!field.value}
            onChange={onChange}
            text={i18n.t('stock.create_offer.outside')}
          />
        </>
      );
    }}
  </Field>
);

export const SelectField = ({ name, onChange, onChangeWithForm, ...rest }) => (
  <Field name={name}>
    {({ field, form }) => {
      return (
        <div>
          <Select
            {...field}
            {...rest}
            onChange={(option) => {
              const value = option.target.value ? option.target.value : '';
              form.setFieldValue(name, value);
              if (onChange) onChange(value || { addresses: [{ id: '' }] });
              if (onChangeWithForm) onChangeWithForm(form);
            }}
            onBlur={() => form.setFieldTouched(name, true)}
          />
          <ErrorMessage
            name={name}
            component='span'
            className={errorStyles.error}
          />
        </div>
      );
    }}
  </Field>
);

export const hookToProductSelect = (name) => (form, value) => {
  form.setFieldValue(name, Number(value.price_per_kilo));
  form.setFieldTouched(name, Number(value.price_per_kilo));
};

export const ProductSelectField = ({ name, onChange, ...rest }) => (
  <Field name={name}>
    {({ field, form }) => (
      <div>
        <Select
          {...field}
          {...rest}
          onChange={(option) => {
            const optionValue = option.target.value
              ? option.target.value
              : null;
            form.setFieldValue(name, optionValue);
            const underlying_value = optionValue
              ? optionValue.value
              : { price: 0 };
            if (onChange) onChange(form, underlying_value);
          }}
          onBlur={() => form.setFieldTouched(name, true)}
        />
        <ErrorMessage
          name={name}
          component='span'
          className={errorStyles.error}
        />
      </div>
    )}
  </Field>
);

InputField.propTypes = {
  name: propTypes.string.isRequired,
};

DateField.propTypes = {
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

CheckboxField.propTypes = {
  name: propTypes.string.isRequired,
  onChangeWithForm: propTypes.func.isRequired,
};

TimeField.propTypes = {
  name: propTypes.string.isRequired,
};

SelectField.propTypes = {
  name: propTypes.string.isRequired,
  onChangeWithForm: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
};

ProductSelectField.propTypes = {
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};
