import React from 'react';
import { connect } from 'react-redux';

import * as modalActions from '../../store/actions';

import Modal from './components/Modal';

class ViewStockProductContainer extends React.Component {
  render() {
    return <Modal {...this.props} />;
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  onCancel: () => {
    dispatch({ type: props.onCancel });
    dispatch(modalActions.closeModal());
  },
});

export default connect(null, mapDispatchToProps)(ViewStockProductContainer);
