import React from 'react';
import propTypes from 'prop-types';
import ReactDatePicker from 'react-datetime';

import { buildDates } from '../helpers';

import './styles.css';
import { isNil } from 'ramda';

const DatePicker = (props) => {
  const {
    input,
    selectedDate,
    onChange,
    isFuture,
    className,
    label,
    required,
    inputProps,
    closeOnScroll,
  } = props;

  return (
    <div>
      {label && (
        <label
          key='1'
          className={`date-picker_label -input-form__label ${
            (isNil(selectedDate) || !selectedDate.isValid()) && required
              ? 'invalid'
              : ''
          }`}
          htmlFor='type'
        >
          {label}: {required ? '*' : ''}
        </label>
      )}
      <ReactDatePicker
        closeOnScroll={closeOnScroll ? closeOnScroll : false}
        input={input}
        className={className}
        onChange={onChange}
        value={selectedDate}
        timeFormat={false}
        isValidDate={buildDates(isFuture)}
        inputProps={inputProps}
      />
    </div>
  );
};

DatePicker.propTypes = {
  selectedDate: propTypes.arrayOf([]).isRequired,
  onChange: propTypes.func.isRequired,
  isFuture: propTypes.bool.isRequired,
  input: propTypes.bool.isRequired,
  className: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  required: propTypes.bool.isRequired,
  inputProps: propTypes.arrayOf(),
  closeOnScroll: propTypes.bool,
};

DatePicker.defaultProps = {
  inputProps: [],
  closeOnScroll: propTypes.bool,
};

export default DatePicker;
