import React from 'react';
import propTypes from 'prop-types';
import styles from './statics/Preview.module.css';
import { makePlural } from '../helpers';
import i18n from '../../../../../app/configs/i18n';

const Preview = (props) => {
  const {
    product,
    unit_type,
    units_per_pack,
    is_bulk,
    unit_measure,
    unit_measure_name,
    units_multiplier,
    single_unit_measure_name,
  } = props;
  const withUnits = units_multiplier > 1;

  const prodShow = product ? product.value : '';
  const unitMeasureShow = unit_measure_name ? unit_measure_name.value : '';
  const unitsPerPack = units_per_pack || '';
  const unitTypeShow = unit_type ? unit_type.value : '';
  const unitTypeShowPlural = makePlural(unitTypeShow);
  const unitTypeShowSingular = unitsPerPack > 1 ? null : unitTypeShow;
  const singleUnitMeasureShow = single_unit_measure_name
    ? makePlural(single_unit_measure_name.value)
    : '';

  const p = 100; //Harcoded, podria venir de API si fuera necesario.
  const render = (is_bulk || unit_type) && product;
  return render ? (
    <div className={styles.container}>
      <h6>{i18n.t('modals.create_variant.preview.title')}</h6>
      <h5>
        {`${prodShow} ${i18n.t('modals.create_variant.preview.en')} `}{' '}
        {is_bulk ? 'bultos ' : unitTypeShow}
        {/* {` de ${unit_measure} ${unitMeasureShow}`} */}
        {` ${i18n.t('modals.create_variant.preview.de')} `}
        {is_bulk ? unitsPerPack : unit_measure} {unitMeasureShow}
        {withUnits
          ? ` ${i18n.t(
              'modals.create_variant.preview.con'
            )} ${units_multiplier} ${singleUnitMeasureShow}.`
          : '.'}
      </h5>
      {(is_bulk && (
        <ul style={{ marginLeft: '10px' }}>
          <li className={styles.list_item}>
            1 {is_bulk ? 'bulto' : unitTypeShow} = ${p}
          </li>
          <li className={styles.list_item}>
            2 {is_bulk ? 'bultos' : unitTypeShow} = ${p * 2}
          </li>
          <li className={styles.list_item}>
            3 {is_bulk ? 'bultos' : unitTypeShow} = ${p * 3}
          </li>
        </ul>
      )) || (
        <ul style={{ marginLeft: '10px' }}>
          <li className={styles.list_item}>
            {/* {`${unit_measure} ${unitMeasureShow}`} */}
            {`${unitsPerPack} ${unitTypeShowSingular || unitTypeShowPlural}`}
            {withUnits
              ? ` (${
                  Number(units_multiplier) * Number(unitsPerPack)
                } ${singleUnitMeasureShow})`
              : ''}{' '}
            = ${p}
          </li>
          <li className={styles.list_item}>
            {`${Number(unitsPerPack) * 2} ${unitTypeShowPlural}`}
            {withUnits
              ? ` (${
                  Number(units_multiplier) * Number(unitsPerPack) * 2
                } ${singleUnitMeasureShow})`
              : ''}{' '}
            = ${p * 2}
          </li>
          <li className={styles.list_item}>
            {`${Number(unitsPerPack) * 3} ${unitTypeShowPlural}`}
            {withUnits
              ? ` (${
                  Number(units_multiplier) * Number(unitsPerPack) * 3
                } ${singleUnitMeasureShow})`
              : ''}{' '}
            = ${p * 3}
          </li>
        </ul>
      )}
    </div>
  ) : null;
};

Preview.propTypes = {
  product: propTypes.arrayOf([]).isRequired,
  unit_type: propTypes.arrayOf([]).isRequired,
  units_per_pack: propTypes.number.isRequired,
  is_bulk: propTypes.bool.isRequired,
  unit_measure: propTypes.number.isRequired,
  unit_measure_name: propTypes.number.isRequired,
  units_multiplier: propTypes.number.isRequired,
  single_unit_measure_name: propTypes.number.isRequired,
};

export default Preview;
