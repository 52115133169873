import { handleActions } from 'redux-actions';
import { omit } from 'ramda';

import * as actions from './actions';
import * as paymentsActions from '../../payments/store/actions';
import * as permissionsActions from 'modules/permissions/store/actions';

const initialState = {
  status: 'init',
  error: '',
  items: {},
};

const reducer = handleActions(
  {
    [actions.fetchAll.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchAll.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchAll.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        ...action.payload.trips,
      },
    }),

    [actions.create.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.create.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.create.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        [action.payload.trip.id]: {
          ...action.payload.trip,
        },
      },
    }),

    [actions.remove.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.remove.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.remove.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: omit([action.payload.id], state.items),
    }),

    [actions.update.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.update.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.update.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        [action.payload.trip.id]: {
          ...state.items[action.payload.trip.id],
          ...action.payload.trip,
        },
      },
    }),

    [actions.fetchTrips.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchTrips.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchTrips.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        ...action.payload.trips,
      },
    }),

    [actions.startTripBroadcast.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.startTripBroadcast.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.startTripBroadcast.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        [action.payload.id]: {
          ...state.items[action.payload.id],
          status: 'waiting_shipper',
          status_detail: 'broadcasted',
        },
      },
    }),

    [actions.pauseTripBroadcast.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.pauseTripBroadcast.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.pauseTripBroadcast.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        [action.payload.id]: {
          ...state.items[action.payload.id],
          status: null,
          status_detail: null,
        },
      },
    }),

    [paymentsActions.create.SUCCESS]: (state, action) => {
      const { id, type, tripId, payments } = action.payload;

      if (type === 'orders') {
        return {
          ...state,
          items: {
            ...state.items,
            [tripId]: {
              ...state.items[tripId],
              orders: state.items[tripId].orders.map((order) => {
                if (order.id === id) {
                  return {
                    ...order,
                    payments: [payments],
                  };
                } else {
                  return order;
                }
              }),
            },
          },
        };
      }

      if (type === 'deliveries') {
        return {
          ...state,
          items: {
            ...state.items,
            [tripId]: {
              ...state.items[tripId],
              orders: state.items[tripId].orders.map((order) => {
                if (order.deliveries[0].id === id) {
                  return {
                    ...order,
                    deliveries: [
                      {
                        ...order.deliveries[0],
                        payments: [payments],
                      },
                    ],
                  };
                } else {
                  return order;
                }
              }),
            },
          },
        };
      }
    },

    [actions.cleanState.type]: () => ({
      ...initialState,
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
