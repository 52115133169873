import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../../components/ModalCenter/components/ModalCenter';
import ModalFooter from '../../../../../components/ModalCenter/components/ModalFooter';
import ModalBody from '../../../../../components/ModalCenter/components/ModalBody';
import Button from '../../../../../components/Button';
import ButtonWithSpinner from '../../../../../components/ButtonWithSpinner/ButtonWithSpinnerContainer';
import i18n from '../../../../../app/configs/i18n';
class Confirmation extends React.Component {
  render() {
    const { onCancel, onConfirm, data } = this.props;
    const { operationName, message } = data;

    return (
      <Modal
        className='create-stock-modal'
        title={<Title operationName={operationName} />}
        onCancel={onCancel}
        {...this.props}
      >
        <ModalBody className='modal-detail__body'>{message}</ModalBody>
        <ModalFooter>
          <Button onClick={onCancel} transparent>
            {i18n.t('modals.confirmation.close')}
          </Button>
          <ButtonWithSpinner onClick={onConfirm} primary>
            {i18n.t('modals.confirmation.submit')}
          </ButtonWithSpinner>
        </ModalFooter>
      </Modal>
    );
  }
}

const Title = ({ operationName }) => (
  <div>
    <div className='create-stock-modal__title'>{operationName}</div>
  </div>
);

Confirmation.propTypes = {
  step: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  operationName: PropTypes.string.isRequired,
};

export default Confirmation;
