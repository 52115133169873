import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';

import Alert from 'components/Alert/index';

import './styles.css';

const ModalCenter = (props) => {
  const {
    title,
    show,
    className,
    onCancel,
    modalError,
    modalSuccess,
    modalStatus,
    onCloseAlert,
  } = props;

  return (
    <div
      className={cn('modal fade show', className)}
      role='dialog'
      tabIndex='-1'
      style={{ display: show && 'block' }}
    >
      <div className='modal-dialog modal-simple modal-center'>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={onCancel}
            >
              <span aria-hidden='true'>×</span>
            </button>
            {typeof title === 'string' && (
              <h4 className='modal-title'>{title}</h4>
            )}
            {typeof title !== 'string' && <span>{title}</span>}
          </div>
          <Alert
            display={modalStatus === 'failure'}
            msg={modalError}
            type='danger'
            onClose={onCloseAlert}
          />
          <Alert
            display={modalStatus === 'success'}
            msg={modalSuccess}
            type='success'
            onClose={onCloseAlert}
          />
          {props.children}
        </div>
      </div>
    </div>
  );
};

ModalCenter.propTypes = {
  onCloseAlert: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
  title: propTypes.oneOfType([propTypes.string, propTypes.object]).isRequired,
  modalStatus: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
  show: propTypes.string.isRequired,
  modalError: propTypes.string.isRequired,
  modalSuccess: propTypes.string.isRequired,
  className: propTypes.string.isRequired,
};

export default ModalCenter;
