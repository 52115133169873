import React from 'react';
import propTypes from 'prop-types';
import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import ProductAdder from './ProductAdder';
import { Formik } from 'formik';
import { Select } from 'components/Select';
import { onlyCompanies } from '../../../../../utils/filter_institutions';
import { useTranslation } from 'react-i18next';
import './statics/style.css';

const EditOrder = (props) => {
  const { onCancel, onConfirm, setOrigin, setHideTable, state } = props;
  const { t } = useTranslation();

  const initialValues = {
    origin: undefined,
    product: undefined,
    quantity: undefined,
  };

  const validOptions = state.options
    .filter((i) => i.used !== true)
    .filter((s) =>
      state.origin ? s.address_id === state.origin.addresses[0].id : false
    );

  return (
    <Modal
      className='edit_date__modal'
      title={t('modals.add_product.title')}
      onCancel={() => onCancel()}
      {...props}
    >
      <ModalBody className='modal-detail__body'>
        <Formik
          enableReinitialize
          onSubmit={onConfirm}
          initialValues={initialValues}
        >
          {() => (
            <>
              <div className='modal-row'>
                <p className='info'>{t('modals.add_product.subtitle')}</p>
                <Select
                  label={t('modals.add_product.origin')}
                  options={props.institutions.filter(onlyCompanies)}
                  placeholder={t('modals.add_product.origin_placeholder')}
                  onChange={(e) => setOrigin(e.target.value)}
                  value={state.origin}
                  onMenuOpen={() => setHideTable(true)}
                  onMenuClose={() => setHideTable(false)}
                />
              </div>
              <div className='modal-row'>
                <ProductAdder
                  validOptions={validOptions}
                  onCancel={() => onCancel()}
                  hideTable={state.hideTable}
                  setHideTable={(hide) => setHideTable(hide)}
                  onSubmit={(data) => onConfirm(data)}
                />
              </div>
            </>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

EditOrder.propTypes = {
  onCancel: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
  setHideTable: propTypes.bool.isRequired,
  state: propTypes.shape({}).isRequired,
  setOrigin: propTypes.string.isRequired,
};

export default EditOrder;
