import React from 'react';
import propTypes from 'prop-types';

import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';

import Button from '../../../../../components/Button';
import { Formik } from 'formik';
import moment from 'moment/moment';
import { validationSchema } from '../validations';
import { Label } from '../../../../../components/Select/Label';
import {
  DateField,
  TimeField,
} from '../../../../stock/components/CreateOffer/Steps/FormHelpers';

import './statics/style.css';

import { useTranslation } from 'react-i18next';

const EditDate = ({ onConfirm, onCancel, ...props }) => {
  const { t } = useTranslation();
  const initialValues = {
    delivery_date: moment().ISO_8601,
    delivery_time: moment().ISO_8601,
  };

  return (
    <Modal
      className='edit_date__modal'
      title={t('modals.edit_order_date.title')}
      onCancel={onCancel}
      {...props}
    >
      <ModalBody className='modal-detail__body'>
        <Formik
          initialValues={initialValues}
          onSubmit={onConfirm}
          validationSchema={validationSchema()}
          isInitialValid
        >
          {({ values, handleChange, handleSubmit, isValid }) => (
            <>
              <div className='grid_date'>
                <div>
                  <Label label={t('modals.edit_order_date.date')} />
                  <DateField
                    name='delivery_date'
                    onChange={handleChange}
                    value={values.delivery_date}
                    required
                  />
                </div>
                <div>
                  <Label label={t('modals.edit_order_date.start_time')} />
                  <TimeField
                    name='delivery_time'
                    onChange={handleChange}
                    value={values.delivery_time}
                    required
                  />
                </div>
              </div>
              <div className='buttons' style={{ marginRight: '40px' }}>
                <div className='container-btns'>
                  <Button className='btn btn-cancel' onClick={onCancel} outline>
                    {t('modals.edit_order_date.close')}
                  </Button>
                  <Button
                    className='btn btn-save'
                    onClick={handleSubmit}
                    disabled={!isValid}
                    primary
                  >
                    {t('modals.edit_order_date.submit')}
                  </Button>
                </div>
              </div>
            </>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

EditDate.propTypes = {
  onCancel: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
  status: propTypes.string.isRequired,
};

export default EditDate;
