import { handleActions } from 'redux-actions';
import { isNil } from 'ramda';

import * as actions from './actions';
import * as permissionsActions from '../../permissions/store/actions';

import types from '../config/types';

const initialState = {
  status: 'init',
  error: '',
  type: '',
  onConfirm: '',
  onCancel: '',
  data: {},
};

const reducer = handleActions(
  {
    [actions.openModal.type]: (state, action) => ({
      ...state,
      type: types[action.payload.type],
      onConfirm: action.payload.onConfirm,
      onCancel: isNil(action.payload.onCancel)
        ? 'CLOSE_MODAL'
        : action.payload.onCancel,
      data: action.payload.data,
    }),

    [actions.onConfirm.START]: (state) => ({
      ...state,
      status: 'pending',
      error: '',
    }),

    [actions.onConfirm.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.onConfirm.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),

    [actions.closeModal.type]: () => ({
      ...initialState,
    }),

    [actions.cleanError.type]: (state) => ({
      ...state,
      status: 'init',
      error: '',
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
