import { omit } from 'ramda';
import { FILTER_STATUS } from '../configs/constants';

/**
 * Transform a string that is in cammelCase to lower_dashes
 * @param str
 */
export const upperToLodash = (str) =>
  str
    .split('')
    .map((letter) => {
      if (letter === letter.toUpperCase()) {
        return `_${letter.toLowerCase()}`;
      }

      return letter;
    })
    .join('');

/**
 * Will map all keys in an object and will apply a function to each one of them
 * @param obj
 * @param func
 */
export const mapAllKeys = (obj, func) =>
  Object.keys(obj).reduce((res, key) => {
    res[func(key)] = obj[key];
    return res;
  }, {});

/**
 * Will transform all keys in an object to lowerdashes
 * @param obj
 */
export const upperToLodashAllKeys = (obj) => mapAllKeys(obj, upperToLodash);

/**
 * Will return shippers list filtered by verified status
 * @param shippers
 * @param filterValue
 * @returns {*}
 */
export const filterShippers = (shippers, filterValue) => {
  if (filterValue === FILTER_STATUS.VERIFIED) {
    return shippers.filter((shipper) => shipper.verified);
  }
  if (filterValue === FILTER_STATUS.UNVERIFIED) {
    return shippers.filter((shipper) => !shipper.verified);
  }
  if (filterValue === FILTER_STATUS.ALL) {
    return shippers;
  }
};

export const buildVehicle = (vehicle) => ({
  ...omit(['refrigerated'], vehicle),
  features: [vehicle.refrigerated ? 'refrigerated' : ''],
});

export const normalizeList = (list, field) =>
  list.reduce((res, elem) => {
    res.push(normalizeItem(elem, field));
    return res;
  }, []);

export const normalizeItem = (item, field) => ({
  ...item,
  [field]: normalize(item[field]),
});

export const normalize = (list) => list.map((elem) => elem.id);

export const buildVehicles = (list) =>
  list
    .map((item) => item.vehicles)
    .reduce((res, list) => {
      res.push(...list);
      return res;
    }, []);
