import React from 'react';
import styles from '../css/Grid.module.css';
import {
  CheckboxField,
  CreatableSelectField,
  LabeledInputField,
  SelectField,
} from './FormHelpers';
import { Tooltips } from '../tooltips';
import PropTypes from 'prop-types';
import { makePlural } from '../helpers';
import i18n from '../../../../../app/configs/i18n';
import Preview from './Preview';

function EditVariant(props) {
  const {
    products,
    categories,
    brands,
    unitTypes,
    measureNames,
    handleInputChange,
    handleChildrenCategoryChange,
    handleCategoryChange,
    units_per_pack,
    unit_measure,
    weight,
    category,
    values,
  } = props;

  const separatorStyles = { margin: '20px 0px 20px 0px' };
  const subCategoriesList = category ? category.sub_categories : [];
  if (subCategoriesList)
    subCategoriesList.forEach((sc) => {
      sc.label = sc.name;
      sc.value = sc.id;
    });
  const pres = values['unit_type'] ? values['unit_type'].value : '';
  const presPlural = units_per_pack > 1 ? makePlural(pres) : pres;

  let weightName = values['unit_measure_name']
    ? values['unit_measure_name'].value
    : '';
  weightName = units_per_pack > 1 ? makePlural(weightName) : weightName;

  const presExists = !!pres.length;

  return (
    <div className={styles.grid}>
      <div className={styles.halfRow}>
        <CreatableSelectField
          label={i18n.t('modals.create_variant.name')}
          placeholder={i18n.t('modals.create_variant.name_placeholder')}
          options={products}
          required
          name='product'
        />
      </div>
      <div className={styles.halfRow}>
        <CreatableSelectField
          className={styles.halfRow}
          label={i18n.t('modals.create_variant.brand')}
          placeholder={i18n.t('modals.create_variant.brand_placeholder')}
          options={brands}
          required
          name='brand'
          tooltip={Tooltips['brand']}
        />
      </div>
      <div className={styles.fullRow}>
        <LabeledInputField
          name='description'
          label={i18n.t('modals.create_variant.description_placeholder')}
          placeholder={i18n.t('modals.create_variant.description_placeholder')}
          type='text'
          tooltip={Tooltips['description']}
          datacy='description'
        />
      </div>
      <div className={styles.halfRow}>
        <SelectField
          className={styles.halfRow}
          label={i18n.t('modals.create_variant.category')}
          placeholder={i18n.t('modals.create_variant.category_placeholder')}
          options={categories}
          name='parent_category_id'
          required
          tooltip={Tooltips['category_id']}
          onChange={(event) => handleCategoryChange(event)}
        />
      </div>
      <div className={styles.halfRow}>
        <SelectField
          className={styles.halfRow}
          label={i18n.t('modals.create_variant.subcategory')}
          placeholder={i18n.t('modals.create_variant.subcategory_placeholder')}
          options={subCategoriesList}
          onChange={handleChildrenCategoryChange}
          name='category_id'
          required
          tooltip={Tooltips['id']}
        />
      </div>
      <hr className={styles.fullRow} style={separatorStyles} />
      <div className={styles.halfRow}>
        <CreatableSelectField
          label={i18n.t('modals.create_variant.presentation')}
          placeholder={i18n.t('modals.create_variant.presentation_placeholder')}
          tooltip={Tooltips['unit_type']}
          options={unitTypes}
          name='unit_type'
          is_bulk={values['is_bulk']}
          disabled={values['is_bulk']}
          required={!values['is_bulk']}
        />
      </div>
      <div className={styles.halfRow}>
        <LabeledInputField
          label={
            presExists
              ? `${presPlural} ${i18n.t('modals.create_variant.by_bulk')}`
              : values['is_bulk']
              ? `${weightName} ${i18n.t('modals.create_variant.by_bulk')}`
              : i18n.t('modals.create_variant.presentation_by_bulk')
          }
          placeholder={i18n.t(
            'modals.create_variant.units_per_pack_placeholder'
          )}
          type='number'
          name='units_per_pack'
          required
          min={1}
          tooltip={Tooltips['units_per_pack']}
          datacy='units_per_pack'
          value={units_per_pack}
          handleInputChange={handleInputChange}
        />
      </div>
      <div className={styles.fullRow} style={separatorStyles}>
        <CheckboxField
          name='is_bulk'
          handleInputChange={handleInputChange}
          label={i18n.t('modals.create_variant.is_bulk')}
          tooltip={Tooltips['is_bulk']}
        />
      </div>
      <div className={styles.halfRow}>
        <LabeledInputField
          label={
            presExists
              ? `${i18n.t('modals.create_variant.weight_by')} ${pres}`
              : i18n.t('modals.create_variant.weight_by_presentation')
          }
          placeholder={i18n.t('modals.create_variant.unit_measure_placeholder')}
          type='number'
          name='unit_measure'
          datacy='unit_measure'
          required={!values['is_bulk']}
          disabled={values['is_bulk']}
          min={0.001}
          step={0.001}
          tooltip={Tooltips['unit_measure']}
          value={unit_measure}
          handleInputChange={handleInputChange}
        />
      </div>
      <div className={styles.halfRow}>
        <SelectField
          label={i18n.t('modals.create_variant.unit_measure_name')}
          placeholder={i18n.t(
            'modals.create_variant.unit_measure_name_placeholder'
          )}
          required
          name='unit_measure_name'
          options={measureNames}
          tooltip={Tooltips['unit_measure_name']}
        />
      </div>
      <div className={styles.halfRow}>
        <LabeledInputField
          label={
            presExists
              ? `${i18n.t('modals.create_variant.unit_by')} ${pres}`
              : i18n.t('modals.create_variant.unit_by_presentation')
          }
          placeholder={i18n.t(
            'modals.create_variant.unit_multiplier_placeholder'
          )}
          type='number'
          datacy='units_multiplier'
          disabled={values['is_bulk']}
          min={1}
          tooltip={Tooltips['units_multiplier']}
          name='units_multiplier'
        />
      </div>
      <div className={styles.halfRow}>
        <CreatableSelectField
          label={i18n.t('modals.create_variant.unit_name')}
          placeholder={i18n.t('modals.create_variant.unit_name_placeholder')}
          options={measureNames} //VER ESTO
          name='single_unit_measure_name'
          disabled={values['is_bulk'] || values['units_multiplier'] <= 1}
          required={!values['is_bulk'] && !(values['units_multiplier'] <= 1)}
          tooltip={Tooltips['single_unit_measure_name']}
        />
      </div>
      <hr className={styles.fullRow} style={separatorStyles} />
      <div className={styles.halfRow}>
        <LabeledInputField
          label={i18n.t('modals.create_variant.weight')}
          placeholder={i18n.t('modals.create_variant.weight_placeholder')}
          type='number'
          name='weight'
          step={0.001}
          min={0.001}
          tooltip={Tooltips['weight']}
          datacy='weight'
          value={weight}
          required
          disabled
        />
      </div>
      <hr className={styles.fullRow} style={separatorStyles} />
      <div className={`${styles.fullRow} ${styles.inline}`}>
        <CheckboxField
          name='light_weight'
          label={i18n.t('modals.create_variant.is_lightweight')}
          tooltip={Tooltips['light_weight']}
        />
        <CheckboxField
          name='need_cooling'
          label={i18n.t('modals.create_variant.needs_cooling')}
          tooltip={Tooltips['need_cooling']}
        />
      </div>
      <div className={styles.fullRow} style={separatorStyles}>
        <Preview
          {...values}
          units_per_pack={units_per_pack}
          unit_measure={unit_measure}
          weight={weight}
        />
      </div>
    </div>
  );
}

EditVariant.propTypes = {
  products: PropTypes.arrayOf([]).isRequired,
  categories: PropTypes.arrayOf([]).isRequired,
  brands: PropTypes.arrayOf([]).isRequired,
  category: PropTypes.shape({}).isRequired,
  category_id: PropTypes.shape({}).isRequired,
  handleCategoryChange: PropTypes.func.isRequired,
  handleChildrenCategoryChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  measureNames: PropTypes.arrayOf([]).isRequired,
  unitTypes: PropTypes.arrayOf([]).isRequired,
  units_per_pack: PropTypes.number.isRequired,
  unit_measure: PropTypes.number.isRequired,
  weight: PropTypes.number.isRequired,
  values: PropTypes.shape({}).isRequired,
};

export default EditVariant;
