import makeActionCreator from 'make-action-creator';

import { NAMESPACE } from '../config/constants';

export const fetchAll = makeActionCreator(NAMESPACE + '/fetchAll');
export const fetchOne = makeActionCreator(NAMESPACE + '/fetchOne');
export const create = makeActionCreator(NAMESPACE + '/create');
export const selectNotification = makeActionCreator(
  NAMESPACE + '/selectNotification'
);
export const setAudienceType = makeActionCreator(NAMESPACE + '/setAudience');
export const fetchAudiences = makeActionCreator(NAMESPACE + '/fetchAudiences');
export const fetchReceivers = makeActionCreator(NAMESPACE + '/fetchReceivers');
