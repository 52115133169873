import React from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';

import i18n from '../../../../../app/configs/i18n';

class ModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };
  }

  componentDidMount() {
    const { comment } = this.props.data.payment;

    this.setState({
      formData: {
        comment,
      },
    });
  }

  onChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onConfirm = () => {
    const { payment, institution } = this.props.data;

    const body = {
      payment: {
        ...this.state.formData,
      },
    };

    this.props.onConfirm(body, institution, payment);
  };

  render() {
    const { formData } = this.state;
    const { payment } = this.props.data;

    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.edit_cupon_comment.success')}
        formData={formData}
        onChange={this.onChange}
        onConfirm={this.onConfirm}
        payment={payment}
      />
    );
  }
}

ModalContainer.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default ModalContainer;
