import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  HeaderConfigNav,
  TitleNav,
  TagA,
  TagUl,
  TagLiContainer,
  TagLi,
} from './statics/styles';
import './statics/styles.css';

const OptionsConfig = ({ cleanModules, user }) => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <HeaderConfigNav>
      <ul className='configNav'>
        <TagLiContainer>
          <TitleNav>{t('modals.setting.setting')}</TitleNav>

          <TagUl>
            {user.roles_mask > 1 ? (
              <TagLi onClick={() => history.push('/settings/networks')}>
                <TagA>{t('modals.setting.networks')}</TagA>
              </TagLi>
            ) : (
              <> </>
            )}

            <TagLi onClick={() => history.push('/settings/users')}>
              <TagA>{t('modals.setting.users')}</TagA>
            </TagLi>

            {user.roles_mask > 1 ? (
              <TagLi onClick={() => history.push('/settings/country')}>
                <TagA>{t('modals.setting.country')}</TagA>
              </TagLi>
            ) : (
              <> </>
            )}

            {/* <TagLi onClick={() => history.push('/settings/districts')}>
              <TagA>{t('modals.setting.districts')}</TagA>
            </TagLi> */}

            {/* 
            <TagLi>
              <TagA>{t('modals.setting.reports')}</TagA>
            </TagLi> */}

            <TagLi>
              <TagA
                href='https://datastudio.google.com/u/0/reporting/1EmFPJusJfHO2e7-yYPwxwhvApJEYwnkO'
                target='_blank'
              >
                {t('modals.setting.traceability')}
              </TagA>
            </TagLi>

            <TagLi>
              <TagA
                href='https://datastudio.google.com/u/0/reporting/1DWulzjq9c8uzdk1Opvfw0fqAhzTDwCf4'
                target='_blank'
              >
                {t('modals.setting.institutionAndDonors')}
              </TagA>
            </TagLi>

            <TagLi>
              <TagA
                href='https://datastudio.google.com/u/0/reporting/1EYjjmOHCDyGvSQzTi7bdnynX9RnA__p0'
                target='_blank'
              >
                {t('modals.setting.coupons')}
              </TagA>
            </TagLi>

            <TagLi>
              <TagA
                href='https://datastudio.google.com/u/0/reporting/1Wl9YT_8Kk43g6i0iJGHFpSlGasKzAep6'
                target='_blank'
              >
                {t('modals.setting.orders')}
                {/* <ArrowIconStyle src={arrowIcon} alt='arrow' /> */}
              </TagA>

              {/* <TagUlSubsesion>
                <TagLi>
                  <TagA>{t('modals.setting.institutions')}</TagA>
                </TagLi>
              </TagUlSubsesion> */}
            </TagLi>

            {/* <TagLi>
              <TagA>{t('modals.setting.customerCategory')}</TagA>
            </TagLi>

            <TagLi>
              <TagA>{t('modals.setting.typesOfBusiness')}</TagA>
            </TagLi>

            <TagLi>
              <TagA>{t('modals.setting.channels')}</TagA>
            </TagLi> */}
            <TagLi>
              <TagA
                onClick={() => {
                  localStorage.clear();
                  history.push('/login');
                  cleanModules();
                }}
              >
                {t('navbar.sign_off')}
              </TagA>
            </TagLi>
          </TagUl>
        </TagLiContainer>
      </ul>
    </HeaderConfigNav>
  );
};

OptionsConfig.propTypes = {
  cleanModules: PropTypes.func.isRequired,
  user: PropTypes.shape({ roles_mask: PropTypes.number.isRequired }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  cleanModules: () => dispatch({ type: 'CLEAN_MODULES' }),
});

export default connect(null, mapDispatchToProps)(OptionsConfig);
