import React from 'react'
import Table from './components/Table'

class TableContainer extends React.Component {
  render () {
    return <Table {...this.props} />
  }
}

export default TableContainer
