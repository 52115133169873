import i18n from 'app/configs/i18n';

export const CONTINUE_ANYWAY = 'continue_anyway';
export const CANCEL_IMPORT = 'cancel_import';

export const CANCEL_OPTION = {
  label: i18n.t('modals.csv_errors.cancel_option'),
  value: CANCEL_IMPORT,
};
export const CONTINUE_OPTION = {
  label: i18n.t('modals.csv_errors.continue_option'),
  value: CONTINUE_ANYWAY,
};
