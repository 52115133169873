import React from 'react';
import propTypes from 'prop-types';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker/TimePickerContainer';
import { useTranslation } from 'react-i18next';

const Step1 = (props) => {
  const { onChange, formData } = props;
  const { t } = useTranslation();

  return (
    <div className='view_trip_detail__body'>
      <div className='view_trip_detail__body__row'>
        <div className='view_trip_detail__body__item'>
          <label className='view_trip_detail__body__label'>
            {t('modals.create_trip.step_1.date')}
          </label>
          <DatePicker
            input={false}
            onChange={onChange}
            value={formData['scheduled_date']}
            name='scheduled_date'
            isFuture={true}
            timeFormat={false}
          />
        </div>
        <div className='view_trip_detail__body__item ml-56'>
          <label className='view_trip_detail__body__label'>
            {t('modals.create_trip.step_1.time')}
          </label>
          <TimePicker
            format='HH:mm'
            onChange={onChange}
            name='scheduled_time'
            value={formData['scheduled_time']}
          />
        </div>
      </div>
      <div className='view_trip_detail__body__row'>
        <div className='view_trip_detail__body__item grow'>
          <label className='view_trip_detail__body__label'>
            {t('modals.create_trip.step_1.notes')}
          </label>
          <textarea
            name='comments'
            className='form-control mb-20'
            onChange={onChange}
            value={formData['comments']}
            autoFocus={true}
          />
        </div>
      </div>
    </div>
  );
};

Step1.propTypes = {
  onChange: propTypes.func.isRequired,
  formData: propTypes.shape({}).isRequired,
};

export default Step1;
