import React, { Component } from 'react';
import styles from '../css/Grid.module.css';
import addProductStyles from '../css/AddNewProduct.module.css';
import propTypes from 'prop-types';
import {
  LabeledInputField,
  CheckboxField,
  CreatableSelectField,
} from './FormHelpers';
import AddNewProduct from './AddNewProduct';
import i18n from '../../../../../app/configs/i18n';

export class CreateCombo extends Component {
  get onlyNormalVariants() {
    const products = this.props.products;
    return products.filter(
      (product) => product.combo_product_items.length === 0
    );
  }

  render() {
    const separatorStyles = { margin: '20px 0px 20px 0px' };
    const {
      selectedProducts,
      addNewProduct,
      onAddProductChange,
      removeSelectedProduct,
    } = this.props;

    const products = this.onlyNormalVariants;

    const weight =
      Math.round(
        selectedProducts.reduce((acc, value) => {
          if (value.product && value.quantity) {
            const totalWeight = parseFloat(value.product.unit_measure);
            //const itemsPerPack = parseFloat(value.product.units_per_pack);
            const quantity = parseFloat(value.quantity);
            return (acc += totalWeight * quantity);
          } else {
            return acc;
          }
        }, 0) * 10
      ) / 10;

    //const dishes = Math.round(weight / 0.25 / 10) * 10;

    const numberOfProducts = selectedProducts.length;
    const textStyle = { fontFamily: 'Poppins-Bold', color: '#000' };
    return (
      <div className={styles.grid}>
        <div className={styles.fullRow}>
          <h4 style={textStyle}>
            {i18n.t('modals.create_combo.identification')}
          </h4>
        </div>
        <div className={styles.fullRow}>
          <LabeledInputField
            name='name'
            label={i18n.t('modals.create_combo.name')}
          />
        </div>
        <div className={styles.fullRow}>
          <LabeledInputField
            name='description'
            label={i18n.t('modals.create_combo.description')}
          />
        </div>
        <hr className={styles.fullRow} style={separatorStyles} />
        <div className={styles.fullRow}>
          <h4 style={textStyle}>{i18n.t('modals.create_combo.composition')}</h4>
        </div>
        {(selectedProducts.length && (
          <>
            <div className={`${styles.selectProductRow} ${styles.labelText}`}>
              {i18n.t('modals.create_combo.product_label')}:
            </div>
            <div
              className={`${styles.selectProductDisplayRow} ${styles.labelText}`}
            >
              {i18n.t('modals.create_combo.appName_label')}:
            </div>
            <div
              className={`${styles.selectProductQuantityRow} ${styles.labelText}`}
            >
              {i18n.t('modals.create_combo.present_label')}:
            </div>
          </>
        )) ||
          null}
        {selectedProducts.map((product, index) => {
          return (
            <AddNewProduct
              onAddProductChange={onAddProductChange}
              removeSelectedProduct={removeSelectedProduct}
              value={product}
              key={product.id}
              id={product.id}
              position={index}
              name={`product` + index}
              app_name={`app_name` + index}
              quantity={`quantity` + index}
              products={products}
            />
          );
        })}

        <div
          className={`${styles.fullRow} ${styles.inline}`}
          style={separatorStyles}
        >
          <button
            className={addProductStyles.addButton}
            onClick={(event) => addNewProduct(event)}
          >
            {'+ '}
            {i18n.t('modals.create_combo.add')}
          </button>
        </div>
        <hr className={styles.fullRow} style={separatorStyles} />
        <div className={styles.fullRow}>
          <h4 style={textStyle}>{i18n.t('modals.create_combo.summary')}</h4>
        </div>
        <div className={styles.numberOfProducts} style={textStyle}>
          {numberOfProducts} {i18n.t('modals.create_combo.numberOfProducts')} -{' '}
          {weight} Kg.
        </div>
        <div className={styles.dishes}>
          <LabeledInputField
            // disabled
            // value={dishes}
            type='number'
            name='dishes'
            label={i18n.t('modals.create_combo.dishes')}
            step={1}
            min={0}
          />
        </div>
        <div className={styles.presentation}>
          <CreatableSelectField
            // options={[{ label: i18n.t('modals.create_combo.box'), value: 'box' }]}
            name='presentation'
            placeholder={i18n.t('modals.create_combo.presentation')}
            label={i18n.t('modals.create_combo.presentation')}
          />
        </div>
        <div
          className={`${styles.fullRow} ${styles.inline}`}
          style={separatorStyles}
        >
          <CheckboxField
            name='is_dense'
            label={i18n.t('modals.create_combo.dense')}
          />
          <CheckboxField
            name='need_cooling'
            label={i18n.t('modals.create_combo.cooling')}
          />
        </div>
      </div>
    );
  }
}
CreateCombo.propTypes = {
  onAddProductChange: propTypes.func.isRequired,
  selectedProducts: propTypes.arrayOf({}).isRequired,
  addNewProduct: propTypes.func.isRequired,
  removeSelectedProduct: propTypes.func.isRequired,
};

export default CreateCombo;
