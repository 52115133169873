import React from 'react';
import propTypes from 'prop-types';

import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import TimePicker from 'components/TimePicker/TimePickerContainer';
import DatePicker from 'components/DatePicker';
import modalStyles from '../css/modal.module.css';
import errorStyles from '../css/errors.module.css';

import { Formik } from 'formik';
import { validationSchema } from '../validations';
import { initialValues, TRIP_PENDING } from '../helpers';
import cn from 'classnames';
import { TripAmountAudits } from '../../../../../components/TripAmountAudits/TripAmountAudits';
import { useTranslation } from 'react-i18next';

const EditTrip = (props) => {
  const { onCancel, onConfirm } = props;
  const { trip } = props;
  const tripPending = TRIP_PENDING(trip);
  const { t } = useTranslation();

  return (
    <Modal
      className='assign_shipper__modal'
      title={t('modals.edit_trip.title')}
      onCancel={onCancel}
      {...props}
    >
      <ModalBody className='modal-detail__body'>
        <Formik
          initialValues={initialValues(trip)}
          onSubmit={onConfirm}
          validationSchema={validationSchema(trip)}
          isInitialValid
        >
          {({ values, handleChange, handleSubmit, isValid, errors }) => (
            <>
              <div className='view_trip_detail__body'>
                <div className='row'>
                  <div className='form-group col-md-12'>
                    <label>{t('modals.edit_trip.driver_pay')}</label>
                    <input
                      onChange={handleChange('amount')}
                      value={values.amount}
                      type='number'
                      min={0}
                      className='form-control'
                      name='amount'
                      placeholder={t('modals.edit_trip.driver_pay_placeholder')}
                    />
                    <span className={errorStyles.error}>{errors.amount}</span>
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-12'>
                    <TripAmountAudits audits={trip.audits} />
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-12'>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <label>{t('modals.edit_trip.date')}</label>
                        <DatePicker
                          onChange={handleChange('scheduled_date')}
                          value={values.scheduled_date}
                          name='scheduled_date'
                          isFuture
                          required
                          timeFormat={false}
                          input={!tripPending}
                          inputProps={{ disabled: !tripPending }}
                        />
                      </div>
                      <span className={errorStyles.error}>
                        {errors.scheduled_date}
                      </span>
                      <div className='col-lg-6'>
                        <label>{t('modals.edit_trip.time')}</label>
                        <div>
                          <TimePicker
                            format='HH:mm'
                            required
                            onChange={handleChange('scheduled_time')}
                            name='scheduled_time'
                            value={values.scheduled_time}
                            disabled={!tripPending}
                          />
                        </div>
                        <span className={errorStyles.error}>
                          {errors.scheduled_time}
                        </span>
                        <div>
                          <label className='form-control-label' htmlFor='type'>
                            {t('modals.edit_trip.notes')}
                          </label>
                          <textarea
                            id='type'
                            name='comments'
                            className='form-control mb-20'
                            onChange={handleChange('comments')}
                            value={values.comments}
                            style={{ height: '120px' }}
                            disabled={!tripPending}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-12'>
                    <div className={modalStyles.buttonGroup}>
                      <button
                        className={cn([
                          modalStyles.saveButton,
                          'btn',
                          'btn-primary',
                        ])}
                        onClick={handleSubmit}
                        disabled={!isValid}
                      >
                        {t('modals.edit_trip.submit')}
                      </button>
                      <button className='btn btn-default' onClick={onCancel}>
                        {t('modals.edit_trip.close')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

EditTrip.propTypes = {
  onCancel: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
  formData: propTypes.shape({}).isRequired,
  trip: propTypes.shape({}).isRequired,
};

export default EditTrip;
