import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import '../css/styles.css';
import StockInformation from './StockInformation';
import StockCategoryLimits from './StockCategoryLimits';
import Wizard from './Wizard';
import { validateGroups } from '../validations';
import { StockAmount } from './FormHelpers';

class UpdateStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      item_checked: null,
    };
  }

  handleCheckbox = (item_checked) => {
    this.setState((prev) => ({ ...prev, item_checked: !item_checked }));
  };

  render() {
    const { onCancel, onConfirm, onChange } = this.props;
    const { initialData, groups_availability } = this.props;
    const { item_checked } = this.state;
    const { rescued } = initialData;
    const currentCheckValue = item_checked !== null ? item_checked : rescued;
    return (
      <Modal
        className='create-stock-modal'
        title={<Title step={1} />}
        onCancel={onCancel}
        {...this.props}
      >
        <ModalBody className='modal-detail__body'>
          <Wizard
            initialValues={initialData}
            onSubmit={onConfirm}
            item_checked={currentCheckValue}
            onCancel={onCancel}
          >
            <Wizard.Page>
              <StockInformation
                variants={[]}
                onChange={onChange}
                addresses={[]}
                initialData={initialData}
                handleCheckbox={this.handleCheckbox}
                item_checked={rescued}
              />
            </Wizard.Page>
            <Wizard.Page validate={validateGroups(groups_availability)}>
              <StockLimits groups_availability={groups_availability} />
            </Wizard.Page>
          </Wizard>
        </ModalBody>
      </Modal>
    );
  }
}

const Title = ({ step }) => (
  <div>
    <div className='create-stock-modal__steps'>Paso {step} de 2</div>
    <div className='create-stock-modal__title'>Editar Lote</div>
  </div>
);

const StockLimits = ({ groups_availability }) => (
  <>
    <StockAmount />
    <hr />
    <StockCategoryLimits groups_availability={groups_availability} />
  </>
);

UpdateStock.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPreviousStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  emptyCategories: PropTypes.bool.isRequired,
  variants: PropTypes.arrayOf([]).isRequired,
  status: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}).isRequired,
  onValidation: PropTypes.func.isRequired,
  addresses: PropTypes.arrayOf([]).isRequired,
  groups_availability: PropTypes.arrayOf([]).isRequired,
};

export default UpdateStock;
