import networks from './networks';

export const buildDropdownOptions = (_networks, onClick) =>
  _networks.reduce((res, network) => {
    res[network] = {
      label: networks[network],
      onClick: () => onClick(network),
    };

    return res;
  }, {});
