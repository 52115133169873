import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datetime';

import './styles.css';

const YearPicker = (props) => {
  const { selectedYear, onChange } = props;

  const thisYear = moment();
  const valid = (current) => current.isBefore(thisYear);

  return (
    <DatePicker
      onChange={onChange}
      value={selectedYear}
      isValidDate={valid}
      viewMode='years'
      dateFormat='YYYY'
      closeOnSelect
    />
  );
};

YearPicker.propTypes = {
  selectedYear: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

export default YearPicker;
