import moment from 'moment';

export const initialData = {
  variant: null,
  expires_on: moment(),
  address: null,
  external_reference: '',
  groups_availability: {},
  item_discount: 0,
  item_price: 0,
  quantity: 0,
  cost: 0,
};

export const adaptToOutputDto = (data, props) => {
  return {
    activity: {
      reason: data.reason.value.reason,
      activity: data.reason.value.activity,
      payment_method: data.payment_method.value,
      amount: data.amount,

      // TODO: fix this it works but it does not scale
      // The problem, when i open a modal i need to send the id as a prop, however, when the modal is subited it goes to
      // the sagas directly (detail -> modal -> sagas) i do not know how to skip the modal and pass the id directly to the sagas
      institution_id: props.institution_id,
      order_id: props.order_id,
    },
  };
};
