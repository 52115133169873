import institutions from './institutions';
import orders from './orders';
// import reports from './reports';
import shippers from './shippers';
import stock from './stock';
import trips from './trips';
import notification from './notifications';
import network from './networks';
import user from './users';
import districts from './districts';
import country from './country';
import permissions from './permissions';

export default {
  institutions,
  orders,
  // reports,
  shippers,
  stock,
  trips,
  notification,
  network,
  user,
  districts,
  country,
  permissions,
};
