import { ErrorMessage, Field } from 'formik';
import errorStyles from '../css/Error.module.css';
import propTypes from 'prop-types';
import { LabeledInput } from '../../../../../components/Input';
import { Select } from '../../../../../components/Select';
import React from 'react';

const getNested = (p, o) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : undefined), o);

export const UntouchedFieldError = ({ name }) => (
  <Field name={name}>
    {({ form: { errors } }) => {
      const error = getNested(name.split('.'), errors);
      return error ? (
        <span className={errorStyles.error}>{error}</span>
      ) : (
        <span />
      );
    }}
  </Field>
);

export const LabeledInputField = ({ name, ...rest }) => (
  <Field name={name}>
    {({ field, _ }) => (
      <div>
        <LabeledInput {...field} {...rest} />
        <ErrorMessage
          name={name}
          component='span'
          className={errorStyles.error}
        />
      </div>
    )}
  </Field>
);

export const SelectField = ({ name, onChangeWithFormValues, ...rest }) => (
  <Field name={name}>
    {({ field, form }) => (
      <div>
        <Select
          {...field}
          {...rest}
          onChange={(option) => {
            const value = option.target.value ? option.target.value : '';
            form.setFieldValue(name, value);
            const { values } = form.values;
            values[name] = value;
            if (onChangeWithFormValues) onChangeWithFormValues(values);
          }}
          onBlur={() => form.setFieldTouched(name, true)}
        />
        <ErrorMessage
          name={name}
          component='span'
          className={errorStyles.error}
        />
      </div>
    )}
  </Field>
);

UntouchedFieldError.propTypes = {
  name: propTypes.string.isRequired,
};

LabeledInputField.propTypes = {
  name: propTypes.string.isRequired,
};

SelectField.propTypes = {
  name: propTypes.string.isRequired,
  onChangeWithFormValues: propTypes.func.isRequired,
};
