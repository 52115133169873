import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ModalForm = (props) => {
  const { onChange, formData } = props;
  const { t } = useTranslation();

  return (
    <span>
      <div className='row'>
        <div className='form-group col-md-12'>
          <input
            onChange={onChange}
            value={formData.bankName}
            type='text'
            className='form-control'
            name='bankName'
            placeholder={t('modals.create_bank_account.bank_name_placeholder')}
            autoComplete='off'
          />
        </div>
      </div>
      <div className='form-group'>
        {/*  TODO: find a way of translate*/}
        <div className='radio-custom radio-default radio-inline'>
          <input
            onChange={onChange}
            type='radio'
            name='type'
            checked={formData.type === 'Cuenta corriente'}
            value='Cuenta corriente'
          />
          <label htmlFor='accountType'>
            {t('modals.create_bank_account.current_account')}
          </label>
        </div>
        <div className='radio-custom radio-default radio-inline'>
          <input
            onChange={onChange}
            type='radio'
            name='type'
            checked={formData.type === 'Caja de ahorro'}
            value='Caja de ahorro'
          />
          <label htmlFor='accountType'>
            {t('modals.create_bank_account.savings_bank')}
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='form-group col-md-12'>
          <input
            onChange={onChange}
            value={formData.number}
            type='text'
            className='form-control'
            name='number'
            placeholder={t('modals.create_bank_account.number_placeholder')}
            autoComplete='off'
          />
        </div>
      </div>
      <div className='row'>
        <div className='form-group col-md-12'>
          <input
            onChange={onChange}
            value={formData.cbu}
            type='text'
            className='form-control'
            name='cbu'
            placeholder={t('modals.create_bank_account.cbu_placeholder')}
            autoComplete='off'
          />
        </div>
      </div>
    </span>
  );
};

ModalForm.propTypes = {
  onChange: propTypes.func.isRequired,
  formData: propTypes.shape({}).isRequired,
};

export default ModalForm;
