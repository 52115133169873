import React, { Component } from 'react';
import propTypes from 'prop-types';

import TimetableSelect from './TimetableSelect';

class TimetableSelectContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };
  }

  onComponentWillMount() {
    this.setState({
      value: this.props.value,
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (this.props.hasOwnProperty('onChange')) {
      this.props.onChange({
        target: { name: this.props.name, value: e.target.value },
      });
    }
  };

  render() {
    return (
      <TimetableSelect value={this.state.value} onChange={this.onChange} />
    );
  }
}

TimetableSelectContainer.propTypes = {
  onChange: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
};

export default TimetableSelectContainer;
