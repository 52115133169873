import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import ModalFooter from 'components/ModalCenter/components/ModalFooter';
import Input from 'components/InputGroup';
import DatePicker from 'components/DatePicker';
import Button from 'components/ButtonWithSpinner/ButtonWithSpinnerContainer';

import './statics/styles.css';

import { useTranslation } from 'react-i18next';

const EditInstitutionUser = (props) => {
  const { onCancel, onConfirm, formData, onChange, status, payment } = props;
  const { t } = useTranslation();

  const Title = () => (
    <div>
      <div className='edit-comment-modal__subtitle'>
        {t('modals.edit_payment_status.cupon')}{' '}
        {!isNil(payment.id) ? payment.id.slice(0, 8) : '-'}{' '}
      </div>
      <div className='edit-comment-modal__title'>
        {t('modals.edit_payment_status.mark_as_payed')}
      </div>
    </div>
  );

  return (
    <Modal
      className='edit-comment-modal'
      title={<Title />}
      onCancel={() => onCancel()}
      {...props}
    >
      <ModalBody className='modal-detail__body'>
        <div className='edit-comment_detail__body'>
          <div className='edit-comment_detail__body__top'>
            <DatePicker
              name='paid_at'
              value={formData['paid_at']}
              className='value'
              placeholder='$'
              onChange={onChange}
              label={t('modals.edit_payment_status.paid_at')}
              required
            />
            <Input
              name='collected_amount'
              value={formData['collected_amount']}
              className='value'
              placeholder='$'
              label={t('modals.edit_payment_status.amount')}
              type='text'
              onChange={onChange}
            />
          </div>
          <div>
            <textarea
              onChange={onChange}
              value={formData.comment}
              className='form-control edit-comment_detail__body__textarea'
              name='comment'
              placeholder={t('modals.edit_payment_status.comment')}
              rows='4'
              maxLength='100'
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={onCancel}>
          {t('modals.edit_payment_status.close')}
        </Button>
        <Button
          primary
          status={status}
          disabled={!props.isValid}
          onClick={onConfirm}
        >
          {t('modals.edit_payment_status.submit')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

EditInstitutionUser.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}).isRequired,
  payment: PropTypes.shape({}).isRequired,
  status: PropTypes.string.isRequired,
};

export default EditInstitutionUser;
