import React from 'react'
import Tooltip from './components/Tooltip'

class TooltipContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      display: false
    }
  }

  onHover = () => {
    this.setState({
      display: true
    })
  }

  onBlur = () => {
    this.setState({
      display: false
    })
  }

  render () {
    return (
      <Tooltip
        {...this.props}
        onHover={this.onHover}
        onBlur={this.onBlur}
        display={this.state.display}
      />
    )
  }
}

export default TooltipContainer