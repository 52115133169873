import i18n from 'app/configs/i18n';

export default {
  afternoon: i18n.t('modals.view_order.afternoon'),
  morning: i18n.t('modals.view_order.afternoon'),
};

// TODO: should remove
export const variants = [
  {
    id: 27,
    description: null,
    unit_type: 'Bulto',
    units_multiplier: 1,
    units_per_pack: 1,
    unit_measure: '5.0',
    unit_measure_name: 'kg',
    external_name: 'ACELGA x 5 kg',
    weight: '5.0',
    price: '3.0',
    currency: 'ARS',
    product: {
      name: 'Acelga',
      id: 9,
    },
    stocks: [
      {
        external_reference: '0000134',
        id: 9,
        quantity: 20,
        limit: 5,
      },
      {
        external_reference: '0000134',
        id: 9,
        quantity: 20,
        limit: 5,
      },
    ],
  },
  {
    id: 28,
    description: null,
    unit_type: 'Bulto',
    units_multiplier: 1,
    units_per_pack: 1,
    unit_measure: '5.0',
    unit_measure_name: 'kg',
    external_name: 'ACELGA x 5 kg',
    weight: '5.0',
    price: '3.0',
    currency: 'ARS',
    product: {
      name: 'Acelga',
      id: 9,
    },
    stocks: [
      {
        external_reference: '0000134',
        id: 9,
        quantity: 20,
        limit: 5,
      },
    ],
  },
];
