import { connect } from 'react-redux';

import Modal from './components/ModalContainer';

import * as actions from '../../store/actions';
import * as shippersActions from 'modules/shippers/store/actions';
import * as tripActions from 'modules/trips/store/actions';

import * as ordersSelectors from 'modules/orders/store/selectors';
import * as shippersSelectors from 'modules/shippers/store/selectors';

import { withHooks } from 'hooks/withHooks';

const mapStateToProps = (state, props) => ({
  shippers: shippersSelectors.shippers(state),
  status: tripActions.createSimple.getStatus(state),
  deliveryPreference: ordersSelectors.getOrder(state)(props.data.orders[0].id)
    .delivery_preference,
  totalWeight: ordersSelectors.getTotalWeight(state)(
    props.data.orders.map((o) => o.id)
  ),
});

const mapDispatchToProps = (dispatch, props) => ({
  onMount: () => dispatch(shippersActions.fetchAll()),
  onConfirm: (trip) => {
    dispatch({ type: props.onConfirm, payload: { trip } });
  },
  onCancel: () => dispatch(actions.closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Modal));
