import makeActionCreator from 'make-action-creator';

import { NAMESPACE } from '../configs/constants';

export const fetchAll = makeActionCreator(`${NAMESPACE}/fetchAll`);
export const fetchOne = makeActionCreator(`${NAMESPACE}/fetchOne`);
export const update = makeActionCreator(`${NAMESPACE}/update`);
export const remove = makeActionCreator(`${NAMESPACE}/remove`);
export const create = makeActionCreator(`${NAMESPACE}/create`);

export const createBankAccount = makeActionCreator(
  `${NAMESPACE}/createBankAccount`
);
export const cancelCreateBankAccount = `${NAMESPACE}/cancelCreateBankAccount`.toUpperCase();

export const createVehicle = makeActionCreator(`${NAMESPACE}/createVehicle`);
export const cancelCreateVehicle = `${NAMESPACE}/cancelCreateVehicle`.toUpperCase();

export const updateBankAccount = makeActionCreator(
  `${NAMESPACE}/updateBankAccount`
);
export const cancelUpdateBankAccount = `${NAMESPACE}/cancelUpdateBankAccount`.toUpperCase();

export const updateVehicle = makeActionCreator(`${NAMESPACE}/updateVehicle`);
export const cancelUpdateVehicle = `${NAMESPACE}/cancelUpdateVehicle`.toUpperCase();
