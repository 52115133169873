export default {
  /** Bank accounts **/
  createBankAccount: 'CREATE_BANK_ACCOUNT',
  updateBankAccount: 'UPDATE_BANK_ACCOUNT',

  /** Vehicles **/
  createVehicle: 'CREATE_VEHICLE',
  updateVehicle: 'UPDATE_VEHICLE',

  /** Payments **/
  createPaymentCoupon: 'CREATE_PAYMENT_COUPON',

  /** Shippers **/
  createShipper: 'CREATE_SHIPPER',
  updateShipper: 'UPDATE_SHIPPER',
  assignShipper: 'ASSIGN_SHIPPER',

  /** Trips **/
  createTrip: 'CREATE_TRIP',
  viewTrip: 'VIEW_TRIP',
  editTrip: 'EDIT_TRIP',

  /** Stock **/
  viewStockProduct: 'VIEW_STOCK_PRODUCT',
  reviseStockProduct: 'REVISE_STOCK_PRODUCT',
  createVariant: 'CREATE_VARIANT',
  createStock: 'CREATE_STOCK',
  editStock: 'EDIT_STOCK',

  /** Orders **/
  viewOrder: 'VIEW_ORDER',
  deleteOrderPayment: 'DELETE_ORDER_PAYMENT',
  createOrderPayment: 'CREATE_ORDER_PAYMENT',

  /** Instititions **/
  createInstitutionUser: 'CREATE_INSTITUTION_USER',
  editInstitutionUser: 'EDIT_INSTITUTION_USER',
  editCouponComment: 'EDIT_COUPON_COMMENT',
  editPaymentStatus: 'EDIT_PAYMENT_STATUS',

  uploadCsv: 'UPLOAD_CSV',
  csvErrors: 'CSV_ERRORS',
  updateStock: 'UPDATE_STOCK',

  confirmationModal: 'CONFIRMATION_MODAL',
  createPayment: 'CREATE_PAYMENT',

  editOrderDate: 'EDIT_ORDER_DATE',
  editOrder: 'EDIT_ORDER',

  addOfferName: 'ADD_OFFER_NAME',

  //** Combos **//
  createCombo: 'createCombo',
  editCombo: 'editCombo',
  errorsImports: 'errorsImports',

  //NETWORKS
  CreateNetwork: 'CreateNetwork',
  uploadCsvOrder: 'UPLOAD_CSV_ORDER',
  csvErrorsOrder: 'CSV_ERRORS_ORDER',
};
