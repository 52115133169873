import React from 'react';
import propTypes from 'prop-types';
import Spinner from '../../../../components/Spinner';
import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import ModalFooter from 'components/ModalCenter/components/ModalFooter';
import Button from 'components/Button';
import {
  InputStyled,
  LabelStyled,
} from '../../../orders/components/List/statics/styles';
import styles from './UploadCsv.module.css';

import i18n from '../../../../app/configs/i18n';

class ImportCsv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      option: '',
    };
  }

  _onFileChange = (file) => {
    this.setState({ file });
  };

  _onOptionChange = (option) => {
    this.setState({ option: option.value });
  };

  render() {
    const Title = () => (
      <div>
        <div className={styles.modal__title}> {i18n.t('import_excel')}</div>
      </div>
    );

    const { orderCsvDownload } = this.props;

    return (
      <Modal
        className='modal'
        title={<Title />}
        onCancel={() => this.props.onCancel()}
        {...this.props}
      >
        <ModalBody className={styles.gridContainer}>
          <div className={`${styles.nilusText} ${styles.subtitle}`}>
            {i18n.t('upload_order')}
          </div>
          <LabelStyled>
            {this.props.isExcelLoading === 'pending' && (
              <Spinner
                color='#ffffff'
                size='20px'
                marginRight='10'
                height='20'
              />
            )}
            {i18n.t('orders.list.import')}
            <InputStyled
              type='file'
              name='file'
              onChange={(e) => {
                return this.props.uploadExcel(e.target.files[0]);
              }}
            />
          </LabelStyled>
          <div className={styles.sheetButton}>
            <Button
              outline
              onClick={orderCsvDownload}
              style={{ height: '30px' }}
            >
              {i18n.t('modals.upload_csv.download_template')}
            </Button>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={this.props.onCancel}>
            {i18n.t('modals.upload_csv.close')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

// const selectStyles = {
//   option: (provided) => {
//     const fontWeight = 'bold';
//     const fontFamily = 'Poppins';
//     return { ...provided, fontWeight, fontFamily };
//   },
//   singleValue: (provided) => {
//     const fontWeight = 'bold';
//     const fontFamily = 'Poppins';
//     return { ...provided, fontWeight, fontFamily };
//   },
//   placeholder: (provided) => {
//     const fontFamily = 'Poppins';
//     return { ...provided, fontFamily };
//   },
// };

ImportCsv.propTypes = {
  onCancel: propTypes.func.isRequired,
};

export default ImportCsv;
