import React from 'react';
import propTypes from 'prop-types';
import Modal from './components/Modal';
import i18n from '../../../../app/configs/i18n';
import * as actions from '../../store/actions';
import * as institutionsActions from 'modules/institutions/store/actions';
import * as institutionsSelectors from 'modules/institutions/store/selectors';
import * as stockActions from 'modules/stock/store/actions';
import * as stockSelectors from 'modules/stock/store/selectors';
import { connect } from 'react-redux';
import { withHooks } from 'hooks/withHooks';
import { formatForSelect } from 'utils';

class EditOrderConatiner extends React.Component {
  state = {
    options: [],
    origin: undefined,
    hideTable: false,
  };

  UNSAFE_componentWillReceiveProps(props) {
    let options = [];

    if (props.offerableStock) {
      options = props.offerableStock.map((s) => {
        return {
          id: s.value.id,
          label: s.label,
          value: s.value.id,
          available: s.value.quantity,
          weight: s.value.weight,
          price: s.value.item_price,
          currency: s.value.currency,
          variant_id: s.value.variant_id,
          address_id: s.value.address_id,
          discount: s.value.item_discount,
        };
      });
    }

    this.setState({
      ...this.state,
      options,
    });
  }

  setOrigin = (origin) => {
    this.setState({ origin: origin });
  };

  setHideTable = async (hide) => {
    await this.setState({ hideTable: hide });
  };

  onConfirm = (data) => {
    const option = {
      currentSelection: this.props.offerableStock.find(
        (option) => option.value.id === data.currentSelection.id
      ).value,
    };
    option.quantity = data.quantity;
    this.props.data.callback(option);
    this.props.onCancel();
  };

  render() {
    return (
      <Modal
        {...this.props}
        state={this.state}
        modalSuccess={i18n.t('modals.edit_order.success')}
        setOrigin={(origin) => this.setOrigin(origin)}
        setHideTable={(hide) => this.setHideTable(hide)}
        onConfirm={this.onConfirm}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  institutions: formatForSelect(
    institutionsSelectors.institutions(state),
    'name'
  ),
  offerableStock: stockSelectors.offerableStock(state),
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch(institutionsActions.fetchAll());
    dispatch(stockActions.fetchOfferableStock());
  },
  onCancel: () => dispatch(actions.closeModal()),
});

EditOrderConatiner.propTypes = {
  onConfirm: propTypes.func.isRequired,
  offerableStock: propTypes.arrayOf([]).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(EditOrderConatiner));
