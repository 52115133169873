import React from 'react';
import propTypes from 'prop-types';
import styles from './RadioButton.module.css';

const RadioButton = (props) => {
  const { onChange, text, value, checked, textStyles } = props;

  const handleChange = () => {
    onChange(value);
  };

  return (
    <div className='pretty p-default p-round'>
      <input
        type='radio'
        checked={checked}
        onChange={handleChange.bind(this)}
      />
      <div className='state p-info-o'>
        <label className={styles.text} style={textStyles}>
          {text}
        </label>
      </div>
    </div>
  );
};

RadioButton.propTypes = {
  onChange: propTypes.func.isRequired,
  text: propTypes.string.isRequired,
  checked: propTypes.bool.isRequired,
  textStyles: propTypes.string.isRequired,
  value: propTypes.any,
};

export default RadioButton;
