import PropTypes from 'prop-types';

export const typesRoute = PropTypes.shape({
  actions: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
});

export default {
  user: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.shape({
      networks: PropTypes.arrayOf(PropTypes.string),
    }),
  ]),
  activeNetwork: PropTypes.string,
  onChangeNetwork: PropTypes.func,
  routes: PropTypes.shape({
    index: typesRoute,
    institutions: typesRoute,
    notifications: typesRoute,
    orders: typesRoute,
    reports: typesRoute,
    shippers: typesRoute,
    stock: typesRoute,
    trips: typesRoute,
    settings: typesRoute,
  }),
};
