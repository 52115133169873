import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import errorStyles from '../css/Error.module.css';
import { LabeledInput, Input } from '../../../../../components/Input';
import { Select } from '../../../../../components/Select';
import React from 'react';
import DatePicker from 'components/DatePicker';
import { useTranslation } from 'react-i18next';

const getNested = (p, o) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : undefined), o);

export const UntouchedFieldError = ({ name }) => (
  <Field name={name}>
    {({ form: { errors } }) => {
      const error = getNested(name.split('.'), errors);
      return error ? (
        <span className={errorStyles.error}>{error}</span>
      ) : (
        <span />
      );
    }}
  </Field>
);

export const StockAmount = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Field name='quantity'>
        {({ field }) => (
          <p className='categories__poppins-bold create-stock__categories-left-title'>
            {field.value
              ? `${field.value} ${t('modals.create_stock.units')}`
              : t('modals.create_stock.no_units')}
          </p>
        )}
      </Field>
    </div>
  );
};

export const InputField = ({ name, ...rest }) => (
  <Field name={name}>
    {({ field }) => (
      <div>
        <Input {...field} {...rest} />
        <ErrorMessage
          name={name}
          component='span'
          className={errorStyles.error}
        />
      </div>
    )}
  </Field>
);

export const LabeledInputField = ({ name, ...rest }) => {
  return (
    <Field name={name}>
      {({ field }) => {
        return (
          <div>
            <LabeledInput {...field} {...rest} />
            <ErrorMessage
              name={name}
              component='span'
              className={errorStyles.error}
            />
          </div>
        );
      }}
    </Field>
  );
};

export const SelectField = ({ name, onSelectedProductchange, ...rest }) => (
  <Field name={name}>
    {({ field, form }) => (
      <div>
        <Select
          {...field}
          {...rest}
          onChange={(option) => {
            if (name === 'variant') {
              const productIsRegular =
                !option.target.value ||
                !option.target.value.combo_product_items.length
                  ? true
                  : false;
              onSelectedProductchange(productIsRegular, option.target.value);
            }
            const value = option.target.value ? option.target.value : '';
            form.setFieldValue(name, value);
          }}
          onBlur={() => form.setFieldTouched(name, true)}
        />
        <ErrorMessage
          name={name}
          component='span'
          className={errorStyles.error}
        />
      </div>
    )}
  </Field>
);

export const DatePickerField = ({ name, ...rest }) => {
  return (
    <Field name={name}>
      {({ field }) => (
        <div>
          <DatePicker {...field} {...rest} />
          <ErrorMessage
            name={name}
            component='span'
            className={errorStyles.error}
          />
        </div>
      )}
    </Field>
  );
};

UntouchedFieldError.propTypes = {
  name: PropTypes.string.isRequired,
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
};

LabeledInputField.propTypes = {
  name: PropTypes.string.isRequired,
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  onSelectedProductchange: PropTypes.func.isRequired,
};

DatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
};
