import { lazy } from 'react';
import reducer from './store/reducers';
import sagas from './store/sagas';
import * as constants from './config/constants';
import * as selectors from './store/selectors';

export default {
  constants,
  reducer,
  sagas,
  scenes: {
    list: lazy(() => import('./scenes/List')),
    create: lazy(() => import('./scenes/CreateOffer')),
    offerDetail: lazy(() => import('./scenes/OfferDetail')),
  },
  selectors,
};
