import { toast } from 'react-toastify'
import { delay } from 'redux-saga'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import * as actions from './actions'
import * as selectors from './selectors'

// Workers
export function * onOpenAlertWorker () {
  const message = yield select(selectors.message)
  const type = yield select(selectors.type)
  const delayTime = yield select(selectors.delay)

  if (!type) {
    toast(message, {
      position: 'top-right'
    })
  } else {
    toast[type](message, {
      position: 'top-right'
    })
  }

  if (delay) {
    yield call(delay, delayTime)
  } else {
    yield call(delay, 5000)
  }

  yield put({ type: actions.closeAlert.type })
}

// Watchers
export function * onOpenAlertWater () {
  yield takeEvery(actions.openAlert.type, onOpenAlertWorker)
}

export default [
  onOpenAlertWater
]
