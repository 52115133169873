import moment from 'moment';
import { createSelector } from 'reselect';
import { pick } from 'ramda';

import { NAMESPACE } from '../config/constants';

moment.locale('es');

export const trips = (state) => state[NAMESPACE].items;
export const status = (state) => state[NAMESPACE].status;
export const error = (state) => state[NAMESPACE].error;

export const isFetching = createSelector(
  status,
  (status) => status === 'pending'
);

export const tripWithFilters = createSelector(trips, (list) => (date) => {
  const tripsIds = Object.keys(list).filter((key) => {
    const pickupTime = list[key].steps.filter(
      (step) => step.action === 'pickup'
    )[0];

    return moment(pickupTime.schedule.start).isSame(date, 'day');
  });

  return pick(tripsIds, list);
});
