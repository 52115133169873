import { connect } from 'react-redux';

import * as modalActions from '../../store/actions';

import ModalContainer from './ModalContainer';
import * as orderActions from '../../../orders/store/actions';
import * as orderSelectors from '../../../orders/store/selectors';

const mapStateToProps = (state) => ({
  isExcelLoading: orderSelectors.statusExcel(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  orderCsvDownload: () => dispatch(orderActions.orderCsvDownload()),
  //onConfirm: (payload) => dispatch({ type: props.onConfirm, payload }),
  uploadExcel: (file) => dispatch(orderActions.uploadExcel({ file })),
  onCancel: () => {
    dispatch({ type: props.onCancel });
    dispatch(modalActions.closeModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
