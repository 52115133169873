import makeActionCreator from 'make-action-creator';

import { NAMESPACE } from '../config/constants';

export const fetchAllNetworks = makeActionCreator(
  NAMESPACE + '/fetchAllNetworks'
);

export const addNetwork = makeActionCreator(NAMESPACE + '/addNetwork');

export const deleteNetwork = makeActionCreator(NAMESPACE + '/deleteNetwork');

export const updateNetwork = makeActionCreator(NAMESPACE + '/updateNetwork');

export const fetchCountry = makeActionCreator(NAMESPACE + 'fetchCountry');
