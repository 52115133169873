import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from './components/Modal';

import * as modalActions from '../../store/actions';
import * as shippersSelectors from 'modules/shippers/store/selectors';
import i18n from '../../../../app/configs/i18n';

class UpdateShipperContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { shipper } = this.props;

    this.setState({
      formData: {
        ...this.state.formData,
        ...shipper,
      },
    });
  }

  onChange(e) {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  }
  onToggle = (field, next) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: next,
      },
    });
  };

  render() {
    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.edit_driver.success')}
        formData={this.state.formData}
        onChange={this.onChange}
        onToggle={this.onToggle}
      />
    );
  }
}

UpdateShipperContainer.propTypes = {
  shipper: propTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  shipper: shippersSelectors.getShipperFromModal(state),
  status: shippersSelectors.status(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onConfirm: (payload) => dispatch({ type: props.onConfirm, payload }),
  onCancel: () => {
    dispatch({ type: props.onCancel });
    dispatch(modalActions.closeModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateShipperContainer);
