import makeActionCreator from 'make-action-creator';

import { NAMESPACE } from '../config/constants';

export const fetchAllCountries = makeActionCreator(
  NAMESPACE + '/fetchAllCountries'
);

export const addCountry = makeActionCreator(NAMESPACE + '/addCountry');

export const updateCountry = makeActionCreator(NAMESPACE + '/updateCountry');

export const deleteCountry = makeActionCreator(NAMESPACE + '/deleteCountry');
