import { pick, isEmpty, filter } from 'ramda'
import { buildScheduledAt } from 'utils/date'

export const buildTrip = (trip, orders) => ({
  ...pick(['comments'], trip),
  amount: trip.trip_amount,
  shipper_id: trip.shipper.id,
  orders_ids: orders.map(order => order.id),
  pickup_schedule: { // TODO-API: should replace with only one time: pickup date/time
    start: buildScheduledAt(trip.scheduled_date, trip.scheduled_time),
    end: buildScheduledAt(trip.scheduled_date, trip.scheduled_time)
  },
  dropoff_schedule: {
    start: buildScheduledAt(trip.scheduled_date, trip.scheduled_time),
    end: buildScheduledAt(trip.scheduled_date, trip.scheduled_time)
  }
})

/**
 * Takes a list of shippers and returns shippers who have vehicles with refrigeration
 * @param shippers
 * @returns {*}
 */
export const filteredShippers = (shippers, vehicles) => shippers
  .filter(shipper => !isEmpty(filteredShipper(shipper, vehicles))
  )

export const filteredShipper = (shipper, vehicles) => 
  filter(vehicle => vehicle.features.refrigerated)(pick(shipper.vehicles, vehicles))

/**
 * Takes a list of packages and returns weight of all packages
 * @param packages
 * @returns {number}
 */
export const totalWeight = packages => Object.keys(packages)
  .reduce((res, key) => {
    const weight = packages[key]
      .reduce((sum, prev) => sum + prev['weight'], 0)

    return res + weight
  }, 0)

export const filterPickupSteps = list => list
  .filter(item => item.action === 'pickup')