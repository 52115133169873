import styles from './Label.module.css';
import cn from 'classnames';
import React from 'react';
import { isEmpty, isNil } from 'ramda';
import propTypes from 'prop-types';
import Spinner from '../Spinner';

export const Label = (props) => {
  const { label, value, required, tooltip, loading } = props;
  const isInvalid = (isNil(value) || isEmpty(value)) && required;
  return (
    <div className={styles.container}>
      <label
        className={cn(styles.label, { [styles.invalid]: isInvalid })}
        htmlFor='type'
      >
        {label}: {required ? '*' : ''}
      </label>
      {tooltip && <i className='far fa-question-circle' />}
      {loading && <Spinner />}
    </div>
  );
};

Label.propTypes = {
  label: propTypes.string.isRequired,
  value: propTypes.shape({}).isRequired,
  required: propTypes.bool.isRequired,
  tooltip: propTypes.string.isRequired,
};
