import { delay } from 'redux-saga';
import { call, put, select, takeEvery, race, take } from 'redux-saga/effects';

import * as actions from './actions';
import * as shippersActions from '../../shippers/store/actions';
import * as vehiclesActions from '../../vehicles/store/actions';
import * as bankAccountsActions from '../../bankAccounts/store/actions';
import * as tripActions from '../../trips/store/actions';
import * as institutionsActions from '../../institutions/store/actions';
import * as stockActions from '../../stock/store/actions';

import * as tripSelectors from '../../trips/store/selectors';
import * as shippersSelectors from '../../shippers/store/selectors';

/**
 * This is a series of mirror sagas
 * They listen for a saga that is handled somewhere else and
 * the perform actions that are needed only by the modal.
 * This way we don't have to include logic regarding the modals
 * in the sagas where the requests are being handled.
 */

export function* onConfirmation(operationName, confirmationMessage) {
  yield put(
    actions.openModal({
      type: 'confirmationModal',
      data: {
        message: confirmationMessage,
        operationName: operationName,
      },
    })
  );

  const { yes, no } = yield race({
    yes: take(actions.onConfirm.type),
    no: take(actions.onCancel.type),
  });
  if (no) {
    yield put(actions.closeModal());
  }
  return yes;
}

// Workers

export function* onConfirmFailureShipperWorker() {
  const error = yield select(shippersSelectors.error);
  yield put(actions.onConfirm.failure({ error: error.data.error[0] }));

  yield call(delay, 3000);
  yield put({ type: actions.cleanError.type });
}

export function* onConfirmCreateShipperWorker() {
  yield put(actions.onConfirm.success());
  yield put({ type: shippersActions.fetchAll.type });

  yield call(delay, 3000);
  yield put({ type: actions.cleanError.type });
}

export function* onConfirmUpdateShipperWorker() {
  yield put(actions.onConfirm.success());
  yield put({ type: shippersActions.fetchAll.type });
  yield put(actions.closeModal());
}

export function* onFailureUpdateTripWorker() {
  const error = yield select(tripSelectors.error);
  yield put(actions.onConfirm.failure({ error: error.data.error[0] }));

  yield call(delay, 3000);
  yield put({ type: actions.cleanError.type });
}

export function* onSuccessGenericWorker() {
  yield put(actions.onConfirm.success());

  yield call(delay, 3000);
  yield put(actions.closeModal());
}

export function* onFailureGenericWorker() {
  yield put(actions.onConfirm.failure({ error: '' }));

  yield call(delay, 3000);
  yield put({ type: actions.cleanError.type });
}

// Watchers
export function* onConfirmCreateSuccessShipperWatcher() {
  yield takeEvery(
    shippersActions.createBankAccount.SUCCESS,
    onConfirmCreateShipperWorker
  );
  yield takeEvery(
    shippersActions.createVehicle.SUCCESS,
    onConfirmCreateShipperWorker
  );
}

export function* onConfirmUpdateSuccessShipperWatcher() {
  yield takeEvery(shippersActions.update.SUCCESS, onConfirmUpdateShipperWorker);
  yield takeEvery(vehiclesActions.update.SUCCESS, onConfirmUpdateShipperWorker);
  yield takeEvery(
    bankAccountsActions.update.SUCCESS,
    onConfirmUpdateShipperWorker
  );
}

export function* onConfirmFailureShipperWatcher() {
  yield takeEvery(
    shippersActions.createBankAccount.FAILURE,
    onConfirmFailureShipperWorker
  );
  yield takeEvery(
    shippersActions.createVehicle.FAILURE,
    onConfirmFailureShipperWorker
  );
  yield takeEvery(
    shippersActions.update.FAILURE,
    onConfirmFailureShipperWorker
  );
  yield takeEvery(
    vehiclesActions.update.FAILURE,
    onConfirmFailureShipperWorker
  );
  yield takeEvery(
    bankAccountsActions.update.FAILURE,
    onConfirmFailureShipperWorker
  );
}

export function* onFailureUpdateTripWatcher() {
  yield takeEvery(tripActions.update.FAILURE, onFailureUpdateTripWorker);
}

export function* onSuccessGenericWatcher() {
  yield takeEvery(tripActions.update.SUCCESS, onSuccessGenericWorker);
  yield takeEvery(shippersActions.create.SUCCESS, onSuccessGenericWorker);
  yield takeEvery(
    institutionsActions.createUser.SUCCESS,
    onSuccessGenericWorker
  );
  yield takeEvery(stockActions.reviseVariant.SUCCESS, onSuccessGenericWorker);
  yield takeEvery(stockActions.editVariant.SUCCESS, onSuccessGenericWorker);
}

export function* onFailureGenericWatcher() {
  yield takeEvery(tripActions.update.FAILURE, onFailureGenericWorker);
  yield takeEvery(shippersActions.create.FAILURE, onFailureGenericWorker);
  yield takeEvery(
    institutionsActions.createUser.FAILURE,
    onFailureGenericWorker
  );
  yield takeEvery(stockActions.reviseVariant.FAILURE, onFailureGenericWorker);
  yield takeEvery(stockActions.editVariant.FAILURE, onFailureGenericWorker);
}

export default [
  onConfirmCreateSuccessShipperWatcher,
  onConfirmUpdateSuccessShipperWatcher,
  onConfirmFailureShipperWatcher,
  onFailureUpdateTripWatcher,
  onSuccessGenericWatcher,
  onFailureGenericWatcher,
];
