import { handleActions } from 'redux-actions';
import { mergeDeepRight } from 'ramda';

import * as actions from './actions';
import * as permissionsActions from 'modules/permissions/store/actions';

const initialState = {
  status: 'init',
  error: '',
  items: {},
  order: {},
  statusExcel: false,
};

const reducer = handleActions(
  {
    [actions.uploadExcel.START]: (state) => ({
      ...state,
      statusExcel: 'pending',
    }),
    [actions.uploadExcel.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
      statusExcel: 'success',
    }),
    [actions.uploadExcel.FAILURE]: (state) => ({
      ...state,
      statusExcel: 'failure',
    }),

    [actions.create.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.create.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
      },
      order: action.payload.order,
    }),

    [actions.create.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.createLogistics.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.createLogistics.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        ...action.payload.order,
      },
    }),

    [actions.createLogistics.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.update.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.update.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: mergeDeepRight(state.items, action.payload.orders),
    }),

    [actions.update.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchAll.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchAll.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...action.payload.orders,
      },
    }),

    [actions.fetchAll.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchOne.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchOne.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      order: action.payload.order,
    }),

    [actions.fetchOne.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
      order: {},
    }),
    [actions.fetchOrdersOfDay.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchOrdersOfDay.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      order: action.payload.orders,
      items: {
        ...action.payload.orders,
      },
    }),

    [actions.fetchOrdersOfDay.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
      order: {},
    }),

    [actions.exportOrders.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.exportOrders.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),

    [actions.exportOrders.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.getCurrentStock.START]: (state, action) => ({
      ...state,
    }),

    [actions.getCurrentStock.SUCCESS]: (state, action) => ({
      ...state,
      currentStock: action.payload.currentStock,
    }),

    [actions.cleanCurrentOrder.type]: (state, action) => ({
      ...state,
      order: {},
    }),

    [actions.deleteOrder.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.deleteOrder.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),

    [actions.deleteOrder.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.editDate.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.editDate.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        [action.payload.order.id]: {
          ...action.payload.order,
        },
      },
      order: {
        ...action.payload.order,
      },
    }),

    [actions.editDate.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.deleteOrderPayment.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.deleteOrderPayment.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),

    [actions.deleteOrderPayment.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.createOrderPayment.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.createOrderPayment.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),

    [actions.createOrderPayment.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.editOrder.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.editOrder.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        ...action.payload.order,
      },
    }),

    [actions.editOrder.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.orderCsvDownload.START]: (state) => ({
      ...state,
      status: 'start',
    }),

    [actions.orderCsvDownload.FAILURE]: (state) => ({
      ...state,
      status: 'failure',
    }),

    [actions.orderCsvDownload.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),

    [actions.importTemplateOrder.START]: (state) => ({
      ...state,
      status: 'start',
    }),

    [actions.importTemplateOrder.FAILURE]: (state) => ({
      ...state,
      status: 'failure',
    }),

    [actions.importTemplateOrder.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
