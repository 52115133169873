import React from 'react';
import propTypes from 'prop-types';

import DatePicker from 'components/DatePicker';
import SwitchInput from '../../../../../components/SwitchInput';
import { useTranslation } from 'react-i18next';

const ModalForm = (props) => {
  const { onChange, formData, onToggle } = props;
  const { t } = useTranslation();

  return (
    <span>
      <div className='row'>
        <div className='form-group col-md-6'>
          <label htmlFor='model'>{t('modals.edit_driver.first_name')}</label>
          <input
            onChange={onChange}
            value={formData.first_name}
            type='text'
            className='form-control'
            name='first_name'
            placeholder={t('modals.edit_driver.first_name_placeholder')}
            autoComplete='off'
          />
        </div>

        <div className='form-group col-md-6'>
          <label htmlFor='model'>{t('modals.edit_driver.last_name')}</label>
          <input
            onChange={onChange}
            value={formData.last_name}
            type='text'
            className='form-control'
            name='last_name'
            placeholder={t('modals.edit_driver.last_name_placeholder')}
            autoComplete='off'
          />
        </div>

        <div className='form-group col-md-6'>
          <label htmlFor='model'>{t('modals.edit_driver.phone_number')}</label>
          <input
            onChange={onChange}
            value={formData.phone_num}
            type='text'
            className='form-control'
            name='phone_num'
            placeholder={t('modals.edit_driver.phone_number_placeholder')}
            autoComplete='off'
          />
        </div>
        <div className='form-group col-md-6'>
          <label htmlFor='brand'>{t('modals.edit_driver.email')}</label>
          <input
            onChange={onChange}
            value={formData.email}
            type='text'
            className='form-control'
            name='email'
            placeholder={t('modals.edit_driver.email_placeholder')}
            autoComplete='off'
          />
        </div>
      </div>
      <div className='row'>
        <div className='form-group col-md-6'>
          <label htmlFor='year'>{t('modals.edit_driver.birth_date')}</label>
          <DatePicker
            name='birth_date'
            value={formData.birth_date}
            isFuture={false}
            onChange={onChange}
            dateFormat={t('date_format')}
            timeFormat={false}
          />
        </div>
        <div className='form-group col-md-6'>
          <label htmlFor='year'>{t('modals.edit_driver.gender')}</label>
          <input
            onChange={onChange}
            value={formData.gender}
            type='text'
            className='form-control'
            name='gender'
            placeholder=''
            autoComplete='off'
          />
        </div>
      </div>

      <div className='row'>
        <div className='form-group col-md-6'>
          <label htmlFor='password'>{t('modals.edit_driver.password')}</label>
          <input
            onChange={onChange}
            value={formData.password}
            type='text'
            className='form-control'
            name='password'
            placeholder={t('modals.edit_driver.password_placeholder')}
            autoComplete='off'
          />
        </div>
        <div className='form-group col-md-6'>
          <label htmlFor='password'>
            {t('modals.edit_driver.new_password')}
          </label>
          <input
            onChange={onChange}
            value={formData.password_confirmation}
            type='text'
            className='form-control'
            name='password_confirmation'
            placeholder={t('modals.edit_driver.new_password_placeholder')}
            autoComplete='off'
          />
        </div>
      </div>

      <div className='row'>
        {formData.verified !== undefined && (
          <div className='form-group col-md-6'>
            <p>
              {formData.verified
                ? t('modals.edit_driver.disable')
                : t('modals.edit_driver.enable')}
              <SwitchInput
                className='verification-switch float-right'
                checked={formData.verified}
                field='verified'
                onToggle={(field, next) => onToggle(field, next)}
              />
            </p>
          </div>
        )}
      </div>
    </span>
  );
};

ModalForm.propTypes = {
  onChange: propTypes.func.isRequired,
  onToggle: propTypes.func.isRequired,
  formData: propTypes.shape({}).isRequired,
};

export default ModalForm;
