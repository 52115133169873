import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';

import './statics/styles.css';

const SelectList = (props) => {
  const { className, inputs, onCheck } = props;

  return (
    <div className={cn('-select-list', className)}>
      {inputs.map((item, i) => (
        <label key={i} className='custom-checkbox'>
          {item.label}
          <input
            type='checkbox'
            name={item.name}
            className={cn('-input-form__input')}
            onChange={onCheck}
            checked={item.checked}
            value={item.value}
          />
          <span className='checkmark'></span>
        </label>
      ))}
    </div>
  );
};

SelectList.propTypes = {
  className: propTypes.string.isRequired,
  onCheck: propTypes.func.isRequired,
  inputs: propTypes.arrayOf([]).isRequired,
};

export default SelectList;
