import React from 'react';
import propTypes from 'prop-types';
import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import styles from '../css/Modal.module.css';
import Button from '../../../../../components/Button';
import CreateCombo from './CreateCombo';
import { createValidationSchema } from '../validation';
import { initialValues, calculateWeight } from '../helpers';
import ButtonWithSpinner from '../../../../../components/ButtonWithSpinner/ButtonWithSpinner';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../app/configs/i18n';

class CreateComboContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProducts: [],
    };
  }

  addNewProduct = (event) => {
    event.preventDefault();
    const selectedProducts = [
      ...this.state.selectedProducts,
      { id: Math.random() * Date.now() },
    ];
    this.setState({ selectedProducts });
  };

  setWeight = (weight) => {
    this.setState({ weight, dishes: weight / 0.25 });
  };

  onAddProductChange = ({ id, showDelete, ...rest }) => {
    const selectedProducts = [...this.state.selectedProducts];
    const selectedProductsUpdate = selectedProducts.map((product) => {
      if (product.id === id) {
        return { id, ...rest };
      } else {
        return product;
      }
    });
    this.setState({ selectedProducts: selectedProductsUpdate });
  };

  removeSelectedProduct = (id) => {
    const selectedProducts = [...this.state.selectedProducts];
    const selectedProductsUpdate = selectedProducts.filter(
      (product) => product.id !== id
    );
    this.setState({ selectedProducts: selectedProductsUpdate });
  };

  onConfirm = (values) => {
    const variants = this.state.selectedProducts;

    const product_variants = variants.reduce((acc, value) => {
      const product = {
        id: value.product.id,
        app_name: value.app_name,
        quantity: value.quantity,
      };
      return [...acc, product];
    }, []);

    const weigth = calculateWeight(this.state.selectedProducts);
    const newCombo = {
      name: values.name,
      description: values.description,
      light_weight: values.is_dense ? values.is_dense : false,
      need_cooling: values.need_cooling ? values.need_cooling : false,
      weigth,
      dishes: values.dishes,
      unit_type: values.presentation.value,
      product_variants,
    };
    this.props.onConfirm({ variant: newCombo });
  };

  render() {
    const { onCancel, confirmStatus } = this.props;
    const { selectedProducts } = this.state;
    const validationSchema = createValidationSchema(selectedProducts);

    return (
      <Modal
        className='create-variant-modal'
        title={<Title step={1} />}
        onCancel={onCancel}
        // onSubmit={this.onConfirm}
        {...this.props}
        show={'CREATE_COMBO'}
      >
        <ModalBody className='modal-detail__body'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.onConfirm}
            enableReinitialize
          >
            {({ isSubmitting, handleSubmit, isValid, values }) => {
              console.log(validationSchema, 'EL HANDLE');
              return (
                <form onSubmit={handleSubmit}>
                  <CreateCombo
                    {...this.props}
                    values={values}
                    selectedProducts={selectedProducts}
                    addNewProduct={this.addNewProduct}
                    onAddProductChange={this.onAddProductChange}
                    removeSelectedProduct={this.removeSelectedProduct}
                  />
                  <div className={styles.buttons}>
                    <Button
                      type='button'
                      datacy={'btnCancel'}
                      onClick={onCancel}
                      outline
                    >
                      {i18n.t('modals.create_variant.close')}
                    </Button>
                    <ButtonWithSpinner
                      type='submit'
                      status={confirmStatus}
                      datacy={'btnSave'}
                      onClick={() => this.onConfirm(values)}
                      primary
                    >
                      {i18n.t('modals.create_variant.submit')}
                    </ButtonWithSpinner>
                  </div>
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

const Title = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.title}>{t('modals.create_combo.title')}</div>
    </div>
  );
};
CreateComboContainer.propTypes = {
  onCancel: propTypes.func.isRequired,
  confirmStatus: propTypes.func.isRequired,
};
export default CreateComboContainer;
