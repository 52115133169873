import reducer from './store/reducers';

import * as constants from './configs/constants';
import * as actions from './store/actions';

import sagas from './store/sagas';

export default {
  constants,
  actions,
  reducer,
  sagas: [...sagas],
};
