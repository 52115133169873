import { createSelector } from 'reselect';
import { NAMESPACE } from '../config/constants';
import { isNil } from 'ramda';
import { denormalize } from '../../../utils';

export const available = (state) => state[NAMESPACE].available;
export const historic = (state) => state[NAMESPACE].historic;
export const unrevised = (state) => state[NAMESPACE].unrevised;
export const offers = (state) => state[NAMESPACE].offers;
export const offerDetail = (state) => state[NAMESPACE].offerDetail;
export const status = (state) => state[NAMESPACE].status;
export const error = (state) => state[NAMESPACE].error;
export const page = (state) => state[NAMESPACE].page;
export const totalPages = (state) => state[NAMESPACE].totalPages;
export const stocks = (state) => state[NAMESPACE].stocks;
export const stock_origin = (state) => state[NAMESPACE].stock_origin;

export const products = (state) => {
  if (!state[NAMESPACE].products) return [];

  return state[NAMESPACE].products;
};

export const getProductList = createSelector((list) => list);

export const isLoading = createSelector(
  status,
  (status) => status === 'pending'
);

const valueOrEmpty = (value) => {
  return value ? value : '';
};

export const getProductLabel = (item) => {
  const category_name = item.category ? item.category.name : '';
  return `${valueOrEmpty(item.external_reference)} ${valueOrEmpty(
    item.name
  )} ${valueOrEmpty(item.description)} ${valueOrEmpty(
    item.brand_name
  )} - ${category_name} ${valueOrEmpty(item.unit_measure)} ${valueOrEmpty(
    item.unit_measure_name
  )}`;
};

export const originIsSelected = createSelector(
  stock_origin,
  (origin) => origin !== ''
);

export const offerableStock = createSelector(
  stocks,
  stock_origin,
  (stocks, origin) =>
    denormalize(stocks)
      .filter((s) => (origin === '' ? true : s.address_id === origin))
      .map((x) => ({ label: getProductLabel(x), value: x }))
);

export const getOffersForTable = (state) => {
  return state.stock.offers.map((x) => {
    return {
      offerId: x.id,
      offerEnd: x.end,
      offerCancel: !isNil(x.cancelled_at) && true,
      deliveryStart: x.delivery_start,
      deliveryEnd: x.delivery_end,
      stocks: x.stocks.map((s) => {
        return {
          id: s.id,
          amountOfStocks: s.quantity,
          originalAmountOfStocks: s.original_quantity,
          variant: s.variant_id,
          name: s.name,
          brand: s.brand,
          description: s.description,
          units_multiplier: s.units_multiplier,
          units_per_pack: s.units_per_pack,
          unit_type: s.unit_type,
          unit_measure_name: s.unit_measure_name,
          item_price: s.item_price,
          expires_on: s.expires_on,
          item_price_lote: s.item_price_lote,
        };
      }),
      amountOfOrders: x.orders.length,
    };
  });
};

export const distinctOfferAndProductsAmount = (state) => {
  const variantIds = state.stock.offers.flatMap((x) =>
    x.stocks.map((x) => x.variant_id)
  );
  return {
    amountOfOffers: state.stock.offers.length,
    amountOfProducts: variantIds.length,
  };
};
