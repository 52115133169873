import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import { isNil, isEmpty } from 'ramda';

import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import SelectList from 'components/SelectList/SelectListContainer';
import DatePicker from 'components/DatePicker__update';
import TimetableSelect from 'components/TimetableSelect';
import CheckBoxGroup from 'components/CheckBoxGroup';

import './statics/styles.css';

const Input = (props) => {
  const {
    name,
    className,
    onChange,
    value,
    placeholder,
    label,
    datacy,
    required,
    type,
    list,
    addMoreLabel,
    disabled,
    maxLength,
    defaultInputValue,
    inputs,
    max,
    min,
  } = props;

  return (
    <span className={cn('-input-form', className)}>
      {label && (
        <label
          key='1'
          className={cn('-input-form__label', {
            invalid: (isNil(value) || isEmpty(value)) && required,
          })}
          htmlFor='type'
        >
          {label}: {required ? '*' : ''}
        </label>
      )}

      {type === 'select' && (
        <Select
          isClearable
          onChange={(value) => onChange({ target: { value } })}
          value={value}
          placeholder={placeholder || ''}
          options={list}
          isDisabled={disabled}
          defaultInputValue={defaultInputValue}
        />
      )}

      {type === 'select-creatable' && (
        <Creatable
          isClearable
          createOptionPosition='first'
          formatCreateLabel={(value) => `Crear: ${value}`}
          onChange={(value) => onChange({ target: { value } })}
          value={value}
          placeholder={placeholder || ''}
          options={list}
          isDisabled={disabled}
        />
      )}

      {type === 'select-list' && (
        <SelectList
          name={name}
          placeholder={placeholder}
          list={list}
          onChange={onChange}
          addMoreLabel={addMoreLabel}
          disabled={disabled}
        />
      )}

      {type === 'date-picker' && (
        <DatePicker
          name={onChange}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
      )}

      {type === 'time-picker' && (
        <TimetableSelect
          name={name}
          onChange={onChange}
          disabled={disabled}
          datacy={datacy}
        />
      )}

      {![
        'select',
        'select-creatable',
        'select-list',
        'date-picker',
        'textarea',
        'time-picker',
        'checkbox',
      ].includes(type) && (
        <input
          key='2'
          type={type || 'text'}
          className={cn('-input-form__input', {
            invalid: isNil(value) && required,
          })}
          onChange={onChange}
          value={value}
          placeholder={placeholder || ''}
          disabled={disabled}
          datacy={datacy}
          max={max}
          min={min}
        />
      )}
      {type === 'textarea' && (
        <textarea
          className={cn('-input-form__input', {
            invalid: isNil(value) && required,
          })}
          onChange={onChange}
          value={value}
          placeholder={placeholder || ''}
          disabled={disabled}
          maxLength={maxLength}
          datacy={datacy}
        />
      )}
      {type === 'checkbox' && (
        <CheckBoxGroup
          list={list}
          inputs={inputs}
          name={name}
          onChange={onChange}
          value={value}
          datacy={datacy}
        />
      )}
    </span>
  );
};

Input.propTypes = {
  onChange: propTypes.func.isRequired,
  onCheck: propTypes.func.isRequired,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
    propTypes.object,
    propTypes.array,
  ]).isRequired,
  placeholder: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  className: propTypes.string.isRequired,
  addMoreLabel: propTypes.string.isRequired,
  required: propTypes.bool.isRequired,
  disabled: propTypes.bool.isRequired,
  valid: propTypes.bool.isRequired,
  list: propTypes.arrayOf([]).isRequired,
  maxLength: propTypes.string.isRequired,
  defaultInputValue: propTypes.string.isRequired,
  inputs: propTypes.arrayOf([]).isRequired,
  datacy: propTypes.string,
  max: propTypes.number.isRequired,
  min: propTypes.number.isRequired,
};

Input.defaultProps = {
  datacy: '',
};
export default Input;
