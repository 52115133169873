import { lazy } from 'react';
import reducer from './store/reducers';
import sagas from './store/sagas';
import * as constants from './config/constants';
import * as selectors from './store/selectors';

export default {
  constants,
  reducer,
  sagas,
  scenes: {
    main: lazy(() => import('./scenes/Main')),
    create: lazy(() => import('./scenes/Create')),
  },
  selectors,
};
