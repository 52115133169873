import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ModalForm = (props) => {
  const { formData, onChange } = props;
  const { t } = useTranslation();

  return (
    <div className='create-institution_user__body'>
      <div className='create-institution_user__body__grid'>
        <div>
          <label className='create-institution_user__body__label'>
            {t('modals.create_institution_user.name')}
          </label>
          <input
            onChange={onChange}
            value={formData.first_name}
            type='text'
            datacy={'first_name'}
            className='form-control create-institution_user__body__input'
            name='first_name'
            placeholder={t('modals.create_institution_user.name_placeholder')}
          />
        </div>
        <div>
          <label className='create-institution_user__body__label'>
            {t('modals.create_institution_user.surname')}
          </label>
          <input
            onChange={onChange}
            datacy={'last_name'}
            value={formData.last_name}
            type='text'
            className='form-control create-institution_user__body__input'
            name='last_name'
            placeholder={t(
              'modals.create_institution_user.surname_placeholder'
            )}
          />
        </div>
        <div>
          <label className='create-institution_user__body__label'>
            {t('modals.create_institution_user.email')}
          </label>
          <input
            onChange={onChange}
            value={formData.email}
            datacy={'email'}
            type='email'
            className='form-control create-institution_user__body__input'
            name='email'
            placeholder={t('modals.create_institution_user.email_placeholder')}
          />
        </div>
        <div>
          <label className='create-institution_user__body__label'>
            {t('modals.create_institution_user.phone')}
          </label>
          <input
            onChange={onChange}
            value={formData.cellphone}
            datacy={'cellphone'}
            type='text'
            className='form-control create-institution_user__body__input'
            name='cellphone'
            placeholder={t('modals.create_institution_user.phone_placeholder')}
          />
        </div>
        <div>
          <label className='create-institution_user__body__label'>
            {t('modals.create_institution_user.password')}
          </label>
          <input
            onChange={onChange}
            value={formData.password}
            type='text'
            datacy={'password'}
            className='form-control create-institution_user__body__input'
            name='password'
            placeholder={t(
              'modals.create_institution_user.password_placeholder'
            )}
          />
        </div>
      </div>
    </div>
  );
};

ModalForm.propTypes = {
  onChange: propTypes.func.isRequired,
  formData: propTypes.shape({}).isRequired,
};

export default ModalForm;
