import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Input from 'components/InputGroup';
import WeightAndPrice from 'modules/orders/components/CreateOrder/WeightAndPrice/WeightAndPrice';
import propTypes from 'prop-types';
import ReactSelect from 'react-select';
import Button from 'components/Button';
import i18n from '../../../../../app/configs/i18n';

const initialValues = {
  currentSelection: null,
  quantity: undefined,
};

const validationSchema = yup.object().shape({
  currentSelection: yup
    .object()
    .nullable()
    .required()
    .label(i18n.t('modals.edit_order.product_adder.validations.product')),
  quantity: yup
    .number()
    .min(1)
    .required()
    .label(i18n.t('modals.edit_order.product_adder.validations.quantity'))
    .test(
      'max-stock',
      i18n.t('modals.edit_order.product_adder.validations.available_quantity'),
      function (value) {
        if (this.parent.quantity > this.parent.currentSelection.available)
          return false;
        return true;
      }
    ),
});

const ProductAdder = (props) => {
  const { onCancel, hideTable, setHideTable } = props;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={props.onSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <>
          <label className='-input-form__label'>
            {i18n.t('modals.edit_order.product_adder.product')}
          </label>
          <ReactSelect
            options={props.validOptions}
            value={formikProps.values.currentSelection}
            placeholder={i18n.t(
              'modals.edit_order.product_adder.product_placeholder'
            )}
            onChange={(e) => {
              formikProps.setFieldValue('quantity', e.available);
              formikProps.setFieldValue('currentSelection', e);
            }}
            onMenuOpen={() => setHideTable(true)}
            onMenuClose={() => setHideTable(false)}
          />
          <p>{formikProps.errors.currentSelection}</p>

          <Input
            placeholder={props.unit}
            type='number'
            label={i18n.t('modals.edit_order.product_adder.quantity')}
            max={
              formikProps.values.currentSelection
                ? formikProps.values.currentSelection.available
                : ''
            }
            min='1'
            value={formikProps.values.quantity}
            onChange={formikProps.handleChange('quantity')}
          />
          <p>{formikProps.errors.quantity}</p>

          <WeightAndPrice
            hidden={hideTable}
            item={formikProps.values.currentSelection}
            quantity={formikProps.values.quantity}
          />

          <div className='buttons'>
            <Button className='btn btn-cancel' onClick={onCancel} outline>
              {i18n.t('modals.edit_order.product_adder.close')}
            </Button>
            <Button
              className='btn btn-save'
              onClick={formikProps.handleSubmit}
              disabled={!formikProps.isValid}
              primary
            >
              {i18n.t('modals.edit_order.product_adder.submit')}
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};

ProductAdder.propTypes = {
  onCancel: propTypes.func.isRequired,
  hideTable: propTypes.bool.isRequired,
  setHideTable: propTypes.bool.isRequired,
  onSubmit: propTypes.func.isRequired,
};

export default ProductAdder;
