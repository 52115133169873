import React from 'react';
import propTypes from 'prop-types';
import './RadioButton.module.css';
import RadioButton from './RadioButton';

class RadioButtonList extends React.Component {
  constructor(props) {
    super(props);
    const { options } = props;
    this.state = {
      options: options.map((x) => ({
        label: x.label,
        value: x.value,
        isChecked: false,
      })),
    };
  }

  _onChange = (value) => {
    const { onChange, isMultiCheck } = this.props;
    let options = this.state.options;
    if (!isMultiCheck) options = this._unCheckAllOptions(this.state.options);
    options = options.map((opt) => this.toggleCheckedOption(opt, value));
    this.setState({ options: options }, () => {
      const checkedValues = this.state.options
        .filter((x) => x.isChecked)
        .map((x) => x.value);
      if (!isMultiCheck) onChange(checkedValues[0] || []);
      else onChange(checkedValues);
    });
  };

  _unCheckAllOptions = (options) => {
    return options.map((opt) => ({ ...opt, isChecked: false }));
  };

  toggleCheckedOption = (option, value) => {
    if (option.value === value)
      return { ...option, isChecked: !option.isChecked };
    return option;
  };

  render() {
    const checkBoxes = this.state.options.map((x) => {
      return (
        <li key={x.value}>
          <RadioButton
            onChange={this._onChange.bind(this)}
            text={x.label}
            checked={x.isChecked}
            value={x.value}
          />
        </li>
      );
    });
    return <ul>{checkBoxes}</ul>;
  }
}

RadioButtonList.propTypes = {
  options: propTypes.arrayOf([]),
  onChange: propTypes.func,
  isMultiCheck: propTypes.bool,
};

RadioButtonList.defaultProps = {
  options: [],
  onChange: () => {},
  isMultiCheck: false,
};

export default RadioButtonList;
