import React, { Component } from 'react';
import propTypes from 'prop-types';
import styles from './FileInput.module.css';
import i18n from '../../app/configs/i18n';

export default class FileInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      file: {},
    };
  }

  _onChange(e) {
    const file = e.target.files[0];
    if (file === undefined) {
      this.setState({ file: {}, fileName: '' });
    } else {
      this.setState({ file: file, fileName: file.name });
    }
    this.props.onChange(file);
  }

  render() {
    const { fileName } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.centered}>
          <label
            htmlFor={'select-file'}
            className={`${styles.button} ${styles.button__outline}`}
          >
            {i18n.t('select_file')}
          </label>
        </div>
        <div className={styles.centered}>
          <span>{fileName}</span>
          <input
            id={'select-file'}
            type='file'
            className={styles.invisible}
            onChange={this._onChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}

FileInput.propTypes = {
  onChange: propTypes.func,
};

FileInput.defaultProps = {
  onChange: () => {},
};
