import React, { Component } from 'react';
import propTypes from 'prop-types';

export default class MultiOriginCheckItem extends Component {
  changeHandler = (event, id) => {
    if (event.target.checked) {
      this.props.onSelect(id);
      this.props.onChange(event);
    } else {
      // handle de-select
    }
  };

  render() {
    const { data, selectedId } = this.props;
    const { id } = data.address;
    const isChecked = id === selectedId;

    return (
      <div className='row'>
        <div
          className='checkbox-custom checkbox-primary form-group col-md-12'
          style={{ marginLeft: '15px' }}
        >
          <input
            id={id}
            name={'initial_origin_id'}
            type='checkbox'
            checked={isChecked}
            value={id}
            onChange={(e) => {
              this.changeHandler(e, id);
            }}
          />
          <label htmlFor='notification'>{data.name}</label>
          <p style={{ marginLeft: '10px' }}> {data.address.street_1}</p>
        </div>
      </div>
    );
  }
}
MultiOriginCheckItem.propTypes = {
  onChange: propTypes.func.isRequired,
  formData: propTypes.shape({}).isRequired,
};
