import reducer from './store/reducers';

import sagas from './store/sagas';

import * as selectors from './store/selectors';
import * as constants from './config/constants';
import * as actions from './store/actions';

export default {
  selectors,
  constants,
  actions,
  reducer,
  sagas: [...sagas],
};
