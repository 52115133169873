import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from './components/Modal';

import * as actions from '../../store/actions';
import * as selectors from '../../store/selectors';

import * as shippersActions from 'modules/shippers/store/actions';
import * as shippersSelectors from 'modules/shippers/store/selectors';

import { withHooks } from 'hooks/withHooks';

import i18n from '../../../../app/configs/i18n';

class AssignShipperContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shipper: {},
    };
  }

  onSelect = (id) => {
    const { shippers } = this.props;

    this.setState({
      shipper: shippers.filter((item) => item.id === id)[0],
    });
  };

  render() {
    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.assign_driver.success')}
        shipper={this.state.shipper}
        onSelect={this.onSelect}
      />
    );
  }
}

AssignShipperContainer.propTypes = {
  shippers: propTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  shippers: shippersSelectors.shippers(state),
  trip: selectors.data(state).trip,
});

const mapDispatchToProps = (dispatch, props) => ({
  onMount: () => dispatch(shippersActions.fetchAll()),
  onConfirm: (trip) => dispatch({ type: props.onConfirm, payload: { trip } }),
  onCancel: () => dispatch(actions.closeModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(AssignShipperContainer));
