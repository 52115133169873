import React from 'react';
import MultiOriginCheckItem from './MultiOriginCheckItem';
import i18n from '../../../../../../app/configs/i18n';
class ModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      selectedId: this.props.data.orders[0].giver[0].addresses[0].id,
    };
  }

  changeHandler = (id) => {
    this.setState({
      ...this.state,
      selectedId: id,
    });
  };

  render() {
    const { orders } = this.props.data;

    // TODO not sure if this is the most efficient way of doing it
    const giversWithAddresses = [];
    orders.forEach((o) =>
      o.giver.forEach((g) =>
        giversWithAddresses.push({ name: g.name, address: g.addresses[0] })
      )
    );

    const filteredGiversByAddress = giversWithAddresses.filter(
      (elem, index, self) =>
        self.findIndex((t) => {
          return t.address.id === elem.address.id;
        }) === index
    );
    return (
      <div className='view_trip_detail__body'>
        <div className='view_trip_detail__body__grid'>
          <div className='view_trip_detaul__body__grid-col'>
            <div className='view_trip_detaul__body__grid-row'>
              <div className='view_trip_detail__body__item'>
                {i18n.t('modals.create_trip.multi_origin_step.message')}
              </div>
            </div>
            {filteredGiversByAddress.map((giver) => (
              <MultiOriginCheckItem
                key={giver.address.id}
                selectedId={this.state.selectedId}
                data={giver}
                onChange={this.props.onChange}
                onSelect={this.changeHandler}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ModalForm;
