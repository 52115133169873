import { put, call, takeEvery, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from './selectors';
import UserApi from '../../../app/api/UserApi';
import * as alertActions from 'modules/alerts/store/actions';
import * as modalActions from 'modules/modals/store/actions';

import history from '../../../app/configs/history';
import { onConfirmation } from '../../modals/store/sagas';
import { delay } from 'redux-saga';
import i18n from '../../../app/configs/i18n';

const confirmationMessage = (values) => {
  const recipientAmount = values.notification.receiver_ids.length;
  return `${i18n.t('notifications.sagas.aprox')} ${recipientAmount} ${i18n.t(
    'notifications.sagas.users'
  )}`;
};

export function* fetchAll() {
  yield put(actions.fetchAll.start());
  try {
    const {
      res: { data },
    } = yield call(UserApi.fetchNotifications);
    yield put(actions.fetchAll.success({ notifications: data }));
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('notifications.sagas.get_error'),
        type: 'error',
        delay: 5000,
      })
    );
    yield put(actions.fetchAll.failure({ error }));
  }
}

export function* create(action) {
  const { payload } = action.payload;
  const message = confirmationMessage(payload);
  const operationName = i18n.t('notifications.sagas.confirm');
  const confirmed = yield call(onConfirmation, operationName, message);
  if (!confirmed) {
    return;
  }

  yield put(actions.create.start());
  try {
    const { res } = yield call(UserApi.createNotification, payload);
    yield put(
      alertActions.openAlert({
        message: `${i18n.t('notifications.sagas.sent')}${
          res.data['result'][payload.service]
        } ${i18n.t('notifications.sagas.notifications')}`,
        type: 'success',
        delay: 2500,
      })
    );
    yield put(actions.create.success());
    yield put(modalActions.closeModal());
  } catch (e) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('notifications.sagas.create_error'),
        type: 'error',
        delay: 2500,
      })
    );
    yield put(actions.fetchAll.failure({ e }));
    yield modalActions.closeModal();
  }
  yield delay(2500);
  history.push('/notifications');
}

export function* fetchAudiences() {
  yield put(actions.fetchAudiences.start());

  try {
    const {
      res: { data },
    } = yield call(UserApi.fetchAudiences);
    return yield put(actions.fetchAudiences.success({ audiences: data }));
  } catch (e) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('notifications.sagas.get_audience_type_error'),
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.fetchAudiences.failure({ e }));
  }
}

export function* setAudience() {
  const audiences = yield select(selectors.audiences);
  const audienceType = yield select(selectors.audienceType);
  if (audiences[audienceType] === undefined) {
    yield fetchReceivers(audienceType);
  }
}

export function* fetchReceivers(audienceType) {
  yield put(actions.fetchReceivers.start());
  try {
    const {
      res: {
        data: { receivers },
      },
    } = yield call(UserApi.fetchReceivers, audienceType);
    return yield put(
      actions.fetchReceivers.success({
        audience: receivers,
        audienceType,
      })
    );
  } catch (e) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('notifications.sagas.get_audience_error'),
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.fetchAudiences.failure({ e }));
  }
}

export function* fetchAudiencesWatcher() {
  yield takeEvery(actions.fetchAudiences.type, fetchAudiences);
}

export function* setAudienceWatcher() {
  yield takeEvery(actions.setAudienceType.type, setAudience);
}

export function* fetchAllWatcher() {
  yield takeEvery(actions.fetchAll.type, fetchAll);
}

export function* createWatcher() {
  yield takeEvery(actions.create.type, create);
}

export default [
  fetchAllWatcher,
  createWatcher,
  fetchAudiencesWatcher,
  setAudienceWatcher,
];
