import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ModalFull = (props) => {
  const { title, children, onCancel } = props;
  const { t } = useTranslation();

  return (
    <div className='modal-full__container'>
      <div className='modal-full__header-container'>
        <div className='modal-full__header-cancel' onClick={onCancel}>
          <img
            alt='cancel'
            className='modal-full__header-cancel-logo'
            src={require('./statics/cancel.svg')}
          />
          {t('close')}
        </div>
        <div className='modal-full__header-title'>{title}</div>
      </div>
      <div className='modal-full__body'>{children}</div>
    </div>
  );
};

ModalFull.propTypes = {
  title: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default ModalFull;
