export const TryCatchInterceptor = async (callback) => {
  try {
    const res = await callback();
    return { res };
  } catch (error) {
    return { error };
  }
};

export const AddingColectorErrors = (instance) => {
  const {
    api,
    token,
    baseUrl,
    activeNetwork,
    createConstructorAxios,
    ...secureMethods
  } = instance;

  const keysApi = Object.keys(secureMethods);

  const methodsApi = Object.values(secureMethods);

  const newMethodsApi = methodsApi.map((methodApi) => async (...params) =>
    await TryCatchInterceptor(() => methodApi(...params))
  );

  return keysApi.reduce((allMethods, keyMethods, i) => {
    return {
      ...allMethods,
      [keyMethods]: newMethodsApi[i],
    };
  }, {});
};
