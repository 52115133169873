import { call, put, takeEvery } from 'redux-saga/effects';
import { get } from 'api-connect/dist/user';
import UserApi from 'app/api/UserApi';
import { isNil } from 'ramda';
import moment from 'moment';

import history from 'app/configs/history';

import * as actions from './actions';

export function* loginWorker(action) {
  const { email, password } = action.payload;
  try {
    const { res } = yield call(UserApi.login, { email, password });
    yield put(actions.loginUser.success({ token: res.data.token }));
    localStorage.setItem('token', res.data.auth_token);
    const date = moment().utc().format('DD-MM-YYYY');
    yield put(actions.fetchOne());
    return yield call(history.push, `/trips/${date}`);
  } catch (error) {
    const errorData = error.response.data;
    yield put(
      actions.loginUser.failure({ error: errorData.errors.user_authentication })
    );
    return { error };
  }
}

export function* fetchOneWorker() {
  yield put(actions.fetchOne.start());

  const { res, error } = yield call(get, 'v1/users/me');

  if (res.status >= 400 || error) {
    yield put(actions.fetchOne.failure({ error }));
    return { error };
  }
  const activeNetwork = localStorage.getItem('active_network');

  const userHasNetwork = res.user.networks.some((n) => n === activeNetwork);

  if (isNil(activeNetwork) || !userHasNetwork) {
    localStorage.setItem('active_network', res.user.networks[0]);
  }

  yield put(actions.fetchOne.success({ user: res.user }));

  return { res };
}

export function* logoutUserWorker() {
  yield put(actions.logoutUser.start());
  localStorage.removeItem('token');

  yield put(actions.logoutUser.success());
  history.push('/login');
}

export function* loginWatcher() {
  yield takeEvery(actions.loginUser.type, loginWorker);
}

export function* logoutUserWatcher() {
  yield takeEvery(actions.logoutUser.type, logoutUserWorker);
}

export function* fetchOneWatcher() {
  yield takeEvery(actions.fetchOne.type, fetchOneWorker);
}

export default [loginWatcher, logoutUserWatcher, fetchOneWatcher];
