import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import '../css/styles.css';
import StockInformation from './StockInformation';
import StockCategoryLimits from './StockCategoryLimits';
import ComboInformation from './ComboInformation';
import Wizard from './Wizard';
import { StockAmount } from './FormHelpers';

import { useTranslation } from 'react-i18next';

class CreateStock extends React.Component {
  state = {
    errors: [],
    productIsRegular: true,
    combo: {},
    item_checked: false,
  };

  onSelectedProductchange = (productIsRegular, combo) => {
    this.setState({ productIsRegular, combo });
  };

  handleCheckbox = (item_checked) => {
    this.setState((prev) => ({ ...prev, item_checked }));
  };

  render() {
    const { onCancel, onConfirm } = this.props;
    const { productIsRegular, combo, item_checked } = this.state;
    const {
      initialData,
      variants,
      addresses,
      groups_availability,
    } = this.props;

    return (
      <Modal
        className='create-stock-modal'
        title={<Title step={1} />}
        onCancel={onCancel}
        {...this.props}
      >
        <ModalBody className='modal-detail__body'>
          <Wizard
            productIsRegular={productIsRegular}
            initialValues={initialData}
            onSubmit={onConfirm}
            item_checked={item_checked}
            onCancel={onCancel}
            combo={combo}
          >
            <Wizard.Page>
              {(productIsRegular && (
                <StockInformation
                  {...this.props}
                  onSelectedProductchange={this.onSelectedProductchange}
                  variants={variants}
                  addresses={addresses}
                  handleCheckbox={this.handleCheckbox}
                  item_checked={item_checked}
                />
              )) || (
                <ComboInformation
                  {...this.props}
                  onSelectedProductchange={this.onSelectedProductchange}
                  variants={variants}
                  combo={combo}
                  addresses={addresses}
                />
              )}
            </Wizard.Page>
            <Wizard.Page>
              <StockAmount />
              <hr />
              <StockCategoryLimits groups_availability={groups_availability} />
            </Wizard.Page>
          </Wizard>
        </ModalBody>
      </Modal>
    );
  }
}

const Title = ({ step }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className='create-stock-modal__steps'>
        {t('modals.create_stock.step')} {step} {t('modals.create_stock.of')}
      </div>
      <div className='create-stock-modal__title'>
        {t('modals.create_stock.create')}
      </div>
    </div>
  );
};

CreateStock.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPreviousStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  emptyCategories: PropTypes.bool.isRequired,
  variants: PropTypes.arrayOf([]).isRequired,
  status: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}).isRequired,
  onValidation: PropTypes.func.isRequired,
  addresses: PropTypes.arrayOf([]).isRequired,
  groups_availability: PropTypes.arrayOf([]).isRequired,
};

export default CreateStock;
