import { connect } from 'react-redux';

import * as modalActions from '../../store/actions';

import ModalContainer from './ModalContainer';
import * as actions from '../../../stock/store/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, props) => ({
  onConfirm: (payload) => dispatch({ type: actions.uploadCsv.type, payload }),
  onCancel: () => {
    dispatch({ type: props.onCancel });
    dispatch(modalActions.closeModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
