export default {
  ROS: 'Rosario',
  MDQ: 'Mar del Plata',
  LP: 'La Plata',
  MCBA: 'Mercado Central de Buenos Aires',
  PR: 'Puerto Rico',
  SP: 'San Pablo',
  CHI: 'Chihuahua',
  YUC: 'Yucatan',
  MOV: 'Montevideo',
  CDMX: 'Ciudad de Mexico',
  TAB: 'Tabasco',
  GUA: 'GUADALAJARA',
  QUE: 'Queretaro',
  SAL: 'El Salvador',
  HON: 'Honduras',
  GUATE: 'Guatemala',
  CBA: 'Cordoba',
  Wayuutaya: 'Wayuutaya',
  Bokitas: 'Bokitas',
};
