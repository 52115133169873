import { put, takeEvery, select, call } from 'redux-saga/effects';
import { put as _put } from 'api-connect/dist/api';
import { normalize } from 'utils';
import * as shippersActions from 'modules/shippers/store/actions';
import * as shippersSelectors from 'modules/shippers/store/selectors';
import * as alertActions from 'modules/alerts/store/actions';
import * as modalActions from 'modules/modals/store/actions';
import * as actions from './actions';
import i18n from '../../../app/configs/i18n';

export function* updateWorker(action) {
  yield put(actions.update.start());

  const bankAccount = action.payload;
  const { res, error } = yield call(
    _put,
    `bank_accounts/${bankAccount.id}`,
    bankAccount
  );

  if (error) {
    yield put(modalActions.closeModal());
    yield put(
      alertActions.openAlert({
        message: i18n.t('drivers.detail.bank_accounts.sagas.error'),
        type: 'error',
      })
    );
    return put(actions.update.failure({ error: error }));
  }

  yield put(actions.update.success({ bankAccount: res.bank_account }));
  yield put(modalActions.closeModal());
  return yield put(
    alertActions.openAlert({
      message: i18n.t('drivers.detail.bank_accounts.sagas.update_success'),
      type: 'success',
      delay: 2000,
    })
  );
}

export function* updateListWorker() {
  const shippersList = yield select(shippersSelectors.shippers);

  const bankAccounts = shippersList
    .map((item) => item.bank_accounts)
    .reduce((res, list) => {
      res.push(...list);
      return res;
    }, []);

  yield put({
    type: actions.updateList,
    payload: { items: normalize(bankAccounts) },
  });
}

export function* updateListWatcher() {
  yield takeEvery(shippersActions.fetchAll.SUCCESS, updateListWorker);
  yield takeEvery(shippersActions.fetchOne.SUCCESS, updateListWorker);
}

export function* fetchAllWatcher() {
  //yield takeEvery(actions.fetchAll.type, fetchAllWorker)
}

export function* fetchOneWatcher() {
  //yield takeEvery(actions.fetchOne.type, fetchOneWorker)
}

export function* createWatcher() {
  //yield takeEvery(actions.create.type, createWorker)
}

export function* updateWatcher() {
  yield takeEvery(actions.update.type, updateWorker);
}

export function* removeWatcher() {
  //yield takeEvery(actions.remove.type, removeWorker)
}

export default [
  fetchAllWatcher,
  fetchOneWatcher,
  createWatcher,
  updateWatcher,
  removeWatcher,
  updateListWatcher,
];
