import * as yup from 'yup';
import i18n from 'app/configs/i18n';

export const validationSchema = yup.object().shape({
  reason: yup
    .object()
    .required(i18n.t('modals.create_payment.validations.required_reason'))
    .nullable(),
  amount: yup
    .number(i18n.t('modals.create_payment.validations.numeric_amount'))
    .positive(i18n.t('modals.create_payment.validations.positive_amount'))
    .required(i18n.t('modals.create_payment.validations.required_amount')),
  payment_method: yup
    .object()
    .required(
      i18n.t('modals.create_payment.validations.required_payment_method')
    )
    .nullable(),
});
