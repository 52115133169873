import React from 'react';
import propTypes from 'prop-types';
import Modal from './components/Modal';
import i18n from '../../../../app/configs/i18n';
import * as modalActions from '../../store/actions';
import * as vehiclesSelectors from 'modules/vehicles/store/selectors';
import { connect } from 'react-redux';

class UpdateVehicleContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { vehicle } = this.props;

    this.setState({
      formData: {
        ...this.state.formData,
        ...vehicle,
        refrigerated: vehicle.features.refrigerated,
      },
    });
  }

  onChange(e) {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  }

  onChecked = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        refrigerated: !this.state.formData.refrigerated,
      },
    });
  };

  render() {
    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.edit_vehicle.success')}
        formData={this.state.formData}
        onChange={this.onChange}
        onChecked={this.onChecked}
      />
    );
  }
}

UpdateVehicleContainer.propTypes = {
  vehicle: propTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  vehicle: vehiclesSelectors.getVehicle(state),
  status: vehiclesSelectors.status(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onConfirm: (payload) => dispatch({ type: props.onConfirm, payload }),
  onCancel: () => {
    dispatch({ type: props.onCancel });
    dispatch(modalActions.closeModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateVehicleContainer);
