import React from 'react';
import propTypes from 'prop-types';
import TimetableSelectContainer from '../TimetableSelect/TimetableSelectContainer';
import { useTranslation } from 'react-i18next';

const TimetableGroup = (props) => {
  const { onChange, value } = props;
  const { t } = useTranslation();

  return [
    <TimetableSelectContainer
      key={0}
      name='from'
      value={value}
      onChange={onChange}
    />,
    t('to'),
    <TimetableSelectContainer
      key={1}
      name='to'
      value={value}
      onChange={onChange}
    />,
  ];
};

TimetableGroup.propTypes = {
  onChange: propTypes.func.isRequired,
  value: propTypes.string.isRequired,
};

export default TimetableGroup;
