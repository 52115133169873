import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import './statics/styles.css';

import TimetableSelectGroup from '../TimetableSelectGroup/TimetableSelectGroupContainer';

const TimetableSingle = (props) => {
  const {
    name,
    day,
    expanded,
    onClickExpand,
    onChange,
    onCheck,
    checked,
    daysList,
  } = props;
  return (
    <div className='timetable-select__item'>
      <span className='timetable-select__item__name'>
        <input
          onClick={() => onCheck(name)}
          type='checkbox'
          className='timetable-select__item__checkbox'
          disabled={!daysList.includes(name)}
          checked={checked.includes(name)}
        />{' '}
        {day}
      </span>
      <TimetableSelectGroup name='morning' onChange={onChange} />
      {expanded && [
        ' y ',
        <TimetableSelectGroup name='afternoon' onChange={onChange} key={0} />,
      ]}
      <div
        onClick={onClickExpand}
        className={cn(
          'timetable-select__item__expand',
          { 'timetable-select__item__expand--inner': expanded },
          { 'timetable-select__item__expand--outer': !expanded }
        )}
      />
    </div>
  );
};

TimetableSingle.propTypes = {
  onChange: propTypes.func.isRequired,
  onCheck: propTypes.func.isRequired,
  onClickExpand: propTypes.func.isRequired,
  day: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  expanded: propTypes.bool.isRequired,
  checked: propTypes.arrayOf([]).isRequired,
  daysList: propTypes.arrayOf([]).isRequired,
};

export default TimetableSingle;
