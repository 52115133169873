import React from 'react';
import propTypes from 'prop-types';
import styles from '../css/ComboInformation.module.css';
import { SelectField, LabeledInputField } from './FormHelpers';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Space, ContainerRow } from '../css/styled';
import { onlyCompanies } from '../../../../../utils/filter_institutions';
import Chexbox from '../../../../../components/Checkbox/Checkbox';
import { CheckLabel, CheckSpacePadding } from '../css/styled';

const ComboInformation = ({
  variants,
  institutions,
  combo,
  onSelectedProductchange,
}) => {
  const { t } = useTranslation();
  const productsInCombo = combo.combo_product_items;

  const changeStockFormatForFormik = (stocks) => {
    return stocks.map((item) => {
      const data = {
        label: `${t('modals.create_stock.pickStock.exp')}: ${moment(
          item.expires_on
        ).format('DD/MM/YY')} - ${t('modals.create_stock.pickStock.stock')}: ${
          item.quantity
        } (${t('modals.create_stock.pickStock.stock_number')}: ${item.code})`,
        value: item.stock_id,
      };
      return data;
    });
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wholeRow}>
          <SelectField
            onSelectedProductchange={onSelectedProductchange}
            name='variant'
            label={t('modals.create_stock.name')}
            placeholder={t('modals.create_stock.name_placeholder')}
            options={variants}
            required={true}
          />
        </div>
        <div className={`${styles.wholeRow} ${styles.container}`}>
          <div className={styles.nineCols}>
            {t('modals.create_stock.titles.variant')}
          </div>
          <div className={styles.fourCols}>
            {t('modals.create_stock.titles.quantity')}
          </div>
          <div className={styles.elevenCols}>
            {t('modals.create_stock.titles.stock')}
          </div>
        </div>

        {productsInCombo.map((product) => {
          return (
            <div
              key={product.id}
              className={`${styles.wholeRow} ${styles.container}`}
            >
              <div className={styles.nineCols}>
                <LabeledInputField
                  name={`product-${product.id}`}
                  disabled
                  value={product.product_variant_name}
                />
              </div>
              <div className={styles.fourCols}>
                <LabeledInputField
                  name={`product-${product.id}-quantity`}
                  disabled
                  value={product.quantity}
                />
              </div>
              <div className={styles.elevenCols}>
                <SelectField
                  options={changeStockFormatForFormik(product.stocks)}
                  required={true}
                  disabled={!product.stocks.length}
                  placeholder={!product.stocks.length ? 'No Stock' : ''}
                  name={`product-${product.id}-lote`}
                />
              </div>
            </div>
          );
        })}
        <div className={styles.wholeRow}>
          <SelectField
            label={t('modals.create_stock.deposit')}
            placeholder={t('modals.create_stock.expiration_placeholder')}
            options={institutions.filter(onlyCompanies)}
            required
            name='address'
          />
        </div>
        <ContainerRow>
          <Space>
            <LabeledInputField
              input='input'
              label={t('modals.create_stock.lote')}
              placeholder={t('modals.create_stock.price_placeholder')}
              type='number'
              name='cost'
              required={true}
              min={0}
            />
          </Space>
        </ContainerRow>
        <hr className={styles.wholeRow} />
        <div className={styles.halfRow}>
          <LabeledInputField
            input='input'
            label={t('modals.create_stock.price')}
            placeholder={t('modals.create_stock.price_placeholder')}
            type='number'
            step={0.01}
            min={0}
            name='item_price'
            required
          />
        </div>
      </div>
      <CheckSpacePadding>
        <CheckLabel>{t('modals.create_stock.rescued')}</CheckLabel>
        <Chexbox />
      </CheckSpacePadding>
    </>
  );
};

ComboInformation.propTypes = {
  variants: propTypes.arrayOf([]).isRequired,
};

export default ComboInformation;
