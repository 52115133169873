import React from 'react';
import cn from 'classnames';
import { isEmpty, isNil } from 'ramda';
import styles from './LabeledInput.module.css';
import inputStyles from './Input.module.css';
import propTypes from 'prop-types';

const Textarea = (props) => {
  const {
    onChange,
    value,
    placeholder,
    disabled,
    maxLength,
    datacy,
    tooltip,
    required,
    label,
  } = props;

  const isInvalid = (isNil(value) || isEmpty(value)) && required;

  return (
    <div className={styles.container}>
      <div>
        <label
          className={cn(styles.label, { [styles.invalid]: isInvalid })}
          htmlFor='type'
        >
          {label}: {required && '*'}
        </label>
        {tooltip && <i className='far fa-question-circle' />}
        <textarea
          className={cn(
            inputStyles.input,
            { [inputStyles.invalid]: isInvalid },
            { [inputStyles.disabled]: disabled }
          )}
          onChange={onChange}
          value={value}
          placeholder={placeholder || ''}
          disabled={disabled}
          maxLength={maxLength}
          datacy={datacy}
          {...props}
        />
      </div>
    </div>
  );
};

Textarea.propTypes = {
  onChange: propTypes.func.isRequired,
  placeholder: propTypes.string.isRequired,
  disabled: propTypes.bool.isRequired,
  datacy: propTypes.string.isRequired,
  required: propTypes.bool.isRequired,
  value: propTypes.string,
  label: propTypes.string.isRequired,
  tooltip: propTypes.string.isRequired,
  maxLength: propTypes.string.isRequired,
};

Textarea.defaultProps = {
  value: '',
};

export default Textarea;
