import React from 'react';
import CsvErrors from './CsvErrors';

class ModalContainer extends React.Component {
  render() {
    return <CsvErrors {...this.props} />;
  }
}

export default ModalContainer;
