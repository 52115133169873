import React from 'react';
import { connect } from 'react-redux';

import * as modalActions from '../../store/actions';

import * as ordersSelectors from 'modules/orders/store/selectors';
import * as orderActions from 'modules/orders/store/actions';

import Modal from './components/Modal';

import { withHooks } from 'hooks/withHooks';

import columns from './columns';

class ViewOrderContainer extends React.Component {
  render() {
    return <Modal {...this.props} />;
  }
}

const mapStateToProps = (state, props) => ({
  order: ordersSelectors.getOrder(state)(props.data.order.id),
  status: ordersSelectors.status(state),
  columns,
});

const mapDispatchToProps = (dispatch, props) => ({
  onMount: () => dispatch(orderActions.fetchOne({ id: props.data.order.id })),
  onCancel: () => {
    dispatch({ type: props.onCancel });
    dispatch(modalActions.closeModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(ViewOrderContainer));
