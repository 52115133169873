import { delay } from 'redux-saga';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { get, post, put as putApi, patch, del } from 'api-connect/dist/api';
import { normalize } from 'utils';
import {
  get as getMkp,
  post as postMkp,
  put as putMkp,
} from 'api-connect/dist/marketplace';
import fileDownload from 'js-file-download';
import { getFileNameFromServerOrBackup } from '../../stock/helpers/helpers';
import { onConfirmation } from '../../modals/store/sagas';
import { isNil } from 'ramda';
import history from '../../../app/configs/history';
import MarketplaceApi from '../../../app/api/MarketplaceApi';
import i18n from '../../../app/configs/i18n';
import * as alertActions from 'modules/alerts/store/actions';
import * as actions from './actions';
import * as selectors from './selectors';
import * as modalActions from '../../modals/store/actions';
import { availableExtensions, readExcel } from '../components/List/helper';
import { ORDER_CREATE } from '../../modals/components/UploadCsvOrder/constants';

function* createWorker(action) {
  const { order } = action.payload;
  const { res, error } = yield call(postMkp, 'shopping/orders', order);

  if (!res || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('orders.sagas.create_error'),
        type: 'error',
        delay: 5000,
      })
    );

    yield delay(5000);
    history.push('/orders');
    return yield put(actions.create.failure({ error }));
  }

  yield put(
    alertActions.openAlert({
      message: i18n.t('orders.sagas.create_success'),
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.create.success({ order: normalize([res.order]) }));

  yield delay(2000);

  history.push('/orders');
}

function* createLogistics(action) {
  const { order } = action.payload;
  const { res, error } = yield call(post, 'orders', order);
  if (!res || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('orders.sagas.create_error'),
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.create.failure({ error }));
  }

  yield put(
    alertActions.openAlert({
      message: i18n.t('orders.sagas.create_success'),
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.create.success({ order: normalize([res.order]) }));

  yield delay(2000);
  history.push('/orders');
}

function* exportOrderDetail(action) {
  const { date } = action.payload;
  yield put(actions.exportOrders.start());
  try {
    // date.getMonth starts from 0 with January. @author: Tom
    const shortDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    const res = yield call(MarketplaceApi.getOrderDetailExcel, shortDate);
    yield put(actions.exportOrders.success());
    fileDownload(res, `maestro-ordenes-x-entrega-${shortDate}.xlsx`);
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('orders.sagas.download_error'),
        type: 'error',
      })
    );
    yield put(actions.exportOrders.failure({ error }));
  }
}

function* fetchCurrentStockWorker(action) {
  try {
    yield put(actions.getCurrentStock.start());

    const giver = action.payload;
    const { res, error } = yield call(
      getMkp,
      `categories/get_current_stock?address_id=${giver.addresses[0].id}`
    );

    if (res.status > 400 || error) {
      return yield put(actions.fetchAll.failure({ error }));
    }

    yield put(actions.getCurrentStock.success({ currentStock: res.data }));
  } catch (error) {
    yield put(actions.getCurrentStock.failure({ error: 'Server error' }));
    yield put(
      alertActions.openAlert({
        message: i18n.t('orders.sagas.server_error'),
        type: 'error',
      })
    );
  }
}

function* fetchAllWorker() {
  try {
    yield put(actions.fetchAll.start());

    const { res, error } = yield call(get, 'orders');

    if (res.status > 400 || error) {
      return yield put(actions.fetchAll.failure({ error }));
    }

    yield put(actions.fetchAll.success({ orders: normalize(res.orders) }));
  } catch (error) {
    yield put(actions.fetchAll.failure({ error: 'Server error' }));
    yield put(
      alertActions.openAlert({
        message: i18n.t('orders.sagas.server_error'),
        type: 'error',
      })
    );
  }
}

function* fetchOrdersOfDayWorker(action) {
  try {
    const { delivery_date } = action.payload;
    const { res } = yield call(get, `orders?delivery_date=${delivery_date}`);
    yield put(actions.fetchAll.success({ orders: normalize(res.orders) }));
  } catch (error) {
    yield put(actions.fetchOrdersOfDay.failure({ error }));
    yield put(
      alertActions.openAlert({
        message: i18n.t('orders.sagas.server_error'),
        type: 'error',
      })
    );
  }
}

function* fetchOneWorker(action) {
  const { id } = action.payload;
  const { res, error } = yield call(getMkp, `orders/${id}`);
  if (!res || error) {
    yield put(actions.fetchOne.failure({ error }));
  }
  yield put(actions.fetchOne.success({ order: res.order }));
}

export function* updateDeliveryWorker(action) {
  yield put(actions.updateDelivery.start());

  const { ordersIds, refrigerated } = action.payload;

  const deliveriesIds = yield select((state) =>
    selectors.getDeliveriesIds(state)(ordersIds)
  );

  for (let i = 0; i < deliveriesIds.length; i++) {
    const payload = {
      order_id: ordersIds[i],
      options: [refrigerated ? 'refrigerated' : ''],
    };

    const { res, error } = yield call(
      patch,
      `deliveries/${deliveriesIds[i]}`,
      payload
    );

    if (res.status >= 400 || error) {
      return put(actions.updateDelivery.failure({ error }));
    } else {
      yield put(actions.updateDelivery.success({ delivery: res.delivery }));
    }
  }
}

function* deleteOrderWorker(action) {
  const message = i18n.t('orders.sagas.delete_message');
  const operationName = i18n.t('orders.sagas.delete_order');
  const confirmed = yield call(onConfirmation, operationName, message);
  if (!confirmed) {
    return;
  }

  yield put(actions.deleteOrder.start());

  const { id } = action.payload;

  const { res, error } = yield call(del, `orders/${id}`);

  if (!res || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('orders.sagas.delete_error'),
        type: 'error',
        delay: 2000,
      })
    );
    yield put(modalActions.closeModal());
    return yield put(actions.deleteOrder.failure({ error }));
  }

  yield put(
    alertActions.openAlert({
      message: i18n.t('orders.sagas.delete_success'),
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.deleteOrder.success());

  yield delay(2000);
  yield put(modalActions.closeModal());
  history.push('/orders');
}

function* editDateOrderWorker(action) {
  const { order_id, day, time } = action.payload.data;

  const payload = {
    delivery_preference: {
      day: day,
      time: time,
    },
  };
  try {
    const { res } = yield call(putApi, `orders/edit_date/${order_id}`, payload);
    yield put(
      alertActions.openAlert({
        message: i18n.t('orders.sagas.date_success'),
        type: 'success',
        delay: 2000,
      })
    );
    yield put(actions.editDate.success(res.data));
    yield delay(2000);
    yield put(modalActions.closeModal());
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('orders.sagas.edit_error'),
        type: 'error',
        delay: 2000,
      })
    );
    yield put(modalActions.closeModal());
    return yield put(actions.editDate.failure({ error }));
  }
}

function* editOrderWorker(action) {
  const message = i18n.t('orders.sagas.edit_payment_message');
  const operationName = i18n.t('orders.sagas.edit_payment');
  const confirmed = yield call(onConfirmation, operationName, message);
  if (!confirmed) {
    return;
  }

  const { order, id } = action.payload;

  const { res, error } = yield call(putMkp, `/shopping/orders/${id}`, {
    order,
  });

  if (isNil(res) || res.status >= 400 || error) {
    let errorMsg = '';
    if (isNil(error)) {
      errorMsg = i18n.t('orders.sagas.server_error');
    } else if (!isNil(error.data.errors[0])) {
      errorMsg = error.data.errors[0].request_error;
    }

    yield put(
      alertActions.openAlert({
        message: i18n.t('orders.sagas.edit_order_error') + errorMsg,
        type: 'error',
        delay: 5000,
      })
    );
    yield put(modalActions.closeModal());
    return yield put(actions.create.failure({ error }));
  }

  yield put(
    alertActions.openAlert({
      message: i18n.t('orders.sagas.edit_order_success'),
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.create.success({ order: normalize([res.order]) }));

  yield delay(2000);
  yield put(modalActions.closeModal());

  history.push('/orders');
}

function* deleteOrderPayment(action) {
  yield put(actions.deleteOrderPayment.start());

  const operationName = i18n.t('orders.sagas.obsolete');
  const message = i18n.t('orders.sagas.obsolete_message');
  const confirmed = yield call(onConfirmation, operationName, message);
  if (!confirmed) {
    return;
  }

  const { order_id, payment_id } = action.payload;

  const { res, error } = yield call(
    del,
    `orders/${order_id}/payment/${payment_id}`
  );

  if (!res || error) {
    yield put(modalActions.closeModal());
    yield put(
      alertActions.openAlert({
        message: i18n.t('orders.sagas.delete_payment_error'),
        type: 'error',
        delay: 2000,
      })
    );
    yield delay(2000);
    return yield put(actions.deleteOrderPayment.failure({ error }));
  }

  yield put(
    alertActions.openAlert({
      message: i18n.t('orders.sagas.delete_payment_success'),
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.deleteOrderPayment.success());
  yield delay(2000);
  yield put(modalActions.closeModal());
}

function* createOrderPayment(action) {
  yield put(actions.createOrderPayment.start());

  const { activity } = action.payload.dto;

  activity.delivery_id = action.payload.delivery_id;

  const { res, error } = yield call(
    post,
    `orders/${activity.order_id}/payment`,
    activity
  );

  if (!res || error) {
    yield put(modalActions.closeModal());
    yield put(
      alertActions.openAlert({
        message: i18n.t('orders.sagas.create_payment_error'),
        type: 'error',
        delay: 2000,
      })
    );
    yield delay(2000);
    return yield put(actions.createOrderPayment.failure({ error }));
  }

  yield put(
    alertActions.openAlert({
      message: i18n.t('orders.sagas.create_payment_success'),
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.createOrderPayment.success());

  yield delay(2000);
  yield put(modalActions.closeModal());
}

export function* uploadExcelWorker(action) {
  try {
    const { file, status } = action.payload;
    yield put(actions.uploadExcel.start());

    const extension = file.name.replace(/^.*\./, '');

    if (availableExtensions.includes(extension)) {
      const newFile = yield call(readExcel, file, status);

      const formatBody = { file: [...newFile.result] };

      const { res } = yield call(postMkp, 'orders/import', formatBody);
      if (res.errors.length) {
        yield put(actions.uploadExcel.failure());
        yield put(
          alertActions.openAlert({
            message: i18n.t('orders.sagas.upload_excel_error'),
            type: 'error',
            delay: 2000,
          })
        );
        return yield put(
          modalActions.openModal({
            type: 'errorsImports',
            data: {
              success: res.ok,
              errors: [...res.errors],
            },
          })
        );
      }
      yield put(actions.uploadExcel.success());
      return yield put(
        alertActions.openAlert({
          message: i18n.t('orders.sagas.upload_excel_success'),
          type: 'success',
          delay: 2000,
        })
      );
    } else {
      yield put(actions.uploadExcel.failure());
      return yield put(
        alertActions.openAlert({
          message: i18n.t('orders.sagas.upload_error'),
          type: 'error',
          delay: 2000,
        })
      );
    }
  } catch (error) {
    yield put(actions.uploadExcel.failure());
    return yield put(
      alertActions.openAlert({
        message: i18n.t('orders.sagas.upload_error'),
        type: 'error',
        delay: 2000,
      })
    );
  }
}

function* makeUploadCsvRequestOrder(action) {
  const formData = new FormData();
  formData.append('file', action.payload.file);

  return yield call(MarketplaceApi.uploadCsvOrder, formData);
}

export function* uploadCsvOrder(action) {
  yield put(actions.uploadCsvOrder.start());
  try {
    const res = yield yield makeUploadCsvRequestOrder(action);
    console.log(res, res.result, '----RESULT CARGA ORDER');
    if (res.result === undefined) {
      yield put(actions.uploadCsvOrder.success());
      yield put(modalActions.closeModal());
      yield put(
        modalActions.openModal({
          type: 'csvErrorsOrder',
          data: { ...action.payload, messages: res.messages },
        })
      );
    } else {
      yield put(actions.uploadCsvOrder.success());
      yield put(
        alertActions.openAlert({
          message: `${i18n.t('stock.sagas.imported')} ${res.result} ${i18n.t(
            'stock.sagas.elements'
          )}`,
          type: 'success',
        })
      );
      if (action.payload.option === ORDER_CREATE) {
        yield put(actions.fetchAll());
      }
      return yield put(modalActions.closeModal());
    }
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: 'Hubo un error al subir la orden',
        type: 'error',
      })
    );
  }
}

export function* donwloadTemplateOrder() {
  yield put(actions.orderCsvDownload.start());
  try {
    const res = yield call(MarketplaceApi.getDonwloadOrder);
    const { data } = res;
    const fileName = getFileNameFromServerOrBackup(
      res.headers['Content-Disposition'],
      'planilla_importacion_order.xlsx'
    );
    yield put(actions.orderCsvDownload.success());
    fileDownload(data, fileName);
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: 'Hubo un error al descargar el csv de ordenes',
        type: 'error',
      })
    );
    yield put(actions.orderCsvDownload.failure({ error }));
  }
}

export function* createWatcher() {
  yield takeEvery(actions.create.type, createWorker);
}

export function* fetchAllWatcher() {
  yield takeEvery(actions.fetchAll.type, fetchAllWorker);
}

export function* fetchCurrentStockWatcher() {
  yield takeEvery(actions.getCurrentStock.type, fetchCurrentStockWorker);
}

export function* fetchOneWatcher() {
  yield takeEvery(actions.fetchOne.type, fetchOneWorker);
}

export function* updateDeliveryWatcher() {
  yield takeEvery(actions.updateDelivery.type, updateDeliveryWorker);
}

export function* fetchOrdersOfDayWatcher() {
  yield takeEvery(actions.fetchOrdersOfDay.type, fetchOrdersOfDayWorker);
}

export function* exportOrderDetailWatcher() {
  yield takeEvery(actions.exportOrders.type, exportOrderDetail);
}

export function* createLogisticsWatcher() {
  yield takeEvery(actions.createLogistics.type, createLogistics);
}

export function* deleteOrderWatcher() {
  yield takeEvery(actions.deleteOrder.type, deleteOrderWorker);
}

export function* editDateOrderWatcher() {
  yield takeEvery(actions.editDate.type, editDateOrderWorker);
}

export function* editOrderWatcher() {
  yield takeEvery(actions.editOrder.type, editOrderWorker);
}

export function* deleteOrderPaymentWatcher() {
  yield takeEvery(actions.deleteOrderPayment.type, deleteOrderPayment);
}
export function* createOrderPaymentWatcher() {
  yield takeEvery(actions.createOrderPayment.type, createOrderPayment);
}
export function* uploadExcelWatcher() {
  yield takeEvery(actions.uploadExcel.type, uploadExcelWorker);
}
export function* donwloadTemplateOrderWatcher() {
  yield takeEvery(actions.orderCsvDownload.type, donwloadTemplateOrder);
}

export function* uploadCsvOrderWatcher() {
  yield takeEvery(actions.uploadCsvOrder.type, uploadCsvOrder);
}

export default [
  createWatcher,
  createLogisticsWatcher,
  fetchAllWatcher,
  fetchCurrentStockWatcher,
  fetchOneWatcher,
  updateDeliveryWatcher,
  fetchOrdersOfDayWatcher,
  exportOrderDetailWatcher,
  deleteOrderWatcher,
  editOrderWatcher,
  editDateOrderWatcher,
  deleteOrderPaymentWatcher,
  createOrderPaymentWatcher,
  uploadExcelWatcher,
  donwloadTemplateOrderWatcher,
  uploadCsvOrderWatcher,
];
