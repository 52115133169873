/* eslint-disable */

import React from 'react';
import moment from 'moment';
import { isNil } from 'ramda';
import i18n from 'app/configs/i18n';

export default {
  id: {
    label: i18n.t('modals.view_order.bank_id'),
    children: (props) => (
      <td key='col-0'>{props.item.stock.external_reference}</td>
    ),
  },
  name: {
    label: i18n.t('modals.view_order.product'),
    children: (props) => (
      <td key='col-1' className='update-stock__bold'>
        {props.item.variant.product.name}
      </td>
    ),
  },
  description: {
    label: i18n.t('modals.view_order.description'),
    children: (props) => (
      <td key='col-2'>{props.item.variant.product.description}</td>
    ),
  },
  brand: {
    label: i18n.t('modals.view_order.brand'),
    children: (props) => (
      <td key='col-3'>{props.item.variant.product.brand_name}</td>
    ),
  },
  expiration: {
    label: i18n.t('modals.view_order.expiration'), // TODO: should remove
    children: (props) => (
      <td key='col-4'>
        {!isNil(props.item.stock.expires_on)
          ? moment(props.item.stock.expires_on).utc().format('DD/MM/YY')
          : 'Sin fecha'}
      </td>
    ),
  },
  quantity: {
    label: i18n.t('modals.view_order.quantity'),
  },
  total_weight: {
    label: i18n.t('modals.view_order.total_weight'),
  },
  total_price: {
    label: i18n.t('modals.view_order.total_cost'),
  },
};
