import { filter, pick } from 'ramda';
import { createSelector } from 'reselect';

import { NAMESPACE } from '../configs/constants';

import * as modalsSelectors from '../../modals/store/selectors';
import * as vehiclesSelectors from '../../vehicles/store/selectors';

export const shippers = (state) => state[NAMESPACE].items;
export const status = (state) => state[NAMESPACE].status;
export const error = (state) => state[NAMESPACE].error;

export const getShipper = createSelector(
  shippers,
  vehiclesSelectors.vehicles,
  (shippers, vehicles) => (shipperId) => {
    const shipper = filter((item) => item.id === shipperId)(shippers)[0];

    if (!shipper) return shipper;

    return {
      ...shipper,
      vehicles: pick(shipper.vehicles, vehicles),
    };
  }
);

export const getShipperFromModal = createSelector(
  getShipper,
  modalsSelectors.data,
  (shipperFn, modalData) => shipperFn(modalData.shipperId)
);
