import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import i18 from '../../app/configs/i18n';

class ErrorCatcher extends React.Component {
  state = { error: null };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return (
        <a href='#error' onClick={() => Sentry.showReportDialog()}>
          {i18.t('login.error')}
          <span role='img' aria-label='cara triste'>
            😓
          </span>
          {i18.t('login.report')}
        </a>
      );
    }

    return children;
  }
}

ErrorCatcher.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorCatcher;
