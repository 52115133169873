import React from 'react';
import PropTypes from 'prop-types';
import Modal from './UpdateStock';
import { adaptToOutputDto, createStateFromProduct } from '../helpers';

class UpdateStockContainer extends React.Component {
  onConfirm = (values) => {
    const dto = adaptToOutputDto(values);
    this.props.onConfirm(dto, values.id);
  };

  render() {
    const initialData = createStateFromProduct(
      this.props.data.full_product,
      this.props.groups_availability
    );
    const newGroups = this.props.groups_availability.map((item) => ({
      ...item,
      [item.code]: this.props.data.full_product.groups_availability[item.code],
    }));

    return (
      <Modal
        {...this.props}
        initialData={initialData}
        onConfirm={this.onConfirm}
        groups_availability={newGroups}
      />
    );
  }
}

UpdateStockContainer.propTypes = {
  step: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default UpdateStockContainer;
