import React from 'react';
import propTypes from 'prop-types';
import Select from 'react-select';
import cn from 'classnames';

import Button from 'components/Button';

import './statics/styles.css';

const SelectList = (props) => {
  const {
    className,
    list,
    selectedList,
    onAddSelect,
    onRemoveOption,
    onSelectOption,
    placeholder,
    addMoreLabel,
    children,
    defaultInputValue,
  } = props;

  return (
    <div className={cn('-select-list', className)}>
      {selectedList.map((each, i) => (
        <div key={i} className='-select-list__list mb-20'>
          {selectedList.length > 1 && i > 0 && (
            <Button transparent onClick={() => onRemoveOption(each)}>
              <div className='-select-list__list__remove'>+</div>
            </Button>
          )}

          <Select
            className='-select-list__list__select'
            options={list}
            value={each}
            placeholder={placeholder}
            onChange={onSelectOption}
            defaultInputValue={defaultInputValue}
          />
          {typeof children === 'function'
            ? children({ ...props, parent: each })
            : children}
        </div>
      ))}
      <Button
        transparent
        disabled={selectedList.includes('')}
        onClick={onAddSelect}
      >
        + {addMoreLabel.toUpperCase()}
      </Button>
    </div>
  );
};

SelectList.propTypes = {
  list: propTypes.oneOfType([propTypes.array, propTypes.object]).isRequired,
  selectedList: propTypes.arrayOf([]).isRequired,
  onAddSelect: propTypes.func.isRequired,
  onRemoveOption: propTypes.func.isRequired,
  onSelectOption: propTypes.func.isRequired,
  placeholder: propTypes.string.isRequired,
  addMoreLabel: propTypes.string.isRequired,
  className: propTypes.string.isRequired,
  defaultInputValue: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
};

export default SelectList;
