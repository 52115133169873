import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';

import './styles.css';

const Slider = (props) => {
  const { slide, length, onNextSlide, onPreviousSlide } = props;

  return (
    <div className='slider'>
      <div
        onClick={onPreviousSlide}
        className={cn('slider__arrow slider__arrow--backwards', {
          'slider__arrow--disabled': slide < 1,
        })}
      />
      {React.cloneElement(props.children, { slide })}
      <div
        onClick={onNextSlide}
        className={cn('slider__arrow slider__arrow--forwards', {
          'slider__arrow--disabled': slide === length - 1,
        })}
      />
    </div>
  );
};

Slider.propTypes = {
  children: propTypes.node.isRequired,
  onPreviousSlide: propTypes.func.isRequired,
  onNextSlide: propTypes.func.isRequired,
  slide: propTypes.number.isRequired,
  length: propTypes.number.isRequired,
};

export default Slider;
