import i18n from 'app/configs/i18n';

export const DELIVERY_PREFERENCES = [
  {
    label: i18n.t('orders.from_stock.morning'),
    value: 'morning',
  },
  {
    label: i18n.t('orders.from_stock.afternoon'),
    value: 'afternoon',
  },
  {
    label: i18n.t('orders.from_stock.no_delivery'),
    value: '',
  },
];

// TODO remove after testing and extract to utils
export const PAYMENT_METHODS = getPaymentMedhods();

function getPaymentMedhods() {
  var network = localStorage.getItem('active_network');
  switch (network) {
    case 'PR':
      return [
        { label: i18n.t('orders.from_stock.paypal'), value: 'paypal' },
        { label: i18n.t('orders.from_stock.other'), value: 'otro' },
      ];
    default:
      return [
        { label: i18n.t('orders.from_stock.rapi_pago'), value: 'rapipago' },
        { label: i18n.t('orders.from_stock.pago_facil'), value: 'pagofacil' },
        { label: i18n.t('orders.from_stock.other'), value: 'otro' },
      ];
  }
}
