import React from 'react';
import propTypes from 'prop-types';
import Alert from './components/Alert';

class AlertContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      msg: '',
    };

    this.onToggleAlert = this.onToggleAlert.bind(this);
  }

  componentDidMount() {
    this.setState({
      display: this.props.display,
    });
  }

  componentDidUpdate(oldProps) {
    const { msg, display } = this.props;

    if (msg !== oldProps.msg || display !== oldProps.display) {
      this.setState({
        msg: msg,
        display: display,
      });
    }
  }

  onToggleAlert() {
    const modalNextState = !this.state.display;

    this.setState({
      display: modalNextState,
    });

    if (this.props.hasOwnProperty('onClose') && !modalNextState) {
      this.props.onClose();
    }
  }

  render() {
    return (
      <Alert
        {...this.props}
        display={this.state.display}
        onToggleAlert={this.onToggleAlert}
      />
    );
  }
}

AlertContainer.propTypes = {
  display: propTypes.bool.isRequired,
  msg: propTypes.string.isRequired,
  onClose: propTypes.func.isRequired,
};

export default AlertContainer;
