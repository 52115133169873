export const Tooltips = {
  external_reference: ' Identificación alfanumérica, puede corresponderse con ' +
    'el sistema de gestión si es que lo hay. ej.: 00012',
  product: 'Es la identificación del producto que será visible en la tienda. ' +
    'Idealmente usa el mismo nombre que muestra el envase. ej.: Agua Mineral',
  description: 'No es obligatorio completar. Agrega detalles sobre el producto. ej.: Sin Gas',
  brand: 'Si aparece en el envase. Es importante este dato para facilitar la trazabilidad de los productos.',
  category_id: 'Grupo de productos al que pertenece.',
  unit_type: 'Hace referencia a la unidad de consumo. ej.: botellas, vasitos (de yogur), paquetes (de yerba)',
  units_multiplier: 'En la app lo que ofrecemos son unidades comercializables pero expresadas en unidades de consumo. ' +
    'Pero no decimos 1 pack de botellas, 2 packs de botellas, ' +
    '3 packs de botellas sino que decimos 6 botellas, 12, 18 (este sería el multiplicador).',
  units_per_pack: 'Hace referencia a la unidad de comercialización o unidad de venta, es decir la unidad que no es divisible para ofrecer.\n' +
    'ej.: en un pack de 6 botellas hay 6 unidades por bulto. ' +
    'Si hablamos de un cajón de frutas o un bolsón de arroz las unidades serían: 1.',
  unit_measure: 'Se refiere al peso dentro de cada unidad de consumo que está en la unidad de comercialización (o pack).',
  unit_measure_name: 'Corresponde a la unidad con que se mide el peso de cada unidad de consumo. ' +
    'Se asume que la densidad es igual a 1 para poder calcular el precio por kg si es que aplica.',
  weight: 'Se calcula automáticamente en función del peso por unidad final y la cantidad de unidades por pack.',
  light_weight: 'Define si el bulto es liviano en relación a su volúmen, por ejemplo un paquete de snacks. ' +
    'Por defecto es no.',
  need_cooling: 'Define si el producto debe conservarse a bajas temperaturas. Por defecto es no.'
};