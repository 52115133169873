import React from 'react';
import propTypes from 'prop-types';
import Gridstyles from '../css/Grid.module.css';
import styles from '../css/AddNewProduct.module.css';
import { SelectField, LabeledInputField } from './FormHelpers';
import i18n from '../../../../../app/configs/i18n';

export const AddNewProduct = (props) => {
  const {
    id,
    product,
    app_name,
    quantity,
    onAddProductChange,
    products,
    removeSelectedProduct,
    position,
  } = props;

  return (
    <div className={`${styles.container} ${Gridstyles.fullRow}`}>
      <div className={`${Gridstyles.addProductKey} ${styles.key}`}>
        <button
          type='button'
          onClick={() => removeSelectedProduct(id)}
          className={`${styles.deleteButton}`}
        >
          x
        </button>
        {position + 1 + '. '}
      </div>
      <div className={Gridstyles.selectProductRow}>
        <SelectField
          options={products}
          onChange={(_, value) => {
            onAddProductChange(props.id, { product: value });
          }}
          name='product'
          label=''
          value={product}
          placeholder={i18n.t('modals.create_combo.product')}
        />
      </div>
      <div className={Gridstyles.selectProductDisplayRow}>
        <LabeledInputField
          onChange={(e) => {
            onAddProductChange(props.id, { [e.target.name]: e.target.value });
          }}
          name='app_name'
          label=''
          value={app_name}
          placeholder={i18n.t('modals.create_combo.appName')}
        />
      </div>
      <div className={Gridstyles.selectProductQuantityRow}>
        <LabeledInputField
          onChange={(e) => {
            onAddProductChange(props.id, {
              [e.target.name]: Number(e.target.value),
            });
          }}
          name='quantity'
          label=''
          value={quantity}
          placeholder='0'
        />
      </div>
    </div>
  );
};

AddNewProduct.propTypes = {
  id: propTypes.number.isRequired,
  product: propTypes.shape({}).isRequired,
  app_name: propTypes.string.isRequired,
  quantity: propTypes.number.isRequired,
  onAddProductChange: propTypes.func.isRequired,
  removeSelectedProduct: propTypes.func.isRequired,
  products: propTypes.arrayOf([]).isRequired,
  position: propTypes.number.isRequired,
};

export default AddNewProduct;
