import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from './components/Modal';

import * as actions from '../../store/actions';
import * as shipperSelector from '../../../shippers/store/selectors';
import i18n from '../../../../app/configs/i18n';
import { withHooks } from 'hooks/withHooks';

class CreateInstitutionUserContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };
  }

  onChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onConfirm = () => {
    const { formData } = this.state;

    const nextShipper = {
      ...formData,
      gateway_id: 1, // TODO: should remove this from api
      verified: true,
    };

    this.props.onConfirm(nextShipper);
  };

  render() {
    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.create_driver.success')}
        onConfirm={this.onConfirm}
        onChange={this.onChange}
        formData={this.state.formData}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  status: shipperSelector.status(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onConfirm: (shipper) =>
    dispatch({ type: props.onConfirm, payload: { shipper } }),
  onCancel: () => dispatch(actions.closeModal()),
});

CreateInstitutionUserContainer.propTypes = {
  onConfirm: propTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(CreateInstitutionUserContainer));
