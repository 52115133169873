import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import { buildDateString } from '../../utils/date';
import sort from 'immutable-sort';
import { useTranslation } from 'react-i18next';

export const TripAmountAudits = ({ audits }) => {
  const { t } = useTranslation();

  const amountAudits = audits.filter((x) => x.field === 'amount');
  const sortedAudits = sort(
    amountAudits,
    (a, b) => a.created_at < b.created_at
  );

  const auditText = (audit) => {
    const date = new Date(audit.created_at);
    const dateString = buildDateString(date);
    return ` ${t('trips.prev')} ${audit.original_value}${t('trips.edited_by')}${
      audit.author_email
    }${t('trips.at')}${dateString}`;
  };

  const messages = (audits) => {
    return audits
      .map((x) => auditText(x))
      .map((x) => <p className={styles.message}>{x}</p>);
  };

  return <div className={styles.container}>{messages(sortedAudits)}</div>;
};

TripAmountAudits.propTypes = {
  audits: PropTypes.arrayOf([]).isRequired,
};
