import React from 'react';
import { Formik } from 'formik';
import styles from '../../UpdateStock/css/Modal.module.css';
import Button from '../../../../../components/Button';
import { validationSchemas, validationSchemasWithCombo } from '../validations';
import i18n from '../../../../../app/configs/i18n';

export default class Wizard extends React.Component {
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues,
    };
  }

  next = (values) =>
    this.setState((state) => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }));

  previous = (callback = () => {}) =>
    this.setState(
      (state) => ({
        page: Math.max(state.page - 1, 0),
      }),
      () => callback()
    );

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values, bag) => {
    const { children, onSubmit, item_checked } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    this.next(values);
    if (isLastPage) {
      return onSubmit(
        { ...values, address_id: values.address.id, rescued: item_checked },
        bag
      );
    } else {
      bag.setTouched({});
      bag.setSubmitting(false);
      this.next(values);
    }
  };

  render() {
    const { children, productIsRegular, combo } = this.props;
    const { page, values } = this.state;

    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    return (
      <Formik
        initialValues={values}
        enableReinitialize={false}
        validate={this.validate}
        validationSchema={
          productIsRegular
            ? validationSchemas[page]
            : validationSchemasWithCombo(combo)[page]
        }
        onSubmit={this.handleSubmit}
        render={({ handleSubmit, isSubmitting, isValid, validateForm }) => {
          return (
            <form onSubmit={handleSubmit}>
              {activePage}
              <div className={styles.buttons}>
                <Button
                  name='btnCanc'
                  type='button'
                  onClick={this.props.onCancel}
                  outline
                >
                  {i18n.t('modals.create_stock.cancel')}
                </Button>
                {page > 0 && (
                  <Button
                    name='btnPrev'
                    type='button'
                    onClick={() => this.previous(validateForm)}
                    outline
                  >
                    {i18n.t('modals.create_stock.previous')}
                  </Button>
                )}
                {!isLastPage && (
                  <Button
                    name='btnNext'
                    type='submit'
                    primary
                    disabled={!isValid}
                  >
                    {i18n.t('modals.create_stock.next')}
                  </Button>
                )}
                {isLastPage && (
                  <Button
                    name='btnSave'
                    type='submit'
                    disabled={isSubmitting || !isValid}
                    primary
                  >
                    {i18n.t('modals.create_stock.submit')}
                  </Button>
                )}
              </div>
            </form>
          );
        }}
      />
    );
  }
}
