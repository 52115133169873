import React from 'react';
import propTypes from 'prop-types';

import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const Step3 = (props) => {
  const { shippers, onChange, formData } = props;
  const { t } = useTranslation();

  return (
    <div className='view_trip_detail__body'>
      <div className='view_trip_detail__body__text mb-20'>
        {t('modals.create_trip.step_3.message')}
      </div>
      <Select
        options={shippers}
        onChange={onChange}
        value={formData['shipper']}
        placeholder={t('modals.create_trip.step_3.select')}
      />
    </div>
  );
};

Step3.propTypes = {
  onChange: propTypes.func.isRequired,
  formData: propTypes.shape({}).isRequired,
  shippers: propTypes.arrayOf([]).isRequired,
};

export default Step3;
