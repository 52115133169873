import React from 'react';
import propTypes from 'prop-types';
import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import styles from '../css/Modal.module.css';
import Button from '../../../../../components/Button';
import CreateVariant from './CreateVariant';
import ButtonWithSpinner from '../../../../../components/ButtonWithSpinner/ButtonWithSpinner';
import i18n from '../../../../../app/configs/i18n';
import { validationSchema } from '../validations';
import { adaptToOutputDto, initialValues } from '../helpers';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

class CreateVariantContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      units_per_pack: 1,
      unit_measure: 1,
      weight: 1,
      parent_category_id: undefined,
      category_id: undefined,
    };
  }

  async handleInputChange(event) {
    if (!event) {
      await this.setState({ unit_measure: 1 });
    } else {
      await this.setState({ [event.target.name]: event.target.value });
    }
    await this.setState({
      weight: this.state.units_per_pack * this.state.unit_measure,
    });
  }

  async handleParentCategoryChange(event) {
    await this.setState({ category_id: null });
    await this.setState({ parent_category_id: event });
  }

  async changeCategory(event) {
    await this.setState({ category_id: event });
  }

  onConfirm = (values) => {
    values.units_per_pack = this.state.units_per_pack;
    values.unit_measure = this.state.unit_measure;
    values.weight = this.state.weight;
    values.category_id = this.state.category_id.id;
    const dto = adaptToOutputDto(values);
    this.props.onConfirm(dto);
  };

  render() {
    const { onCancel, confirmStatus } = this.props;
    const {
      products,
      parent_categories,
      brands,
      unitTypes,
      measureNames,
    } = this.props;
    const initValues = initialValues(measureNames);
    return (
      <Modal
        className='create-variant-modal'
        title={<Title step={1} />}
        onCancel={onCancel}
        {...this.props}
      >
        <ModalBody className='modal-detail__body'>
          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={this.onConfirm}
          >
            {({ isSubmitting, handleSubmit, isValid, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <CreateVariant
                    products={products}
                    categories={parent_categories}
                    brands={brands}
                    changeCategory={(c) => this.changeCategory(c)}
                    handleParentCategoryChange={(event) =>
                      this.handleParentCategoryChange(event)
                    }
                    unitTypes={unitTypes}
                    measureNames={measureNames}
                    values={values}
                    state={this.state}
                    handleInputChange={(event) => this.handleInputChange(event)}
                  />
                  <div className={styles.buttons}>
                    <Button
                      type='button'
                      datacy={'btnCancel'}
                      onClick={onCancel}
                      outline
                    >
                      {i18n.t('modals.create_variant.close')}
                    </Button>
                    <ButtonWithSpinner
                      type='submit'
                      status={confirmStatus}
                      datacy={'btnSave'}
                      disabled={isSubmitting || !isValid}
                      primary
                    >
                      {i18n.t('modals.create_variant.submit')}
                    </ButtonWithSpinner>
                  </div>
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

const Title = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.title}>{t('modals.create_variant.add')}</div>
    </div>
  );
};

CreateVariantContainer.propTypes = {
  onConfirm: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
  confirmStatus: propTypes.string.isRequired,
};

export default CreateVariantContainer;
