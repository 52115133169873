import React from 'react';
import propTypes from 'prop-types';
import { Field } from 'react-final-form';
import errorStyles from '../css/Error.module.css';
import LabeledInput from '../../../../../components/Input/LabeledInput';
import CreatableSelect from '../../../../../components/Select/CreatableSelect';
import { minValue } from '../validations';
import DatePicker from 'components/DatePicker';
import { useTranslation } from 'react-i18next';

export const StockAmount = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Field name='quantity'>
        {({ input }) => (
          <p className='categories__poppins-bold create-stock__categories-left-title'>
            {input.value
              ? `${input.value} ${t('modals.update_stock.units')}`
              : t('modals.update_stock.no_units')}
          </p>
        )}
      </Field>
    </div>
  );
};

export const InputField = ({ name, ...rest }) => (
  <Field name={name} validate={minValue(0)}>
    {({ input, meta }) => (
      <div>
        <LabeledInput {...input} {...meta} {...rest} />
        {meta.error && meta.touched && (
          <span className={errorStyles.error}>{meta.error}</span>
        )}
      </div>
    )}
  </Field>
);

export const CreatableSelectField = ({ name, ...rest }) => (
  <Field name={name}>
    {({ input, meta }) => <CreatableSelect {...input} {...meta} {...rest} />}
  </Field>
);

export const DatePickerField = ({ name, ...rest }) => (
  <Field name={name}>
    {({ input, meta }) => <DatePicker {...input} {...meta} {...rest} />}
  </Field>
);

InputField.propTypes = {
  name: propTypes.string.isRequired,
};

CreatableSelectField.propTypes = {
  name: propTypes.string.isRequired,
};

DatePickerField.propTypes = {
  name: propTypes.string.isRequired,
};
