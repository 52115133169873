import React from 'react';
import propTypes from 'prop-types';
import TimetableSingle from './TimetableSingle';

class Timetable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      timetable: [],
    };
  }

  onClickExpand = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  onChange = (e) => {
    const nextTimetable = [...this.state.timetable, { ...e.target.value }];

    this.setState(
      {
        timetable: nextTimetable,
      },
      () => {
        if (this.props.hasOwnProperty('onChange')) {
          this.props.onChange({
            target: { name: this.props.name, value: nextTimetable },
          });
        }
      }
    );
  };

  render() {
    return (
      <TimetableSingle
        {...this.props}
        expanded={this.state.expanded}
        onChange={this.onChange}
        onClickExpand={this.onClickExpand}
      />
    );
  }
}

Timetable.propTypes = {
  onChange: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
};

export default Timetable;
