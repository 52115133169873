import React from 'react';
import ButtonWithSpinner from './ButtonWithSpinner';
import propTypes from 'prop-types';

class ButtonWithSpinnerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'init',
    };
  }

  componentDidMount() {
    this.setState({
      status: this.props.status || 'init',
    });
  }

  componentDidUpdate(oldProps) {
    if (this.props.rerender !== oldProps.rerender) {
      this.setState({ status: 'init' });
    }

    if (this.props.status !== oldProps.status) {
      this.setState({
        status: this.props.status,
      });
    }
  }

  onClick = () => {
    if (!this.props.noSpin) {
      this.setState({
        status: 'pending',
      });
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    const { status } = this.state;

    return (
      <ButtonWithSpinner
        {...this.props}
        status={status}
        onClick={this.onClick}
        datacy={this.props.datacy}
      />
    );
  }
}

ButtonWithSpinnerContainer.propTypes = {
  onClick: propTypes.func.isRequired,
  noSpin: propTypes.bool.isRequired,
  status: propTypes.string.isRequired,
};

export default ButtonWithSpinnerContainer;
