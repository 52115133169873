import React from 'react';
import { connect } from 'react-redux';

import Modal from './components/Modal';
import * as modalActions from '../../store/actions';
import * as paymentSelectors from 'modules/payments/store/selectors';
import i18n from '../../../../app/configs/i18n';

class CreatePaymentCouponContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        method: '',
      },
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  }

  render() {
    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.create_payment_cupon.success')}
        formData={this.state.formData}
        onChange={this.onChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  paymentStatus: paymentSelectors.status(state),
  paymentCoupon: paymentSelectors.getPaymentGatewayId(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onConfirm: (payload) => dispatch({ type: props.onConfirm, payload }),
  onCancel: () => {
    dispatch({ type: props.onCancel });
    dispatch(modalActions.closeModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePaymentCouponContainer);
