import { handleActions } from 'redux-actions';
import { normalize } from '../../../utils';

import * as actions from './actions';
import * as permissionsActions from 'modules/permissions/store/actions';

/*
  TODO: institution payments should be stored in this reducer, not in institution. It breaks modularity.
    @author: Tom
 */
const initialState = {
  status: 'init',
  error: '',
  items: {},
  current_payments: {},
  balancesByInstitutionId: {},
  current_payee: {},
};

const reducer = handleActions(
  {
    [actions.create.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.create.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        [action.payload.payments.id]: {
          ...action.payload.payments,
        },
      },
    }),

    [actions.create.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchPayee.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchPayee.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      current_payee: action.payload,
    }),

    [actions.fetchPayee.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.resetPayee.type]: (state, action) => ({
      ...state,
      current_payee: {},
    }),

    [actions.setPayment.type]: (state, action) => ({
      ...state,
      current_payments: {
        ...state.current_payments,
        [action.payload.payment.id]: action.payload.payment,
      },
    }),

    [actions.fetchPaymentsOf.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchPaymentsOf.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      current_payments: normalize(action.payload.payments),
    }),

    [actions.fetchPaymentsOf.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchBalances.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchBalances.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      balancesByInstitutionId: action.payload.balancesByInstitutionId,
    }),

    [actions.fetchBalances.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.obsolesce.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.obsolesce.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
    }),

    [actions.obsolesce.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.createUntrackedActivityPayment.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.createUntrackedActivityPayment.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      current_payments: {
        ...state.current_payments,
        [action.payload.payment.id]: action.payload.payment,
      },
    }),

    [actions.createUntrackedActivityPayment.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.cleanStatus.type]: (state) => ({
      ...state,
      status: 'init',
      error: '',
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
