import { connect } from 'react-redux';

import Modal from './components/CreateStockContainer';

import * as actions from '../../store/actions';
import * as selectHelpersActions from 'modules/selectHelpers/store/actions';
import * as selectHelpersSelectors from 'modules/selectHelpers/store/selectors';
import * as institutionsActions from 'modules/institutions/store/actions';
import { formatForSelect } from 'utils';
import * as institutionsSelectors from 'modules/institutions/store/selectors';

import { withHooks } from 'hooks/withHooks';

const mapStateToProps = (state) => ({
  institutions: formatForSelect(
    institutionsSelectors.institutions(state),
    'name'
  ),
  variants: selectHelpersSelectors.getVariantList(state)('product_variants'),
  addresses: selectHelpersSelectors.getAddressList(state),
  groups_availability: selectHelpersSelectors.categories(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onMount: () => {
    dispatch(selectHelpersActions.fetchVariants({ type: 'product_variants' }));
    //dispatch(selectHelpersActions.fetchAvailableStock({ type: 'stock' }));
    dispatch(institutionsActions.fetchAll());
  },
  onConfirm: (body) => {
    return dispatch({
      type: props.onConfirm,
      payload: { body, type: props.data.type },
    });
  },
  onCancel: () => dispatch(actions.closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Modal));
