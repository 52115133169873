import { call, put, select, takeEvery } from 'redux-saga/effects';
import { pipe } from 'ramda';

import { get, post, put as _put } from 'api-connect/dist/api';

import * as actions from './actions';
import * as alertActions from 'modules/alerts/store/actions';
import * as modalActions from 'modules/modals/store/actions';
import * as modalSelectors from 'modules/modals/store/selectors';

import { updateListWorker } from 'modules/vehicles/store/sagas';

import {
  upperToLodashAllKeys,
  buildVehicle,
  normalizeList,
  normalizeItem,
  buildVehicles,
} from '../utils/helpers';

import i18n from '../../../app/configs/i18n';

// Workers

export function* createShipper(action) {
  yield put(actions.create.start());

  const { shipper } = action.payload;

  //* CRUD action
  const { res, error } = yield call(post, 'shippers', shipper);

  if (error || res.status >= 400) {
    yield put(modalActions.closeModal());
    yield put(
      alertActions.openAlert({
        message: i18n.t('drivers.sagas.error'),
        type: 'error',
      })
    );
    return yield put(actions.create.failure({ error }));
  }

  yield put(actions.create.success({ shipper: res.shipper }));
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('drivers.sagas.create_success'),
      type: 'success',
      delay: 2000,
    })
  );
}

export function* fetchAllShipper() {
  try {
    yield put(actions.fetchAll.start());

    const { res: items, error } = yield call(get, 'shippers');

    if (error || items.status >= 400) {
      return yield put(actions.fetchAll.failure({ error: error }));
    }

    yield put(
      actions.fetchAll.success({
        shippers: normalizeList(items.shippers, 'vehicles'),
      })
    );
    yield call(updateListWorker, {
      payload: { vehicles: buildVehicles(items.shippers) },
    });
  } catch (error) {
    yield put(actions.fetchAll.failure({ error: 'Server error' }));
    yield put(
      alertActions.openAlert({
        message: i18n.t('drivers.sagas.server_error'),
        type: 'error',
      })
    );
  }
}

export function* fetchOneShipper(action) {
  yield put(actions.fetchOne.start());

  const { shipperId } = action.payload;

  const { res: item, error } = yield call(get, `shippers/${shipperId}`);

  if (error || item.status >= 400) {
    return yield put(actions.fetchOne.failure({ error: error }));
  }

  yield put(
    actions.fetchOne.success({
      shipper: normalizeItem(item.shipper, 'vehicles'),
    })
  );
  yield call(updateListWorker, {
    payload: { vehicles: buildVehicles([item.shipper]) },
  });
}

export function* updateShipper(action) {
  yield put(actions.update.start());

  const shipper = action.payload;
  const nextShipper = {
    first_name: shipper.first_name,
    last_name: shipper.last_name,
    phone_num: shipper.phone_num,
    birth_date: shipper.birth_date,
    email: shipper.email,
    gender: shipper.gender,
    verified: shipper.verified,
    password: shipper.password,
    password_confirmation: shipper.password_confirmation,
  };

  const { res, error } = yield call(
    _put,
    `shippers/${shipper.id}`,
    nextShipper
  );

  if (error || res.status >= 400) {
    yield put(modalActions.closeModal());
    yield put(
      alertActions.openAlert({
        message: i18n.t('drivers.sagas.error'),
        type: 'error',
      })
    );
    return yield put(actions.update.failure({ error: error }));
  }

  yield put(actions.update.success({ shipper: res }));
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('drivers.sagas.update_success'),
      type: 'success',
      delay: 2000,
    })
  );
}

export function* removeShipper() {}

export function* createBankAccount(action) {
  yield put(actions.createBankAccount.start());

  const { shipperId } = yield select(modalSelectors.data);
  const bankAccount = upperToLodashAllKeys(action.payload);

  const { res, error } = yield call(
    post,
    `shippers/${shipperId}/bank_accounts`,
    bankAccount
  );

  if (error) {
    yield put(modalActions.closeModal());
    yield put(
      alertActions.openAlert({
        message: i18n.t('drivers.sagas.error'),
        type: 'error',
      })
    );
    return yield put(actions.createBankAccount.failure({ error }));
  }

  yield put(actions.createBankAccount.success({ bankAccounts: res }));
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('drivers.sagas.create_account_success'),
      type: 'success',
      delay: 2000,
    })
  );
}

export function* createVehicle(action) {
  yield put(actions.createVehicle.start());

  const { shipperId } = yield select(modalSelectors.data);

  const vehicle = pipe(upperToLodashAllKeys, buildVehicle)(action.payload);

  const { res, error } = yield call(
    post,
    `shippers/${shipperId}/vehicles`,
    vehicle
  );

  if (error) {
    yield put(modalActions.closeModal());
    yield put(
      alertActions.openAlert({
        message: i18n.t('drivers.sagas.error'),
        type: 'error',
      })
    );
    return yield put(actions.createVehicle.failure({ error }));
  }

  yield put(actions.createVehicle.success({ vehicle: res }));
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('drivers.sagas.create_vehicle_success'),
      type: 'success',
      delay: 2000,
    })
  );
}

// Watchers

export function* fetchAllWatcher() {
  yield takeEvery(actions.fetchAll.type, fetchAllShipper);
}

export function* fetchOneWatcher() {
  yield takeEvery(actions.fetchOne.type, fetchOneShipper);
}

export function* createWatcher() {
  yield takeEvery(actions.create.type, createShipper);
}

export function* updateWatcher() {
  yield takeEvery(actions.update.type, updateShipper);
}

export function* removeWatcher() {
  yield takeEvery(actions.remove.type, removeShipper);
}

export function* createBankAccountWatcher() {
  yield takeEvery(actions.createBankAccount.type, createBankAccount);
}

export function* createVehicleWatcher() {
  yield takeEvery(actions.createVehicle.type, createVehicle);
}

export default [
  fetchAllWatcher,
  fetchOneWatcher,
  createWatcher,
  updateWatcher,
  removeWatcher,
  createBankAccountWatcher,
  createVehicleWatcher,
];
