import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as permissionsActions from 'modules/permissions/store/actions';

const initialState = {
  status: 'init',
  error: '',
  users: [],
  networks: [],
};

const reducer = handleActions(
  {
    [actions.fetchAllUsers.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.fetchAllUsers.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      users: action.payload.users,
    }),
    [actions.fetchAllUsers.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.getNetworks.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.getNetworks.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      networks: action.payload.networks,
    }),
    [actions.getNetworks.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.addUser.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.addUser.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),
    [actions.addUser.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.updateUser.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.updateUser.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      users: action.payload.users,
    }),
    [actions.updateUser.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.deleteUser.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.deleteUser.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      users: action.payload.users,
    }),
    [actions.deleteUser.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
