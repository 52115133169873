import React from 'react';
import cn from 'classnames';
import { isEmpty, isNil } from 'ramda';

import Input from './Input';
import propTypes from 'prop-types';
import styles from './LabeledInput.module.css';

const LabeledInput = (props) => {
  const {
    label,
    tooltip,
    type,
    value,
    onChange,
    placeholder,
    disabled,
    required,
    name,
  } = props;

  const isInvalid = (isNil(value) || isEmpty(value)) && required;
  return (
    <div className={styles.container}>
      <div>
        {label ? (
          <label
            className={cn(styles.label, { [styles.invalid]: isInvalid })}
            htmlFor='type'
          >
            {label}: {required ? '*' : ''}
          </label>
        ) : (
          ''
        )}
        {tooltip && <i className='far fa-question-circle' />}
      </div>
      <Input
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        type={type}
        value={value}
        step='any'
        required={required}
        name={name}
        {...props}
      />
    </div>
  );
};

LabeledInput.propTypes = {
  label: propTypes.string.isRequired,
  tooltip: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  placeholder: propTypes.string.isRequired,
  required: propTypes.bool.isRequired,
  name: propTypes.string.isRequired,
  disabled: propTypes.bool.isRequired,
  type: propTypes.string.isRequired,
  value: propTypes.string,
};

LabeledInput.defaultProps = {
  value: '',
};

export default LabeledInput;
