import moment from 'moment';

const GRAMS = 'gr';
const MILILITRE = 'ml';

export const inKilograms = (magnitude, unit = GRAMS) => {
  const divisor = unit === MILILITRE || unit === GRAMS ? 1000 : 1;
  return magnitude / divisor;
};

export const calculateWeight = ({
  unit_measure,
  unit_measure_name,
  units_per_pack,
  units_multiplier,
}) => {
  const unit_measure_number = unit_measure ? unit_measure : 0;
  const units_per_pack_number = units_per_pack ? units_per_pack : 0;
  const units_multiplier_number = units_multiplier ? units_multiplier : 1;
  const unit_measure_name_value = unit_measure_name
    ? unit_measure_name.value
    : undefined;
  return (
    inKilograms(unit_measure_number, unit_measure_name_value) *
    units_per_pack_number *
    units_multiplier_number
  );
};

export const makePlural = (word) => {
  //this function works only for 1 word.
  if (word.split(' ').length > 1 || word.length === 0) {
    return word;
  }

  // TODO check if this works for english
  const lettersArray = ['a', 'e', 'i', 'o', 'u', 'g'];
  const lastLetter = word.toLowerCase()[word.length - 1];
  return lettersArray.indexOf(lastLetter) > -1
    ? word.concat('s')
    : word.concat('es');
};

export const initialValues = (measureNames) => {
  const unit_measure_name = measureNames
    ? measureNames.find((measure) => measure.value === 'lb') ||
      measureNames.find((measure) => measure.value === 'kg')
    : null;
  return {
    units_multiplier: 1,
    units_per_pack: 1,
    unit_measure: 1,
    light_weight: false,
    need_cooling: false,
    product: null,
    brand: null,
    description: '',
    category_id: null,
    parent_category_id: null,
    unit_measure_name,
    unit_type: null,
    is_bulk: false,
    single_unit_measure_name: null,
  };
};

export const adaptToOutputDto = (values) => {
  // return { variant: {} };
  return {
    variant: {
      description: values.description,
      product: values.product.value,
      brand: values.brand.value,
      category_id: values.category_id,
      unit_type: values.unit_type ? values.unit_type.value : null,
      units_multiplier: values.units_multiplier,
      units_per_pack: values.units_per_pack,
      unit_measure: values.unit_measure,
      unit_measure_name: values.unit_measure_name.value,
      light_weight: values.light_weight,
      need_cooling: values.need_cooling,
      revised: true,
      revised_at: moment().utc()._d,
      weight: values.weight,
      is_bulk: values.is_bulk,
      single_unit_measure_name: values.single_unit_measure_name
        ? values.single_unit_measure_name.value
        : null,
    },
  };
};

export const onlyParseProductName = (product) =>
  product.replace(/ *\([^)]*\) */g, '');
