import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as permissionsActions from 'modules/permissions/store/actions';

const initialState = {
  status: 'init',
  error: '',
  items: {},
};

const reducer = handleActions(
  {
    [actions.updateList]: (state, action) => ({
      ...state,
      items: {
        ...state.items,
        ...action.payload.items,
      },
    }),

    [actions.create.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.create.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        [action.payload.vehicle.id]: {
          ...action.payload.vehicle,
        },
      },
    }),

    [actions.create.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.update.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.update.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        [action.payload.vehicle.id]: {
          ...action.payload.vehicle,
        },
      },
    }),

    [actions.update.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
