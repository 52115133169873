import envUrls from './envUrls';
import { getUploadUrl } from 'modules/stock/helpers/helpers';
import { TryCatchInterceptor } from './utils';
import ConstructorApi from './ConstructorApi';

class MarketplaceApi extends ConstructorApi {
  getProductCsv = async () => {
    const response = await this.api.get(`export/variants.csv`);
    return response.data;
  };

  getStockCsv = async () => {
    const response = await this.api.get(`export/stocks.csv`);
    return response.data;
  };

  getImportTemplate = async () => {
    const response = await this.api.get(`file/import_template`, {
      responseType: 'blob',
    });
    return response;
  };

  getDonwloadOrder = async () => {
    const response = await this.api.get(`file/orders_import_template`, {
      responseType: 'blob',
    });
    return response;
  };

  getOrderDetailExcel = async (date) => {
    const response = await this.api.get(`export/orders.xlsx?date=${date}`, {
      responseType: 'blob',
    });
    return response.data;
  };

  uploadCsv = async (type, validate, formData) => {
    const url = getUploadUrl(type, validate);
    const response = await this.api.post(url, formData, {
      'Content-Type': 'multipart/form-data',
    });
    return response.data;
  };

  uploadCsvOrder = async (formData) => {
    const response = await this.api.post(`shopping/orders`, formData, {
      'Content-Type': 'multipart/form-data',
    });
    return response.data;
  };

  updateStock = async (variant_id, payload, stock_id) => {
    const response = await this.api.put(
      `variants/${variant_id}/stocks/${stock_id}`,
      payload
    );
    return response;
  };

  fetchOfferableStock = async () => {
    const response = await this.api.get('v2/stocks/offerable');
    return response.data;
  };

  createOffer = async (offer) => {
    const response = await this.api.post('v2/offers', offer);
    return response.data;
  };

  getOffer = async (id) => {
    const response = await this.api.get(`v3/offers/${id}`);
    return response;
  };

  getPurchaseGroups = async () => {
    return TryCatchInterceptor(
      async () => await this.api.get('v2/purchase_groups')
    );
  };
}

export default new MarketplaceApi(envUrls.marketplace, 'Marketplace');
