import * as yup from 'yup';
import i18n from 'app/configs/i18n';

export const createValidationSchema = (newProducts) => {
  const variables = ['app_name', 'product', 'quantity'];
  let keys = [];
  if (!newProducts.length) {
    variables.map((value) => keys.push(value + '0'));
  } else {
    newProducts.map((ix) =>
      variables.map((value) => keys.push(value + `${ix}`))
    );
  }

  keys.push('name', 'price', 'presentation');

  const schema = keys.reduce((acc, value) => {
    if (value.includes('product')) {
      acc = {
        ...acc,
        [value]: yup
          .object()
          .required(i18n.t('modals.create_combo.validation.product'))
          .nullable(),
      };
    } else if (value.includes('app_name')) {
      acc = {
        ...acc,
        [value]: yup
          .string()
          .required(i18n.t('modals.create_combo.validation.appName'))
          .nullable(),
      };
    } else if (value.includes('quantity')) {
      acc = {
        ...acc,
        [value]: yup
          .number()
          .required(i18n.t('modals.create_combo.validation.quantity'))
          .nullable(),
      };
    } else {
      switch (value) {
        case 'name':
          acc = {
            ...acc,
            name: yup
              .string()
              .required(i18n.t('modals.create_combo.validation.name'))
              .nullable(),
          };
          break;
        case 'price':
          acc = {
            ...acc,
            price: yup
              .number()
              .required(i18n.t('modals.create_combo.validation.name'))
              .nullable(),
          };
          break;
        case 'presentation':
          acc = {
            ...acc,
            presentation: yup
              .object()
              .required(i18n.t('modals.create_combo.validation.name'))
              .nullable(),
          };
          break;
        default:
          break;
      }
    }
    return acc;
  }, {});
  return yup.object().shape(schema);
};
