import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ErrorCatcher from '../components/ErrorCatcher';
import { Route, Router as BrowserRouter, Switch } from 'react-router-dom';
import history from './configs/history';
import ValidatorRoutes from 'components/ValidatorRoutes';
import permissions from 'modules/permissions';
import routes from './routes';
import configureStore from './store/configureStore';
import MainLayout from 'components/MainLayout';
import Loading from 'components/Loading';

const store = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <ErrorCatcher>
          <BrowserRouter history={history}>
            <Switch>
              <Route exact path='/login' component={permissions.scenes.login} />
              <MainLayout>
                {routes.map(({ name, path, component }) => {
                  return (
                    <ValidatorRoutes
                      key={`${Math.random()}_${name}_${path}`}
                      name={name}
                      path={path}
                      component={component}
                    />
                  );
                })}
              </MainLayout>
            </Switch>
          </BrowserRouter>
        </ErrorCatcher>
      </Suspense>
    </Provider>
  );
};

export default App;
