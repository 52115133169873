import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import Confirmation from './components/Confirmation';

const mapDispatchToProps = (dispatch) => ({
  onConfirm: () => dispatch(actions.onConfirm()),
  onCancel: () => dispatch(actions.onCancel()),
});

export default connect(null, mapDispatchToProps)(Confirmation);
