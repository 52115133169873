import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as modalActions from '../../modals/store/actions';
import * as vehiclesActions from '../../vehicles/store/actions';
import * as permissionsActions from 'modules/permissions/store/actions';

const initialState = {
  status: 'init',
  error: {},
  items: [], // TODO: rename to shippers
};

const reducer = handleActions(
  {
    [actions.create.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.create.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.create.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: [...state.items, action.payload.shipper],
    }),

    [actions.fetchAll.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchAll.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchAll.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: action.payload.shippers,
    }),

    [actions.fetchOne.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchOne.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchOne.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: [...state.items, action.payload.shipper],
    }),

    [actions.createBankAccount.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.createBankAccount.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.createBankAccount.SUCCESS]: (state) => ({
      ...state, // TODO: should add the bank account to the shipper
      status: 'success',
    }),

    [vehiclesActions.create.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [vehiclesActions.create.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [vehiclesActions.create.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: state.items.map((shipper) => {
        if (shipper.id !== action.payload.vehicle.shipper.id) return shipper;
        return {
          ...shipper,
          vehicles: [...shipper.vehicles, action.payload.vehicle.id],
        };
      }),
    }),

    [modalActions.cleanError.type]: (state) => ({
      ...state,
      status: 'init',
      error: '',
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
