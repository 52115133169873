import { apiConnectConfig } from 'api-connect/dist/config';

const envUrls = {
  marketplace: process.env.REACT_APP_MARKETPLACE_API,
  api: process.env.REACT_APP_LOGISTIC_API,
  user: process.env.REACT_APP_USER_API,
};

export default envUrls;

apiConnectConfig(envUrls);
