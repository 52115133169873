import React from 'react';
import propTypes from 'prop-types';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import './statics/styles.css';
import { WrapFlex } from './statics/styles';

const ButtonWithSpinner = (props) => {
  const {
    children,
    disabled,
    primary,
    secondary,
    datacy,
    outline,
    status,
    onClick,
  } = props;

  switch (status) {
    case 'pending': {
      return (
        <WrapFlex>
          <Button
            primary={primary}
            secondary={secondary}
            outline={outline}
            datacy={datacy}
            onClick={onClick}
          >
            <Spinner />
            {children}
          </Button>
        </WrapFlex>
      );
    }

    case 'success': {
      return (
        <Button
          disabled={disabled}
          primary={primary}
          secondary={secondary}
          outline={outline}
          onClick={onClick}
        >
          {children}
        </Button>
      );
    }

    default:
      return (
        <Button
          disabled={disabled}
          primary={primary}
          secondary={secondary}
          outline={outline}
          onClick={onClick}
          datacy={datacy}
        >
          {children}
        </Button>
      );
  }
};

ButtonWithSpinner.propTypes = {
  children: propTypes.node.isRequired,
  onClick: propTypes.func.isRequired,
  datacy: propTypes.string,
  status: propTypes.string.isRequired,
  primary: propTypes.string.isRequired,
  secondary: propTypes.string.isRequired,
  outline: propTypes.string.isRequired,
  disabled: propTypes.bool,
};

ButtonWithSpinner.defaultProps = {
  datacy: '',
  disabled: false,
};

export default ButtonWithSpinner;
