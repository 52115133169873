import React from 'react';
import propTypes from 'prop-types';
import YearPicker from './components/YearPicker';

class YearPickerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: '',
    };
  }

  componentDidMount() {
    if (!this.props.value) return;
    this.setState({
      selectedYear: String(this.props.value),
    });
  }

  onChange = (value) => {
    this.setState({
      selectedYear: value,
    });

    if (this.props.hasOwnProperty('onChange')) {
      const { onChange, name } = this.props;

      onChange({ target: { name, value } });
    }
  };

  render() {
    const { selectedYear } = this.state;

    return <YearPicker onChange={this.onChange} selectedYear={selectedYear} />;
  }
}

YearPickerContainer.propTypes = {
  onChange: propTypes.func.isRequired,
  value: propTypes.any,
  name: propTypes.string.isRequired,
};

export default YearPickerContainer;
