import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const Alert = (props) => {
  const { msg, type, display, onToggleAlert, datacy } = props;
  const { t } = useTranslation();

  if (!display) return <span />;
  return (
    <div
      className={cn(
        'alert dark alert-alt alert-dismissible animated fadeIn',
        `alert-${type}`
      )}
      role='alert'
      datacy={datacy}
    >
      <button
        onClick={onToggleAlert}
        type='button'
        className='close'
        data-dismiss='alert'
        aria-label='Close'
      >
        <span aria-hidden='true'>×</span>
      </button>
      {type === 'danger' && `✋ ${t('alert.error')} ${msg}`}
      {type === 'success' && msg}
    </div>
  );
};

Alert.propTypes = {
  msg: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  display: propTypes.bool.isRequired,
  onToggleAlert: propTypes.func.isRequired,
  datacy: propTypes.string,
};

Alert.defaultProps = {
  datacy: '',
};

export default Alert;
