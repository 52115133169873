import { fork, all } from 'redux-saga/effects';
import permissions from '../../modules/permissions';
import shippers from '../../modules/shippers';
import trips from '../../modules/trips';
import modals from '../../modules/modals';
import bankAccounts from '../../modules/bankAccounts';
import vehicles from '../../modules/vehicles';
import institutions from '../../modules/institutions';
import alerts from '../../modules/alerts';
import reports from '../../modules/reports';
import payments from '../../modules/payments';
import orders from '../../modules/orders';
import stock from '../../modules/stock';
import selectHelpers from '../../modules/selectHelpers';
import notifications from '../../modules/notifications';
import networks from '../../modules/networks';
import users from '../../modules/users';
import districts from '../../modules/districts';
import country from '../../modules/country';

const startSagas = (...sagas) => {
  return function* rootSaga() {
    yield all(sagas.map((saga) => fork(saga)));
  };
};

export default startSagas(
  ...permissions.sagas,
  ...shippers.sagas,
  ...trips.sagas,
  ...modals.sagas,
  ...bankAccounts.sagas,
  ...vehicles.sagas,
  ...institutions.sagas,
  ...alerts.sagas,
  ...payments.sagas,
  ...orders.sagas,
  ...reports.sagas,
  ...stock.sagas,
  ...selectHelpers.sagas,
  ...notifications.sagas,
  ...networks.sagas,
  ...users.sagas,
  ...districts.sagas,
  ...country.sagas
);
