import React, { Component } from 'react';
import propTypes from 'prop-types';
import TimetableSelectGroup from './TimetableSelectGroup';

class TimetableSelectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: '',
      to: '',
    };
  }

  componentDidMount() {
    this.setState({
      from: this.props.from,
      to: this.props.to,
    });
  }

  onChange = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.props.hasOwnProperty('onChange') && e.target.name === 'to') {
          this.props.onChange({
            target: { name: this.props.name, value: this.state },
          });
        }
      }
    );
  };

  render() {
    return (
      <TimetableSelectGroup
        from={this.state.from}
        to={this.state.to}
        onChange={this.onChange}
      />
    );
  }
}

TimetableSelectContainer.propTypes = {
  onChange: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  from: propTypes.string.isRequired,
  to: propTypes.string.isRequired,
};

export default TimetableSelectContainer;
