import cn from 'classnames';
import { isEmpty, isNil } from 'ramda';
import React from 'react';
import propTypes from 'prop-types';
import styles from './Input.module.css';

const Input = (props) => {
  const {
    type,
    name,
    value,
    onChange,
    placeholder,
    disabled,
    required,
    datacy,
    min,
    max,
    step,
  } = props;
  const isInvalid = (isNil(value) || isEmpty(value)) && required;

  return (
    <input
      type={type}
      className={cn(
        styles.input,
        { [styles.invalid]: isInvalid },
        { [styles.disabled]: disabled }
      )}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      datacy={datacy}
      name={name}
      min={min}
      max={max}
      step={step}
      {...props}
    />
  );
};

Input.propTypes = {
  type: propTypes.string,
  placeholder: propTypes.string,
  disabled: propTypes.bool,
  onChange: propTypes.func,
  required: propTypes.bool.isRequired,
  datacy: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  min: propTypes.number.isRequired,
  max: propTypes.number.isRequired,
  step: propTypes.number.isRequired,
  value: propTypes.string,
};

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  value: '',
  disabled: false,
  onChange: () => {},
};

export default Input;
