import React from 'react'
import Modal from './component/Modal'
import './component/statics/styles.css'
class ModalContainer extends React.Component {
  render () {
    return (
      <Modal {...this.props}/>
    )
  }
}

export default ModalContainer