import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import styles from '../css/Modal.module.css';
import Button from '../../../../../components/Button';
import EditCombo from './EditCombo';
import { createInitialValues, calculateWeight } from '../helpers';
import ButtonWithSpinner from '../../../../../components/ButtonWithSpinner/ButtonWithSpinner';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../app/configs/i18n';
import { createProducts } from './utils';

const EditComboContainer = (props) => {
  const [state, setState] = useState({
    selectedProducts: [],
  });

  const [loadingProducts, setLoadingProducts] = useState(true);

  const {
    statusProducts,
    products,
    data: { combo },
    onCancel,
    confirmStatus,
    onConfirm: confirmProducts,
  } = props;

  const { selectedProducts } = state;

  useEffect(() => {
    const createSelectedProducts = () => {
      const selectedProducts = createProducts(products, combo);
      setState({
        selectedProducts,
      });
      setLoadingProducts(false);
    };
    if (statusProducts === 'success' && products.length) {
      createSelectedProducts();
    }
  }, [statusProducts, products, combo, loadingProducts]);

  const addNewProduct = (event) => {
    event.preventDefault();
    const selectedProducts = [
      ...state.selectedProducts,
      { id: Math.random() * Date.now() },
    ];
    setState({ selectedProducts });
  };

  const removeSelectedProduct = (id) => {
    const selectedProducts = [...state.selectedProducts];
    const selectedProductsUpdate = selectedProducts.filter(
      (product) => product.id !== id
    );
    setState({ selectedProducts: selectedProductsUpdate });
  };

  const onAddProductChange = (id, rest) => {
    const selectedProductsUpdate = selectedProducts.map((product) =>
      product.id === id ? { id, ...product, ...rest } : product
    );
    setState({ selectedProducts: selectedProductsUpdate });
  };

  const onConfirm = (values) => {
    const variants = state.selectedProducts;
    const product_variants = variants.reduce(
      (acc, value) => [
        ...acc,
        {
          id: value.product.id,
          app_name: value.app_name,
          quantity: value.quantity,
        },
      ],
      []
    );
    const weigth = calculateWeight(state.selectedProducts);
    const newCombo = {
      name: values.name,
      description: values.description,
      light_weight: values.is_dense ? values.is_dense : false,
      need_cooling: values.need_cooling ? values.need_cooling : false,
      weigth,
      dishes: values.dishes,
      unit_type: values.presentation.value,
      product_variants,
    };
    confirmProducts({ variant: newCombo }, combo.id);
  };
  const initVal = createInitialValues(combo);

  return (
    <Modal
      className='create-variant-modal'
      title={<Title step={1} />}
      onCancel={onCancel}
      onSubmit={onConfirm}
      {...props}
      show={'CREATE_COMBO'}
    >
      <ModalBody className='modal-detail__body'>
        <Formik
          initialValues={initVal}
          // validationSchema={validationSchema}
          onSubmit={onConfirm}
          enableReinitialize
        >
          {({ handleSubmit, values }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <EditCombo
                  {...props}
                  values={values}
                  loadingProducts={loadingProducts}
                  selectedProducts={selectedProducts}
                  addNewProduct={addNewProduct}
                  onAddProductChange={onAddProductChange}
                  removeSelectedProduct={removeSelectedProduct}
                />
                <div className={styles.buttons}>
                  <Button
                    type='button'
                    datacy={'btnCancel'}
                    onClick={onCancel}
                    outline
                  >
                    CLOSE
                  </Button>
                  <ButtonWithSpinner
                    type='submit'
                    status={confirmStatus}
                    datacy={'btnSave'}
                    primary
                  >
                    {i18n.t('modals.create_variant.submit')}
                  </ButtonWithSpinner>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

const Title = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.title}>{t('modals.edit_combo.title')}</div>
    </div>
  );
};

EditComboContainer.propTypes = {
  onConfirm: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
  statusProducts: propTypes.string.isRequired,
  data: propTypes.shape({
    combo: propTypes.shape({}).isRequired,
  }).isRequired,
  products: propTypes.arrayOf({
    brand_name: propTypes.string.isRequired,
    category: propTypes.shape({
      id: propTypes.number.isRequired,
      name: propTypes.string.isRequired,
    }).isRequired,
    combo_product_items: propTypes.arrayOf({
      id: propTypes.number.isRequired,
      product_variant_id: propTypes.number.isRequired,
      product_variant_name: propTypes.string.isRequired,
      quantity: propTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export default EditComboContainer;
