import {
  call,
  put,
  takeEvery,
  takeLatest,
  cancelled,
  take,
  cancel,
} from 'redux-saga/effects'; //take, cancel, fork
import { delay } from 'redux-saga';

import { get, del, post, put as _put } from 'api-connect/dist/api';

import * as actions from './actions';
import * as alertActions from 'modules/alerts/store/actions';
import * as ordersActions from 'modules/orders/store/actions';
import * as modalActions from 'modules/modals/store/actions';

import { buildTrip } from '../helpers/helpers';
import { normalize } from 'utils';

import { updateDeliveryWorker } from 'modules/orders/store/sagas';
import i18n from '../../../app/configs/i18n';

export function* update(action) {
  yield put(actions.update.start());

  const { trip } = action.payload;
  const { res, error } = yield call(_put, `trips/${trip.id}`, {
    shipper_id: trip.shipperId,
    ...trip,
  });

  if (error || res.status >= 400) {
    return put(actions.update.failure({ error: error }));
  }

  yield put(actions.update.success({ trip: res.trip }));
}

export function* create(action, orders) {
  yield put(actions.create.start());
  const trip = buildTrip(action.payload.order, orders);

  //* CRUD action
  const { res, error } = yield call(post, 'trips', trip);

  if (error || res.status >= 400) {
    return yield put(actions.create.failure({ error }));
  }

  yield put(actions.create.success({ trip: res.trip }));
  yield put(
    alertActions.openAlert({
      message: i18n.t('trips.sagas.create_success'),
      type: 'success',
      delay: 2000,
    })
  );
}

/**
 * TODO: should remove. I can't use the create function because it calls a function that we need to call
 * internally instead of here.
 * This implementation is the one we should keep.
 */
export function* createSimpleTrip(action) {
  yield put(actions.create.start());

  const { trip } = action.payload;

  //* CRUD action
  const { res, error } = yield call(post, 'trips', trip);

  if (error || res.status >= 400) {
    yield put(actions.create.failure({ error }));
    return yield put(
      alertActions.openAlert({
        message: i18n.t('trips.sagas.create_error'),
        type: 'error',
        delay: 10000,
      })
    );
  }

  yield put(actions.create.success({ trip: res.trip }));
  yield put(
    ordersActions.update.success({ orders: normalize(res.trip.orders) })
  );

  yield put(modalActions.closeModal());

  yield put(
    alertActions.openAlert({
      message: i18n.t('trips.sagas.create_success'),
      type: 'success',
      delay: 2000,
    })
  );

  yield call(updateDeliveryWorker, {
    payload: { ordersIds: trip.orders_ids, refrigerated: trip.refrigerated },
  });

  if (trip.broadcast) {
    yield call(startBroadcast, { payload: { id: res.trip.id } });
  }
}

export function* startBroadcast(action) {
  yield put(actions.startTripBroadcast.start());
  const { id } = action.payload;

  //* CRUD action
  const { res, error } = yield call(post, `trips/${id}/broadcast`);

  if (error || res.status >= 400) {
    return yield put(actions.startTripBroadcast.failure({ error }));
  }

  yield put(actions.startTripBroadcast.success({ id }));
}

export function* pauseBroadcast(action) {
  yield put(actions.pauseTripBroadcast.start());
  const { id } = action.payload;

  //* CRUD action
  const { res, error } = yield call(post, `trips/${id}/pause`);

  if (error || res.status >= 400) {
    return yield put(actions.pauseTripBroadcast.failure({ error }));
  }

  yield put(actions.pauseTripBroadcast.success({ id }));
}

export function* remove(action) {
  yield put(actions.remove.start());
  const { id } = action.payload;

  //* CRUD action
  const { res, error } = yield call(del, `trips/${id}`, { id: id });

  if (error || res.status >= 400) {
    return yield put(actions.remove.failure({ error }));
  }

  yield put(actions.remove.success({ id }));
  yield put(
    alertActions.openAlert({
      message: i18n.t('trips.sagas.delete_success'),
      type: 'success',
      delay: 2000,
    })
  );
}

export function* fetchTrips(action) {
  try {
    while (true) {
      // eslint-disable-line
      yield put(actions.fetchTrips.start());

      const { date } = action.payload;

      //* CRUD action
      const { res, error } = yield call(get, `trips?delivery_at=${date}`);

      if (error || res.status >= 400) {
        yield put(
          alertActions.openAlert({
            message: i18n.t('trips.sagas.load_error'),
            type: 'error',
          })
        );
        return yield put(actions.fetchTrips.failure({ error: error }));
      }

      yield put(actions.fetchTrips.success({ trips: normalize(res.trips) }));
      yield call(delay, 10000); // repeat every 10 secs
    }
  } catch (error) {
    yield put(actions.fetchTrips.failure({ error: 'Server error' }));
    yield put(
      alertActions.openAlert({
        message: i18n.t('trips.sagas.server_error'),
        type: 'error',
      })
    );
  } finally {
    if (yield cancelled()) {
      yield put(actions.fetchTrips.failure({ error: 'Fetch all canceled' }));
    }
  }
}

// This function is needed because we need to cancel the fetching eventually
export function* handleFetchTrips() {
  while (true) {
    // eslint-disable-line
    const fetchTripsTask = yield takeLatest(
      actions.fetchTrips.type,
      fetchTrips
    );

    yield take(actions.cancelFetchTrips.type);
    yield cancel(fetchTripsTask);
  }
}

export function* createWatcher() {
  yield takeEvery(actions.create.type, create);
}

// TODO: should remove
export function* createSimpleWatcher() {
  yield takeEvery(actions.createSimple.type, createSimpleTrip);
}

export function* updateWatcher() {
  yield takeEvery(actions.update.type, update);
}

export function* removeWatcher() {
  yield takeEvery(actions.remove.type, remove);
}

export function* startBroadcastWatcher() {
  yield takeEvery(actions.startTripBroadcast.type, startBroadcast);
}

export function* pauseBroadcastWatcher() {
  yield takeEvery(actions.pauseTripBroadcast.type, pauseBroadcast);
}

export default [
  createWatcher,
  createSimpleWatcher,
  updateWatcher,
  removeWatcher,
  startBroadcastWatcher,
  pauseBroadcastWatcher,
  handleFetchTrips,
];
