import { reducer as asyncReducer } from 'make-action-creator/dist/reducer';
import { combineReducers } from 'redux';
import permissions from '../../modules/permissions';
import shippers from '../../modules/shippers';
import trips from '../../modules/trips';
import modals from '../../modules/modals';
import bankAccounts from '../../modules/bankAccounts';
import vehicles from '../../modules/vehicles';
import institutions from '../../modules/institutions';
import alerts from '../../modules/alerts';
import payments from '../../modules/payments';
import orders from '../../modules/orders';
import reports from '../../modules/reports';
import stock from '../../modules/stock';
import selectHelpers from '../../modules/selectHelpers';
import notifications from '../../modules/notifications';
import networks from '../../modules/networks';
import users from '../../modules/users';
import districts from '../../modules/districts';
import country from '../../modules/country';

const appReducer = combineReducers({
  async: asyncReducer,
  permissions: permissions.reducer,
  [trips.constants.NAMESPACE]: trips.reducer,
  [shippers.constants.NAMESPACE]: shippers.reducer,
  [modals.constants.NAMESPACE]: modals.reducer,
  [bankAccounts.constants.NAMESPACE]: bankAccounts.reducer,
  [vehicles.constants.NAMESPACE]: vehicles.reducer,
  [institutions.constants.NAMESPACE]: institutions.reducer,
  [alerts.constants.NAMESPACE]: alerts.reducer,
  [payments.constants.NAMESPACE]: payments.reducer,
  [orders.constants.NAMESPACE]: orders.reducer,
  [reports.constants.NAMESPACE]: reports.reducer,
  [stock.constants.NAMESPACE]: stock.reducer,
  [selectHelpers.constants.NAMESPACE]: selectHelpers.reducer,
  [notifications.constants.NAMESPACE]: notifications.reducer,
  [networks.constants.NAMESPACE]: networks.reducer,
  [users.constants.NAMESPACE]: users.reducer,
  [districts.constants.NAMESPACE]: districts.reducer,
  [country.constants.NAMESPACE]: country.reducer,
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === 'USER_LOGOUT') {
    newState = undefined;
  }

  return appReducer(newState, action);
};

export default rootReducer;
