import * as yup from 'yup';
import i18n from 'app/configs/i18n';

export const validationSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .max(40, i18n.t('modals.offer_name.validations.max_name'))
      .required(i18n.t('modals.offer_name.validations.required_name')),
  });
