import makeActionCreator from 'make-action-creator';

import { NAMESPACE } from '../config/constants';

export const fetchBalances = makeActionCreator(NAMESPACE + '/fetchBalances');

export const fetchAll = makeActionCreator(NAMESPACE + '/fetchAll');
export const fetchOne = makeActionCreator(NAMESPACE + '/fetchOne');
export const fetchPaymentsOf = makeActionCreator(
  NAMESPACE + '/fetchPaymentsOf'
);
export const update = makeActionCreator(NAMESPACE + '/update');
export const remove = makeActionCreator(NAMESPACE + '/remove');
export const create = makeActionCreator(NAMESPACE + '/create');
export const obsolesce = makeActionCreator(NAMESPACE + 'obsolesce');
export const createUntrackedActivityPayment = makeActionCreator(
  NAMESPACE + '/createUntracked'
);
export const fetchPayee = makeActionCreator(`${NAMESPACE}/fetchPayee`);
export const resetPayee = makeActionCreator(`${NAMESPACE}/resetPayee`);
export const setPayment = makeActionCreator(`${NAMESPACE}/setPayment`);
export const editPaymentCoupon = makeActionCreator(
  `${NAMESPACE}/editPaymentCoupon`
);

export const cleanStatus = makeActionCreator(NAMESPACE + '/cleanStatus');
