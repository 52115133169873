import React from 'react';
import propTypes from 'prop-types';
import { without } from 'ramda';

import SelectList from './components/SelectList';

class SelectListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedList: [''],
      list: [],
    };
  }

  componentDidMount() {
    this.setState({
      list: this.props.list,
    });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.list !== this.props.list) {
      this.setState({ list: this.props.list });
    }
  }

  onAddSelect = () => {
    const { list, selectedList } = this.state;
    this.setState({
      selectedList: [...selectedList, ''],
      list: without(selectedList, list),
    });
  };

  onRemoveOption = (id) => {
    const { selectedList } = this.state;
    const nextList = selectedList.filter((item) => item !== id);

    this.setState({
      selectedList: nextList,
    });

    if (this.props.hasOwnProperty('onChange')) {
      this.props.onChange({
        target: { name: this.props.name, value: nextList },
      });
    }
  };

  onSelectOption = (value) => {
    const { selectedList } = this.state;

    const nextList = selectedList.map((item, i) =>
      i === selectedList.length - 1 ? value : item
    );

    this.setState({
      selectedList: nextList,
    });

    if (this.props.hasOwnProperty('onChange')) {
      this.props.onChange({
        target: { name: this.props.name, value: nextList },
      });
    }
  };

  render() {
    const { list, selectedList } = this.state;

    return (
      <SelectList
        {...this.props}
        list={list}
        selectedList={selectedList}
        onAddSelect={this.onAddSelect}
        onRemoveOption={this.onRemoveOption}
        onSelectOption={this.onSelectOption}
      />
    );
  }
}

SelectListContainer.propTypes = {
  name: propTypes.string.isRequired,
  placeholder: propTypes.string.isRequired,
  addMoreLabel: propTypes.string.isRequired,
  list: propTypes.arrayOf([]).isRequired,
  onChange: propTypes.func.isRequired,
};

export default SelectListContainer;
