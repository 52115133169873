import { handleActions } from 'redux-actions';
import moment from 'moment';

import * as actions from './actions';

import i18n from 'app/configs/i18n';

const date = moment().utc().format('DD-MM-YYYY');

const initialState = {
  status: 'init',
  error: '',
  user: {},
  modules: {
    index: {
      pathname: '/',
      name: 'Checkbox.jsx',
      label: false,
    },
    trips: {
      actions: ['list', 'create'],
      pathname: `/trips/${date}`,
      name: 'trips',
      label: i18n.t('navbar.trips'),
    },
    orders: {
      actions: ['list'],
      pathname: '/orders',
      name: 'orders',
      label: i18n.t('navbar.orders'),
    },
    shippers: {
      actions: ['list', 'detail', 'edit'],
      pathname: '/shippers',
      name: 'shippers',
      label: i18n.t('navbar.shippers'),
    },
    institutions: {
      actions: ['list', 'create', 'detail', 'edit'],
      pathname: '/institutions/list/organizations',
      name: 'institutions',
      label: i18n.t('navbar.institutions'),
    },
    stock: {
      actions: ['list'],
      pathname: '/stock/available',
      name: 'stock',
      label: i18n.t('navbar.stock'),
    },
    // reports: {
    //   actions: ['list'],
    //   pathname: '/reports',
    //   name: 'reports',
    //   label: i18n.t('navbar.reports'),
    // },
    notifications: {
      actions: ['list'],
      pathname: '/notifications/list/soup_kitchen',
      name: 'notifications',
      label: i18n.t('navbar.notifications'),
    },
    settings: {
      actions: ['list'],
      name: 'settings',
      pathname: '/settings/networks',
    },
  },
};

const reducer = handleActions(
  {
    [actions.loginUser.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.loginUser.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.loginUser.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),

    [actions.logoutUser.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.logoutUser.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.logoutUser.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
      user: {},
    }),

    [actions.fetchOne.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchOne.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchOne.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      user: action.payload.user,
    }),
  },
  initialState
);

export default reducer;
