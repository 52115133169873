import { map, isEmpty, isNil } from 'ramda'

export const parsedValues = list => map((elem) => {
  if ((isEmpty(elem) || isNil(elem))) {
    return ''
  } else if (Object(elem).hasOwnProperty('__isNew__')) {
    return String(elem.value)
  } else if (Object(elem).hasOwnProperty('id')) {
    return String(elem.id)
  } else if (typeof elem === 'string' || typeof elem === 'number') {
    return String(elem)
  } else {
    return String(elem.value)
  }
})(list)