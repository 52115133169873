import { createSelector } from 'reselect';
import { NAMESPACE } from '../config/constants';
import { isEmpty } from 'ramda';

import { trips } from '../../trips/store/selectors';
import { data } from '../../modals/store/selectors';
import sort from 'immutable-sort';
import { denormalize } from '../../../utils';

export const items = (state) => state[NAMESPACE].items;
export const status = (state) => state[NAMESPACE].status;
export const error = (state) => state[NAMESPACE].error;
export const payee = (state) => state[NAMESPACE].current_payee;
export const current_payments = (state) => state[NAMESPACE].current_payments;

export const currentPaymentList = createSelector(current_payments, (payments) =>
  denormalize(payments)
);

export const getPaymentsOrderedByCreation = createSelector(
  currentPaymentList,
  (payments) =>
    sort(payments, (a, b) => new Date(b.created_at) - new Date(a.created_at))
);

export const isLoading = createSelector(
  status,
  (status) => status === 'pending'
);

export const currentPayee = createSelector(payee, (payee) => {
  const hasPayee = Object.entries(payee).length !== 0;
  return hasPayee ? { name: payee.payee_name, email: payee.payee_email } : {};
});

export const getPaymentGatewayId = createSelector(
  trips,
  data,
  (tripsList, modalData) => {
    const { id, type, tripId } = modalData;

    if (type === 'orders') {
      const order = tripsList[tripId].orders.filter((order) => order.id === id);

      if (isEmpty(order[0].payments)) return false;
      return order[0].payments[0].gateway_info.id;
    }

    if (type === 'deliveries') {
      const order = tripsList[tripId].orders.filter(
        (order) =>
          !isEmpty(order.deliveries.filter((delivery) => delivery.id === id))
      );

      if (isEmpty(order[0].deliveries[0].payments)) return false;
      return order[0].deliveries[0].payments[0].gateway_info.id;
    }
  }
);
