import React from "react";
import ModalCenter from "./components/ModalCenter";

class ModalCenterContainer extends React.Component {
  constructor(props) {
    super(props);
    const d = document;
    this.body = d.querySelector("body");
  }

  componentDidMount() {
    this.body.classList.add("modal-open");
  }

  componentWillUnmount() {
    this.body.classList.remove("modal-open");
  }

  render() {
    return <ModalCenter {...this.props} />;
  }
}

export default ModalCenterContainer;
