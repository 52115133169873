import React from 'react';
import PropTypes from 'prop-types';
import CreateStockModal from './CreateStock';
import {
  adaptToOutputDto,
  adaptToOutputDtoCombo,
  groupsConstructor,
  initialData,
} from '../helpers';

export default class CreateStockContainer extends React.Component {
  onConfirm = (values) => {
    const [groups_availability, groupsDefault] = groupsConstructor(
      this.props.groups_availability
    );

    let dto;
    if (!values.variant.combo_product_items.length) {
      dto = adaptToOutputDto(values);
    } else {
      dto = adaptToOutputDtoCombo(values);
    }

    if (values.groups_availability[groups_availability[0].code]) {
      this.props.onConfirm(dto);
    } else {
      this.props.onConfirm({
        ...dto,
        stock: {
          ...dto.stock,
          groups_availability: { ...groupsDefault },
        },
      });
    }
  };

  render() {
    const [groups_availability, groupsDefault] = groupsConstructor(
      this.props.groups_availability
    );

    return (
      <CreateStockModal
        {...this.props}
        initialData={initialData(groupsDefault)}
        groups_availability={groups_availability}
        onConfirm={this.onConfirm}
      />
    );
  }
}

CreateStockContainer.propTypes = {
  step: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};
