import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment/moment';
import { isNil } from 'ramda';

import DatePicker from './components/DatePicker';

class DatePickerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: props.value,
      errors: [],
    };
  }

  componentDidMount() {
    if (this.props.value) {
      this.setState({
        selectedDate: this.props.value,
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.selectedState !== this.props.selectedState) {
      this.setState({
        selectedDate: oldProps.selectedState,
      });
    }
  }

  onChange = (value) => {
    const errors = this.props.rules.map((validation) => validation(value));
    this.setState({
      selectedDate: value,
      errors: errors,
    });
    this.props.onValidation(errors);
    if (this.props.onChange) {
      this.props.onChange({ target: { name: this.props.name, value } });
    }
  };

  static getDerivedStateFromProps(props) {
    if (!isNil(props.value)) {
      return {
        selectedDate: moment(props.value),
      };
    }
    return '';
  }

  render() {
    const { selectedDate } = this.state;
    return (
      <DatePicker
        {...this.props}
        onChange={this.onChange}
        selectedDate={selectedDate}
      />
    );
  }
}

DatePickerContainer.propTypes = {
  onChange: propTypes.func.isRequired,
  value: propTypes.any,
  name: propTypes.oneOfType([propTypes.func, propTypes.string]).isRequired,
  rules: propTypes.arrayOf([]),
  onValidation: propTypes.func,
};

DatePickerContainer.defaultProps = {
  rules: [],
  onValidation: (x) => {},
};

export default DatePickerContainer;
