import { getProductLabel } from '../../../stock/store/selectors';

export const createStateFromProduct = (product) => {
  const groupsInStock = product.groups_availability;
  return {
    variant: { label: getProductLabel(product), value: product.variant_id },
    address: {
      label: product.address.institution_name,
      value: product.address_id,
    },
    external_reference: product.external_reference,
    quantity: product.quantity,
    item_price: product.item_price,
    cost: product.cost,
    item_discount: product.item_discount,
    groups_availability: groupsInStock,
    expires_on: product.expires_on,
    id: product.id,
    rescued: product.rescued,
  };
};

export const adaptToOutputDto = (data) => {
  return {
    stock: {
      variant_id: data.variant.value,
      expires_on: data.expires_on ? data.expires_on._d : null,
      quantity: data.quantity,
      item_discount: data.item_discount,
      item_price: data.item_price,
      cost: data.cost,
      groups_availability: data.groups_availability,
      rescued: data.rescued,
    },
  };
};
