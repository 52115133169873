export const initialData = (groups) => ({
  variant: null,
  expires_on: null,
  address: null,
  external_reference: '',
  groups_availability: {
    ...groups,
  },
  item_price: 0,
  quantity: 0,
  cost: 0,
  item_check: false,
});

export const groupsConstructor = (groups) => {
  const groupsDefault = groups.reduce((acc, item) => {
    return {
      ...acc,
      [item.code]: {
        min: 1,
        max: 10,
      },
    };
  }, {});

  const groups_availability = groups.map((item) => {
    return {
      ...item,
      [item.code]: {
        ...groupsDefault[item.code],
      },
    };
  });

  return [groups_availability, groupsDefault];
};

export const adaptToOutputDto = (data) => {
  return {
    stock: {
      variant_id: data.variant.id,
      address_id: data?.address?.addresses[0].id,
      external_reference: data.external_reference,
      expires_on: data.expires_on ? data.expires_on._d : null,
      quantity: data.quantity,
      item_discount: 0,
      item_price: data.item_price,
      cost: data.cost,
      groups_availability: data.groups_availability,
      rescued: data.rescued,
    },
  };
};

export const adaptToOutputDtoCombo = (data) => {
  const neededKeys = Object.keys(data).filter((key) => key.includes('product'));

  const combo_product_items = neededKeys.reduce((acc, key) => {
    const newPair = {
      combo_product_id: Number(key.split('-')[1]),
      stock_id: data[key].value,
    };
    return [...acc, newPair];
  }, []);

  return {
    stock: {
      variant_id: data.variant.id,
      address_id: data?.address?.addresses[0].id,
      external_reference: data.external_reference,
      expires_on: data.expires_on ? data.expires_on._d : null,
      quantity: data.quantity,
      item_discount: 0,
      item_price: data.item_price,
      cost: data.cost,
      groups_availability: data.groups_availability,
      combo_product_items,
      rescued: data.rescued,
    },
  };
};
