import makeActionCreator from 'make-action-creator';

import { NAMESPACE } from '../config/constants';

export const cleanCurrentOrder = makeActionCreator(
  NAMESPACE + '/cleanCurrentOrder'
);

export const fetchAll = makeActionCreator(NAMESPACE + '/fetchAll');
export const fetchOne = makeActionCreator(NAMESPACE + '/fetchOne');
export const fetchOrdersOfDay = makeActionCreator(
  NAMESPACE + '/fetchOrdersOfDay'
);
export const update = makeActionCreator(NAMESPACE + '/update');
export const remove = makeActionCreator(NAMESPACE + '/remove');
export const create = makeActionCreator(NAMESPACE + '/create');
export const createLogistics = makeActionCreator(
  `${NAMESPACE}/createLogistics`
);
export const getCurrentStock = makeActionCreator(
  NAMESPACE + '/getCurrentStock'
);

export const updateDelivery = makeActionCreator(NAMESPACE + '/updateDelivery');
export const exportOrders = makeActionCreator(NAMESPACE + '/exportOrders');

export const deleteOrder = makeActionCreator(NAMESPACE + '/deleteOrder');
export const editDate = makeActionCreator(NAMESPACE + 'editDate');
export const editOrder = makeActionCreator(NAMESPACE + 'editOrder');
export const removeProduct = makeActionCreator(NAMESPACE + '/removeProduct');

export const deleteOrderPayment = makeActionCreator(
  NAMESPACE + '/deleteOrderPayment'
);
export const createOrderPayment = makeActionCreator(
  NAMESPACE + 'createOrderPayment'
);

export const csvErrorsOrder = makeActionCreator(NAMESPACE + '/csvErrorsOrder');
export const uploadExcel = makeActionCreator(NAMESPACE + '/uploadExcel');

export const importTemplateOrder = makeActionCreator(
  `${NAMESPACE}/importTemplateOrder`
);

export const orderCsvDownload = makeActionCreator(
  `${NAMESPACE}/ORDER_CSV_DOWNLOAD`
);
export const uploadCsvOrder = makeActionCreator(
  `${NAMESPACE}/UPLOAD_CSV_ORDER`
);
export const productCsvDownload = makeActionCreator(
  `${NAMESPACE}/PRODUCT_CSV_DOWNLOAD`
);

export const stockCsvDownload = makeActionCreator(
  `${NAMESPACE}/STOCK_CSV_DOWNLOAD`
);
export const importTemplateDownload = makeActionCreator(
  `${NAMESPACE}/importTemplateDownload`
);
