import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as permissionsActions from 'modules/permissions/store/actions';

const initialState = {
  status: 'init',
  error: '',
  networks: [],
  countries: [],
};

const reducer = handleActions(
  {
    [actions.fetchAllNetworks.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.fetchAllNetworks.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      networks: action.payload.networks,
    }),
    [actions.fetchAllNetworks.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.addNetwork.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.addNetwork.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),
    [actions.addNetwork.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.updateNetwork.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.updateNetwork.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      networks: action.payload.networks,
    }),
    [actions.updateNetwork.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.deleteNetwork.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.deleteNetwork.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      networks: action.payload.networks,
    }),
    [actions.deleteNetwork.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchCountry.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.fetchCountry.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      countries: action.payload.countries,
    }),
    [actions.fetchCountry.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
