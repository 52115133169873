import i18n from 'app/configs/i18n';

// export const createInitialValues = (newProducts) => {
//   const variables = ['app_name', 'product', 'quantity'];
//   let keys = [];
//   if (newProducts.length) {
//     newProducts.map((value, ix) => variables.map((value) => keys.push(value + `${ix}`)));
//   }

//   keys.push('name', 'description', 'need_cooling', 'is_dense', 'presentation', 'price', 'dishes');

//   return keys.reduce((acc, value) => {
//     if (value.includes('product')) {
//       acc = {
//         ...acc,
//         [value]: null
//       };
//     } else if (value.includes('app_name')) {
//       acc = {
//         ...acc,
//         [value]: ''
//       };
//     } else if (value.includes('quantity')) {
//       acc = {
//         ...acc,
//         [value]: null
//       };
//     } else {
//       switch (value) {
//         case 'name':
//           acc = {
//             ...acc,
//             name: ''
//           };
//           break;
//         case 'description':
//           acc = {
//             ...acc,
//             description: ''
//           };
//           break;
//         case 'price':
//           acc = {
//             ...acc,
//             price: ''
//           };
//           break;
//         case 'presentation':
//           acc = {
//             ...acc,
//             presentation: null
//           };
//           break;
//         case 'is_dense':
//           acc = {
//             ...acc,
//             is_dense: false
//           };
//           break;
//         case 'need_cooling':
//           acc = {
//             ...acc,
//             need_cooling: false
//           };
//           break;
//         case 'dishes':
//           acc = {
//             ...acc,
//             dishes: 0
//           };
//           break;
//       }
//     }
//     return acc;
//   }, {});
// };

export const initialValues = {
  name: '',
  description: '',
  price: 0,
  presentation: { label: i18n.t('modals.create_combo.box'), value: 'Caja' },
  is_dense: false,
  need_cooling: false,
  dishes: 0,
};

export const createInitialValues = (combo) => {
  const {
    name,
    description,
    dishes,
    weight,
    is_dense,
    need_cooling,
    unit_type,
  } = combo;
  //{ id: this.props.id, product: {}, app_name: '', quantity: 0 }
  return {
    name,
    description,
    dishes,
    weight,
    is_dense,
    need_cooling,
    presentation: { label: unit_type, value: unit_type },
  };
};

export const calculateWeight = (selectedProducts) => {
  return selectedProducts.reduce((acc, value) => {
    if (value.product && value.quantity) {
      const totalWeight = parseFloat(value.product.unit_measure);
      // const itemsPerPack = parseFloat(value.product.units_per_pack);
      const quantity = parseFloat(value.quantity);
      return (acc += totalWeight * quantity);
    } else {
      return acc;
    }
  }, 0);
};
