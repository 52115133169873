import React from 'react';
import propTypes from 'prop-types';
import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import ModalFooter from 'components/ModalCenter/components/ModalFooter';
import Button from 'components/ButtonWithSpinner/ButtonWithSpinnerContainer';
import ModalForm from './ModalForm';
import { useTranslation } from 'react-i18next';

const CreatePaymentCoupon = (props) => {
  const { paymentStatus, onCancel, onConfirm, formData } = props;
  const { t } = useTranslation();

  return (
    <Modal
      title={t('modals.create_payment_cupon.title')}
      onCancel={() => onCancel()}
      {...props}
    >
      <ModalBody>
        <ModalForm {...props} />
      </ModalBody>
      <ModalFooter>
        {paymentStatus !== 'success' && [
          <button key='1' className='btn btn-default' onClick={onCancel}>
            {t('modals.create_payment_cupon.cancel')}
          </button>,
          <Button
            key='2'
            className='btn btn-primary'
            onClick={() => onConfirm({ ...formData })}
          >
            {t('modals.create_payment_cupon.submit')}
          </Button>,
        ]}
        {paymentStatus === 'success' && (
          <button className='btn btn-default' onClick={onCancel}>
            {t('modals.create_payment_cupon.close')}
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

CreatePaymentCoupon.propTypes = {
  onCancel: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
  formData: propTypes.shape({}).isRequired,
  paymentStatus: propTypes.string.isRequired,
};

export default CreatePaymentCoupon;
