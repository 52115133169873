import React from 'react';
import propTypes from 'prop-types';

import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import ModalFooter from 'components/ModalCenter/components/ModalFooter';
import Button from 'components/Button';

import './styles.css';
import RadioButtonList from '../../../../components/RadioButton/RadioButtonList';
import { CANCEL_OPTION, CONTINUE_ANYWAY, CONTINUE_OPTION } from './constants';
import i18n from '../../../../app/configs/i18n';

class CsvErrors extends React.Component {
  constructor(props) {
    super(props);
    const { file, option, messages } = this.props.data;
    this.state = {
      file: file,
      option: option,
      messages: messages,
      continueOperation: null,
    };
  }

  _onButtonClick = () => {
    const { option, file } = this.state;
    if (this.state.continueOperation) {
      this.props.onConfirm({ file: file, option: option, validate: false });
    } else this.props.onCancel();
  };

  _continueOperation = (value) => {
    const continueOperation = value === CONTINUE_ANYWAY;
    this.setState({ continueOperation });
  };

  render() {
    const { continueOperation } = this.state;
    const Title = () => (
      <div>
        <div className='modal__title'>{i18n.t('modals.csv_errors.title')}</div>
      </div>
    );
    return (
      <Modal
        className='modal'
        title={<Title />}
        onCancel={() => this.props.onCancel()}
        {...this.props}
      >
        <ModalBody className='modal-detail__body nilus-common-text'>
          <div>{i18n.t('modals.csv_errors.subtitle')}</div>
          <div className={'message-container'}>
            <MessageList messages={this.state.messages} />
          </div>
          <div className={'radio-group'}>
            <RadioButtonList
              options={[CANCEL_OPTION, CONTINUE_OPTION]}
              onChange={this._continueOperation.bind(this)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            primary
            disabled={continueOperation === null}
            onClick={this._onButtonClick.bind(this)}
          >
            {i18n.t('modals.csv_errors.accept')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const MessageList = ({ messages }) => {
  const messageList = messages.map((x) => {
    return (
      <li className='message' key={`${x.line}-${x.type}-${x.message}`}>
        {' '}
        - {x.line}: {x.type}-{x.message}
      </li>
    );
  });
  return <ul>{messageList}</ul>;
};

CsvErrors.propTypes = {
  onCancel: propTypes.func.isRequired,
  messages: propTypes.arrayOf([]).isRequired,
};

MessageList.propTypes = {
  messages: propTypes.arrayOf([]).isRequired,
};

export default CsvErrors;
