import { call, put, takeEvery } from 'redux-saga/effects';

import { get } from 'api-connect/dist/marketplace';
import { get as _get } from 'api-connect/dist/user';

import * as alertActions from '../../alerts/store/actions';

import * as actions from './actions';
import { formatForSelect } from 'utils';

export function* fetchAllWorker(action) {
  yield put(actions.fetchAll.start());

  const { types } = action.payload;

  if (types.includes('variants')) {
    yield fetchAddressesWorker();
    yield fetchCategoriesWorker();
  }

  for (let i = 0; i < types.length; i++) {
    const { res, error } = yield call(get, `js_helpers/${types[i]}`);

    if (res.status > 201 || error) {
      return put(actions.fetchAll.failure({ error }));
    } else {
      const key = Object.keys(res.data)[0];
      yield put(
        actions.fetchAll.success({
          items: { [key]: formatForSelect(res.data[key], 'name') },
        })
      );
    }
  }
}

export function* fetchVariantsWorker(action) {
  yield put(actions.fetchVariants.start());

  yield fetchAddressesWorker();
  yield fetchCategoriesWorker();

  const combo = 'combo_variants';
  const product = 'product_variants';
  const variants = 'variants';

  const { res, error } = yield call(
    get,
    `js_helpers/${product}?filter[where][revised]=true`
  );

  if (res.status > 201 || error) {
    return put(actions.fetchVariants.failure({ error }));
  } else {
    yield put(
      actions.fetchVariants.success({
        items: {
          [product]: formatForSelect(
            res.data[product] || res.data[combo] || res.data[variants],
            'name'
          ),
        },
      })
    );
  }
}

export function* fetchAddressesWorker() {
  yield put(actions.fetchAddresses.start());

  const { res, error } = yield call(_get, 'v1/addresses');

  if (res.status > 400 || error) {
    return yield put(actions.fetchAddresses.failure({ error }));
  }

  yield put(actions.fetchAddresses.success({ addresses: res.addresses }));
}

export function* fetchCategoriesWorker() {
  yield put(actions.fetchCategories.start());

  try {
    const { res } = yield call(get, 'v2/purchase_groups');
    yield put(
      actions.fetchCategories.success({ categories: res.purchase_groups })
    );
  } catch (error) {
    yield put(actions.fetchCategories.failure({ error }));
    yield put(
      alertActions.openAlert({
        message: 'Error server',
        type: 'error',
        delay: 2000,
      })
    );
  }
}

export function* fetchOfferStock(action) {
  yield put(actions.fetchOfferStock.start());

  const { address_id } = action.payload;

  //* CRUD action
  const { res, error } = yield call(
    get,
    `v2/categories/stocks?filter[where][address_id]=${address_id}&filter[where][assigned_to_offer]=false&filter[where][has_stock]=true&filter[where][revised]=true`
  );

  if (res.status > 400 || error) {
    return yield put(actions.fetchOfferStock.failure({ error }));
  }

  yield put(actions.fetchOfferStock.success({ stock: res.data }));
}

export function* fetchAllWatcher() {
  yield takeEvery(actions.fetchAll.type, fetchAllWorker);
}

export function* fetchVariantsWatcher() {
  yield takeEvery(actions.fetchVariants.type, fetchVariantsWorker);
}

export function* fetchAddressesWatcher() {
  yield takeEvery(actions.fetchAddresses.type, fetchAddressesWorker);
}

export function* fetchCategoriesWatcher() {
  yield takeEvery(actions.fetchCategories.type, fetchCategoriesWorker);
}

export function* fetchOfferStockWatcher() {
  yield takeEvery(actions.fetchOfferStock.type, fetchOfferStock);
}

export default [
  fetchAllWatcher,
  fetchVariantsWatcher,
  fetchAddressesWatcher,
  fetchCategoriesWatcher,
  fetchOfferStockWatcher,
];
