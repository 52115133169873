import React from 'react';
import propTypes from 'prop-types';

import SwitchInput from './components/SwitchInput';

class SwitchInputContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };

    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
    this.setState({
      checked: this.props.checked,
    });
  }

  onToggle() {
    const { field } = this.props;
    const nextChecked = !this.state.checked;

    this.setState({
      checked: nextChecked,
    });

    if (this.props.hasOwnProperty('onToggle')) {
      this.props.onToggle(field, nextChecked);
    }
  }

  render() {
    const { checked } = this.state;
    const { type } = this.props;

    return (
      <SwitchInput
        {...this.props}
        checked={checked}
        onChange={this.onToggle}
        type={type}
      />
    );
  }
}

SwitchInputContainer.propTypes = {
  checked: propTypes.bool.isRequired,
  onToggle: propTypes.func.isRequired,
  field: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
};

export default SwitchInputContainer;
