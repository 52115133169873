import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'ramda';

import Modal from './Modal';

import i18n from '../../../../../app/configs/i18n';

class ModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      isValid: false,
    };
  }

  componentDidMount() {
    const { comment, paid_at, collected_amount } = this.props.data.payment;
    this.setState({
      formData: {
        comment,
        collected_amount,
        paid_at,
      },
    });
    if (paid_at !== null) {
      this.setState({
        isValid: true,
      });
    }
  }

  onChange = (e) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (this.state.formData.paid_at !== null) {
          this.setState({
            isValid: true,
          });
        }
      }
    );
  };

  onConfirm = () => {
    const { payment, institution } = this.props.data;
    const { formData } = this.state;

    const body = {
      payment: {
        ...omit(['paid_at'], formData),
        paid_at: formData.paid_at._d,
      },
    };

    this.props.onConfirm(body, institution, payment);
  };

  render() {
    const { formData, isValid } = this.state;
    const { payment } = this.props.data;

    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.edit_payment_status.success')}
        formData={formData}
        onChange={this.onChange}
        onConfirm={this.onConfirm}
        payment={payment}
        isValid={isValid}
      />
    );
  }
}

ModalContainer.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default ModalContainer;
