import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import Tooltip from 'components/Tooltip';
import { useTranslation } from 'react-i18next';

const ModalForm = (props) => {
  const { shippers, shipper, onSelect } = props;
  const { t } = useTranslation();

  return (
    <span>
      <div className='row'>
        <div className='form-group col-md-12'>
          <label htmlFor='model'>{t('modals.assign_driver.drivers')}</label>
          <ul className='list-group'>
            {shippers.map((item, i) => (
              <li
                key={i}
                onClick={() => onSelect(item.id)}
                className={cn('list-group-item', {
                  active: shipper.id === item.id,
                })}
              >
                {item.first_name} {item.last_name}
                {i === 4 && (
                  <Tooltip
                    className='tooltip_cold'
                    text={t('modals.assign_driver.tooltip')}
                  >
                    <i className='icon cold' />
                  </Tooltip>
                )}
                {shipper.id === item.id && <i className='icon wb-check' />}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </span>
  );
};

ModalForm.propTypes = {
  onSelect: propTypes.func.isRequired,
  shippers: propTypes.shape({}).isRequired,
  shipper: propTypes.shape({}).isRequired,
};

export default ModalForm;
