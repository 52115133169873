import i18n from '../../app/configs/i18n';
export const daysList = [
  {
    name: 'monday',
    label: i18n.t('institutions.days.monday'),
  },
  {
    name: 'tuesday',
    label: i18n.t('institutions.days.tuesday'),
  },
  {
    name: 'wednesday',
    label: i18n.t('institutions.days.wednesday'),
  },
  {
    name: 'thursday',
    label: i18n.t('institutions.days.thursday'),
  },
  {
    name: 'friday',
    label: i18n.t('institutions.days.friday'),
  },
  {
    name: 'saturday',
    label: i18n.t('institutions.days.saturday'),
  },
  {
    name: 'sunday',
    label: i18n.t('institutions.days.sunday'),
  },
];
