import React from 'react';
import propTypes from 'prop-types';
import modals from '../config/types';
import CreateBankAccount from './CreateBankAccount/index';
import CreateVehicle from './CreateVehicle/index';
import CreatePaymentCoupon from './CreatePaymentCoupon/index';
import UpdateBankAccount from './UpdateBankAccount/index';
import UpdateVehicle from './UpdateVehicle/index';
import UpdateShipper from './UpdateShipper/index';
import CreateShipper from './CreateShipper/index';
import AssignShipper from './AssignShipper/index';
import CreateTrip from './CreateTrip/index';
import ViewTrip from './ViewTrip/index';
import EditTrip from './EditTrip/index';
import ViewStockProduct from './ViewStockProduct/index';
import ReviseStockProduct from './ReviseStockProduct/index';
import EditStockProduct from './EditVariant/index';
import ViewOrder from './ViewOrder/index';
import CreateInstitutionUser from './CreateInstitutionUser/index';
import EditInstitutionUser from './EditInstitutionUser/index';
import EditCouponComment from './EditCouponComment/index';
import EditPaymentStatus from './EditPaymentStatus/index';
import CreateVariant from './CreateVariant/index';
import CreateStock from './CreateStock/index';
import UploadCsv from './UploadCsv/index';
import CsvErrors from './CsvErrors/index';
import CsvErrorsOrder from './CsvErrorsOrder/index';
import UpdateStock from './UpdateStock/index';
import ConfirmationModal from './ConfirmationModal/index';
import CreatePayment from './CreatePayment/index';
import EditOrderDate from './EditOrderDate/index';
import AddOfferName from './AddOfferName/index';
import EditOrder from './EditOrder/index';
import CreateCombo from './CreateCombo/index';
import EditCombo from './EditCombo/index';
import UploadCsvOrder from './UploadCsvOrder';

import { connect } from 'react-redux';
import * as actions from '../store/actions';
import * as selectors from '../store/selectors';
import ErrorsImports from './ErrorsImports';

const Modal = (props) => {
  const { type } = props;

  return (
    {
      [modals.createBankAccount]: <CreateBankAccount {...props} />,
      [modals.createVehicle]: <CreateVehicle {...props} />,
      [modals.createPaymentCoupon]: <CreatePaymentCoupon {...props} />,
      [modals.updateVehicle]: <UpdateVehicle {...props} />,
      [modals.updateBankAccount]: <UpdateBankAccount {...props} />,
      [modals.createShipper]: <CreateShipper {...props} />,
      [modals.updateShipper]: <UpdateShipper {...props} />,
      [modals.assignShipper]: <AssignShipper {...props} />,
      [modals.createTrip]: <CreateTrip {...props} />,
      [modals.viewTrip]: <ViewTrip {...props} />,
      [modals.editTrip]: <EditTrip {...props} />,
      [modals.viewStockProduct]: <ViewStockProduct {...props} />,
      [modals.reviseStockProduct]: <ReviseStockProduct {...props} />,
      [modals.editStock]: <EditStockProduct {...props} />,
      [modals.viewOrder]: <ViewOrder {...props} />,
      [modals.createInstitutionUser]: <CreateInstitutionUser {...props} />,
      [modals.editInstitutionUser]: <EditInstitutionUser {...props} />,
      [modals.editCouponComment]: <EditCouponComment {...props} />,
      [modals.editPaymentStatus]: <EditPaymentStatus {...props} />,
      [modals.createVariant]: <CreateVariant {...props} />,
      [modals.createStock]: <CreateStock {...props} />,
      [modals.uploadCsv]: <UploadCsv {...props} />,
      [modals.csvErrors]: <CsvErrors {...props} />,
      [modals.csvErrorsOrder]: <CsvErrorsOrder {...props} />,
      [modals.updateStock]: <UpdateStock {...props} />,
      [modals.confirmationModal]: <ConfirmationModal {...props} />,
      [modals.createPayment]: <CreatePayment {...props} />,
      [modals.editOrderDate]: <EditOrderDate {...props} />,
      [modals.editOrder]: <EditOrder {...props} />,
      [modals.addOfferName]: <AddOfferName {...props} />,
      [modals.createCombo]: <CreateCombo {...props} />,
      [modals.editCombo]: <EditCombo {...props} />,
      [modals.errorsImports]: <ErrorsImports {...props} />,
      [modals.uploadCsvOrder]: <UploadCsvOrder {...props} />,
    }[type] || <div />
  );
};

Modal.propTypes = {
  type: propTypes.string,
  onConfirm: propTypes.string,
  onCancel: propTypes.string,
};

const mapStateToProps = (state) => ({
  type: selectors.type(state),
  modalError: selectors.error(state),
  modalStatus: selectors.status(state),
  onConfirm: selectors.onConfirm(state),
  onCancel: selectors.onCancel(state),
  data: selectors.data(state),
});

const mapDispatchToProps = (dispatch) => ({
  onCloseAlert: () => dispatch(actions.cleanError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
