import { omit, flatten, isEmpty, isNil } from 'ramda';
import moment from 'moment';
import {
  STOCK_CREATE,
  STOCK_OVERWRITE,
  VARIANT_CREATE,
} from '../../modals/components/UploadCsv/constants';
import { ORDER_CREATE } from '../../modals/components/UploadCsvOrder/constants';

export const mergeStockIntoVariant = (list) => (displayExpired) =>
  flatten(
    list.map((item) => {
      const nextItem = omit(['stocks'], item);

      if (isEmpty(item.stocks)) {
        return {
          ...nextItem,
          stock: {},
        };
      }

      return item.stocks.map((stock) => ({
        ...nextItem,
        stock: {
          ...stock,
        },
      }));
    })
  ).filter(
    (item) => !(!displayExpired && moment().isAfter(item.stock.expires_on))
  );

export const mergeCategories = (list) => {
  return Object.keys(list).reduce((acc, item) => {
    list[item].map((item) => acc.push(item));
    return acc;
  }, []);
};

export const getFileNameFromServerOrBackup = (fileName, backup) => {
  const serverFileNameRegex = /filename="(.*)"/;
  const isValid = serverFileNameRegex.test(fileName);
  if (isValid) return serverFileNameRegex.exec(fileName)[1];
  return backup;
};

export const getURLForFetch = (type, page) => {
  switch (type) {
    case 'available':
      return '?filter[where][has_stock]=true&filter[where][revised]=true';
    case 'unrevised':
      return `?filter[where][revised]=false&filter[where][has_stock]=true&filter[page]=${
        !isNil(page) ? page : 1
      }`;
    case 'offer':
      return '?filter[where][assigned_to_offer]=true';
    default:
      return `?filter[where][revised]=true&filter[page]=${
        !isNil(page) ? page : 1
      }`;
  }
};

export const getUploadUrl = (option, validate = true) => {
  switch (option) {
    case VARIANT_CREATE:
      return `csv_upload/upload_variant_csv?validate=${validate}`;
    case STOCK_OVERWRITE:
      return `csv_upload/upload_stock_csv?validate=${validate}&overwrite=true`;
    case STOCK_CREATE:
      return `csv_upload/upload_stock_csv?validate=${validate}`;
    default:
      throw new Error('no default case');
  }
};

export const getUploadUrlOrder = (option) => {
  switch (option) {
    case ORDER_CREATE:
      return `shopping/orders`;
    case VARIANT_CREATE:
      return `csv_upload/upload_variant_csv?validate`;
    default:
      throw new Error('no default case');
  }
};

export const localSearching = (available, parameter) => {
  const filteredValues = Object.values(available);

  const filteredKeys = Object.keys(available);

  const mappingKeys = (obj, param) => {
    const {
      brand_name,
      description,
      name,
      external_reference,
      expires_on,
      unit_type,
      unit_measure,
      item_price,
      cost,
      quantity,
      address: { institution_name },
    } = obj;

    const date = moment(expires_on).format('DD/MM/YY');

    return JSON.stringify({
      brand_name,
      description,
      name,
      external_reference,
      unit_type,
      unit_measure,
      item_price,
      cost,
      quantity,
      institution_name,
      date,
    })
      .toLowerCase()
      .includes(String(param).toLowerCase())
      ? obj
      : null;
  };

  const searchInAvailable = filteredValues.map((itemToIterator) => {
    return itemToIterator.filter((item) => {
      return mappingKeys(item, parameter);
    });
  });

  const reconstructorObject = filteredKeys.reduce((acc, item, i) => {
    return {
      ...acc,
      [item]: searchInAvailable[i],
    };
  }, {});

  return reconstructorObject;
};
