import { ErrorMessage, Field } from 'formik';
import errorStyles from '../css/Error.module.css';
import React from 'react';
import { Select, CreatableSelect } from '../../../../../components/Select';
import { Checkbox } from '../../../../../components/Checkbox';
import { LabeledInput } from '../../../../../components/Input';
import propTypes from 'prop-types';

export const LabeledInputField = ({ name, handleInputChange, ...rest }) => (
  <Field name={name}>
    {({ field, form }) => (
      <div>
        <LabeledInput
          {...field}
          {...rest}
          onChange={(option) => {
            const value = option.target.value ? option.target.value : '';
            form.setFieldValue(name, value);
            if (handleInputChange) {
              handleInputChange({
                target: {
                  name,
                  value: option.target.value ? option.target.value : '',
                },
              });
            } else {
              if (name === 'units_multiplier' && value === '1') {
                form.setFieldValue('single_unit_measure_name', null);
              }
            }
          }}
        />
        <ErrorMessage
          name={name}
          component='span'
          className={errorStyles.error}
        />
      </div>
    )}
  </Field>
);

export const CreatableSelectField = ({ name, ...rest }) => (
  <Field name={name}>
    {({ field, form }) => (
      <div>
        <CreatableSelect
          {...field}
          {...rest}
          onChange={(option) => {
            const value = option.target.value ? option.target.value : '';
            form.setFieldValue(name, value);
          }}
          onBlur={() => form.setFieldTouched(name, true)}
        />
        <ErrorMessage
          name={name}
          component='span'
          className={errorStyles.error}
        />
      </div>
    )}
  </Field>
);

export const SelectField = ({ name, onChange, ...rest }) => (
  <Field name={name}>
    {({ field, form }) => (
      <div>
        <Select
          {...field}
          {...rest}
          onChange={(option) => {
            const value = option.target.value ? option.target.value : '';
            form.setFieldValue(name, value);
            if (onChange) onChange(value || '');
          }}
          onBlur={() => form.setFieldTouched(name, true)}
        />
        <ErrorMessage
          name={name}
          component='span'
          className={errorStyles.error}
        />
      </div>
    )}
  </Field>
);

export const CheckboxField = ({ name, handleInputChange, ...rest }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <Checkbox
            {...field}
            {...rest}
            checked={field.value}
            onChange={(option) => {
              const value = option.target.checked
                ? option.target.checked
                : false;
              form.setFieldValue(name, value);
              if (name === 'is_bulk' && value === true) {
                form.setFieldValue('unit_type', null);
                form.setFieldValue('single_unit_measure_name', null);
                form.setFieldValue('units_multiplier', '1');
                handleInputChange(null);
              }
            }}
          />
        );
      }}
    </Field>
  );
};

LabeledInputField.propTypes = {
  name: propTypes.string.isRequired,
  handleInputChange: propTypes.func.isRequired,
};

CreatableSelectField.propTypes = {
  name: propTypes.string.isRequired,
};

SelectField.propTypes = {
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

CheckboxField.propTypes = {
  name: propTypes.string.isRequired,
  handleInputChange: propTypes.func.isRequired,
};
