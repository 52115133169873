import React, { useState } from 'react';
import styles from './Checkbox.module.css';
import propTypes from 'prop-types';

const Checkbox = (props) => {
  const { label, value, onChange, name, tooltip, checked } = props;
  const labelStyleOveride = { marginRight: '0.5rem' };
  const checkStyle = { stroke: 'white', fill: 'white' };

  const [isChecked, setIsChecked] = useState(checked || false);

  const handleChange = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <div className='pretty p-svg p-curve' style={{ marginLeft: '7px' }}>
      <input
        type='checkbox'
        onChange={handleChange}
        checked={isChecked}
        name={name}
        value={value}
      />
      <div className='state p-primary'>
        <svg className='svg svg-icon' viewBox='0 0 20 20'>
          <path
            d='M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z'
            style={checkStyle}
          />
        </svg>
        <label className={styles.label} style={labelStyleOveride}>
          {label}
        </label>
        {tooltip && <i className='far fa-question-circle' />}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  label: propTypes.string.isRequired,
  value: propTypes.shape({}),
  onChange: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  tooltip: propTypes.string.isRequired,
  checked: propTypes.bool.isRequired,
};

Checkbox.defaultProps = {
  value: {},
};

export default Checkbox;
