import React from 'react';
import propTypes from 'prop-types';

const Loader = (props) => {
  const { title } = props;

  return (
    <div className='loader__modal'>
      <div className='loader__container'>
        <div className='loader__bar'>
          <div className='loader__filler'></div>
        </div>
        <span className='loader__text'>{title.toUpperCase()}</span>
      </div>
    </div>
  );
};

Loader.propTypes = {
  title: propTypes.string.isRequired,
};

export default Loader;
