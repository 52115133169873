import React, { useState } from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import { isEmpty } from 'ramda';
import './styles.css';

const Table = ({
  list,
  columns,
  emptyContent,
  headClassName,
  bodyClassName,
  sortable,
  onClick,
  children,
  groupsNoAvailableFilters,
}) => {
  const [itemSelected, setItemSelected] = useState('');

  const selectedItem = (value, index) => {
    if (sortable) {
      onClick(value);
      setItemSelected(index);
    }
  };

  const noAvailableFilters = () =>
    !groupsNoAvailableFilters.includes(itemSelected);

  const sortableStyle = (i) =>
    sortable
      ? {
          borderTop:
            itemSelected === i && noAvailableFilters()
              ? '3px solid #626262'
              : '',
        }
      : {};

  return (
    <table
      className={cn('table table-custom is-indent animated fadeIn', {
        empty: isEmpty(list),
      })}
      data-plugin='animateList'
      data-animate='fade'
      data-child='tr'
      data-selectable='selectable'
    >
      <thead className={headClassName}>
        <tr>
          {Object.keys(columns).map((key, i) => (
            <th
              key={i}
              scope='col'
              style={sortableStyle(i)}
              onClick={() => selectedItem(columns[key].label, i)}
            >
              {columns[key].label}
            </th>
          ))}
        </tr>
      </thead>
      {isEmpty(list) && (
        <tbody className={bodyClassName}>
          <tr className='empty-content'>
            <td>{emptyContent}</td>
          </tr>
        </tbody>
      )}
      {children}
    </table>
  );
};

Table.propTypes = {
  list: propTypes.arrayOf([]).isRequired,
  columns: propTypes.shape({}).isRequired,
  children: propTypes.node.isRequired,
  emptyContent: propTypes.string.isRequired,
  headClassName: propTypes.string.isRequired,
  bodyClassName: propTypes.string.isRequired,
  sortable: propTypes.bool.isRequired,
  onClick: propTypes.func.isRequired,
  groupsNoAvailableFilters: propTypes.arrayOf([]).isRequired,
};

export default Table;
