import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import * as styled from './statics/styles';

const DropdownButton = ({ children, text }) => {
  const newChildren = typeof children === 'string' ? children.toUpperCase() : children;
  return (
    <Button outline>
      <styled.ContainerContentButton>
        <styled.ButtonIcon />
        {children ? newChildren : text.toUpperCase()}
      </styled.ContainerContentButton>
    </Button>
  );
};

DropdownButton.propTypes = {
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

DropdownButton.defaultProps = {
  text: '',
  children: '',
};

export default DropdownButton;
