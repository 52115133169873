import React from 'react';
import PropTypes from 'prop-types';
import ButtonStyled from './statics/styles';

const Button = ({
  primary,
  secondary,
  name,
  outline,
  disabled,
  transparent,
  children,
  datacy,
  onClick,
  big,
  type,
}) => {
  const newChildren =
    typeof children === 'string' ? children.toUpperCase() : children;

  return (
    <ButtonStyled
      disabled={disabled}
      primary={primary}
      secondary={secondary}
      outline={outline}
      transparent={transparent}
      big={big}
      onClick={onClick}
      datacy={datacy}
      type={type || 'submit'}
      name={name}
    >
      {newChildren}
    </ButtonStyled>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  outline: PropTypes.bool,
  transparent: PropTypes.bool,
  big: PropTypes.bool,
  datacy: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  // eslint-disable-next-line no-console
  onClick: () => console.log('default'),
  primary: false,
  secondary: false,
  outline: false,
  transparent: false,
  disabled: false,
  big: false,
  name: '',
  datacy: '',
  type: '',
};

export default Button;
