import React from 'react';
import propTypes from 'prop-types';
import styles from '../css/StockInformation.module.css';
import {
  CreatableSelectField,
  DatePickerField,
  InputField,
} from './FormHelpers';
import { useTranslation } from 'react-i18next';
import Chexbox from '../../../../../components/Checkbox/Checkbox';
import { CheckLabel, CheckSpacePadding } from '../../CreateStock/css/styled';

const StockInformation = ({
  variants,
  addresses,
  handleCheckbox,
  item_checked,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wholeRow}>
          <CreatableSelectField
            name='variant'
            label={t('modals.update_stock.name')}
            placeholder={t('modals.update_stock.name_placeholder')}
            type='select-creatable'
            list={variants}
            required
            disabled
          />
        </div>
        <InputField
          input='input'
          label={t('modals.update_stock.variant')}
          placeholder={t('modals.update_stock.variant_placeholder')}
          type='number'
          name='external_reference'
          disabled
          required
          datacy='batch'
        />
        <CreatableSelectField
          label={t('modals.update_stock.deposit')}
          placeholder={t('modals.update_stock.deposit_placeholder')}
          type='select-creatable'
          list={addresses}
          disabled
          required
          name='address'
        />
        <DatePickerField
          input='input'
          name='expires_on'
          className='value'
          label={t('modals.update_stock.expiration')}
          required
          isFuture
        />
        <InputField
          input='input'
          label={t('modals.update_stock.stock')}
          placeholder={t('modals.update_stock.stock_placeholder')}
          type='number'
          name='quantity'
          required
          datacy='stock'
        />
        <hr className={styles.wholeRow} />
        <InputField
          input='input'
          label={t('modals.update_stock.cost')}
          placeholder={t('modals.update_stock.price_placeholder')}
          type='number'
          min='0'
          name='cost'
          datacy='cost'
          required
          step='0.01'
        />
        <InputField
          input='input'
          label={t('modals.update_stock.price')}
          placeholder={t('modals.update_stock.price_placeholder')}
          type='number'
          min='0'
          name='item_price'
          datacy='price_per_pack'
          require
          step='0.01'
        />
        <InputField
          input='input'
          label={t('modals.update_stock.discount')}
          placeholder={t('modals.update_stock.discount_placeholder')}
          min='0'
          type='number'
          datacy='item_discount'
          name='item_discount'
        />
      </div>
      <CheckSpacePadding>
        <CheckLabel>{t('modals.create_stock.rescued')}</CheckLabel>
        <Chexbox
          onChange={() => handleCheckbox(item_checked)}
          checked={item_checked}
        />
      </CheckSpacePadding>
    </>
  );
};

StockInformation.propTypes = {
  variants: propTypes.arrayOf([]).isRequired,
  addresses: propTypes.arrayOf([]).isRequired,
  handleCheckbox: propTypes.func.isRequired,
  item_checked: propTypes.bool.isRequired,
};

export default StockInformation;
