import React from 'react';
import propTypes from 'prop-types';
import { buildDayTimes } from 'utils/date';

const TimetableSelect = (props) => {
  const { onChange, value } = props;

  const time = buildDayTimes();

  return (
    <select
      onChange={onChange}
      className='form-control timetable-select__item__time'
      name='value'
    >
      {time.map((t, i) => (
        <option selected={value === t} value={t} key={i}>
          {t}
        </option>
      ))}
    </select>
  );
};

TimetableSelect.propTypes = {
  value: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

export default TimetableSelect;
