import React from 'react';
import propTypes from 'prop-types';
import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import ModalFooter from 'components/ModalCenter/components/ModalFooter';
import { useTranslation } from 'react-i18next';
import ModalForm from './ModalForm';

const ViewTripDetail = (props) => {
  const { onCancel } = props;
  const { t } = useTranslation();

  return (
    <Modal
      className='assign_shipper__modal'
      title={t('modals.view_trip.title')}
      onCancel={() => onCancel()}
      {...props}
    >
      <ModalBody className='modal-detail__body'>
        <ModalForm {...props} />
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-default' onClick={onCancel}>
          {t('modals.view_trip.close')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

ViewTripDetail.propTypes = {
  onCancel: propTypes.func.isRequired,
};

export default ViewTripDetail;
