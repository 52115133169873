import React from 'react';
import { Creatable } from 'react-select';
import propTypes from 'prop-types';
import { Label } from './Label';
import styles from './Select.module.css';

const CreatableNilusSelect = (props) => {
  const {
    label,
    onChange,
    value,
    placeholder,
    options,
    disabled,
    required,
    tooltip,
  } = props;
  return (
    <div className={styles.container}>
      {label && (
        <Label
          label={label}
          required={required}
          value={value}
          tooltip={tooltip}
        />
      )}
      <Creatable
        {...props}
        isClearable
        isSearchable
        createOptionPosition='first'
        formatCreateLabel={(value) => `Crear: ${value}`}
        onChange={(value) => onChange({ target: { value } })}
        options={options}
        value={value}
        isDisabled={disabled}
        placeholder={placeholder}
        styles={selectStyles}
      />
    </div>
  );
};

const selectStyles = {
  option: (provided) => {
    const fontWeight = 'bold';
    const fontFamily = 'Poppins';
    const color = '#666666';
    return { ...provided, fontWeight, fontFamily, color };
  },
  singleValue: (provided) => {
    const fontWeight = 'bold';
    const fontFamily = 'Poppins';
    const color = '#666666';
    return { ...provided, fontWeight, fontFamily, color };
  },
  placeholder: (provided) => {
    const fontFamily = 'Poppins';
    const fontWeight = 'bold';
    const color = '#EDEDED';
    return { ...provided, fontWeight, fontFamily, color };
  },
};

CreatableNilusSelect.defaultProps = {
  placeholder: 'Seleccionar',
  value: {},
  onChange: () => {},
  options: [],
  disabled: false,
  required: false,
  label: '',
};

CreatableNilusSelect.propTypes = {
  label: propTypes.string,
  options: propTypes.arrayOf([]),
  placeholder: propTypes.string,
  value: propTypes.shape({}),
  disabled: propTypes.bool,
  onChange: propTypes.func,
  required: propTypes.bool,
  tooltip: propTypes.string.isRequired,
};

export default CreatableNilusSelect;
