import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal/component/Modal';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as modalActions from '../../store/actions';
import { v4 as uuid } from 'uuid';

const ModalContainer = styled.div`
  .modal_background {
    z-index: 1500;
    background-color: rgba(0, 0, 0, 0.38);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    height: 100%;
    min-height: 100vh;
  }
`;

const ModalStyled = styled(Modal)`
  border-radius: 5px;
  max-width: 100% !important;
  height: min-content;
  position: relative;
  min-width: calc(100vw - 20%); /* margin-top: 30rem; */
  .container_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .button {
    flex-basis: 10%;
    border: none;
    background: white;
    text-align: end;
    color: #37474f;
    padding: 0 2rem;
    cursor: pointer;
    width: max-content;
  }

  .separator {
    margin: 0;
    padding: 0;
  }

  .horizontal-divisor {
    width: 0.5rem;
  }

  .vertical-divisor {
    height: 0.5rem;
  }

  .section-title {
    font-size: 14px;
  }
`;

const ModalBodyStyled = styled(ModalBody)`
  max-height: calc(100vh - 10rem);
  min-height: min-content;
  overflow-y: scroll;
`;

const SectionContainer = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.p`
  font-size: 14px;
`;

const Row = styled.tr`
  color: white;
  font-size: 13px;
`;

const Cell = styled.td`
  padding: 0.5rem 1rem;
  background-color: #dc3545;
  border-radius: 5px;
`;

const Title = styled.h4`
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  padding: 1rem 0;
  flex-basis: 75%;
  text-align: end;
`;

const HeaderComponent = ({
  closeModal,
  classNameContainer,
  classNameButton,
  classNameSeparator,
  success,
}) => (
  <>
    <div className={classNameContainer}>
      <Title>Ups, hay unos cuantos errores en tu importación.</Title>
      <button className={classNameButton} type='button' onClick={closeModal}>
        X
      </button>
    </div>
    <hr className={classNameSeparator} />
    <h4 style={{ marginLeft: '46px' }}>Cantidad procesada: {success}</h4>
  </>
);

const ErrorsImports = ({ data: { errors, success }, closeModal }) => {
  return (
    <ModalContainer>
      <ModalStyled
        title={
          <HeaderComponent
            closeModal={closeModal}
            classNameContainer='container_title'
            classNameButton='button'
            classNameSeparator='separator'
            success={success}
          />
        }
        classNameBackground='modal_background'
      >
        <ModalBodyStyled>
          {errors.map((headerLine) => (
            <SectionContainer key={uuid()}>
              <SectionTitle>
                Linea:
                {` ${headerLine.line}`}
              </SectionTitle>
              <table>
                <tbody>
                  {headerLine.errors.length > 0 &&
                    headerLine.errors.map((row) => (
                      <>
                        <Row key={uuid()}>
                          {typeof row === 'string' ? (
                            <Cell>{row}</Cell>
                          ) : (
                            row.map((cell) => (
                              <>
                                <Cell key={uuid()}>{cell}</Cell>
                                <td className='horizontal-divisor' />
                              </>
                            ))
                          )}
                        </Row>
                        <tr className='vertical-divisor' />
                      </>
                    ))}
                </tbody>
              </table>
            </SectionContainer>
          ))}
        </ModalBodyStyled>
      </ModalStyled>
    </ModalContainer>
  );
};

ErrorsImports.propTypes = {
  data: PropTypes.shape({ errors: PropTypes.array }).isRequired,
  closeModal: PropTypes.func.isRequired,
  classNameContainer: PropTypes.string.isRequired,
  classNameButton: PropTypes.string.isRequired,
  classNameSeparator: PropTypes.string.isRequired,
};
ErrorsImports.defaultProps = {};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(modalActions.closeModal()),
});

export default connect(null, mapDispatchToProps)(ErrorsImports);
