import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from './components/Modal';

import * as actions from '../../store/actions';
import * as selectors from '../../store/selectors';

import { withHooks } from 'hooks/withHooks';

class ViewTripDetailContainer extends React.Component {
  render() {
    return <Modal {...this.props} />;
  }
}

ViewTripDetailContainer.propTypes = {
  shippers: propTypes.object,
};

const mapStateToProps = (state) => ({
  trip: selectors.data(state).trip,
});

const mapDispatchToProps = (dispatch) => ({
  onConfirm: () => dispatch(actions.closeModal()),
  onCancel: () => dispatch(actions.closeModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(ViewTripDetailContainer));
