import React from 'react';
import { ErrorMessage, Field } from 'formik';
import errorStyles from '../css/Error.module.css';
import { Select, CreatableSelect } from '../../../../../components/Select';
import { Checkbox } from '../../../../../components/Checkbox';
import { LabeledInput } from '../../../../../components/Input';
import propTypes from 'prop-types';

export const LabeledInputField = ({
  name,
  value,
  label,
  placeholder,
  ...rest
}) => {
  return (
    <Field name={name}>
      {({ field }) => {
        return (
          <div>
            <LabeledInput
              {...field}
              {...rest}
              label={label}
              value={value}
              placeholder={placeholder}
            />
            <ErrorMessage
              name={name}
              component='span'
              className={errorStyles.error}
            />
          </div>
        );
      }}
    </Field>
  );
};

export const SelectField = ({ name, onChange, ...rest }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <div>
          <Select
            {...field}
            {...rest}
            onChange={(option) => {
              const value = option.target.value ? option.target.value : '';
              form.setFieldValue(name, value);
              if (onChange) onChange(name, value);
            }}
            onBlur={() => form.setFieldTouched(name, true)}
          />
          <ErrorMessage
            name={name}
            component='span'
            className={errorStyles.error}
          />
        </div>
      )}
    </Field>
  );
};

export const CreatableSelectField = ({ name, ...rest }) => (
  <Field name={name}>
    {({ field, form }) => (
      <div>
        <CreatableSelect
          {...field}
          {...rest}
          onChange={(option) => {
            const value = option.target.value ? option.target.value : '';
            form.setFieldValue(name, value);
          }}
          onBlur={() => form.setFieldTouched(name, true)}
        />
        <ErrorMessage
          name={name}
          component='span'
          className={errorStyles.error}
        />
      </div>
    )}
  </Field>
);

export const CheckboxField = ({ name, handleInputChange, ...rest }) => (
  <Field name={name} type='checkbox'>
    {({ field }) => <Checkbox {...field} {...rest} />}
  </Field>
);

LabeledInputField.propTypes = {
  name: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  placeholder: propTypes.string.isRequired,
};

SelectField.propTypes = {
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

CreatableSelectField.propTypes = {
  name: propTypes.string.isRequired,
};

CheckboxField.propTypes = {
  name: propTypes.string.isRequired,
  handleInputChange: propTypes.func.isRequired,
};
