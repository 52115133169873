import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import styled from 'styled-components';

import { LabeledInputField, SelectField } from './FormHelpers';
import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import '../css/styles.css';
import styles from '../css/Modal.module.css';
import Button from '../../../../../components/Button';
import formStyles from '../css/Form.module.css';
import { validationSchema } from '../validations';
import { paymentMethods, reasons } from '../options';
import Spinner from '../../../../../components/Spinner';
import { PAYMENT_METHODS } from '../../../../orders/components/CreateOrder/constants';
import i18n from '../../../../../app/configs/i18n';

class CreatePayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
  }

  getPreferredMethodOption = () => {
    let preferredPaymentMethod;

    if (
      this.props.data &&
      this.props.data.institution &&
      this.props.data.institution.purchase_profile
    ) {
      preferredPaymentMethod = this.props.data.institution.purchase_profile
        .preferred_payment_method;
    }

    const index = paymentMethods.findIndex(
      (pm) => pm.value === preferredPaymentMethod
    );
    return paymentMethods[index] || {};
  };

  objectIsEmpty = (obj) => Object.entries(obj).length === 0;

  fetchPayeeIfCorresponds = (values) => {
    const { fetchPayee, resetPayee } = this.props;
    if (values.payment_method === null || values.reason === null) resetPayee();
    else if (
      !this.objectIsEmpty(values.payment_method) &&
      !this.objectIsEmpty(values.reason)
    ) {
      const { value: payment_type } = values.payment_method;
      const { value: payable_type_select } = values.reason;
      const { activity } = payable_type_select;
      fetchPayee({ payment_type: payment_type, payable_type: activity });
    }
  };

  render() {
    const { onCancel, onConfirm, isLoading, currentPayee } = this.props;
    const payee = Payee({ contact: currentPayee, isLoading });

    return (
      <Modal
        className='create-stock-modal'
        title={<Title />}
        onCancel={onCancel}
        {...this.props}
      >
        <ModalBody className='modal-detail__body'>
          <Formik
            initialValues={{
              payment_method: this.getPreferredMethodOption(),
              reason: {},
            }}
            validationSchema={validationSchema}
            onSubmit={onConfirm}
          >
            {({ isSubmitting, isValid, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className={formStyles.container}>
                  <div className={formStyles.wholeRow}>
                    <SelectField
                      label={i18n.t('modals.create_cupon.reason')}
                      placeholder={i18n.t(
                        'modals.create_cupon.reason_placeholder'
                      )}
                      options={reasons}
                      required={true}
                      name='reason'
                      onChangeWithFormValues={this.fetchPayeeIfCorresponds}
                    />
                  </div>
                  <SelectField
                    label={i18n.t(
                      'modals.create_cupon.payment_method_placeholder'
                    )}
                    placeholder={i18n.t(
                      'modals.create_cupon.payment_method_placeholder'
                    )}
                    options={PAYMENT_METHODS}
                    required={true}
                    name='payment_method'
                    onChangeWithFormValues={this.fetchPayeeIfCorresponds}
                  />
                  <LabeledInputField
                    label={i18n.t('modals.create_cupon.amount_placeholder')}
                    placeholder={i18n.t(
                      'modals.create_cupon.amount_placeholder'
                    )}
                    type='number'
                    name='amount'
                    required={true}
                  />
                  {payee}
                  <div className={formStyles.wholeRow}>
                    <div className={styles.buttons}>
                      <Button
                        type='button'
                        onClick={this.props.onCancel}
                        transparent
                      >
                        {i18n.t('modals.create_cupon.close')}
                      </Button>
                      <Button
                        type='submit'
                        disabled={isSubmitting || !isValid}
                        primary
                      >
                        {i18n.t('modals.create_cupon.submit')}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

const PayeeContainer = styled.div`
  grid-column: -1/1;
  margin-top: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;

  .header {
    font-family: 'Poppins-Bold', sans-serif;
    display: flex;
  }
`;

const Payee = ({ contact, isLoading }) => {
  if (isLoading) return <ContactSpinner />;
  else if (Object.entries(contact).length === 0) {
    return (
      <PayeeContainer>{i18n.t('modals.create_cupon.subtitle')}</PayeeContainer>
    );
  } else {
    return <PayeeInfo name={contact.name} email={contact.email} />;
  }
};

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  grid-column: -1/1;
  margin-top: 10px;
`;

const ContactSpinner = () => (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
);

const PayeeInfo = ({ name, email }) => (
  <PayeeContainer>
    <div className={'header'}>
      <p>{i18n.t('modals.create_cupon.receiver')}</p>
    </div>
    <ul>
      <li>
        {i18n.t('modals.create_cupon.name')} {name}
      </li>
      <li>
        {i18n.t('modals.create_cupon.mail')} {email}
      </li>
    </ul>
  </PayeeContainer>
);

const Title = () => (
  <div className='create-stock-modal__title'>
    {i18n.t('modals.create_cupon.title')}
  </div>
);

CreatePayment.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPreviousStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  emptyCategories: PropTypes.bool.isRequired,
  variants: PropTypes.arrayOf([]).isRequired,
  status: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.arrayOf({}).isRequired,
  onValidation: PropTypes.func.isRequired,
  addresses: PropTypes.arrayOf([]).isRequired,
  groups_availability: PropTypes.arrayOf([]).isRequired,
};

export default CreatePayment;
