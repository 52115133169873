import { call, put, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { del } from 'api-connect/dist/api';
import {
  get as getMkp,
  post as postMkp,
  put as putMkp,
} from 'api-connect/dist/marketplace';

import i18n from '../../../app/configs/i18n';
import history from '../../../app/configs/history';

import { onConfirmation } from '../../modals/store/sagas';

import * as actions from './actions';
import * as alertActions from 'modules/alerts/store/actions';
import * as modalActions from 'modules/modals/store/actions';

//FETCH ALL NETWORKS
export function* fetchAllNetworksWorker() {
  try {
    yield put(actions.fetchAllNetworks.start());
    const { res, error } = yield call(getMkp, 'networks');

    if (res.status > 400 || error) {
      return yield put(actions.fetchAllNetworks.failure({ error }));
    }

    yield put(
      actions.fetchAllNetworks.success({
        networks: res.data,
      })
    );
  } catch (error) {
    yield put(actions.fetchAllNetworks.failure({ error: 'Server error' }));
    yield put(
      alertActions.openAlert({
        message: i18n.t('networks.sagas.server_error'),
        type: 'error',
      })
    );
  }
}

//CREATE NETWORK
export function* createNetworkWorker(action) {
  history.push('/settings/networks');

  const { res, error } = yield call(postMkp, 'networks', action.payload);
  if (res.status > 400 || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('networks.sagas.server_error'),
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.addNetwork.failure({ error }));
  }

  yield put(actions.addNetwork.success());
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('networks.sagas.create'),
      type: 'success',
      delay: 2000,
    })
  );

  yield put(actions.fetchAllNetworks());
}

//UPDATE NETWORK
function* editNetworkWorker(action) {
  const { network_id } = action.payload;

  const { res, error } = yield call(
    putMkp,
    `networks/${network_id}`,
    action.payload
  );

  if (error || res.status >= 400) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('networks.sagas.server_error'),
        type: 'error',
      })
    );
    return yield put(actions.updateNetwork.failure({ error }));
  }

  yield put(actions.updateNetwork.success({ res }));

  yield put(modalActions.closeModal());

  yield put(
    alertActions.openAlert({
      message: i18n.t('networks.sagas.update'),
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.fetchAllNetworks());
}

//DELETE NETWORK
function* deleteNetworkWorker(action) {
  const message = i18n.t('networks.sagas.delete_message');
  const operationName = i18n.t('networks.sagas.delete_network');
  const confirmed = yield call(onConfirmation, operationName, message);
  if (!confirmed) {
    return;
  }
  yield put(actions.deleteNetwork.start());
  const { network_id } = action.payload;

  const { res, error } = yield call(del, `networks/${network_id}`);

  if (!res || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('networks.sagas.delete_error'),
        type: 'error',
        delay: 2000,
      })
    );
    yield put(modalActions.closeModal());
    return yield put(actions.deleteNetwork.failure({ error }));
  }

  yield put(
    alertActions.openAlert({
      message: i18n.t('networks.sagas.delete_success'),
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.deleteNetwork.success());
  yield delay(2000);
  yield put(modalActions.closeModal());
}

//FETCH ALL COUNTRIES
export function* fetchAllCountry() {
  try {
    yield put(actions.fetchCountry.start());
    const { res, error } = yield call(getMkp, 'countries');

    if (res.status > 400 || error) {
      return yield put(actions.fetchCountry.failure({ error }));
    }

    yield put(
      actions.fetchCountry.success({
        countries: res.data,
      })
    );
  } catch (error) {
    yield put(actions.fetchCountry.failure({ error: 'Server error' }));
    yield put(
      alertActions.openAlert({
        message: i18n.t('networks.sagas.server_error'),
        type: 'error',
      })
    );
  }
}

//WATCHERS
export function* fetchAllWatcher() {
  yield takeEvery(actions.fetchAllNetworks.type, fetchAllNetworksWorker);
}
export function* fetchCountryWatcher() {
  yield takeEvery(actions.fetchCountry.type, fetchAllCountry);
}

export function* createNetworkWatcher() {
  yield takeEvery(actions.addNetwork.type, createNetworkWorker);
}

export function* editNetworkWatcher() {
  yield takeEvery(actions.updateNetwork.type, editNetworkWorker);
}

export function* deleteNetworWatcher() {
  yield takeEvery(actions.deleteNetwork.type, deleteNetworkWorker);
}

//EXPORTS WATCHERS
export default [
  fetchAllWatcher,
  createNetworkWatcher,
  editNetworkWatcher,
  fetchCountryWatcher,
  deleteNetworWatcher,
];
