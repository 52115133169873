import makeActionCreator from 'make-action-creator';

import { NAMESPACE } from '../config/constants';

export const onConfirm = makeActionCreator(NAMESPACE + '/onConfirm');
export const onCancel = makeActionCreator(`${NAMESPACE}/onCancel`);
export const cleanError = makeActionCreator(NAMESPACE + 'cleanError');

export const openModal = makeActionCreator(NAMESPACE + '/open_Modal');
export const closeModal = makeActionCreator(NAMESPACE + '/close_Modal');
