import moment from 'moment';

const GRAMS = 'gr';
const MILILITRE = 'ml';

export const inKilograms = (magnitude, unit = GRAMS) => {
  const divisor = unit === MILILITRE || unit === GRAMS ? 1000 : 1;
  return magnitude / divisor;
};

export const calculateWeight = ({
  unit_measure,
  unit_measure_name,
  units_per_pack,
  units_multiplier
}) => {
  const unit_measure_number = unit_measure ? unit_measure : 0;
  const units_per_pack_number = units_per_pack ? units_per_pack : 0;
  const units_multiplier_number = units_multiplier ? units_multiplier : 1;
  const unit_measure_name_value = unit_measure_name ? unit_measure_name.value : undefined;
  return (
    inKilograms(unit_measure_number, unit_measure_name_value) *
    units_per_pack_number *
    units_multiplier_number
  );
};

export const makePlural = (word) => {
  //this function works only for 1 word.
  if (word.split(' ').length > 1 || word.length === 0) {
    return word;
  }

  const lettersArray = ['a', 'e', 'i', 'o', 'u', 'g'];
  const lastLetter = word.toLowerCase()[word.length - 1];
  return lettersArray.indexOf(lastLetter) > -1 ? word.concat('s') : word.concat('es');
};

export const initialValues = (variant) => {
  //Product viene el ID, no el producto en si mosmo
  //Marca viene el nombre
  //Descripcion viene la descripcion
  //Categoria viene el id y el nombre.
  //subcategoria viene el id, el nombre y el codigo
  //unit_type(presentacion) viene el nombre
  //units_per_pack (presentacion por bulto) viene el valor (en numero)
  //is_bulk viene true/false
  //unit_measure (PEso por Presentacion) viene el valor directamente (en string)
  //unit_measure_name (unidad de peso) viene el valor directamente
  //units_multiplier (Unidad por Presentacion) --> NO ESTA VINIENDO EN LA DATA
  //single_unit_measure_name (Nombre de la unidad)
  //weight esta el valor, no se por que no lo esta usando. viene en string
  return {
    product: variant.product_id ? variant.product_id : null,
    brand: variant.brand ? variant.brand : null,
    description: variant.description ? variant.description : null,
    parent_category_id: variant.sub_category_id ? variant.sub_category_id : {},
    category_id: variant.category_id ? variant.category_id : null,
    unit_type: variant.unit_type ? variant.unit_type : null,
    units_per_pack: variant.units_per_pack ? parseInt(variant.units_per_pack) : 1,
    is_bulk: variant.is_bulk ? variant.is_bulk : false,
    unit_measure: variant.unit_measure ? parseInt(variant.unit_measure) : 1,
    unit_measure_name: variant.unit_measure_name ? variant.unit_measure_name : null,
    units_multiplier: variant.units_multiplier ? parseInt(variant.units_multiplier) : 1,
    single_unit_measure_name: variant.single_unit_measure_name
      ? variant.single_unit_measure_name
      : null,
    weight: variant.weight ? parseInt(variant.weight) : null,
    light_weight: variant.light_weight ? variant.light_weight : false,
    need_cooling: variant.need_cooling ? variant.need_cooling : false
  };
};

export const adaptToOutputDto = (values) => {
  return {
    variant: {
      description: values.description,
      product: onlyParseProductName(values.product.value),
      brand: values.brand.value,
      category_id: values.category_id,
      unit_type: values.unit_type ? values.unit_type.value : null,
      units_multiplier: values.units_multiplier,
      units_per_pack: values.units_per_pack,
      unit_measure: values.unit_measure,
      unit_measure_name: values.unit_measure_name.value,
      light_weight: values.light_weight,
      need_cooling: values.need_cooling,
      revised: true,
      revised_at: moment().utc()._d,
      weight: values.weight,
      is_bulk: values.is_bulk,
      single_unit_measure_name: values.single_unit_measure_name
        ? values.single_unit_measure_name.value
        : null
    }
  };
};

export const onlyParseProductName = (product) => product.replace(/ *\([^)]*\) */g, '');

export const makeCategorySubcategoryBrand = (variant, categories, brands) => {
  let category;
  let subCategory;
  let brand;
  if (variant.brand_name) {
    let sub;
    category = categories.find( (c) => {
      sub = c.sub_categories.find((s) => s.id === variant.category_id);
      return sub !== undefined
    });
    subCategory = sub;
    if (subCategory) subCategory.label = subCategory.name;
    brand = brands.find((brand) => brand.name === variant.brand_name);

    return {category, subCategory, brand}
  }

  category = categories.find((category) => category.id === variant.category_id);
  subCategory = category
      ? category.sub_categories.find((sub) => sub.id === variant.sub_category_id)
      : undefined;

  if (subCategory) subCategory.label = subCategory.name;

  brand = brands.find((brand) => brand.name === variant.brand);

  return {category, subCategory, brand}
};
