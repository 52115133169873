import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import * as selectors from './store/selectors';

const Alert = (props) => {
  const { delay } = props;
  const validateDelay = delay < 1 ? 1000 : delay;
  return <ToastContainer autoClose={validateDelay} pauseOnHover />;
};

Alert.propTypes = {
  delay: propTypes.oneOfType([propTypes.number, propTypes.bool]),
};

Alert.defaultProps = {
  delay: false,
};

const mapStateToProps = (state) => ({
  delay: selectors.delay(state),
});

export default connect(mapStateToProps)(Alert);
