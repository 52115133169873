import * as yup from 'yup';
import i18n from 'app/configs/i18n';

export const validateGroups = (groups_availability) => (values) => {
  const errors = {};
  const group_keys = values.groups_availability
    ? Object.keys(values.groups_availability)
    : {};
  const allZeros =
    group_keys
      .map((x) => values.groups_availability[x])
      .filter((x) => x !== 0)
      .filter((x) => x !== '0').length === 0;

  const atLeastANegative =
    group_keys
      .map((x) => values.groups_availability[x])
      .filter((x) => Number(x) < 0).length !== 0;

  const atLeastOneEmpty = group_keys.length !== groups_availability.length;
  const atLeastOneNan =
    group_keys.map((x) => isNaN(values.groups_availability[x])).filter((x) => x)
      .length > 0;
  const atLeastOneNull =
    group_keys
      .map((x) => values.groups_availability[x] === null)
      .filter((x) => x).length > 0;

  if (allZeros)
    errors.groups = i18n.t('modals.create_stock.validations.not_zero');
  if (atLeastOneNan)
    errors.groups = i18n.t('modals.create_stock.validations.only_numbers');
  if (atLeastOneEmpty || atLeastOneNull)
    errors.groups = i18n.t('modals.create_stock.validations.valid_fields');
  if (atLeastANegative)
    errors.groups = i18n.t('modals.create_stock.validations.only_positive');
  return errors;
};

const createFirstStepSchemaWithCombo = (combo) => {
  const keys = combo.combo_product_items.reduce((acc, product) => {
    const newKey = `product-${product.id}-lote`;
    return [...acc, newKey];
  }, []);

  keys.push('variant', 'address', 'item_price');

  const schema = keys.reduce((acc, key) => {
    if (key.includes('product')) {
      acc = {
        ...acc,
        [key]: yup
          .object('PLEASE APPEAR HERE!')
          .required('Please select a Stock'),
      };
    } else {
      switch (key) {
        case 'variant':
          acc = {
            ...acc,
            variant: yup
              .object()
              .required(
                i18n.t('modals.create_stock.validations.required_product')
              )
              .nullable(),
          };
          break;
        case 'item_price':
          acc = {
            ...acc,
            item_price: yup
              .number(i18n.t('modals.create_stock.validations.numeric_price'))
              .min(0, i18n.t('modals.create_stock.validations.min_price'))
              .required(
                i18n.t('modals.create_stock.validations.required_price')
              ),
          };
          break;
        default:
          break;
      }
    }
    return acc;
  }, {});

  return yup.object().shape(schema);
};

export const firstStepSchema = yup.object().shape({
  variant: yup
    .object()
    .required(i18n.t('modals.create_stock.validations.required_product'))
    .nullable(),
  address: yup
    .object()
    .required(i18n.t('modals.create_stock.validations.required_address'))
    .nullable(),
  quantity: yup
    .number(i18n.t('modals.create_stock.validations.numeric_quantity'))
    .required(i18n.t('modals.create_stock.validations.required_quantity'))
    .positive(i18n.t('modals.create_stock.validations.positive_quantity'))
    .integer(i18n.t('modals.create_stock.validations.integer_quantity')),
  item_price: yup
    .number(i18n.t('modals.create_stock.validations.numeric_price'))
    .min(0, i18n.t('modals.create_stock.validations.min_price'))
    .required(i18n.t('modals.create_stock.validations.required_price')),
  expires_on: yup
    .object()
    .required(i18n.t('modals.create_stock.validations.required_date')),
});

export const emptyGroupsSchema = yup.object().shape({
  groups: yup
    .string()
    .required(i18n.t('modals.create_stock.validations.required_groups')),
});

export const secondStepSchema = yup.object().shape({
  groups_availability: yup.lazy((value) => {
    const keys = Object.values(value)
      .filter((item) => {
        if (typeof item.min !== 'number' || typeof item.max !== 'number') {
          return false;
        }
        return item;
      })
      .filter((item) => (item.min > 0 || item.max > 0) && item);

    if (keys.length !== Object.values(value).length) {
      return emptyGroupsSchema;
    } else {
      const validation = yup.number().positive('Debe ser 0 o mas');
      const validations = keys.reduce(
        (acc, curr) => ({ [curr]: validation, ...acc }),
        {}
      );
      return yup.object().shape(validations);
    }
  }),
});

export const firstStepSchemaWithCombo = yup.object().shape({});

export const validationSchemas = [firstStepSchema, secondStepSchema];
export const validationSchemasWithCombo = (combo) => {
  return [createFirstStepSchemaWithCombo(combo), secondStepSchema];
};
