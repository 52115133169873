import React from 'react';
import propTypes from 'prop-types';
import i18n from '../../../../../../app/configs/i18n';
class ModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
  }
  addErrorIfNumberIsNegative = (num) => {
    const errorMessage = 'El número debe ser positivo';
    let errors = this.state.errors.filter((x) => x !== errorMessage);
    if (num < 0) errors.push(errorMessage);
    this.setState(errors);
    return errors;
  };

  render() {
    const { formData, onChange, totalWeight, onValidation } = this.props;

    return (
      <div className='view_trip_detail__body'>
        <div className='view_trip_detail__body__grid'>
          <div className='view_trip_detaul__body__grid-col'>
            <div className='view_trip_detail__body__item'>
              {i18n.t('modals.create_trip.step_2.total_weight')}
            </div>
            <div className='view_trip_detail__body__item'>
              {i18n.t('modals.create_trip.step_2.distance')}
            </div>
            <div className='view_trip_detail__body__item'>
              {i18n.t('modals.create_trip.step_2.time')}
            </div>
            <label className='view_trip_detail__body__label mt-24'>
              {i18n.t('modals.create_trip.step_2.driver_pay')}
            </label>
          </div>
          <div className='view_trip_detaul__body__grid-col'>
            <div className='view_trip_detail__body__item'>
              {totalWeight} kg.
            </div>
            <div className='view_trip_detail__body__item'>120 km.</div>
            <div className='view_trip_detail__body__item'>150 min.</div>
            <input
              name='amount'
              datacy={'amount'}
              onChange={(e) => {
                onValidation(this.addErrorIfNumberIsNegative(e.target.value));
                onChange(e);
              }}
              value={formData['amount']}
              type='number'
              min={0}
              className='form-control mt-24'
              autoFocus={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

ModalForm.propTypes = {
  onChange: propTypes.func.isRequired,
  formData: propTypes.shape({}).isRequired,
  totalWeight: propTypes.number.isRequired,
  onValidation: propTypes.func,
};

ModalForm.defaultProps = {
  onValidation: () => {},
};

export default ModalForm;
