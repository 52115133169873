import React from 'react';
import propTypes from 'prop-types';
import '../css/styles.css';
import styles from '../css/StockCategoryLimits.module.css';
import { Input } from 'components/Input';
import { Field, FormSpy } from 'react-final-form';
import errorStyles from '../css/Error.module.css';
import { useTranslation } from 'react-i18next';

const minValue = (min) => (value) =>
  isNaN(value) || value >= min
    ? undefined
    : `El máximo debe ser mayor o igual a ${min}`;

const InputField = ({ name, ...rest }) => (
  <Field name={name} validate={minValue(0)}>
    {({ input, meta }) => {
      const initial = meta.initial ? meta.initial : 0;
      return <Input {...input} {...meta} initial={initial} {...rest} />;
    }}
  </Field>
);

const StockCategoryLimits = (props) => {
  const { groups_availability } = props;

  return (
    <>
      <FormSpy>
        {({ errors }) => {
          return (
            <div className={styles.errorMessage}>
              <span className={errorStyles.error}>{errors.groups}</span>
            </div>
          );
        }}
      </FormSpy>
      <div className={styles.container}>
        <Headers />
        {groups_availability.map((item, i) => {
          return <CategoryLimits key={i} item={item} />;
        })}
      </div>
    </>
  );
};

const CategoryLimits = ({ item }) => (
  <>
    <label className={styles.categoryName}>{item.name}</label>
    <InputField
      placeholder='0'
      name={`groups_availability.${item.code}.min`}
      type='number'
      min={0}
    />
    <InputField
      name={`groups_availability.${item.code}.max`}
      type='number'
      placeholder='0'
      min={0}
    />
  </>
);

const Headers = () => {
  const { t } = useTranslation();

  return (
    <>
      <label className='categories__label'>
        {t('modals.update_stock.category')}
      </label>
      <label className='categories__label'>
        {t('modals.update_stock.min')}
      </label>
      <label className='categories__label'>
        {t('modals.update_stock.max')}
      </label>
    </>
  );
};

StockCategoryLimits.propTypes = {
  groups_availability: propTypes.arrayOf([]).isRequired,
  onChange: propTypes.func.isRequired,
  value: propTypes.oneOfType([
    propTypes.string.isRequired,
    propTypes.number.isRequired,
    propTypes.shape({}).isRequired,
    propTypes.arrayOf([]).isRequired,
  ]).isRequired,
};

InputField.propTypes = {
  name: propTypes.string.isRequired,
};

CategoryLimits.propTypes = {
  item: propTypes.shape({}).isRequired,
};

export default StockCategoryLimits;
