import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const ContainerContentButton = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonIcon = styled.div`
  background: url('/static/icon_geo.svg') no-repeat;
  height: 24px;
  width: 24px;
  margin-top: 5px;
  margin-right: 8px;
`;

export const ContainerLinkTo = styled.div`
  padding: 2rem 0;
  .active {
    width: 100%;
    border-bottom: 8px solid #37afff;
  }
`;

export const LinkTo = styled(NavLink)`
  padding: 31px 12px 24px;
  font-family: 'Poppins';
  font-size: 13.5px;
  letter-spacing: 1.25px;
  color: #606060;
  cursor: pointer;
  box-sizing: border-box;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #37afff;
  }
`;

export const NavbarContainer = styled.nav`
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #eeeeee;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;

  .navbar-left {
    display: flex;
    align-items: center;
    flex-basis: 20%;
    justify-content: center;
  }

  .navbar-right {
    flex-basis: 40%;
    text-align: end;
    margin-right: 0.5rem;
  }

  .logo {
    padding: 2rem 0;
    margin: 0;
  }

  .navbar-center {
    flex-basis: 60%;
    display: flex;
    align-items: center;
  }
`;

export const ArrowIconStyle = styled.img`
  width: 12px;
  margin-left: 100px;
  height: 12px;
`;

export const HeaderConfigNav = styled.div`
  max-width: 500px;
  font-family: 'Poppins';
  z-index: 1;
  background-color: white;
  color: #606060;
  &:hover {
    background-color: #f4f4f4;
  }
`;

export const TitleNav = styled.a`
  text-transform: uppercase;
  letter-spacing: 1.25px;
  color: #606060;
  cursor: pointer;
  box-sizing: border-box;
  font-family: 'Poppins';
  font-size: 13.5px;
  text-decoration: none;
  padding: 10px 12px;
  display: block;
  &:hover {
    background-color: #f4f4f4;
  }
`;

export const TagA = styled.a`
  color: #606060;
  text-decoration: none;
  padding: 10px 12px;
  display: block;
  &:hover {
    background-color: #f4f4f4;
    color: #606060;
  }
`;

export const TagUl = styled.ul`
  position: relative;
  background-color: #ffffff;
  display: none;
  position: absolute;
  min-width: 140px;
  cursor: pointer;
`;

export const TagUlSubsesion = styled.ul`
  position: relative;
  background-color: #ffffff;
  display: none;
  position: absolute;
  min-width: 140px;
  right: -140px;
  top: 0px;
`;

export const TagLiContainer = styled.li`
  &:hover > ul {
    display: block;
  }
`;

export const TagLi = styled.li`
  position: relative;
  &:hover > ul {
    display: block;
  }
`;
