import i18n from 'app/configs/i18n';

export const Tooltips = {
  external_reference: i18n.t(
    'modals.create_variant.tooltips.external_reference'
  ),
  product: i18n.t('modals.create_variant.tooltips.product'),
  description: i18n.t('modals.create_variant.tooltips.description'),
  brand: i18n.t('modals.create_variant.tooltips.brand'),
  category_id: i18n.t('modals.create_variant.tooltips.category_id'),
  // TODO: replace and translate
  is_bulk:
    'Hace referncia a si el producto viene envasado a granel o se vende por peso',
  unit_type: i18n.t('modals.create_variant.tooltips.unit_type'),
  units_multiplier: i18n.t('modals.create_variant.tooltips.units_multiplier'),
  units_per_pack: i18n.t('modals.create_variant.tooltips.units_per_pack'),
  unit_measure: i18n.t('modals.create_variant.tooltips.unit_measure'),
  unit_measure_name: i18n.t('modals.create_variant.tooltips.unit_measure_name'),
  weight: i18n.t('modals.create_variant.tooltips.weight'),
  light_weight: i18n.t('modals.create_variant.tooltips.light_weight'),
  need_cooling: i18n.t('modals.create_variant.tooltips.need_cooling'),
};
