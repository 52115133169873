import { handleActions } from 'redux-actions';
import * as actions from './actions';
import * as permissionsActions from '../../permissions/store/actions';

const initialState = {
  status: 'init',
  error: '',
  districts: [],
};

const reducer = handleActions(
  {
    [actions.fetchAllDistricts.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.fetchAllDistricts.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),
    [actions.fetchAllDistricts.FAILURE]: (state) => ({
      ...state,
      status: 'failure',
    }),

    [actions.addDistricts.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.addDistricts.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),
    [actions.addDistricts.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.updateDistricts.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.updateDistricts.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      districts: action.payload.districts,
    }),
    [actions.updateDistricts.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.deleteDistricts.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.deleteDistricts.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      networks: action.payload.networks,
    }),
    [actions.deleteDistricts.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),
    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);
export default reducer;
