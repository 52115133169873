import { isEmpty, isNil } from 'ramda';
import { services } from '../configs/constants';
import i18n from '../../../app/configs/i18n';

export const parseLatLng = (str) => {
  if (isEmpty(str) || isNil(str)) return null;
  const latLng = str.split(',').map((e) => e.trim());
  return {
    lat: Number(latLng[0]),
    lng: Number(latLng[1]),
  };
};

export const extractIfProp = (list, prop) =>
  list.filter((elem) => elem.types.includes(prop))[0];

export const getInstitutionFormat = (institution) => {
  if (institution.updated_delivery_address) {
    if (institution.delivery_address) {
      institution.delivery_address.geometry.location =
        institution.updated_delivery_address;
    }
    if (institution.addresses) {
      institution.addresses[0].latlng = `${institution.updated_delivery_address.lat}, ${institution.updated_delivery_address.lng}`;
    }
  }
  if (institution.updated_residency_address) {
    if (institution.residence_address.geometry) {
      institution.residence_address.geometry.location =
        institution.updated_residency_address;
    } else {
      institution.residence_address.latlng = `${institution.updated_residency_address.lat}, ${institution.updated_residency_address.lng}`;
    }
  }
  return {
    ...institution,
    type: institution.category,
    category: institution.category,
    uid_type: 'CUIT',
  };
};

// EDIT address
export const getAddressFormat = (institution) => {
  let latlng;
  if (institution.delivery_address) {
    //este se usa cuando se crea la institucion
    latlng = institution.updated_delivery_address
      ? `${institution.updated_delivery_address.lat}, ${institution.updated_delivery_address.lng}`
      : `${institution.delivery_address.geometry.location.lat()}, ${institution.delivery_address.geometry.location.lng()}`;
  } else {
    //este se usa cuando se hace update
    latlng = institution.updated_delivery_address
      ? `${institution.updated_delivery_address.lat}, ${institution.updated_delivery_address.lng}`
      : `${institution.address.geometry.location.lat()}, ${institution.address.geometry.location.lng()}`;
  }

  return {
    latlng,
    street_1: institution.address.name,
    zip_code: institution.zip_code,
    city: institution.city,
    state: institution.state,
    country: institution.country,
    formatted_address: institution.address.formatted_address,
    vicinity: institution.address.vicinity,
    notes: institution.notes,
    ...getContactInfo(institution),
  };
};

export const getAddressFormatNoMap = (institution) => ({
  notes: institution.notes,
  ...getContactInfo(institution),
});

// CREATE address
export const getCreateAddressFormat = (institution) => {
  const latlng = institution.updated_delivery_address
    ? `${institution.updated_delivery_address.lat}, ${institution.updated_delivery_address.lng}`
    : `${institution.delivery_address.geometry.location.lat()}, ${institution.delivery_address.geometry.location.lng()}`;
  return {
    latlng,
    street_1: institution.delivery_address.name,
    zip_code: institution.delivery_params.zip_code,
    city: institution.delivery_params.city,
    state: institution.delivery_params.state,
    country: institution.delivery_params.country,
    formatted_address: institution.delivery_address.formatted_address,
    vicinity: institution.delivery_address.vicinity,
    notes: institution.delivery_params.notes,
    ...getContactInfo(institution),
  };
};

// CREATE residence address
export const getResidenceAddressWithMap = (institution) => {
  const latlng = institution.updated_residency_address
    ? `${institution.updated_residency_address.lat}, ${institution.updated_residency_address.lng}`
    : `${institution.residence_address.geometry.location.lat()}, ${institution.residence_address.geometry.location.lng()}`;

  return {
    latlng,
    street_1: institution.residence_address.name,
    zip_code: institution.residence_params.zip_code,
    city: institution.residence_params.city,
    state: institution.residence_params.state,
    country: institution.residence_params.country,
    formatted_address: institution.residence_address.formatted_address,
    vicinity: institution.residence_address.vicinity,
    notes: institution.residence_params.notes,
    ...getContactInfo(institution),
  };
};

// EDIT residence address
export const getResidenceAddressNoMap = (institution) => ({
  notes: institution.new_residence.notes,
  ...getContactInfo(institution),
});

export const getEditResidenceAddressFormat = (institution) => {
  const extractIfProp = (list, prop, evalProp) => {
    if (!list) return '';
    let r = list.filter((elem) => elem.types.includes(prop));
    if (r.length === 0) return '';
    return r[0][evalProp];
  };

  let latlng;
  if (institution.new_residence) {
    latlng = institution.updated_residency_address
      ? `${institution.updated_residency_address.lat}, ${institution.updated_residency_address.lng}`
      : `${institution.new_residence.geometry.location.lat()}, ${institution.new_residence.geometry.location.lng()}`;
  } else {
    latlng = institution.updated_residency_address
      ? `${institution.updated_residency_address.lat}, ${institution.updated_residency_address.lng}`
      : `${institution.residence_address.geometry.location.lat()}, ${institution.residence_address.geometry.location.lng()}`;
  }

  return {
    latlng,
    street_1: institution.new_residence.name,
    zip_code: extractIfProp(
      institution.new_residence.address_components,
      'postal_code',
      'long_name'
    ),
    city: extractIfProp(
      institution.new_residence.address_components,
      'administrative_area_level_2',
      'long_name'
    ),
    state: extractIfProp(
      institution.new_residence.address_components,
      'administrative_area_level_1',
      'long_name'
    ),
    country: extractIfProp(
      institution.new_residence.address_components,
      'country',
      'short_name'
    ),
    formatted_address: institution.new_residence.formatted_address,
    vicinity: institution.new_residence.vicinity,
    notes: institution.residence_notes,
    ...getContactInfo(institution),
  };
};

export const getContactInfo = (institution) => {
  return {
    contact_name: institution.contact_name,
    contact_cellphone: institution.contact_cellphone,
    contact_email: institution.contact_email,
    telephone: institution.telephone,
    open_hours: institution.open_hours,
  };
};

export const calculatePaymentStatus = (amount, collected_amount) => {
  const x = parseFloat(amount);
  const y = parseFloat(collected_amount);

  if (x === y) {
    return 'payed';
  } else if (y === 0 || isNaN(y)) {
    return 'not-payed';
  } else {
    return 'partially-payed';
  }
};

export const getPaymentStatus = (payment) => {
  const status = payment.status;
  if (status === 'obsolete') return 'obsolete';
  else if (status === 'cancelled') return 'cancelled';
  else if (status === 'expired') return 'expired';
  else if (status === 'pending') return 'not-payed';
  else if (status === 'approved') return 'payed';
  else if (payment.amount === payment.collected_amount) return 'payed';
  else if (payment.collected_amount === 0 || isNaN(payment.collected_amount))
    return 'not-payed';
  else return 'not-payed';
};

export const getStatusTranslation = (status) => {
  if (status === 'obsolete')
    return i18n.t('institutions.payment_status.obsolete');
  else if (status === 'cancelled')
    return i18n.t('institutions.payment_status.cancelled');
  else if (status === 'expired')
    return i18n.t('institutions.payment_status.exipred');
  else if (status === 'pending')
    return i18n.t('institutions.payment_status.pending');
  else if (status === 'approved')
    return i18n.t('institutions.payment_status.approved');
  else return '';
};

export const nameNormailizer = (name) => {
  if (name.toLowerCase().includes('organization')) {
    return i18n.t('institutions.organization');
  } else {
    return i18n.t('institutions.company');
  }
};

export const serviceNormailizer = (list) => {
  return list.reduce((acc, item) => {
    services.map((service) => {
      if (service.value === item) {
        acc.push(service.label);
      }
      return acc;
    });
    return acc;
  }, []);
};

export const normalizeSelectList = (list) => {
  return list?.reduce((acc, item) => {
    acc.push({
      label: item.name,
      value: item.id,
    });
    return acc;
  }, []);
};

export const stringNormailizerForSelect = (value, list) => {
  if (!isNil(value)) {
    return list.reduce((acc, item) => {
      if (value === item.value) {
        acc = item;
      }
      return acc;
    }, {});
  } else {
    return null;
  }
};

export const objectNormailizerForSelect = (value) => {
  if (!isNil(value)) {
    const district = {
      label: value.name,
      value: value.id,
    };
    return district;
  } else {
    return null;
  }
};

export const stringTranslator = (str, obj) => {
  return Object.keys(obj).reduce((acc, item) => {
    if (item === str) {
      acc = obj[item];
    }
    return acc;
  }, '');
};

export const messageTranslator = (data) => {
  switch (data) {
    case "can't be blank":
      return i18n.t('institutions.no_blank');
    case 'has already been taken':
      return i18n.t('institutions.alerady_used');
    default:
      return data;
  }
};
