import moment from 'moment/moment';

export const initialValues = (trip) => {
  const {amount, steps, comments} = trip;
  return {
    amount: amount,
    comments: comments,
    scheduled_date: moment(steps[0].schedule.start, moment.ISO_8601),
    scheduled_time: moment(steps[0].schedule.start, moment.ISO_8601)
  }
};

export const TRIP_PENDING = (trip) => trip.status === null;
export const TRIP_ENDED = (trip) => trip.status === "completed";
export const TRIP_IN_PROGRESS = (trip) => !TRIP_PENDING(trip) && !TRIP_ENDED(trip);