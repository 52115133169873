import React from 'react';
import { connect } from 'react-redux';

import Modal from './components/Modal';
import * as modalActions from '../../store/actions';
import * as shipperSelector from '../../../shippers/store/selectors';
import i18n from '../../../../app/configs/i18n';

class CreateBankAccountContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      formData: {
        type: 'Cuenta corriente',
      },
    });
  }

  onChange(e) {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  }

  render() {
    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.create_bank_account.success')}
        formData={this.state.formData}
        onChange={this.onChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  status: shipperSelector.status(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onConfirm: (payload) => dispatch({ type: props.onConfirm, payload }),
  onCancel: () => {
    dispatch({ type: props.onCancel });
    dispatch(modalActions.closeModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBankAccountContainer);
