import envUrls from './envUrls';
import ConstructorApi from './ConstructorApi';
import { AddingColectorErrors } from './utils';

class LogisticApi extends ConstructorApi {
  obsolescePayment = async (paymentId) => {
    return await this.api.put(`payments/obsolesce/${paymentId}`);
  };

  createUntrackedActivityPayment = async (body) => {
    return await this.api.post(`untracked_activity`, body);
  };

  fetchPaymentsOf = async (institutionId) => {
    return await this.api.get(`/v2/institutions/${institutionId}/payments`);
  };

  fetchBalances = async (network) => {
    return await this.api.post(`/account_balances?network=${network}`);
  };

  fetchPayee = async ({ payable_type, payment_type }) => {
    return await this.api.get(
      `/payments/payee?payable_type=${payable_type}&payment_type=${payment_type}`
    );
  };

  getOrders = async (institutionId) => {
    return await this.api.get(`institutions/${institutionId}/orders`);
  };

  editCoupon = async (institutionId, paymentId, body) => {
    return await this.api.patch(
      `v2/institutions/${institutionId}/payments/${paymentId}`,
      body
    );
  };
}

export default AddingColectorErrors(new LogisticApi(envUrls.api, 'Logistic'));
