import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import './styles.css';

const ModalForm = (props) => {
  const { onChange, formData, paymentStatus, paymentCoupon } = props;
  const { t } = useTranslation();

  return (
    <span className='trip-create__shipper payment-modal'>
      <div className='row'>
        <div className='form-group col-md-12'>
          <p>{t('modals.create_payment_cupon.subtitle')}</p>
          <ul className='list-group'>
            <li
              className={cn('list-group-item', {
                active: formData['type'] === 'pagofacil',
              })}
              onClick={() =>
                onChange({ target: { name: 'method', value: 'pagofacil' } })
              }
            >
              <span>{t('modals.create_payment_cupon.pago_facil')}</span>{' '}
              {formData['method'] === 'pagofacil' && (
                <i className='icon wb-check' />
              )}
            </li>
            <li
              className={cn('list-group-item', {
                active: formData['type'] === 'rapipago',
              })}
              onClick={() =>
                onChange({ target: { name: 'method', value: 'rapipago' } })
              }
            >
              <span>{t('modals.create_payment_cupon.rapi_pago')}</span>{' '}
              {formData['method'] === 'rapipago' && (
                <i className='icon wb-check' />
              )}
            </li>
          </ul>
        </div>
        {paymentStatus === 'success' && (
          <div className='form-group col-md-12'>
            <p>{t('modals.create_payment_cupon.number')}</p>
            <div className='payment-modal__coupon'>
              <h2 className='payment-modal__coupon__number'>{paymentCoupon}</h2>
            </div>
          </div>
        )}
      </div>
    </span>
  );
};

ModalForm.propTypes = {
  onChange: propTypes.func.isRequired,
  formData: propTypes.shape({}).isRequired,
  paymentStatus: propTypes.string.isRequired,
  paymentCoupon: propTypes.string.isRequired,
};

export default ModalForm;
