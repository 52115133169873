import React from 'react';
import propTypes from 'prop-types';
import { isNil } from 'ramda';
import i18n from '../../../../../app/configs/i18n';

const WeightAndPrice = ({ item, quantity, hidden }) => {
  const rows = item ? (
    <tr>
      <td>{item.weight}</td>
      <td>{item.weight * quantity}</td>
      <td>
        {item.price} {item.currency}
      </td>
      <td>
        {item.price * quantity} {item.currency}
      </td>
    </tr>
  ) : (
    <tr>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
    </tr>
  );

  const hide = isNil(hidden) ? false : hidden;

  return (
    <table className='table' hidden={hide}>
      <tbody>
        <tr>
          <th style={{ zIndex: 0 }}>
            {i18n.t('orders.from_stock.weight_by_unit')}
          </th>
          <th style={{ zIndex: 0 }}>
            {i18n.t('orders.from_stock.total_weight')}
          </th>
          <th style={{ zIndex: 0 }}>
            {i18n.t('orders.from_stock.price_per_unit')}
          </th>
          <th style={{ zIndex: 0 }}>
            {i18n.t('orders.from_stock.total_price')}
          </th>
        </tr>

        {rows}
      </tbody>
    </table>
  );
};

WeightAndPrice.propTypes = {
  item: propTypes.arrayOf([]).isRequired,
  quantity: propTypes.number.isRequired,
  hidden: propTypes.bool.isRequired,
};

export default WeightAndPrice;
