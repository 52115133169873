import React from 'react';
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';

const Spinner = ({ color, height, width, margin, size, marginRight }) => {
  return (
    <ClipLoader
      height={height}
      width={width}
      color={color}
      margin={margin}
      size={size}
      css={`
        margin-right: ${marginRight}px;
      `}
    />
  );
};

Spinner.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  size: PropTypes.string,
  marginRight: PropTypes.string,
};

Spinner.defaultProps = {
  color: '#37afff',
  height: '5',
  width: '3',
  margin: '4px',
  size: '16px',
  marginRight: '0',
};
export default Spinner;
