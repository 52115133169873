import React from 'react';
import propTypes from 'prop-types';
import { daysList } from './constants';

import TimetableSingleContainer from './components/TimetableSingle/TimetableSingleContainer';

class Timetable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      days: {},
    };
  }

  onChange = (e) => {
    const nextDays = {
      ...this.state.days,
      [e.target.name]: e.target.value,
    };

    this.setState(
      {
        days: nextDays,
        checked: [...this.state.checked, e.target.name],
      },
      () => {
        if (this.props.hasOwnProperty('onChange')) {
          this.props.onChange({
            target: { name: this.props.name, value: nextDays },
          });
        }
      }
    );
  };

  onCheck = (item) => {
    const { checked } = this.state;

    this.setState({
      checked: checked.includes(item)
        ? checked.filter((i) => i !== item)
        : [...checked, item],
    });
  };

  render() {
    return daysList.map((day, i) => (
      <TimetableSingleContainer
        key={i}
        day={day.label}
        name={day.name}
        checked={this.state.checked}
        daysList={Object.keys(this.state.days)}
        onChange={this.onChange}
        onCheck={this.onCheck}
      />
    ));
  }
}

Timetable.propTypes = {
  onChange: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
};

export default Timetable;
