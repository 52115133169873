import React from 'react';
import propTypes from 'prop-types';
import Slider from './Slider';

class SliderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slide: 0,
    };
  }

  onNextSlide = () => {
    const sliderBody = document.querySelector('.slider__list');
    sliderBody.scrollTop = 0;

    if (this.state.slide === this.props.slides.length - 1) return;
    this.setState({
      slide: this.state.slide + 1,
    });
  };

  onPreviousSlide = () => {
    const sliderBody = document.querySelector('.slider__list');
    sliderBody.scrollTop = 0;

    if (this.state.slide < 1) return;
    this.setState({
      slide: this.state.slide - 1,
    });
  };

  render() {
    return (
      <Slider
        {...this.props}
        length={this.props.slides.length}
        slide={this.state.slide}
        onNextSlide={this.onNextSlide}
        onPreviousSlide={this.onPreviousSlide}
      />
    );
  }
}

SliderContainer.propTypes = {
  slides: propTypes.arrayOf([]).isRequired,
};

export default SliderContainer;
