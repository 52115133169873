import { handleActions } from 'redux-actions';
import * as actions from './actions';
import * as permissionsActions from 'modules/permissions/store/actions';

const initialState = {
  status: 'init',
  message: '',
  type: '',
  delay: 0,
};

const reducer = handleActions(
  {
    [actions.openAlert.type]: (state, action) => ({
      ...state,
      type: action.payload.type,
      message: action.payload.message,
      delay: action.payload.delay,
    }),

    [actions.closeAlert.type]: () => ({
      ...initialState,
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
