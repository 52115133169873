import makeActionCreator from 'make-action-creator';

import { NAMESPACE } from '../config/constants';

export const fetchAllUsers = makeActionCreator(NAMESPACE + '/fetchAllUsers');

export const addUser = makeActionCreator(NAMESPACE + '/addUser');

export const updateUser = makeActionCreator(NAMESPACE + '/updateUser');

export const deleteUser = makeActionCreator(NAMESPACE + '/deleteUser');

export const getNetworks = makeActionCreator(NAMESPACE + '/getNetworks');
