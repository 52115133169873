import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';

import TimePicker from './TimePicker';

class TimePickerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment(),
    };
  }

  componentDidMount() {
    if (this.props.value) {
      this.setState({
        time: this.props.value,
      });
    }
  }

  componentDidUpdate(oldProps) {
    const { value } = this.props;

    if (value !== oldProps.value) {
      this.setState({
        time: this.props.value,
      });
    }
  }

  onChange = (value) => {
    this.setState({
      time: value,
    });

    if (this.props.onChange) {
      this.props.onChange({ target: { name: this.props.name, value } });
    }
  };

  render() {
    return (
      <TimePicker
        {...this.props}
        time={this.state.time}
        onChange={this.onChange}
      />
    );
  }
}

TimePickerContainer.propTypes = {
  onChange: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.string, propTypes.object]).isRequired,
};

export default TimePickerContainer;
