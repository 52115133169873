import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as permissionsActions from 'modules/permissions/store/actions';
import { localSearching } from '../helpers/helpers';

const initialState = {
  status: 'init',
  error: '',
  available: {},
  historic: {},
  unrevised: {},
  offers: [],
  products: undefined,
  offerDetail: {
    offer: undefined,
    status: 'init',
  },
  page: 1,
  totalPages: 1,
  stocks: {},
  stock_origin: '',
  stock_external: true,
};

const reducer = handleActions(
  {
    [actions.fetchAll.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchAll.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchAll.SUCCESS]: (state, action) => {
      return {
        ...state,
        status: 'success',
        [action.payload.type]: action.payload.stock,
        totalPages: action.payload.totalPages ? action.payload.totalPages : 1,
        page: action.payload.page ? action.payload.page : 1,
      };
    },

    [actions.update.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.update.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.update.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
    }),

    [actions.reviseVariant.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.reviseVariant.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.reviseVariant.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
    }),

    [actions.editVariant.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.editVariant.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.editVariant.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
    }),

    [actions.createVariant.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.createVariant.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.createVariant.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
    }),

    [actions.searchStock.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.searchStock.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.searchStock.SUCCESS]: (state, action) => {
      return {
        ...state,
        status: 'success',
        prevData: state.available,
        [action.payload.type]: localSearching(
          state.available,
          action.payload.search
        ),
      };
    },

    [actions.createStock.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.createStock.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.createStock.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
    }),

    [actions.fetchOffers.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchOffers.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchOffers.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      offers: action.payload.offers,
    }),

    [actions.fetchProducts.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchProducts.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchProducts.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      products: action.payload.products,
    }),

    [actions.fetchOffer.START]: (state) => ({
      ...state,
      offerDetail: {
        ...state.offerDetail,
        status: 'pending',
      },
    }),

    [actions.fetchOffer.FAILURE]: (state) => ({
      ...state,
      offerDetail: {
        ...state.offerDetail,
        status: 'failure',
      },
    }),

    [actions.fetchOffer.SUCCESS]: (state, action) => ({
      ...state,
      offerDetail: {
        ...state.offerDetail,
        status: 'success',
        offer: action.payload,
      },
    }),

    [actions.clearOffer]: (state) => ({
      ...state,
      offerDetail: {
        offer: undefined,
        status: 'init',
      },
    }),

    [actions.editStock.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.editStock.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.editStock.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
    }),

    [actions.createOffer.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.createOffer.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.createOffer.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
    }),

    [actions.cancelOffer.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.cancelOffer.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.cancelOffer.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
    }),

    [actions.pageChange.START]: (state, action) => ({
      ...state,
      status: 'pending',
    }),

    [actions.pageChange.SUCCESS]: (state, action) => ({
      ...state,
      page: action.payload.page,
    }),

    [actions.productCsvDownload.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.productCsvDownload.FAILURE]: (state) => ({
      ...state,
      status: 'failure',
    }),

    [actions.productCsvDownload.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),

    [actions.stockCsvDownload.START]: (state) => ({
      ...state,
      status: 'start',
    }),

    [actions.stockCsvDownload.FAILURE]: (state) => ({
      ...state,
      status: 'failure',
    }),

    [actions.stockCsvDownload.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),

    [actions.fetchOfferableStock.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchOfferableStock.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchOfferableStock.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      stocks: action.payload.stock,
    }),
    [actions.createCombo.START]: (state) => ({
      ...state,
      status: 'start',
    }),

    [actions.createCombo.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.createCombo.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
    }),
    [actions.editCombo.START]: (state) => ({
      ...state,
      status: 'start',
    }),
    [actions.editCombo.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),
    [actions.editCombo.FAILURE]: (state) => ({
      ...state,
      status: 'failure',
    }),
    [actions.getAllCombos.START]: (state) => ({
      ...state,
      status: 'start',
    }),

    [actions.getAllCombos.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.getAllCombos.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      combos: action.payload.combos,
    }),
    [actions.fetchAvailableStock.START]: (state) => ({
      ...state,
      status: 'start',
    }),

    [actions.fetchAvailableStock.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchAvailableStock.SUCCESS]: (state, action) => {
      return {
        ...state,
        status: 'success',
        combos: action.payload.stock,
      };
    },

    [actions.setStockOrigin.type]: (state, action) => ({
      ...state,
      stock_origin: action.payload.origin_id,
    }),
    [actions.archiveStock.START]: (state) => ({
      ...state,
      status: 'start',
    }),
    [actions.archiveStock.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),
    [actions.archiveStock.FAILURE]: (state) => ({
      ...state,
      status: 'failure',
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
