import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { omit } from 'ramda';

import Modal from './Modal';

import { buildScheduledAt } from 'utils/date';
import { isValid } from 'utils';

import { fields } from '../constants';

class ModalContainer extends React.Component {
  constructor(props) {
    super(props);

    const formData = {
      scheduled_date: moment(),
      scheduled_time: moment(),
      shipper_id: '',
      comments: '',
      amount: 0,
      broadcast: false,
      initial_origin_id: this.props.data.orders[0].giver[0].addresses[0].id,
    };

    this.isValid = isValid(fields);

    this.state = {
      formData,
      // If the giver ids from all the selected orders are the same, start at step o
      // Step -1 is to select the origin of the trip
      step: this.props.data.orders
        .map((o) => o.giver.map((g) => g.name))
        .flat()
        .every((val, i, arr) => val === arr[0])
        ? 0
        : -1,
      isValid: this.isValid(formData, 0),
    };
  }

  componentDidMount() {
    const { deliveryPreference, step } = this.props;

    if (step) {
      this.setState({
        step: this.props.step,
      });
    }

    this.setState({
      formData: {
        ...this.state.formData,
        scheduled_date: moment(deliveryPreference.day),
      },
    });
  }

  componentDidUpdate(oldProps) {
    const { deliveryPreference } = this.props;

    if (oldProps.deliveryPreference !== deliveryPreference) {
      this.setState({
        formData: {
          ...this.state.formData,
          scheduled_date: moment(deliveryPreference.day),
        },
      });
    }
  }

  onChange = (e) => {
    const { formData, step } = this.state;

    const nextFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };

    this.setState({
      isValid: this.isValid(nextFormData, step),
      formData: nextFormData,
    });
  };

  onNextStep = () => {
    const { formData, step } = this.state;

    const nextStep = step + 1;

    this.setState({
      isValid: this.isValid(formData, nextStep),
      step: nextStep,
    });
  };

  onConfirm = () => {
    const { formData } = this.state;
    const { data } = this.props;

    const time = buildScheduledAt(
      formData.scheduled_date,
      formData.scheduled_time
    );

    const nextFormData = {
      ...omit(['scheduled_date', 'scheduled_time'], formData),
      pickup_schedule: {
        start: time,
        end: time,
      },
      dropoff_schedule: {
        start: time,
        end: time,
      },
      orders_ids: data.orders.map((o) => o.id),
      initial_origin_id: formData.initial_origin_id,
      refrigerated: data.refrigerated,
    };

    if (this.props.onConfirm) {
      this.props.onConfirm(nextFormData);
    }
  };

  render() {
    const { step, isValid, formData } = this.state;

    return (
      <Modal
        {...this.props}
        step={step}
        isValid={isValid}
        formData={formData}
        onChange={this.onChange}
        onConfirm={this.onConfirm}
        onNextStep={this.onNextStep}
      />
    );
  }
}

ModalContainer.propTypes = {
  step: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default ModalContainer;
