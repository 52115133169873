import * as yup from 'yup';
import i18n from 'app/configs/i18n';

//export const validationSchema = yup.object(.shape)
export const validationSchema = yup.object().shape(
  {
    is_bulk: yup.boolean(),
    product: yup
      .object()
      .required(i18n.t('modals.create_variant.validations.required_product'))
      .nullable(),
    description: yup.string(),
    brand: yup
      .object()
      .required(i18n.t('modals.create_variant.validations.required_brand'))
      .nullable(),
    parent_category_id: yup
      .object()
      .required(i18n.t('modals.create_variant.validations.required_category'))
      .nullable(),
    category_id: yup
      .object()
      .required(
        i18n.t('modals.create_variant.validations.required_subcategory')
      )
      .nullable(),
    units_multiplier: yup
      .number(
        i18n.t('modals.create_variant.validations.numeric_units_multiplier')
      )
      .positive(
        i18n.t('modals.create_variant.validations.positive_units_multiplier')
      )
      .integer(
        i18n.t('modals.create_variant.validations.integer_units_multiplier')
      ),
    unit_type: yup
      .object()
      .nullable()
      .when('is_bulk', {
        is: false,
        then: yup
          .object()
          .required(
            i18n.t('modals.create_variant.validations.required_unit_type')
          )
          .nullable(),
      }),
    units_per_pack: yup
      .number(
        i18n.t('modals.create_variant.validations.numeric_units_per_pack')
      )
      .required(
        i18n.t('modals.create_variant.validations.required_units_per_pack')
      )
      .integer(
        i18n.t('modals.create_variant.validations.positive_units_per_pack')
      )
      .min(1),
    unit_measure: yup
      .number(i18n.t('modals.create_variant.validations.numeric_units_measure'))
      .positive(
        i18n.t('modals.create_variant.validations.positive_units_measure')
      ),
    unit_measure_name: yup
      .object()
      .required(
        i18n.t('modals.create_variant.validations.numeric_unit_measure')
      )
      .nullable(),
    single_unit_measure_name: yup
      .object()
      .nullable()
      .when('units_multiplier', {
        is: (units_multiplier) => units_multiplier > 1,
        then: yup
          .object()
          .nullable()
          .required(
            i18n.t(
              'modals.create_variant.validations.required_single_unit_measure_name'
            )
          ),
      }),
    weight: yup
      .number(i18n.t('modals.create_variant.validations.numeric_weight'))
      .positive(i18n.t('modals.create_variant.validations.positive_weight')),
  },
  [['units_multiplier'], ['is_bulk']]
);
