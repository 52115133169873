import React from 'react';
import propTypes from 'prop-types';
import catParse from '../constants';
import Modal from 'components/Modal';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import './statics/styles.css';

const ViewStockProduct = (props) => {
  const { onCancel, data } = props;
  const { product } = data;
  const { t } = useTranslation();

  return (
    <Modal
      className='-modal'
      title={`${t('modals.view_stock_product.product')} ${product.name}`}
      onCancel={onCancel}
    >
      <div className='edit-stock__categories'>
        <div className='edit-stock__categories-top'>
          <p className='categories__label'>
            {t('modals.view_stock_product.stock')}
          </p>
          <p className='categories__poppins-bold edit-stock__categories-left-title'>
            {product.quantity
              ? `${product.quantity} ${t('modals.view_stock_product.units')}`
              : t('modals.view_stock_product.no_units')}
          </p>
        </div>
        <hr />
        <div className='edit-stock__categories-bottom'>
          <div className='edit-stock__categories-label-group'>
            <label className='categories__label'>
              {t('modals.view_stock_product.category')}
            </label>
            <div className='edit-stock__categories-label-right'>
              <label className='categories__label'>
                {t('modals.view_stock_product.min')}
              </label>
              <label className='categories__label'>
                {t('modals.view_stock_product.max')}
              </label>
            </div>
          </div>
          {Object.keys(product.groups_availability).map((key, i) => (
            <div key={i} className='edit-stock__categories-label-group'>
              <label className='categories__label'>Cat.{catParse[key]}</label>
              <div className='edit-stock__categories-label-right'>
                <input className='categories__input' value={'0'} disabled />
                <input
                  className='categories__input'
                  value={
                    isNil(product.groups_availability[key])
                      ? '0'
                      : product.groups_availability[key]
                  }
                  disabled
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

ViewStockProduct.propTypes = {
  onCancel: propTypes.func.isRequired,
  data: propTypes.shape({}).isRequired,
};

export default ViewStockProduct;
