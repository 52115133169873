import MarketplaceApi from 'app/api/MarketplaceApi';
import UserApi from 'app/api/UserApi';
import LogisticApi from 'app/api/LogisticApi';
import history from 'app/configs/history';
import i18n from 'app/configs/i18n';
import * as actions from './actions';
import * as alertActions from 'modules/alerts/store/actions';
import * as modalActions from 'modules/modals/store/actions';
import * as actionsPayments from '../../payments/store/actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'utils';
import { isEmpty, isNil } from 'ramda';
import {
  availableExtensions,
  readExcel,
} from '../../orders/components/List/helper';
import {
  getAddressFormat,
  getContactInfo,
  getCreateAddressFormat,
  getEditResidenceAddressFormat,
  getInstitutionFormat,
  getResidenceAddressWithMap,
  messageTranslator,
} from '../helpers/helpers';

// Workers
export function* fetchAll() {
  const { res: items, error } = yield call(UserApi.fetchInstitutions);

  if (error || items.status >= 400) {
    yield put(actions.fetchAll.failure({ error }));
    return yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.server_error'),
        type: 'error',
      })
    );
  }
  return yield put(
    actions.fetchAll.success({ items: normalize(items.data.institutions) })
  );
}

export function* fetchOne(action) {
  const { institutionId } = action.payload;

  const { res, error } = yield call(UserApi.fetchInstitution, institutionId);

  if (error || res.status >= 400) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.get_institution_error'),
        type: 'error',
      })
    );
    return yield put(actions.fetchOne.failure({ error }));
  }

  yield put(actions.fetchOne.success({ item: res.data.institution }));
  return yield put(actions.fetchDistricts());
}

export function* fetchNetworksWorker() {
  const activeNetwork = localStorage.getItem('active_network');
  const { res, error } = yield call(UserApi.fetchNetworks);

  if (error) {
    return yield put(
      alertActions.openAlert({
        message: 'Error al obtener la red',
        type: 'error',
        delay: 2000,
      })
    );
  }

  const infoActiveNetwork = res.data.filter(
    (item) => item.name === activeNetwork
  )[0];

  return yield put(actions.fetchNetworks.success({ infoActiveNetwork }));
}

export function* fetchOneOrder(action) {
  yield put(actions.fetchOneOrder.start());

  const { institutionId } = action.payload;

  try {
    const { res } = yield call(LogisticApi.getOrders, institutionId);
    yield put(
      actions.fetchOneOrder.success({
        id: institutionId,
        orders: res.data.orders,
      })
    );
  } catch (error) {
    yield put(actions.fetchOneOrder.failure({ error }));
    history.push('/institutions/list/organizations');
    yield put(
      alertActions.openAlert({
        message: 'Server error',
        type: 'error',
        delay: 2000,
      })
    );
  }
}

export function* fetchOneHandler(action) {
  yield all([yield call(fetchOne, action), yield call(fetchOneOrder, action)]);
}

export function* create(action) {
  yield put(actions.create.start());
  const { institution } = action.payload;
  // * CRUD action
  const { res, error } = yield call(
    UserApi.createInstitution,
    getInstitutionFormat(institution)
  );

  if (error) {
    return yield put(actions.create.failure({ error }));
  }

  yield put(actions.create.success({ institution: res.data.institution }));

  yield call(createAddress, {
    ...institution,
    institutionId: res.data.institution.id,
  });
  yield call(createResidenceAddress, {
    ...getResidenceAddressWithMap(institution),
    institutionId: res.data.institution.id,
  });

  history.push('/institutions/list/organizations');
  yield put(
    alertActions.openAlert({
      message: i18n.t('institutions.sagas.create_success'),
      type: 'success',
      delay: 2000,
    })
  );
}

export function* createAddress(institution) {
  yield put(actions.createAddress.start());

  const { res, error } = yield call(
    UserApi.createAddressInstitution,
    institution.institutionId,
    getCreateAddressFormat(institution)
  );

  if (error || res.status >= 400) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.create_error'),
        type: 'error',
      })
    );
    return yield put(actions.createAddress.failure({ error }));
  }

  return yield put(actions.createAddress.success(res.data));
}

export function* createResidenceAddress(institution) {
  yield put(actions.createResidenceAddress.start());

  const { res, error } = yield call(
    UserApi.createResidenceAddress,
    institution.institutionId,
    institution
  );

  if (error || res.status >= 400) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.create_error'),
        type: 'error',
      })
    );
    return yield put(actions.createResidenceAddress.failure(error));
  }

  return yield put(actions.createResidenceAddress.success(res.data));
}

export function* updateRecurrentWorker(action) {
  const { institution, recurrent } = action.payload;
  const newFormat = getInstitutionFormat(institution);

  const { res, error } = yield call(
    UserApi.updateRecurrent,
    institution.id,
    newFormat
  );

  if (error || res.status >= 400) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.update_error'),
        type: 'error',
      })
    );
    return yield put(actions.updateRecurrent.failure({ error }));
  }

  if (!error) {
    yield put(
      actions.updateRecurrent.success({ institution: res.data.institution })
    );
  }

  if (recurrent) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.update_success'),
        type: 'success',
        delay: 2000,
      })
    );
  }
}

export function* update(action) {
  const { institution, only_toggle } = action.payload;

  const newFormat = getInstitutionFormat(institution);

  const { res, error } = yield call(
    UserApi.updateInstitution,
    institution.id,
    newFormat
  );

  if (error || res.status >= 400) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.update_error'),
        type: 'error',
      })
    );
    return yield put(actions.update.failure({ error }));
  }

  if (!error) {
    yield put(actions.update.success({ institution: res.data.institution }));
  }

  if (only_toggle) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.update_success'),
        type: 'success',
        delay: 2000,
      })
    );
  }

  const addresses = res.data.institution.addresses[0]
    ? res.data.institution.addresses[0].latlng
    : 0;

  const validateUpdated = institution.updated_delivery_address
    ? `${institution.updated_delivery_address.lat},${institution.updated_delivery_address.lng}`
    : addresses;

  const { notes } = institution;

  const validateResResidenceId = res?.data?.institution?.addresses[0]
    ? res?.data?.institution?.addresses[0].id
    : null;

  const validateInsResidenceId = institution.addresses[0]
    ? institution.addresses[0].id
    : null;

  const addressId = validateResResidenceId || validateInsResidenceId;

  const validateInstitutionId = res?.data?.institution?.id || institution?.id;

  if (!isNil(institution.address)) {
    yield call(updateAddress, {
      ...getAddressFormat(institution),
      notes,
      institutionId: validateInstitutionId,
      addressId,
    });
  } else {
    yield call(updateAddress, {
      ...getContactInfo(institution),
      notes,
      institutionId: validateInstitutionId,
      addressId,
      latlng: validateUpdated,
    });
  }

  if (!isNil(institution.new_residence)) {
    if (isEmpty(institution.residence_address)) {
      yield call(createResidenceAddress, {
        ...getEditResidenceAddressFormat(institution),
        institutionId: validateInstitutionId,
      });
    } else {
      yield call(updateResidenceAddress, {
        ...getEditResidenceAddressFormat(institution),
        institutionId: validateInstitutionId,
        addressId: institution?.residence_address?.id,
      });
    }
  } else {
    const contactInfo = getContactInfo(institution);
    contactInfo['notes'] = institution.residence_notes;
    console.log('Me ejecuté', institution, res.data.institution);
    yield call(updateResidenceAddress, {
      ...contactInfo,
      institutionId: res?.data?.institution?.id,
      addressId:
        institution?.residence_address?.id ||
        res?.data?.institution?.residence_address?.id,
    });
  }

  if (!error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.update_success'),
        type: 'success',
        delay: 2000,
      })
    );
    yield put(alertActions.closeAlert());
  }

  yield delay(1000);
  yield history.push(`/institutions/list/organizations`);
}

export function* updateAddress(institution) {
  yield put(actions.updateAddress.start());

  if (isEmpty(institution.addresses)) {
    return yield createAddress(institution);
  }

  const validateAddressId =
    typeof institution?.addressId === 'string'
      ? institution?.addressId
      : institution?.addressId?.id;

  const validateRequestToCall = ({
    newInstitution,
    action,
    institutionId,
    addressId,
  }) => {
    return {
      create: async () =>
        await UserApi.createAddressInstitution(institutionId, newInstitution),
      update: async () =>
        await UserApi.updateAddress(institutionId, addressId, newInstitution),
    }[action];
  };

  console.log('institution', institution);
  const request = validateRequestToCall({
    action: validateAddressId ? 'update' : 'create',
    newInstitution: institution,
    institutionId: institution.institutionId,
    addressId: validateAddressId,
  });

  const { res, error } = yield call(request);

  if (error || res.status >= 400) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.update_error'),
        type: 'error',
      })
    );
    return yield put(actions.updateAddress.failure({ error }));
  }

  yield put(actions.updateAddress.success({ res }));
}

export function* updateResidenceAddress(institution) {
  yield put(actions.updateResidenceAddress.start());
  const { res, error } = yield call(
    UserApi.updateResidenceAddress,
    institution.institutionId,
    institution.addressId,
    institution
  );

  if (error || res.status >= 400) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.update_error'),
        type: 'error',
      })
    );
    return yield put(actions.updateResidenceAddress.failure({ error }));
  }
  yield put(actions.updateResidenceAddress.success({ res: res.data }));
}

export function* createUserWorker(action) {
  yield put(actions.createUser.start());
  yield put(modalActions.cleanError());

  const { user, institution } = action.payload;
  const { res, error } = yield call(UserApi.createUser, institution.id, user);

  if (error || res.status >= 400) {
    const keyError = Object.keys(error.data.errors)[0];
    const messageError = `${keyError.toUpperCase()}: ${messageTranslator(
      error.data.errors[keyError]
    )}`;
    yield put(modalActions.onConfirm.failure({ error: messageError }));
    return yield put(actions.createUser.failure({ error }));
  }

  yield put(actions.createUser.success({ institution: res }));
  return yield put(actions.fetchOne({ institutionId: institution.id }));
}

export function* removeUserWorker(action) {
  const { user_id, institution_id } = action.payload;

  const { res, error } = yield call(
    UserApi.removeUser,
    institution_id,
    user_id
  );

  if (error || res.status >= 400) {
    return yield put(actions.removeUser.failure({ error }));
  }

  yield put(actions.removeUser.success({ id: user_id }));
  yield put(
    alertActions.openAlert({
      message: i18n.t('institutions.sagas.delete_user_success'),
      type: 'success',
      delay: 2000,
    })
  );

  return yield put(actions.fetchOne({ institutionId: institution_id }));
}

export function* updateUserWorker(action) {
  yield put(actions.updateUser.start());
  const { user, institution } = action.payload;

  //* CRUD action
  const { res, error } = yield call(
    UserApi.updateUser,
    institution.id,
    user.id,
    user
  );

  if (error || res.status >= 400) {
    return yield put(actions.updateUser.failure({ error }));
  }

  yield put(actions.updateUser.success({ institution: res.data }));
  yield put(modalActions.closeModal());
  yield call(fetchOneHandler, { payload: { institutionId: institution.id } });
}

export function* fetchDistrictsWorker() {
  const { res, error } = yield call(UserApi.fetchDistricts);

  if (error || res.status >= 400) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.get_districts_error'),
        type: 'error',
      })
    );
    return yield put(actions.fetchDistricts.failure({ error }));
  }

  yield put(actions.fetchDistricts.success({ districts: res.data.districts }));
  return yield put(actions.fetchCategories());
}

export function* fetchCategoriesWorker() {
  const { res, error } = yield call(MarketplaceApi.getPurchaseGroups);

  if (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.get_categories_error'),
        type: 'error',
      })
    );
    return yield put(actions.fetchCategories.failure({ error }));
  }
  return yield put(
    actions.fetchCategories.success({ categories: res.data.purchase_groups })
  );
}

export function* editCouponWorker(action) {
  // yield put(actions.editCoupon.start());

  const { institution, payment, body } = action.payload;

  const { res, error } = yield call(
    LogisticApi.editCoupon,
    institution.id,
    payment.id,
    body
  );

  if (error || res.status >= 400) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.update_payment_error'),
        type: 'error',
      })
    );
    return yield put(actions.editCoupon.failure({ error }));
  }

  yield put(
    actions.editCoupon.success({
      payment: res.data.payment,
      id: res.data.payment.id,
    })
  );

  yield put(
    actionsPayments.setPayment({
      payment: res.data.payment,
      id: res.data.payment.id,
    })
  );
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('institutions.sagas.update_payment_success'),
      type: 'success',
      delay: 2000,
    })
  );
}

export function* fetchInfoClientWorker() {
  const { res, error } = yield call(UserApi.fetchBusinessRawData);

  if (error) {
    return yield put(actions.fetchInfoClient.failure({ error }));
  }
  return yield put(
    actions.fetchInfoClient.success({ infoClient: { ...res.data } })
  );
}

export function* uploadExcelInstitutionWorker(action) {
  try {
    const { file, status } = action.payload;

    yield put(actions.uploadExcelInstitution.start());

    const extension = file.name.replace(/^.*\./, '');

    if (availableExtensions.includes(extension)) {
      const newFile = yield call(readExcel, file, status);
      const formatBody = { file: [...newFile.result] };
      const {
        res: { data: res },
      } = yield call(UserApi.uploadExcelInstitutions, formatBody);

      if (res.errors.length) {
        yield put(actions.uploadExcelInstitution.failure());
        yield put(
          alertActions.openAlert({
            message: i18n.t('institutions.sagas.excel_error'),
            type: 'error',
            delay: 2000,
          })
        );
        delay(2000);
        return yield put(
          modalActions.openModal({
            type: 'errorsImports',
            data: {
              success: res.ok,
              errors: [...res.data.errors],
            },
          })
        );
      }
      yield put(actions.uploadExcelInstitution.success());
      yield put(
        alertActions.openAlert({
          message: i18n.t('institutions.sagas.upload_excel_success'),
          type: 'success',
          delay: 2000,
        })
      );
    } else {
      yield put(actions.uploadExcelInstitution.failure());
      return yield put(
        alertActions.openAlert({
          message: i18n.t('institutions.sagas.upload_excel_error'),
          type: 'error',
          delay: 2000,
        })
      );
    }
  } catch (error) {
    yield put(actions.uploadExcelInstitution.failure());
    return yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.sagas.upload_excel_error'),
        type: 'error',
        delay: 2000,
      })
    );
  }
}

// Watchers
export function* fetchAllWatcher() {
  yield takeEvery(actions.fetchAll.type, fetchAll);
}

export function* fetchOneWatcher() {
  yield takeEvery(actions.fetchOne.type, fetchOneHandler);
}

export function* createWatcher() {
  yield takeEvery(actions.create.type, create);
}

export function* updateWatcher() {
  yield takeEvery(actions.update.type, update);
}

export function* createUserWatcher() {
  yield takeEvery(actions.createUser.type, createUserWorker);
}

export function* removeUserWatcher() {
  yield takeEvery(actions.removeUser.type, removeUserWorker);
}

export function* updateUserWatcher() {
  yield takeEvery(actions.updateUser.type, updateUserWorker);
}

export function* fetchDistrictsWatcher() {
  yield takeEvery(actions.fetchDistricts.type, fetchDistrictsWorker);
}

export function* fetchNetworks() {
  yield takeEvery(actions.fetchNetworks.type, fetchNetworksWorker);
}

export function* fetchCategoriesWatcher() {
  yield takeEvery(actions.fetchCategories.type, fetchCategoriesWorker);
}

export function* editCouponWatcher() {
  yield takeEvery(actions.editCoupon.type, editCouponWorker);
}

export function* fetchInfoClientWatcher() {
  yield takeEvery(actions.fetchInfoClient.type, fetchInfoClientWorker);
}

export function* uploadExcelInstitutionWatcher() {
  yield takeEvery(
    actions.uploadExcelInstitution.type,
    uploadExcelInstitutionWorker
  );
}

export function* updateRecurrentWatcher() {
  yield takeEvery(actions.updateRecurrent.type, updateRecurrentWorker);
}

export default [
  fetchAllWatcher,
  fetchOneWatcher,
  createWatcher,
  updateWatcher,
  createUserWatcher,
  updateUserWatcher,
  removeUserWatcher,
  fetchDistrictsWatcher,
  fetchCategoriesWatcher,
  editCouponWatcher,
  fetchNetworks,
  fetchInfoClientWatcher,
  uploadExcelInstitutionWatcher,
  updateRecurrentWatcher,
];
