import EditComboContainer from './components/EditComboContainer';

import { connect } from 'react-redux';

import { withHooks } from 'hooks/withHooks';

import * as modalActions from '../../store/actions';
import * as selectHelpersActions from 'modules/selectHelpers/store/actions';
import * as stockActions from 'modules/stock/store/actions';

import * as selectHelpersSelectors from 'modules/selectHelpers/store/selectors';

//IMPORTANT NOTICE//
//EDIT COMBO is only being used to display information about the combo//
//no actual editing function is being performed//
//this components can be reused for editing afterwards//

const mapStateToProps = (state) => ({
  products: selectHelpersSelectors.getVariantList(state)('product_variants'),
  statusProducts: state.select_helpers.status,
});

const mapDispatchToProps = (dispatch, props) => ({
  onMount: () => {
    dispatch(selectHelpersActions.fetchVariants({ type: 'product_variants' }));
  },
  onConfirm: (body, id) => {
    dispatch({ type: stockActions.editCombo.type, payload: { ...body, id } });
  },
  onCancel: () => {
    dispatch({ type: props.onCancel });
    dispatch(modalActions.closeModal());
  },
  //  onUnmount: () => dispatch(stockActions.reviseVariant.clearStatus)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(EditComboContainer));
