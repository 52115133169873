import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from './components/Modal';

import * as actions from '../../store/actions';
import * as selectors from '../../store/selectors';

import { withHooks } from 'hooks/withHooks';

import { buildScheduledAt } from 'utils/date';

import i18n from '../../../../app/configs/i18n';

class EditTripContainer extends React.Component {
  buildDto = (values, trip) => {
    const time = buildScheduledAt(values.scheduled_date, values.scheduled_time);
    return {
      id: trip.id,
      amount: values.amount,
      comments: values.comments,
      pickup_schedule: {
        start: time,
        end: time,
      },
      dropoff_schedule: {
        start: time,
        end: time,
      },
    };
  };

  onConfirm = (values) => {
    const dto = this.buildDto(values, this.props.trip);
    this.props.onConfirm(dto);
  };

  render() {
    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.edit_trip.success')}
        onConfirm={this.onConfirm}
        onChange={this.onChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  trip: selectors.data(state).trip,
});

const mapDispatchToProps = (dispatch, props) => ({
  onConfirm: (trip) => dispatch({ type: props.onConfirm, payload: { trip } }),
  onCancel: () => dispatch(actions.closeModal()),
});

EditTripContainer.propTypes = {
  trip: propTypes.shape({}).isRequired,
  onConfirm: propTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(EditTripContainer));
