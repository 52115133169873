import { createSelector } from 'reselect';
import {
  NAMESPACE,
  daysOfTheWeek,
  typeOfInstitution,
} from '../configs/constants';
import { sortBy, prop, pipe, isNil, mergeDeepRight, isEmpty } from 'ramda';
import {
  stringNormailizerForSelect,
  objectNormailizerForSelect,
} from '../helpers/helpers';

export const institutions = (state) => state[NAMESPACE].items;
export const status = (state) => state[NAMESPACE].status;
export const error = (state) => state[NAMESPACE].error;
export const districts = (state) => state[NAMESPACE].districts;
export const categories = (state) => state[NAMESPACE].categories;
export const payments = (state) => state[NAMESPACE].payments;
export const loadingPrueba = (state) => state[NAMESPACE].loadingPrueba;
export const infoActiveNetwork = (state) => state[NAMESPACE].infoActiveNetwork;
export const infoClient = (state) => state[NAMESPACE].infoClient;
export const statusExcel = (state) => state[NAMESPACE].statusExcel;

export const isLoading = createSelector(
  status,
  (status) => status === 'pending'
);

export const filterInstitutionsByType = createSelector(
  institutions,
  (list) => (type) => {
    return Object.keys(list).reduce((res, key) => {
      // TODO: this needs refactor, what happens when an institution has category: null
      if (
        !isNil(list[key]) &&
        !isNil(list[key].category) &&
        list[key].category.includes(type)
      ) {
        res.push(list[key]);
      }
      return res;
    }, []);
  }
);

export const institutionOrderByDate = createSelector(
  institutions,
  (list) => (id) => {
    if (!list.hasOwnProperty(id) || !list[id].hasOwnProperty('orders')) return;

    const orders = pipe(sortBy(prop('created_at')))(list[id].orders);

    return {
      ...list[id],
      orders,
    };
  }
);

const parseInstitutionsFormat = createSelector(
  institutions,
  (institutionList) => {
    return Object.keys(institutionList).reduce((acc, key) => {
      const institution = institutionList[key];
      acc[key] = {
        ...mergeDeepRight(institution, {
          category: stringNormailizerForSelect(
            institution.category,
            typeOfInstitution
          ),
          order_days: stringNormailizerForSelect(
            institution.order_days,
            daysOfTheWeek
          ),
          district_id: objectNormailizerForSelect(institution.district),
          purchase_group_id: objectNormailizerForSelect(
            institution.purchase_group
          ),
        }),
      };
      return acc;
    }, {});
  }
);

export const oneInstitutionSelector = createSelector(
  parseInstitutionsFormat,
  (list) => (id) => {
    if (isEmpty(list)) return {};
    return list[id];
  }
);
