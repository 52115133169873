import CreateComboContainer from './components/CreateComboContainer';

import { connect } from 'react-redux';

import { withHooks } from 'hooks/withHooks';

import * as modalActions from '../../store/actions';
import * as selectHelpersActions from 'modules/selectHelpers/store/actions';

import * as selectHelpersSelectors from 'modules/selectHelpers/store/selectors';

const mapStateToProps = (state) => ({
  products: selectHelpersSelectors.getVariantList(state)('product_variants'),
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    onMount: () => {
      dispatch(
        selectHelpersActions.fetchVariants({ type: 'product_variants' })
      );
    },
    onConfirm: (body) => {
      dispatch({
        type: props.onConfirm,
        payload: { body },
      });
    },
    onCancel: () => {
      dispatch({ type: props.onCancel });
      dispatch(modalActions.closeModal());
    },
  };
  // onUnmount: () => dispatch(stockActions.reviseVariant.clearStatus)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(CreateComboContainer));
