import React from 'react';
import propTypes from 'prop-types';

import TripSummarySlider from 'modules/trips/components/TripSummarySlider/TripSummarySliderContainer';

const ModalForm = (props) => {
  const { trip } = props;

  return (
    <div className='view_trip_detail__body'>
      <TripSummarySlider trip={trip} />
    </div>
  );
};

ModalForm.propTypes = {
  trip: propTypes.shape({}).isRequired,
};

export default ModalForm;
