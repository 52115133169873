import styled from 'styled-components';

export const ContainerInputs = styled.div`
  flex-direction: column;
`;

export const ContainerRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WholeRow = styled.div`
  grid-column: 1 / -1;
`;
export const Space = styled.div`
  width: 220px;
`;
export const CheckLabel = styled.label`
  font-family: Open Sans;
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.22px;
  text-align: left;
  line-hight: 32px;
  margin-top: 0.2rem;
  margin-right: 0.4rem;
`;
export const CheckSpace = styled.div`
  margin-top: 5px;
`;
export const CheckSpacePadding = styled.div`
  margin-top: 5px;
  padding: 6px;
`;
