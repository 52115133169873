import { createSelector } from 'reselect';
import { isNil, pick } from 'ramda';
import moment from 'moment';

import { NAMESPACE } from '../config/constants';

export const orders = (state) => state[NAMESPACE].items;
export const status = (state) => state[NAMESPACE].status;
export const error = (state) => state[NAMESPACE].error;
export const singleOrder = (state) => state[NAMESPACE].order;
export const statusExcel = (state) => state[NAMESPACE].statusExcel;

export const getOrder = createSelector(orders, (list) => (id) => {
  if (isNil(list)) return {};
  return list[id];
});

export const ordersFilteredByDay = createSelector(orders, (list) => (day) => {
  const ordersIds = Object.keys(list).filter((key) => {
    const dropoffTime = list[key].delivery_preference;

    if (isNil(dropoffTime)) return false;
    return moment(dropoffTime.day).isSame(day, 'day');
  });

  return pick(ordersIds, list);
});

export const groupedOrdersByTime = (state) => {
  const items =
    Object.keys(state[NAMESPACE].items).length === 0
      ? {
          morning: [],
          afternoon: [],
          without_delivery: [],
        }
      : Object.values(state[NAMESPACE].items)?.reduce(
          (acc, item) => {
            if (item.delivery_preference.time) {
              return {
                ...acc,
                [item.delivery_preference.time]: [
                  ...acc[item.delivery_preference.time],
                  item,
                ],
              };
            }

            return {
              ...acc,
              without_delivery: [...acc.without_delivery, item],
            };
          },
          {
            morning: [],
            afternoon: [],
            without_delivery: [],
          }
        );

  return items;
};

export const getOrdersBySource = (orderList) => {
  const resultMap = new Map();
  let multiOriginOrders = [];
  let singleOriginOrders = [];
  let removedOrders = [];

  if (orderList.length >= 1) {
    orderList.forEach((o) => {
      if (isNil(o)) return;
      if (o.removed) {
        removedOrders.push(o);
      } else {
        !isNil(o.giver) &&
          (o.giver.length > 1 ? multiOriginOrders : singleOriginOrders).push(o);
      }
    });
  }

  addSingleOriginOrdersToMap(resultMap, singleOriginOrders);
  addMultiOriginOrdersToMap(resultMap, multiOriginOrders);
  addRemovedOrdersToMap(resultMap, removedOrders);
  return resultMap;
};

const addSingleOriginOrdersToMap = (map, orders) => {
  orders.reduce((accumulated, currentOrder) => {
    const ordersOfSource = accumulated.get(currentOrder.giver[0].name);
    let orderListToPutInMap;
    if (accumulated.get(currentOrder.giver[0].name)) {
      orderListToPutInMap = ordersOfSource.concat([currentOrder]);
    } else {
      orderListToPutInMap = [currentOrder];
    }
    accumulated.set(currentOrder.giver[0].name, orderListToPutInMap);
    return accumulated;
  }, map);
};

const addMultiOriginOrdersToMap = (map, orders) => {
  if (orders.length >= 1) {
    map.set('Múltiple', orders);
  }
};

const addRemovedOrdersToMap = (map, orders) => {
  if (orders.length >= 1) {
    map.set('Eliminadas', orders);
  }
};

export const getDeliveriesIds = createSelector(
  orders,
  (list) => (ordersIds) => {
    const orders = pick(ordersIds, list);
    return Object.keys(orders).map((key) => orders[key].deliveries[0].id);
  }
);

export const getTotalWeight = createSelector(orders, (list) => (ordersIds) => {
  const orders = pick(ordersIds, list);
  return Object.keys(orders).reduce((res, key) => {
    res =
      res +
      orders[key].deliveries[0].packages.reduce(
        (res, prev) => res + prev.weight,
        0
      );

    return res;
  }, 0);
});

export const getOrderItems = createSelector(singleOrder, (list) => {
  if (isNil(list)) return {};
  return list.order_items;
});
