import { handleActions } from 'redux-actions';

import * as actions from './actions';
import { normalize } from '../../../utils';

const initialState = {
  status: 'init',
  error: '',
  notifications: [],
  selected: '',
  audienceType: '',
  audiences: [],
  receivers: [],
};

const reducer = handleActions(
  {
    [actions.fetchAll.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchAll.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchAll.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      notifications: normalize(action.payload.notifications),
    }),

    [actions.fetchOne.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchOne.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchOne.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
    }),

    [actions.create.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.create.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.create.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
    }),

    [actions.selectNotification.type]: (state, action) => ({
      ...state,
      selected_id: action.payload.selected_id,
    }),
    [actions.fetchAudiences.START]: (state, action) => ({
      ...state,
      status: 'pending',
    }),
    [actions.fetchAudiences.SUCCESS]: (state, action) => ({
      ...state,
      audiences: action.payload.audiences,
      status: 'success',
    }),
    [actions.fetchAudiences.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
    }),
    [actions.fetchReceivers.START]: (state, action) => ({
      ...state,
      status: 'pending',
    }),
    [actions.fetchReceivers.SUCCESS]: (state, action) => ({
      ...state,
      receivers: {
        ...state.receivers,
        [action.payload.audienceType]: action.payload.audience,
      },
      status: 'success',
    }),
    [actions.fetchReceivers.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
    }),
    [actions.setAudienceType.type]: (state, action) => ({
      ...state,
      audienceType: action.payload,
    }),
  },
  initialState
);

export default reducer;
