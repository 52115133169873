import { connect } from 'react-redux';

import UpdateStock from './components/UpdateStockContainer';

import * as actions from '../../store/actions';
import * as stockActions from '../../../stock/store/actions';
import * as selectHelpersSelectors from 'modules/selectHelpers/store/selectors';

import { withHooks } from 'hooks/withHooks';
import * as selectHelpersActions from '../../../selectHelpers/store/actions';

const mapStateToProps = (state, props) => ({
  groups_availability: selectHelpersSelectors.categories(state),
  product: selectHelpersSelectors.stockForUpdate(props.data.full_product)(
    state
  ),
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => dispatch(selectHelpersActions.fetchCategories()),
  onConfirm: (payload, stock_id) =>
    dispatch({
      type: stockActions.updateStock.type,
      payload,
      stock_id,
    }),
  onCancel: () => dispatch(actions.closeModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(UpdateStock));
