import { call, put, takeEvery } from 'redux-saga/effects';

import { put as del } from 'api-connect/dist/api';
import { delay } from 'redux-saga';

import {
  get as getMkp,
  post as postMkp,
  put as putMkp,
} from 'api-connect/dist/marketplace';

import i18n from '../../../app/configs/i18n';
import history from '../../../app/configs/history';

import { onConfirmation } from '../../modals/store/sagas';

import * as actions from './actions';
import * as alertActions from 'modules/alerts/store/actions';
import * as modalActions from 'modules/modals/store/actions';

//FETCH ALL COUNTRIES

export function* fetchAllCountriesWorker() {
  try {
    const { res, error } = yield call(getMkp, 'countries');

    if (res.status > 400 || error) {
      return yield put(actions.fetchAllCountries.failure({ error }));
    }

    yield put(
      actions.fetchAllCountries.success({
        countries: res.data,
      })
    );
  } catch (error) {
    yield put(actions.fetchAllCountries.failure({ error: 'Server error' }));
    yield put(
      alertActions.openAlert({
        message: i18n.t('networks.sagas.server_error'),
        type: 'error',
      })
    );
  }
}

//CREATE COUNTRY

export function* createCountrykWorker(action) {
  history.push('/settings/country/');
  const { res, error } = yield call(postMkp, 'countries', action.payload);

  if (res.status > 400 || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('country.sagas.error_message'),
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.addCountry.failure({ error }));
  }
  yield put(actions.addCountry.success());
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('country.sagas.create'),
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.fetchAllCountries());
}

//UPDATE COUNTRY

export function* updateCountryWorker(action) {
  const { id } = action.payload;

  const { res, error } = yield call(putMkp, `/countries/${id}`, action.payload);

  if (error || res.status >= 400) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('country.sagas.error_message'),
        type: 'error',
      })
    );
    return yield put(actions.updateCountry.failure({ error }));
  }

  yield put(actions.updateCountry.success({ res }));
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('country.sagas.update'),
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.fetchAllCountries());
}

//DELETE COUNTRY

function* deleteCountryWorker(action) {
  const message = i18n.t('country.sagas.delete_message');

  const operationName = i18n.t('country.sagas.delete_country');

  const confirmed = yield call(onConfirmation, operationName, message);
  if (!confirmed) {
    return;
  }

  const { id } = action.payload;
  const { res, error } = yield call(del, `/countries/${id}`);

  if (!res || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('country.sagas.error_message'),
        type: 'error',
        delay: 2000,
      })
    );
    yield put(modalActions.closeModal());
    return yield put(actions.deleteCountry.failure({ error }));
  }

  yield put(
    alertActions.openAlert({
      message: i18n.t('country.sagas.delete'),
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.deleteCountry.success());

  yield delay(2000);
  yield put(modalActions.closeModal());
}

//WATCHERS

export function* fetchAllWatcher() {
  yield takeEvery(actions.fetchAllCountries.type, fetchAllCountriesWorker);
}

export function* createCountryWatcher() {
  yield takeEvery(actions.addCountry.type, createCountrykWorker);
}

export function* updateCountryWatcher() {
  yield takeEvery(actions.updateCountry.type, updateCountryWorker);
}

export function* deleteCountryWatcher() {
  yield takeEvery(actions.deleteCountry.type, deleteCountryWorker);
}

//EXPORTS WATCHERS

export default [
  fetchAllWatcher,
  createCountryWatcher,
  updateCountryWatcher,
  deleteCountryWatcher,
];
