import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import '../statics/styles.css';

const Dropdown = (props) => {
  const { handleClickOutside } = props;

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleRef = (ref) => {
    props.handleRef(ref);
  };

  const { options, onToggle } = props;

  return (
    <div className='-dropdown max_width' ref={handleRef}>
      {Object.keys(options).map((key) => (
        <div
          key={key}
          aria-hidden='true'
          onClick={() => {
            if (options[key].disabled) return;
            options[key].onClick();
            onToggle(options[key]);
          }}
          disabled={options[key].disabled}
          className={cn('-dropdown__elem', {
            '-dropdown__elem--disabled': options[key].disabled,
            '-dropdown__elem--warning': options[key].warning,
          })}
        >
          {options[key].label}
        </div>
      ))}
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.shape({}).isRequired,
  onToggle: PropTypes.func.isRequired,
  handleRef: PropTypes.func.isRequired,
  handleClickOutside: PropTypes.func.isRequired,
};

export default Dropdown;
