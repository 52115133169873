import React from 'react';
import { connect } from 'react-redux';
import Navbar from './components/Navbar';
import * as permissionsSelectors from 'modules/permissions/store/selectors';

const NavbarContainer = (props) => {
  return <Navbar {...props} />;
};

const mapStateToProps = (state) => ({
  user: permissionsSelectors.user(state),
});

export default connect(mapStateToProps)(NavbarContainer);
