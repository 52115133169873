import makeActionCreator from 'make-action-creator';

import { NAMESPACE } from '../config/constants';

export const loginUser = makeActionCreator(NAMESPACE + '/loginUser');
export const logoutUser = makeActionCreator(NAMESPACE + '/logoutUser');

export const fetchOne = makeActionCreator(NAMESPACE + '/fetchOne');

export const cleanModules = 'CLEAN_MODULES';
