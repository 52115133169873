import * as yup from 'yup';

import i18n from 'app/configs/i18n';

export const validationSchema = (trip) =>
  yup.object().shape({
    amount: yup
      .number(i18n.t('modals.edit_trip.validations.amount_must_be_numeric'))
      .positive(i18n.t('modals.edit_trip.validations.amount_must_be_positive'))
      .required(i18n.t('modals.edit_trip.validations.amount_must_exist')),
    comments: yup.string(),
    scheduled_date: yup
      .object()
      .nullable()
      .required(
        i18n.t('modals.edit_trip.validations.schedule_date_must_exist')
      ),
    scheduled_time: yup
      .object()
      .nullable()
      .required(
        i18n.t('modals.edit_trip.validations.schedule_time_must_exist')
      ),
  });
