import { connect } from 'react-redux';
import ModalContainer from './components/ModalContainer';
import * as actions from '../../store/actions';
import { withHooks } from 'hooks/withHooks';
import * as institutionSelectors from 'modules/institutions/store/selectors';

const mapStateToProps = (state) => ({
  status: institutionSelectors.status(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onConfirm: (body, institution, payment) =>
    dispatch({
      type: props.onConfirm,
      payload: { body, institution, payment },
    }),
  onCancel: () => dispatch(actions.closeModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(ModalContainer));
