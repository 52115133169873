import React from 'react';
import PropTypes from 'prop-types';
import LoadingBar from 'components/Loader';
import { useTranslation } from 'react-i18next';

const Loading = () => {
  const { t } = useTranslation();
  return <LoadingBar title={t('loading')} />;
};

Loading.propTypes = {
  modules: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default Loading;
