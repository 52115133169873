import React from 'react';
import PropTypes from 'prop-types';

import Step3 from './Step3';

import { formatForSelect } from 'utils';
import i18n from '../../../../../../app/configs/i18n';

class Step3Container extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shippers: [],
      formData: {},
    };
  }

  componentDidMount() {
    const { shippers } = this.props;
    this.onParseShippersList(shippers);
  }

  componentDidUpdate(oldProps) {
    const { shippers } = this.props;

    if (shippers !== oldProps.shippers) {
      this.onParseShippersList(shippers);
    }
  }

  onParseShippersList = (shippers) => {
    const nextShippers = [
      {
        label: '',
        options: [
          {
            label: i18n.t('modals.create_trip.step_3.broadcast'),
            value: 'broadcast',
          },
        ],
      },
      {
        label: i18n.t('modals.create_trip.step_3.assign'),
        options: formatForSelect(shippers, 'name'),
      },
    ];

    this.setState({
      shippers: nextShippers,
    });
  };

  onChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        shipper: e,
      },
    });

    if (e.value === 'broadcast') {
      this.props.onChange({ target: { name: 'broadcast', value: true } });
      this.props.onChange({ target: { name: 'shipper_id', value: false } });
    } else {
      this.props.onChange({ target: { name: 'shipper_id', value: e.id } });
    }
  };

  render() {
    const { shippers } = this.state;

    return (
      <Step3
        shippers={shippers}
        formData={this.state.formData}
        onChange={this.onChange}
      />
    );
  }
}

Step3Container.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  shippers: PropTypes.shape({}).isRequired,
};

export default Step3Container;
