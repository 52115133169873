import React from 'react'
import ModalFull from './components/ModalFull'
import './components/statics/styles.css'
class ModalFullContainer extends React.Component {
  render () {
    return (
      <ModalFull {...this.props}/>
    )
  }
}

export default ModalFullContainer