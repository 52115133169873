import i18n from 'app/configs/i18n';

export const validateGroups = (groups_availability) => (values) => {
  const errors = {};
  const group_keys = values.groups_availability
    ? Object.keys(values.groups_availability)
    : {};
  const allZeros =
    group_keys
      .map((x) => values.groups_availability[x])
      .filter((x) => x !== 0)
      .filter((x) => x !== '0').length === 0;

  const atLeastANegative =
    group_keys
      .map((x) => values.groups_availability[x])
      .filter((x) => Number(x) < 0).length !== 0;

  const atLeastOneEmpty = group_keys.length !== groups_availability.length;
  const atLeastOneNull =
    group_keys
      .map((x) => values.groups_availability[x] === null)
      .filter((x) => x).length > 0;

  if (allZeros)
    errors.groups = i18n.t(
      'modals.update_stock.validations.at_least_one_grater'
    );
  if (atLeastOneEmpty || atLeastOneNull)
    errors.groups = i18n.t('modals.update_stock.validations.zero_or_more');
  if (atLeastANegative)
    errors.groups = i18n.t('modals.update_stock.validations.only_positive');
  return errors;
};

export const minValue = (min) => (value) =>
  isNaN(value) || value >= min
    ? undefined
    : `${i18n.t(
        'modals.update_stock.validations.greater_or_equal_than'
      )}${min}`;
