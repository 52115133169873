import i18n from '../../../app/configs/i18n';

export const NAMESPACE = 'shippers';

export const REQUIREMENTS_NAMES = {
  driving_license: i18n.t('drivers.detail.verifications.driving_license'),
  is_monotributista: i18n.t('drivers.detail.verifications.is_monotributista'),
  has_cuit_or_cuil: i18n.t('drivers.detail.verifications.has_cuit_or_cuil'),
  has_banking_account: i18n.t(
    'drivers.detail.verifications.has_banking_account'
  ),
  has_paypal_account: i18n.t('drivers.detail.verifications.has_paypal_account'),
  habilitation_transport_food: i18n.t(
    'drivers.detail.verifications.habilitation_transport_food'
  ),
  sanitary_notepad: i18n.t('drivers.detail.verifications.sanitary_notepad'),
};

export const FILTER_STATUS = {
  UNVERIFIED: 'unverified',
  VERIFIED: 'verified',
  ALL: 'all',
};
