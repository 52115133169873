import React from 'react';
import PropTypes from 'prop-types';

import DropdownWithButton from './components/DropdownWithButton';

class DropdownWithButtonContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selected: {},
    };
  }

  componentDidMount() {
    const { options, _default } = this.props;

    this.setState({
      selected: options[_default],
    });
  }

  componentDidUpdate(oldProps) {
    const { options, _default } = this.props;

    if (_default !== oldProps._default) {
      this.setState({
        selected: options[_default],
      });
    }
  }

  onToggle = (selected) => {
    const { open } = this.state;

    this.setState({
      open: !open,
      selected,
    });
  };

  handleRef = (ref) => {
    this.wrapperRef = ref;
  };

  onClickOutsideDropdown = () => {
    this.wrapperRef &&
      this.setState({
        open: !this.state.open,
      });
  };

  render() {
    const { open, selected } = this.state;

    return (
      <DropdownWithButton
        {...this.props}
        open={open}
        selected={selected}
        onToggle={this.onToggle}
        handleRef={this.handleRef}
        handleClickOutside={this.onClickOutsideDropdown}
      />
    );
  }
}

DropdownWithButtonContainer.propTypes = {
  _default: PropTypes.string.isRequired,
  options: PropTypes.shape({}).isRequired,
};

export default DropdownWithButtonContainer;
