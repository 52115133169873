import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';

import './styles.css';

const ToolTip = (props) => {
  const { className, text, display, children, onHover, onBlur } = props;

  return (
    <span className={className} style={{ position: 'absolute' }}>
      <div key='1' className={cn('example example-tooltip tooltip__container')}>
        <div
          style={display ? { opacity: 1 } : { opacity: 0 }}
          className='tooltip tooltip-top'
          role='tooltip'
        >
          <div className='tooltip-arrow' />
          <div className='tooltip-inner'>{text}</div>
        </div>
      </div>
      <span key='2' onMouseOver={onHover} onMouseLeave={onBlur}>
        {children}
      </span>
    </span>
  );
};

ToolTip.propTypes = {
  className: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  display: propTypes.bool.isRequired,
  children: propTypes.node.isRequired,
  onBlur: propTypes.func.isRequired,
  onHover: propTypes.func.isRequired,
};

export default ToolTip;
