import React from 'react';
import PropTypes from 'prop-types';
import CreatePayment from './CreatePayment';
import { initialData, adaptToOutputDto } from '../helpers';

export default class CreatePaymentContainer extends React.Component {
  onConfirm = (values) => {
    const dto = adaptToOutputDto(values, this.props.data);
    this.props.onConfirm({ dto, delivery_id: this.props.data.delivery_id });
  };

  render() {
    return (
      <CreatePayment
        {...this.props}
        initialData={initialData}
        onConfirm={this.onConfirm}
      />
    );
  }
}

CreatePaymentContainer.propTypes = {
  step: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};
