import React from 'react';
import propTypes from 'prop-types';
import { isNil } from 'ramda';

import Input from './components/Input';

class InputContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });

    if (this.props.hasOwnProperty('onChange')) {
      this.props.onChange({
        target: { value: e.target.value, name: this.props.name },
      });
    }
  };

  static getDerivedStateFromProps(props) {
    if (!isNil(props.value)) {
      return {
        value: props.value,
      };
    }
    return '';
  }

  render() {
    const { value } = this.state;
    return (
      <Input
        {...this.props}
        value={value}
        onChange={this.onChange}
        onCheck={this.onCheck}
      />
    );
  }
}

InputContainer.propTypes = {
  onChange: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
    propTypes.object,
    propTypes.array,
  ]).isRequired,
};

export default InputContainer;
