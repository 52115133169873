import { createSelector } from 'reselect';
import { isNil, omit, values } from 'ramda';

import { NAMESPACE } from '../config/constants';
import { getProductLabel } from '../../stock/store/selectors';

export const items = (state) => state[NAMESPACE].items;
export const status = (state) => state[NAMESPACE].status;
export const error = (state) => state[NAMESPACE].error;
export const addresses = (state) => state[NAMESPACE].addresses;
export const categories = (state) => state[NAMESPACE].categories;
export const stock = (state) => state[NAMESPACE].stock;
export const isLoading = (state) => state[NAMESPACE].status === 'pending';

export const getItemsList = createSelector(items, (list) => (type) =>
  list[type]
);

const valueOrEmpty = (value) => {
  return value ? value : '';
};

export const parseParentCategories = createSelector(items, (list) => (type) => {
  if (isNil(list[type])) return [];
  return list[type].map((parent_category) => {
    return {
      ...parent_category,
      label: parent_category.name,
      value: parent_category.id,
      code: parent_category.code,
      sub_categories: parent_category['sub_categories'].map((sub_category) => {
        return {
          ...sub_category,
          label: sub_category.name,
          value: sub_category.id,
          code: sub_category.code,
        };
      }),
    };
  });
});

export const getVariantList = createSelector(items, (list) => (type) => {
  if (isNil(list[type])) return [];
  return list[type].map((item) => {
    const label = getProductLabel(item);
    const newList = {
      ...omit(['name'], item),
      label: label,
    };
    return newList;
  });
});

export const getAddressList = createSelector(addresses, (list) => {
  if (isNil(list)) return [];
  return list.map((item) => {
    const newList = {
      value: item.id,
      label: !isNil(item.institution_name)
        ? item.institution_name
        : 'Sin Nombre',
    };
    return newList;
  });
});

export const parseStock = createSelector(stock, (list) => {
  if (isNil(list)) return [];
  const newList = values(list);
  return newList.flatMap((item) =>
    item.map((i) => ({
      value: i.id,
      external: i.external,
      label: `${i.name} ${valueOrEmpty(i.brand_name)} ${valueOrEmpty(
        i.description
      )} ${i.unit_measure}${i.unit_measure_name} - Lote ${
        i.external_reference
      }`,
      variant_id: i.variant_id,
    }))
  );
});

export const createStockForUpdate = (product, groups_availability) => {
  return {
    variant: { label: getProductLabel(product), value: product.variant_id },
    address: {
      label: product.address.institution_name,
      value: product.address_id,
    },
    external_reference: product.external_reference,
    quantity: product.quantity,
    item_price: product.item_price,
    item_discount: product.item_discount,
    groups_availability: categoryLimitsToArray(product, groups_availability),
    expires_on: product.expires_on,
    item_check: product.rescued,
  };
};

export const categoryLimitsToArray = (product, groups_availability) => {
  return Object.keys(groups_availability).map((key) => {
    return {
      name: groups_availability[key].name,
      category_identifier: groups_availability[key].category_identifier,
      min: 0,
      max:
        product['groups_availability'][
          groups_availability[key].category_identifier
        ],
    };
  });
};

export const stockForUpdate = (product) =>
  createSelector(categories, (categories) =>
    createStockForUpdate(product, categories)
  );
