import { connect } from 'react-redux';
import { withHooks } from 'hooks/withHooks';
import * as modalActions from '../../store/actions';
import * as selectHelpersActions from 'modules/selectHelpers/store/actions';
import * as stockActions from 'modules/stock/store/actions';
import ModalContainer from './components/ModalContainer';

const mapDispatchToProps = (dispatch, props) => ({
  onMount: () => {
    dispatch(
      selectHelpersActions.fetchAll({
        types: [
          'simple_products',
          'parent_categories',
          'brands',
          'variant/unit_types',
          'variant/measure_names',
        ],
      })
    );
  },
  onConfirm: (data) => dispatch({ type: props.onConfirm, payload: { data } }),
  onCancel: () => {
    dispatch({ type: props.onCancel });
    dispatch(modalActions.closeModal());
  },
  onUnmount: () => dispatch(stockActions.reviseVariant.clearStatus),
});

export default connect(null, mapDispatchToProps)(withHooks(ModalContainer));
