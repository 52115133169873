import React from 'react'

import Loader from './components/Loader'

import './components/statics/styles.css'

class LoaderContainer extends React.Component {
  render () {
    return (
      <Loader {...this.props} />
    )
  }
}

export default LoaderContainer