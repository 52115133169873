import { lazy } from 'react';
import reducer from './store/reducers';
import sagas from './store/sagas';
import * as selectors from './store/selectors';
import * as constants from './config/constants';
import * as actions from './store/actions';

export default {
  selectors,
  constants,
  actions,
  reducer,
  scenes: {
    list: lazy(() => import('./scenes/List')),
    create: lazy(() => import('./scenes/Create')),
    createOrder: lazy(() => import('./scenes/CreateOrder')),
    detail: lazy(() => import('./scenes/Detail')),
  },
  sagas: [...sagas],
};
