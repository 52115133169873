import React from 'react';
import propTypes from 'prop-types';
import Modal from './Modal';
import { parsedValues } from '../helpers';
import { isNil } from 'ramda';
import Loader from '../../../../../components/Loader/components/Loader';
import i18n from '../../../../../app/configs/i18n';

class ModalContainer extends React.Component {
  state = {
    formData: {},
    loading: true,
  };

  componentDidUpdate() {
    const {
      brands,
      parent_categories,
      measureNames,
      products,
      unitTypes,
      data,
    } = this.props;
    if (
      this.state.loading &&
      !isNil(data) &&
      !isNil(brands) &&
      !isNil(parent_categories) &&
      !isNil(measureNames) &&
      !isNil(products) &&
      !isNil(unitTypes)
    ) {
      const parent_category = parent_categories.find(
        (category) => category.id === data.variant.parent_category_id
      );
      const category = parent_category
        ? parent_category.sub_categories.find(
            (sub) => sub.id === data.variant.category_id
          )
        : undefined;

      let formData = {
        brand: brands.find((brand) => brand.name === data.variant.brand_name),
        parent_category_id: parent_category ? parent_category : undefined,
        category_id: category ? category : undefined,
        description: data.variant.description,
        product: products.find(
          (product) => product.id === data.variant.product_id
        ),
        unit_measure: data.variant.unit_measure,
        unit_measure_name: measureNames.find(
          (mn) => mn.value === data.variant.unit_measure_name
        ),
        unit_type: unitTypes.find((ut) => ut.value === data.variant.unit_type),
        units_multiplier: data.variant.units_multiplier
          ? data.variant.units_multiplier
          : 1,
        units_per_pack: data.variant.units_per_pack,
      };
      this.setState({ formData: formData, loading: false });
    }
  }

  onChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  };

  clearSubCategory = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
        category_id: null,
      },
    });
  };

  onConfirm = () => {
    const { formData } = this.state;

    const body = {
      ...parsedValues(formData),
      revised: true,
    };

    if (this.props.onConfirm) {
      this.props.onConfirm(body);
    }
  };

  render() {
    return this.state.loading ? (
      <div>
        <Loader title={i18n.t('modals.revise_product.loading')} />
      </div>
    ) : (
      <Modal
        {...this.props}
        onChange={this.onChange}
        onParentCategoryChange={(e) => this.clearSubCategory(e)}
        onConfirm={this.onConfirm}
        formData={this.state.formData}
      />
    );
  }
}

ModalContainer.propTypes = {
  brands: propTypes.arrayOf([]).isRequired,
  parent_categories: propTypes.arrayOf([]).isRequired,
  measureNames: propTypes.arrayOf([]).isRequired,
  products: propTypes.arrayOf([]).isRequired,
  unitTypes: propTypes.arrayOf([]).isRequired,
  data: propTypes.arrayOf([]).isRequired,
};
export default ModalContainer;
