import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';

const DropdownWithButton = ({
  onToggle,
  open,
  disabled,
  options,
  handleRef,
  handleClickOutside,
  children,
}) => {
  return (
    <div className='-dropdown-with-button'>
      <div
        className='-dropdown-with-button__button'
        aria-hidden='true'
        onClick={onToggle}
      >
        {children}
      </div>
      {open && !disabled && (
        <Dropdown
          onToggle={onToggle}
          options={options}
          handleRef={handleRef}
          handleClickOutside={handleClickOutside}
        />
      )}
    </div>
  );
};

DropdownWithButton.propTypes = {
  options: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
  onToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  handleRef: PropTypes.func.isRequired,
  handleClickOutside: PropTypes.func.isRequired,
};

DropdownWithButton.defaultProps = {
  disabled: false,
};

export default DropdownWithButton;
