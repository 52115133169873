import React from 'react';
import propTypes from 'prop-types';
import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import ModalFooter from 'components/ModalCenter/components/ModalFooter';
import ModalForm from './ModalForm';
import { useTranslation } from 'react-i18next';
import './styles.css';

const UpdateShipper = (props) => {
  const { onCancel, onConfirm, shipper, trip } = props;
  const { t } = useTranslation();

  const header = (
    <span>
      <h4>{t('modals.assign_driver.assign')}</h4>
    </span>
  );

  return (
    <Modal
      className='assign_shipper__modal'
      title={header}
      onCancel={() => onCancel()}
      {...props}
    >
      <ModalBody>
        <ModalForm {...props} />
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-default' onClick={onCancel}>
          {t('modals.assign_driver.close')}
        </button>
        <button
          className='btn btn-primary'
          onClick={() => onConfirm({ shipperId: shipper.id, id: trip })}
        >
          {t('modals.assign_driver.submit')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

UpdateShipper.propTypes = {
  onCancel: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
  shipper: propTypes.shape({}).isRequired,
  trip: propTypes.shape({}).isRequired,
};

export default UpdateShipper;
