import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import { isEmpty, isNil } from 'ramda';

import './statics/styles.css';

import { useTranslation } from 'react-i18next';

const TripSummarySlider = (props) => {
  const { trip, slide, openCreatePaymentCouponModal } = props;

  const order = trip.orders[slide];
  const { t } = useTranslation();

  return (
    <ul className='slider__list'>
      <li className='slider__list__item animated fadeIn'>
        <div className='slider__list__item__content'>
          <div className='view_trip_detail__body__block d-flex'>
            <div className='place'>
              <label className='place__label'>
                {t('trips.summary.origin')}
              </label>
              {order.giver.map((giver, key) => (
                <div key={key} className='place__title'>
                  {giver.name}
                </div>
              ))}
            </div>
            <i className='icon-arrow ml-16' />
            <div className='place ml-16'>
              <label className='place__label'>
                {t('trips.summary.destination')}
              </label>
              <div className='place__title'>{order.receiver.name}</div>
            </div>
            {trip.orders.length > 1 && (
              <span className='place__badge badge badge-warning badge-sm'>
                {t('trips.summary.has_multiple_destinations')}
              </span>
            )}
          </div>
          <hr />
          <div className='view_trip_detail__body__block d-flex-btw'>
            <div className='input-box'>
              <label className='input-box__label'>
                {t('trips.summary.date')}
              </label>
              <div className='input-box__container'>
                <div className='input-box__container__icon'>
                  <i className='icon icon-calendar' />
                </div>
                <div className='input-box__container__text'>
                  {moment(trip.steps[0].schedule.start).format(
                    t('date_format')
                  )}
                </div>
              </div>
            </div>
            <div className='input-box ml-16'>
              <label className='input-box__label'>
                {t('trips.summary.schedule')}
              </label>
              <div className='input-box__container'>
                <div className='input-box__container__icon'>
                  <i className='icon icon-clock' />
                </div>
                <div className='input-box__container__text'>
                  {moment(trip.steps[0].schedule.start).format('HH:mm')} HS.
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className='view_trip_detail__body__block'>
            <label className='input-box__label'>
              {t('trips.summary.driver')}
            </label>
            <div className='input-box d-flex-btw'>
              <div
                className={cn('input-box__container flex-1', {
                  'input-box__container--disabled': isEmpty(trip.shipper),
                })}
              >
                <div className='input-box__container__icon'>
                  <i className='icon icon-car' />
                </div>
                <div className='input-box__container__text'>
                  {isNil(trip.shipper)
                    ? 'Sin asignar'
                    : `${trip.shipper.first_name} ${trip.shipper.last_name}`}
                </div>
              </div>
              <div
                className={cn('input-box__icon ml-16', {
                  'input-box__icon--disabled': !order.deliveries[0].options
                    .refrigerated,
                })}
              >
                <i className='icon icon-cold' />
              </div>
            </div>
          </div>
          <div className='view_trip_detail__body__block'>
            <label className='input-box__label'>
              {t('trips.summary.driver_pay')}
            </label>
            <div className='input-box d-flex-btw'>
              <div className='input-box__container flex-1'>
                <div className='input-box__container__icon'>$</div>
                <div className='input-box__container__text'>{trip.amount}</div>
              </div>
            </div>
          </div>
          <div className='view_trip_detail__body__block '>
            <label className='input-box__label'>
              {t('trips.summary.delivery_total')}
            </label>
            <div className='input-box d-flex-btw'>
              <div className='input-box__container'>
                <div className='input-box__container__icon'>$</div>
                <div className='input-box__container__text'>
                  {order.deliveries[0].amount}
                </div>
              </div>
              <span>
                {isEmpty(order.deliveries[0].payments) && (
                  <button
                    onClick={() =>
                      openCreatePaymentCouponModal(
                        'deliveries',
                        order.deliveries[0].id,
                        trip.id
                      )
                    }
                    className='input-button btn btn-block btn-success button-coupon ml-16'
                  >
                    {t('trips.summary.create_payment')}
                  </button>
                )}

                {!isEmpty(order.deliveries[0].payments) && (
                  <div className='coupon_number'>
                    <h4 className='coupon_number__title'>
                      {order.deliveries[0].payments[0].gateway_info.id}
                    </h4>
                  </div>
                )}
              </span>
            </div>
          </div>
          <div className='payment-status-container'>
            {!isEmpty(order.deliveries[0].payments) && (
              <div className='payment-status-container__block'>
                {order.deliveries[0].payments[0].status === 'pending' && (
                  <p className='payment-status__pending'>
                    {t('trips.summary.pending_payment')}
                  </p>
                )}
                {order.deliveries[0].payments[0].status === 'failed' && (
                  <p className='payment-status__failed'>
                    {t('trips.summary.payment_error')}
                  </p>
                )}
                {order.deliveries[0].payments[0].status === 'success' && (
                  <p className='payment-status__success'>
                    {t('trips.summary.payment_success')}
                  </p>
                )}
              </div>
            )}
          </div>
          <hr />
          <div className='view_trip_detail__body__block'>
            <div className='input-box'>
              <label className='input-box__label'>
                {t('trips.summary.order')}
              </label>
              <ul className='input-box__list'>
                {order.deliveries[0].packages.map((pack, i) => (
                  <li key={i} className='input-box__list__item'>
                    <div className='input-box__list__item__title'>
                      {pack.description}
                    </div>
                    <div className='input-box__list__item__qty'>
                      {pack.weight} kg.
                    </div>
                  </li>
                ))}
              </ul>
              <div className='input-box__total mt-16'>
                <div className='input-box__total__qty'>
                  {t('trips.summary.order_total')}{' '}
                  {order.deliveries[0].packages.reduce(
                    (sum, last) => sum + last.weight,
                    0
                  )}{' '}
                  kg.
                </div>
              </div>
            </div>
          </div>
          <div className='view_trip_detail__body__block'>
            <label className='input-box__label'>
              {t('trips.summary.order_amount')}
            </label>
            <div className='input-box d-flex-btw'>
              <div className='input-box__container'>
                <div className='input-box__container__icon'>$</div>
                <div className='input-box__container__text'>{order.amount}</div>
              </div>
              <span>
                {isEmpty(order.payments) && (
                  <button
                    onClick={() =>
                      openCreatePaymentCouponModal('orders', order.id, trip.id)
                    }
                    className='input-button btn btn-block btn-success button-coupon ml-16'
                  >
                    {t('trips.summary.create_payment')}
                  </button>
                )}

                {!isEmpty(order.payments) && (
                  <div className='coupon_number'>
                    <h4 className='coupon_number__title'>
                      {order.payments[0].gateway_info.id}
                    </h4>
                  </div>
                )}
              </span>
            </div>
          </div>
          <div className='payment-status-container'>
            {!isEmpty(order.payments) && (
              <div className='payment-status-container__block'>
                {order.payments[0].status === 'pending' && (
                  <p className='payment-status__pending'>
                    {t('trips.summary.pending_payment')}
                  </p>
                )}
                {order.payments[0].status === 'rejected' && (
                  <p className='payment-status__failed'>
                    {t('trips.summary.payment_error')}
                  </p>
                )}
                {order.payments[0].status === 'approved' && (
                  <p className='payment-status__success'>
                    {t('trips.summary.success')}
                  </p>
                )}
                {order.payments[0].status === 'in_process' && (
                  <p className='payment-status__success'>
                    {t('trips.summary.payment_in_process')}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        <hr />
        <div className='view_trip_detail__body__block d-flex'>
          <div style={{ display: 'flex' }}>
            <label
              className='id__label'
              style={{ fontWeight: 'bold', marginRight: 8 }}
            >
              {t('trips.summary.trip_id')}{' '}
            </label>
            <div className='id__title'>{trip.id}</div>
          </div>
        </div>
      </li>
    </ul>
  );
};

TripSummarySlider.propTypes = {
  openCreatePaymentCouponModal: propTypes.func.isRequired,
  slide: propTypes.number.isRequired,
  trip: propTypes.object.isRequired.isRequired,
};

export default TripSummarySlider;
