import { isEmpty, isNil, pick } from 'ramda';
import spected from 'spected';

export const normalize = (list) =>
  list.reduce((res, item) => {
    res[item.id] = item;
    return res;
  }, {});

export const denormalize = (list) => Object.keys(list).map((key) => list[key]);

export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const sumField = (list, field) =>
  Object.keys(list).reduce((res, key) => {
    res = res + Number(list[key][field]);
    return res;
  }, 0);

/**
 * Add the props `value` and `label` to a list to use the list
 * with the `react-select` component
 */
export const formatForSelect = (list, label) => {
  return Object.keys(list).map((key) => {
    if (typeof list[key] === 'string') {
      return {
        value: list[key],
        label: list[key],
        disabled: 'disabled',
      };
    }

    return {
      ...list[key],
      value: list[key][label],
      label: list[key][label],
    };
  });
};

// export const parseStock = data => {
//   const newList = values(data)
//   return newList.flatMap(item => item.map(i => ({...i, value: i.id, label: i.name})))
// }

/**
 *
 * @param {} formData
 * @param {*} step
 */
export const isValid = (fields) => (formData, step) => {
  // TODO there must be a cleaner way, however i was desperate
  // The logic behind my reasoning was: there is always one checkbox checked + a checkbox cant be unchecked
  if (step === -1) {
    return true;
  } else {
    const selectedFields = fields[step].list;
    const nestedKey = fields[step].nested_key;

    if (!isNil(nestedKey)) {
      const nestedData = formData[nestedKey];
      return Object.keys(pick(fields[step].nested_values, nestedData)).every(
        (key) => !isEmpty(nestedData[key]) && !isNil(nestedData[key])
      );
    } else {
      return Object.keys(pick(selectedFields, formData)).every(
        (key) => !isEmpty(formData[key]) && !isNil(formData[key])
      );
    }
  }
};

export const validateFormData = (data, validationRules) => {
  const values = Object.values(spected(validationRules, data));
  const errors = values.filter((x) => x !== true);
  // Apparently flatMap isn't supported by Node.js
  return errors.reduce((acc, x) => acc.concat(x), []);
};
