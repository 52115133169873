import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import Button from '../../../../../components/Button';
import styles from '../css/Modal.module.css';
import i18n from '../../../../../app/configs/i18n';

export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.initialValues !== prevProps.initialValues) {
      const groups_availability = this.props.initialValues.groups_availability;
      this.setState({
        values: { ...this.state.values, groups_availability },
      });
    }
  }

  next = (values) =>
    this.setState((state) => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }));

  previous = () =>
    this.setState((state) => ({
      page: Math.max(state.page - 1, 0),
    }));

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values) => {
    const { children, onSubmit, item_checked } = this.props;

    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit({ ...values, rescued: item_checked });
    } else {
      this.next(values);
    }
  };

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    return (
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, submitting, invalid }) => (
          <form onSubmit={handleSubmit}>
            {activePage}
            <div className={styles.buttons}>
              <Button
                type='button'
                onClick={() => this.props.onCancel()}
                outline
              >
                {i18n.t('modals.update_stock.close')}
              </Button>
              {page > 0 && (
                <Button
                  type='button'
                  datacy={'btnPrev'}
                  onClick={this.previous}
                  outline
                >
                  {i18n.t('modals.update_stock.previous')}
                </Button>
              )}
              {!isLastPage && (
                <Button datacy={'btnNext'} type='submit' primary>
                  {i18n.t('modals.update_stock.next')}
                </Button>
              )}
              {isLastPage && (
                <Button
                  type='submit'
                  datacy={'btnSave'}
                  disabled={submitting || invalid}
                  primary
                >
                  {i18n.t('modals.update_stock.submit')}
                </Button>
              )}
            </div>
          </form>
        )}
      </Form>
    );
  }
}
