import React from 'react'
import UploadCsvModal from './UploadCsvModal'

class ModalContainer extends React.Component {
  render () {
    return (
      <UploadCsvModal {...this.props}/>
    )
  }
}

export default ModalContainer
