import styled from 'styled-components';

const ButtonStyled = styled.button`
  padding: 3px 17px 5px;
  background-color: #37afff;
  border-radius: 50px;
  font-family: 'Poppins';
  letter-spacing: 1.3px;
  text-align: center;
  outline: 0;
  cursor: pointer;
  border: none;
  color: #37afff;
  text-align: center;
  margin-right: 2px;
  &:focus {
    outline: 0;
  }

  ${(props) =>
    props.primary
      ? `
    color: #ffffff;
	`
      : ''};

  ${({ outline }) =>
    outline
      ? `
		background-color: #ffffff;
    border: 1px solid #37afff;
	`
      : ''}

  ${({ transparent }) =>
    transparent
      ? `
    background: none;
	`
      : ''}

	${({ disabled }) =>
    disabled
      ? `
		cursor: not-allowed;
		opacity: 0.8;
	`
      : ''}

	${({ big }) =>
    big
      ? `
		padding: 8px 20px;
    font-size: 16px;
	`
      : ''}
      
  ${({ height }) =>
    height
      ? `
     height: 30px;
    `
      : ''}    

	${({ secondary }) =>
    secondary
      ? `
		background-color: #ff4d4a;
  	color: #ffffff;
	`
      : ''}
`;

export default ButtonStyled;
