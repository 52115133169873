import i18n from '../../../app/configs/i18n';
export const NAMESPACE = 'institutions';

export const stepTitles = [
  i18n.t('institutions.titles.data'),
  i18n.t('institutions.titles.location'),
  i18n.t('institutions.titles.cantact'),
  i18n.t('institutions.titles.services'),
];

export const typeOfInstitution = [
  {
    label: i18n.t('institutions.organization'),
    value: 'Institutions::Organization',
  },
  {
    label: i18n.t('institutions.company'),
    value: 'Institutions::Company',
  },
];

export const daysOfTheWeek = [
  {
    label: i18n.t('institutions.days.monday'),
    value: 'monday',
  },
  {
    label: i18n.t('institutions.days.tuesday'),
    value: 'tuesday',
  },
  {
    label: i18n.t('institutions.days.wednesday'),
    value: 'wednesday',
  },
  {
    label: i18n.t('institutions.days.thursday'),
    value: 'thursday',
  },
  {
    label: i18n.t('institutions.days.friday'),
    value: 'friday',
  },
  {
    label: i18n.t('institutions.days.saturday'),
    value: 'saturday',
  },
  {
    label: i18n.t('institutions.days.sunday'),
    value: 'sunday',
  },
];

export const days = {
  monday: i18n.t('institutions.days.monday'),
  tuesday: i18n.t('institutions.days.tuesdy'),
  wednesday: i18n.t('institutions.days.wednesday'),
  thursday: i18n.t('institutions.days.thursday'),
  friday: i18n.t('institutions.days.friday'),
  saturday: i18n.t('institutions.days.saturday'),
  sunday: i18n.t('institutions.days.sunday'),
};

export const services = [
  {
    label: i18n.t('institutions.services.breakfast'),
    name: 'breakfast',
    value: 'breakfast',
  },
  {
    label: i18n.t('institutions.services.lunch'),
    name: 'lunch',
    value: 'lunch',
  },
  {
    label: i18n.t('institutions.services.snack'),
    name: 'snack',
    value: 'snack',
  },
  {
    label: i18n.t('institutions.services.dinner'),
    name: 'dinner',
    value: 'dinner',
  },
  {
    label: i18n.t('institutions.services.foodBag'),
    name: 'foodBag',
    value: 'foodBag',
  },
];

export const servicesValues = {
  lunch: i18n.t('institutions.services.lunch'),
  breakfast: i18n.t('institutions.services.breakfast'),
  snack: i18n.t('institutions.services.snack'),
  dinner: i18n.t('institutions.services.dinner'),
  foodBag: i18n.t('institutions.services.foodBag'),
};

export const openHours = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
};

export const paymentMethods = [
  { label: i18n.t('institutions.payment_methods.no'), value: '' },
  {
    label: i18n.t('institutions.payment_methods.rapi_pago'),
    value: 'rapipago',
  },
  {
    label: i18n.t('institutions.payment_methods.pago_facil'),
    value: 'pagofacil',
  },
  { label: i18n.t('institutions.payment_methods.other'), value: 'otro' },
];
