import React from 'react';
import propTypes from 'prop-types';
import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import ModalFooter from 'components/ModalCenter/components/ModalFooter';
import Button from 'components/ButtonWithSpinner/ButtonWithSpinnerContainer';
import ModalForm from './ModalForm';
import { useTranslation } from 'react-i18next';
import './statics/styles.css';

const CreateShipper = (props) => {
  const { t } = useTranslation();

  const { onCancel, onConfirm, modalStatus } = props;
  const Title = () => (
    <div>
      <div className='create-institution-modal__title'>
        {t('modals.create_institution_user.title')}
      </div>
    </div>
  );

  return (
    <Modal
      className='create-institution-modal'
      title={<Title />}
      onCancel={() => onCancel()}
      {...props}
    >
      <ModalBody className='modal-detail__body'>
        <ModalForm {...props} />
      </ModalBody>
      <ModalFooter>
        <Button outline datacy={'btnCanc'} onClick={onCancel}>
          {t('modals.create_institution_user.close')}
        </Button>
        <Button
          primary
          datacy={'btnSave'}
          onClick={onConfirm}
          status={modalStatus}
        >
          {t('modals.create_institution_user.submit')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CreateShipper.propTypes = {
  onCancel: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
  modalStatus: propTypes.string.isRequired,
};

export default CreateShipper;
