import './app/configs/sentry';
import './app/api/envUrls';
import './index.css';
import 'react-datetime/css/react-datetime.css';
import 'rc-time-picker/assets/index.css';
import '@progress/kendo-theme-material/dist/all.css';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';
import 'react-tippy/dist/tippy.css';
import './app/configs/i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
