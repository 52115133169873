import React from 'react';
import propTypes from 'prop-types';

import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import ModalFooter from 'components/ModalCenter/components/ModalFooter';
import Button from 'components/Button';

import styles from './UploadCsv.module.css';
import Select from 'react-select';
import FileInput from 'components/FileInput/FileInput';
import { STOCK_CREATE, STOCK_OVERWRITE, VARIANT_CREATE } from './constants';

import i18n from '../../../../app/configs/i18n';

class ImportCsv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      option: '',
    };
  }

  _onFileChange = (file) => {
    this.setState({ file });
  };

  _onOptionChange = (option) => {
    this.setState({ option: option.value });
  };

  render() {
    const Title = () => (
      <div>
        <div className={styles.modal__title}>
          {i18n.t('modals.upload_csv.title')}
        </div>
      </div>
    );

    const options = [
      {
        value: VARIANT_CREATE,
        label: i18n.t('modals.upload_csv.create_product'),
      },
      { value: STOCK_CREATE, label: i18n.t('modals.upload_csv.create_stock') },
      {
        value: STOCK_OVERWRITE,
        label: i18n.t('modals.upload_csv.replace_stock'),
      },
    ];

    const { file, option } = this.state;
    const { downloadImportTemplate } = this.props;

    return (
      <Modal
        className='modal'
        title={<Title />}
        onCancel={() => this.props.onCancel()}
        {...this.props}
      >
        <ModalBody className={styles.gridContainer}>
          <div className={`${styles.nilusText} ${styles.subtitle}`}>
            {i18n.t('modals.upload_csv.subtitle')}
          </div>
          <div className={styles.actionSelect}>
            <label>{i18n.t('modals.upload_csv.action')}</label>
            <Select
              styles={selectStyles}
              options={options}
              placeholder={i18n.t('modals.upload_csv.action_placeholder')}
              onChange={this._onOptionChange}
            />
          </div>
          <div className={styles.sheetButton}>
            <Button outline onClick={downloadImportTemplate}>
              {i18n.t('modals.upload_csv.download_template')}
            </Button>
          </div>
          <div className={styles.fileSelect}>
            <FileInput onChange={this._onFileChange.bind(this)} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button outline onClick={this.props.onCancel}>
            {i18n.t('modals.upload_csv.close')}
          </Button>
          <Button
            primary
            onClick={() => this.props.onConfirm({ file: file, option: option })}
          >
            {i18n.t('modals.upload_csv.submit')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const selectStyles = {
  option: (provided) => {
    const fontWeight = 'bold';
    const fontFamily = 'Poppins';
    return { ...provided, fontWeight, fontFamily };
  },
  singleValue: (provided) => {
    const fontWeight = 'bold';
    const fontFamily = 'Poppins';
    return { ...provided, fontWeight, fontFamily };
  },
  placeholder: (provided) => {
    const fontFamily = 'Poppins';
    return { ...provided, fontFamily };
  },
};

ImportCsv.propTypes = {
  onCancel: propTypes.func.isRequired,
};

export default ImportCsv;
