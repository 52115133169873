import * as yup from 'yup';
import i18n from 'app/configs/i18n';

export const validationSchema = () =>
  yup.object().shape({
    delivery_date: yup
      .object()
      .nullable()
      .required(i18n.t('modals.edit_order_date.validations.delivery_date')),
    delivery_time: yup
      .object()
      .nullable()
      .required(i18n.t('modals.edit_order_date.validations.delivery_time')),
  });
