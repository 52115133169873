import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from './components/Modal';

import * as actions from '../../store/actions';

import { withHooks } from 'hooks/withHooks';

import i18n from '../../../../app/configs/i18n';

class EditDateConatiner extends React.Component {
  onConfirm = (formData) => {
    const dateISO = `${formData.delivery_date.format(
      'YYYY-MM-DD'
    )}T${formData.delivery_time.format('HH:mm:ss')}-03:00`;
    const data = {
      day: dateISO,
      order_id: this.props.data.order_id,
      time: this.props.data.time,
    };
    this.props.onConfirm(data);
  };

  render() {
    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.edit_order_date.success')}
        onConfirm={this.onConfirm}
      />
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  onConfirm: (data) => dispatch({ type: props.onConfirm, payload: { data } }),
  onCancel: () => dispatch(actions.closeModal()),
});

EditDateConatiner.propTypes = {
  onConfirm: propTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(withHooks(EditDateConatiner));
