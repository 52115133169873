import React, { Component } from 'react';
import propTypes from 'prop-types';
import Gridstyles from '../css/Grid.module.css';
import styles from '../css/AddNewProduct.module.css';
import { SelectField, LabeledInputField } from './FormHelpers';
import i18n from '../../../../../app/configs/i18n';

export class AddNewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = { id: this.props.id, product: {}, app_name: '', quantity: 0 };
  }

  onChange = (name, value) => {
    let item;
    if (name.includes('product')) {
      item = 'product';
    } else if (name.includes('app_name')) {
      item = 'app_name';
    } else if (name.includes('quantity')) {
      item = 'quantity';
    }

    return item ? this.setState({ [item]: value }) : null;
  };

  componentDidUpdate(prevProps, prevState) {
    const { onAddProductChange } = this.props;
    if (prevState !== this.state) {
      onAddProductChange(this.state);
    }
  }

  removeSelectedProduct = (event) => {
    event.preventDefault();
    this.props.removeSelectedProduct(this.props.id);
  };

  render() {
    const { products, name, app_name, quantity } = this.props;

    return (
      <div className={`${styles.container} ${Gridstyles.fullRow}`}>
        <div
          className={`${Gridstyles.addProductKey} ${styles.buttonContainer}`}
        >
          <button
            onClick={this.removeSelectedProduct}
            className={`${styles.deleteButton}`}
          >
            x
          </button>
        </div>
        <div className={`${Gridstyles.addProductKey} ${styles.key}`}>{`${
          this.props.position + 1
        }.`}</div>
        <div className={Gridstyles.selectProductRow}>
          <SelectField
            options={products}
            onChange={this.onChange}
            name={name}
            value={this.state.product}
            placeholder={i18n.t('modals.create_combo.product')}
          />
        </div>
        <div className={Gridstyles.selectProductDisplayRow}>
          <LabeledInputField
            onChange={this.onChange}
            name={app_name}
            value={this.state.app_name}
            placeholder={i18n.t('modals.create_combo.appName')}
          />
        </div>
        <div className={Gridstyles.selectProductQuantityRow}>
          <LabeledInputField
            onChange={this.onChange}
            name={quantity}
            value={this.state.quantity}
            placeholder='0'
          />
        </div>
      </div>
    );
  }
}

AddNewProduct.propTypes = {
  onAddProductChange: propTypes.func.isRequired,
};

export default AddNewProduct;
