import { call, put, takeEvery } from 'redux-saga/effects';
import moment from 'moment';

import { get } from 'api-connect/dist/api';

import * as actions from './actions';
import i18n from '../../../app/configs/i18n';

export function* create(action) {
  yield put(actions.create.start());

  const { date } = action.payload;

  const createdSince = moment(date.created_since).format(i18n.t('date_format'));
  const createdUntil = moment(date.created_until).format(i18n.t('date_format'));

  const ENDPOINT = `trips/export?created_since=${createdSince}&created_until=${createdUntil}`;

  const { res, error } = yield call(get, ENDPOINT, null, 'arraybuffer');

  if (error || res.error) {
    return yield put(actions.create.failure({ error }));
  }

  const url = window.URL.createObjectURL(
    new Blob([res.data], {
      type: 'application/vnd.ms-excel; charset=utf-8; header=present',
    })
  );
  window.open(url);

  yield put(actions.create.success());
}

export function* createWatcher() {
  yield takeEvery(actions.create.type, create);
}

export default [createWatcher];
