import makeActionCreator from 'make-action-creator';

import { NAMESPACE } from '../config/constants';

export const fetchAll = makeActionCreator(NAMESPACE + '/fetchAll');
export const fetchOne = makeActionCreator(NAMESPACE + '/fetchOne');
export const update = makeActionCreator(NAMESPACE + '/update');
export const remove = makeActionCreator(NAMESPACE + '/remove');
export const create = makeActionCreator(NAMESPACE + '/create');
export const createSimple = makeActionCreator(NAMESPACE + '/createSimple'); // TODO: should remove

export const startTripBroadcast = makeActionCreator(
  NAMESPACE + '/startBroadcast'
);
export const pauseTripBroadcast = makeActionCreator(
  NAMESPACE + '/pauseBroadcast'
);

export const cleanState = makeActionCreator(NAMESPACE + '/cleanState');

export const fetchTrips = makeActionCreator(NAMESPACE + '/fetchTrips');
export const cancelFetchTrips = makeActionCreator(
  NAMESPACE + '/cancelFetchTrips'
);
export const cancelFetchAll = makeActionCreator(NAMESPACE + '/cancelFetchAll');
