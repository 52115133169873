import { handleActions } from 'redux-actions';
import { omit } from 'ramda';

import * as actions from './actions';
import * as permissionsActions from 'modules/permissions/store/actions';

const initialState = {
  status: 'init',
  error: {},
  items: {},
  districts: [],
  payments: [],
  categories: [],
  infoActiveNetwork: {},
  infoClient: {},
  statusExcel: false,
};

const reducer = handleActions(
  {
    [actions.uploadExcelInstitution.START]: (state) => ({
      ...state,
      statusExcel: 'pending',
    }),
    [actions.uploadExcelInstitution.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
      statusExcel: 'success',
    }),
    [actions.uploadExcelInstitution.FAILURE]: (state) => ({
      ...state,
      statusExcel: 'failure',
    }),

    [actions.fetchInfoClient.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.fetchInfoClient.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      infoClient: {
        ...action.payload.infoClient,
      },
    }),
    [actions.fetchInfoClient.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),
    [actions.fetchAll.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchAll.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchAll.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: action.payload.items,
    }),

    [actions.fetchOne.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchOne.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchOne.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        [action.payload.item.id]: action.payload.item,
      },
    }),

    [actions.fetchOneOrder.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchOneOrder.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchOneOrder.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        [action.payload.id]: {
          ...state.items[action.payload.id],
          orders: action.payload.orders,
        },
      },
    }),

    [actions.create.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.create.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.create.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        ...action.payload.institution,
      },
    }),
    [actions.update.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.update.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.update.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        [action.payload.institution.id]: action.payload.institution,
      },
    }),
    [actions.oneClickUpdateDeliveryAddress.SUCCESS]: (state, action) => ({
      ...state,
      items: {
        ...state.items,
        [action.payload.id]: {
          ...state.items[action.payload.id],
          addresses: [
            ...state.items[action.payload.id].addresses,
            {
              ...state.items[action.payload.id].addresses[0],
              latlng: action.payload.latlng,
            },
          ],
        },
      },
    }),
    [actions.removeUser.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.removeUser.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.removeUser.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: omit([action.payload.id], state.items),
    }),

    [actions.fetchAllCoupons.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchAllCoupons.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchAllCoupons.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      payments: action.payload.payments,
    }),

    [actions.editCoupon.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.editCoupon.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.editCoupon.SUCCESS]: (state, action) => {
      return {
        ...state,
        status: 'success',
        payments: {
          ...state.payments,
          current_payments: {
            ...state.payments.current_payments,
            [action.payload.id]: {
              ...action.payload.payment,
            },
          },
        },
        // payments: state.payments.reduce((acc, item) => {
        // 	item.id === action.payload.id
        // 		? acc.push(action.payload.payment)
        // 		: acc.push(item);
        // 	return acc;
        // }, []),
      };
    },

    [actions.fetchDistricts.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchDistricts.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchDistricts.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      districts: action.payload.districts,
    }),

    [actions.fetchCategories.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchCategories.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchCategories.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      categories: action.payload.categories,
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
    [actions.fetchNetworks.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.fetchNetworks.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      infoActiveNetwork: { ...action.payload.infoActiveNetwork },
    }),
    [actions.fetchNetworks.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),
    [actions.cleanModule.type]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
