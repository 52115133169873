import makeActionCreator from 'make-action-creator';

import { NAMESPACE } from '../config/constants';

export const fetchAllDistricts = makeActionCreator(
  NAMESPACE + '/fetchAllDistricts'
);

export const addDistricts = makeActionCreator(NAMESPACE + '/addDistricts');

export const updateDistricts = makeActionCreator(NAMESPACE + 'updateDistricts');

export const deleteDistricts = makeActionCreator(NAMESPACE + 'deleteDistricts');
