import React from 'react';
import propTypes from 'prop-types';
import '../css/styles.css';
import styles from '../css/StockCategoryLimits.module.css';
import { InputField, UntouchedFieldError } from './FormHelpers';
import { useTranslation } from 'react-i18next';

const StockCategoryLimits = (props) => {
  const { groups_availability } = props;

  return (
    <>
      <div className={styles.errorMessage}>
        <UntouchedFieldError name={'groups_availability.groups'} />
      </div>
      <div className={styles.container}>
        <Headers />
        {groups_availability.map((item, i) => (
          <CategoryLimits key={i} item={item} />
        ))}
      </div>
    </>
  );
};

const CategoryLimits = ({ item }) => {
  return (
    <>
      <label className={styles.categoryName}>{item.name}</label>
      <InputField
        placeholder='1'
        type='number'
        name={`groups_availability.${item.code}.min`}
        min={0}
      />
      <InputField
        name={`groups_availability.${item.code}.max`}
        type='number'
        placeholder='10'
        min={0}
      />
    </>
  );
};

const Headers = () => {
  const { t } = useTranslation();

  return (
    <>
      <label className='categories__label'>
        {t('modals.create_stock.category')}
      </label>
      <label className='categories__label'>
        {t('modals.create_stock.min')}
      </label>
      <label className='categories__label'>
        {t('modals.create_stock.max')}
      </label>
    </>
  );
};
StockCategoryLimits.propTypes = {
  groups_availability: propTypes.arrayOf([]).isRequired,
  onChange: propTypes.func.isRequired,
  value: propTypes.oneOfType([
    propTypes.string.isRequired,
    propTypes.number.isRequired,
    propTypes.shape({}).isRequired,
    propTypes.arrayOf([]).isRequired,
  ]).isRequired,
};

export default StockCategoryLimits;
