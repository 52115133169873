import { connect } from 'react-redux';

import Modal from './components/CreatePaymentContainer';

import { closeModal } from '../../store/actions';
import * as paymentSelectors from '../../../payments/store/selectors';
import { fetchPayee, resetPayee } from '../../../payments/store/actions';

const mapStateToProps = (state) => ({
  isLoading: paymentSelectors.isLoading(state),
  currentPayee: paymentSelectors.currentPayee(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onConfirm: (body) => dispatch({ type: props.onConfirm, payload: body }),
  onCancel: () => {
    dispatch(closeModal());
    dispatch(resetPayee());
  },
  fetchPayee: (payload) => dispatch(fetchPayee(payload)),
  resetPayee: () => dispatch(resetPayee()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
