import axios from 'axios';

export default class ConnectedApiService {
  constructor(baseUrl, envName) {
    if (process.env.NODE_ENV === 'development') {
      console.log(`Conected to ${envName} Api using: ${baseUrl}`);
    }
    this.token = localStorage.getItem('token');
    this.activeNetwork = localStorage.getItem('active_network');
    this.baseUrl = baseUrl;
    this.api = this.createConstructorAxios();
  }

  createConstructorAxios = () => {
    const api = axios.create({
      baseURL: `${this.baseUrl}/`,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    api.interceptors.request.use((config) => {
      const newConfig = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Token ${this.token}`,
          'X-Network-Id': `${this.activeNetwork}`,
        },
      };
      return newConfig;
    });

    return api;
  };
}
