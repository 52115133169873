import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './statics/styles.css';

const DatePicker = (props) => {
  const { date, onChangeDate } = props;
  const { t } = useTranslation();

  return (
    <div className='date-picker__container'>
      <div
        className='date-picker__background date-picker__btn date-picker__left-arrow'
        onClick={() => onChangeDate('substract')}
      >
        <img alt='go back arrow' src={require('./statics/arrow_left.svg')} />
      </div>
      <div className='date-picker__background date-picker__data'>
        {moment(date).format(t('date_format'))}
      </div>
      <div
        className='date-picker__background date-picker__btn date-picker__right-arrow'
        onClick={() => onChangeDate('add')}
      >
        <img
          alt='go forward arrow'
          src={require('./statics/arrow_right.svg')}
        />
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  date: propTypes.shape({}).isRequired,
  onChangeDate: propTypes.func.isRequired,
};
export default DatePicker;
