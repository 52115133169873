import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import ModalFooter from 'components/ModalCenter/components/ModalFooter';
import Button from 'components/ButtonWithSpinner/ButtonWithSpinnerContainer';
import { isNil } from 'ramda';
import './statics/styles.css';
import { useTranslation } from 'react-i18next';

const EditInstitutionUser = (props) => {
  const { onCancel, onConfirm, formData, onChange, status, payment } = props;
  const { t } = useTranslation();

  const Title = () => (
    <div>
      <div className='edit-comment-modal__subtitle'>
        {t('modals.edit_cupon_comment.cupon')}{' '}
        {!isNil(payment.id) ? payment.id.slice(0, 8) : '-'}{' '}
      </div>
      <div className='edit-comment-modal__title'>
        {t('modals.edit_cupon_comment.comment')}
      </div>
    </div>
  );

  return (
    <Modal
      className='edit-comment-modal'
      title={<Title />}
      onCancel={() => onCancel()}
      {...props}
    >
      <ModalBody className='modal-detail__body'>
        <div className='edit-comment_detail__body'>
          <div>
            <textarea
              onChange={onChange}
              value={formData.comment}
              className='form-control edit-comment_detail__body__textarea'
              name='comment'
              placeholder={t('modals.edit_cupon_comment.comment_placeholder')}
              rows='6'
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={onCancel}>
          {t('modals.edit_cupon_comment.close')}
        </Button>
        <Button primary status={status} onClick={onConfirm}>
          {t('modals.edit_cupon_comment.submit')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

EditInstitutionUser.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}).isRequired,
  payment: PropTypes.shape({}).isRequired,
  status: PropTypes.string.isRequired,
};

export default EditInstitutionUser;
