import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ModalForm = (props) => {
  const { formData, onChange } = props;
  const { t } = useTranslation();

  return (
    <div className='view_trip_detail__body'>
      <div className='row'>
        <div className='form-group col-md-12'>
          <div className='row'>
            <div className='col-lg-6'>
              <label>{t('modals.create_driver.name')}</label>
              <input
                onChange={onChange}
                value={formData.first_name}
                type='text'
                className='form-control'
                name='first_name'
                placeholder={t('modals.create_driver.name_placeholder')}
              />
            </div>
            <div className='col-lg-6'>
              <label>{t('modals.create_driver.surname')}</label>
              <input
                onChange={onChange}
                value={formData.last_name}
                type='text'
                className='form-control'
                name='last_name'
                placeholder={t('modals.create_driver.surname_placeholder')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='form-group col-md-12'>
          <label>{t('modals.create_driver.email')}</label>
          <input
            onChange={onChange}
            value={formData.email}
            type='email'
            className='form-control'
            name='email'
            placeholder={t('modals.create_driver.email_placeholder')}
          />
        </div>
        <div className='form-group col-md-12'>
          <label>{t('modals.create_driver.password')}</label>
          <input
            onChange={onChange}
            value={formData.password}
            type='text'
            className='form-control'
            name='password'
            placeholder={t('modals.create_driver.password_placeholder')}
          />
        </div>
      </div>
    </div>
  );
};

ModalForm.propTypes = {
  onChange: propTypes.func.isRequired,
  formData: propTypes.shape({}).isRequired,
};

export default ModalForm;
