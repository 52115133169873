import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import es from './langs/es';
import en from './langs/en';

const isDevEnv =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false,
      fallbackLng: 'es',
      debug: isDevEnv,
    },
    resources: {
      en,
      es,
    },
  });

export default i18next;
