import i18n from '../../../../app/configs/i18n';

export const paymentMethods = [
  { label: i18n.t('modals.create_cupon.pago_facil'), value: 'pagofacil' },
  { label: i18n.t('modals.create_cupon.rapi_pago'), value: 'rapipago' },
  { label: i18n.t('modals.create_cupon.other'), value: 'otro' },
];

export const reasons = [
  {
    label: i18n.t('modals.create_cupon.food_reason'),
    value: {
      reason: 'Contribución por entrega de alimentos',
      activity: 'Order',
    },
  },
  {
    label: i18n.t('modals.create_cupon.trip_reason'),
    value: {
      reason: 'Contribución por servicio de flete',
      activity: 'Delivery',
    },
  },
];
