import moment from 'moment';

export const buildDates = (isFuture) => {
  let today = moment();

  if (typeof isFuture === 'undefined') {
    return undefined;
  }

  if (isFuture) {
    today.subtract(1, 'days');
    return (current) => current.isAfter(today);
  }

  return (current) => current.isBefore(today);
};
