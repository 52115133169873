import { NAMESPACE } from '../config/constants';
import { createSelector } from 'reselect';
import { denormalize } from '../../../utils';

export const notifications = (state) => state[NAMESPACE].notifications;
export const selected_id = (state) => state[NAMESPACE].selected_id;
export const audienceType = (state) => state[NAMESPACE].audienceType;
export const audiences = (state) => state[NAMESPACE].audiences;
export const receivers = (state) => state[NAMESPACE].receivers;

export const selected = createSelector(
  notifications,
  selected_id,
  (notifications, selected_id) => notifications[selected_id]
);

export const currentAudience = createSelector(
  receivers,
  audienceType,
  (receivers, audienceType) =>
    receivers[audienceType] ? receivers[audienceType] : []
);

export const selectableAudiences = createSelector(audiences, (audiences) =>
  audiences.length
    ? audiences.map((x) => ({ label: x.name, value: x.handler }))
    : []
);

export const notificationList = createSelector(notifications, (notifications) =>
  denormalize(notifications)
);

export const institutionNotifications = createSelector(
  notificationList,
  (notifications) =>
    notifications.filter((x) => x.receiver_type === 'Institution')
);
export const shipperNotifications = createSelector(
  notificationList,
  (notifications) => notifications.filter((x) => x.receiver_type === 'Shipper')
);
