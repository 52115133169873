import { lazy } from 'react';
import reducer from './store/reducers';
import sagas from './store/sagas';
import * as constants from './config/constants';
import * as actions from './store/actions';

export default {
  constants,
  actions,
  reducer,
  sagas: [...sagas],
  scenes: {
    list: lazy(() => import('./scenes/List')),
  },
};
