import { lazy } from 'react';
import reducer from './store/reducers';
import sagas from './store/sagas';
import * as selectors from './store/selectors';
import * as constants from './configs/constants';
import * as actions from './store/actions';

export default {
  selectors,
  constants,
  actions,
  reducer,
  sagas: [...sagas],
  scenes: {
    list: lazy(() => import('./scenes/List')),
    detail: lazy(() => import('./scenes/Detail')),
  },
};
