import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as permissionsActions from 'modules/permissions/store/actions';

const initialState = {
  status: 'init',
  error: '',
  items: {},
  categories: [],
  addresses: [],
  stock: {},
};

const reducer = handleActions(
  {
    [actions.fetchAll.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchAll.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        ...action.payload.items,
      },
    }),

    [actions.fetchAll.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchVariants.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchVariants.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      items: {
        ...state.items,
        ...action.payload.items,
      },
    }),

    [actions.fetchVariants.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchAddresses.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchAddresses.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      addresses: [...action.payload.addresses],
    }),

    [actions.fetchAddresses.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchCategories.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchCategories.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      categories: [...action.payload.categories],
    }),

    [actions.fetchCategories.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),
    [actions.fetchParentCategories.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchParentCategories.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      categories: [...action.payload.parent_categories],
    }),

    [actions.fetchParentCategories.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchOfferStock.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.fetchOfferStock.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.fetchOfferStock.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      stock: {
        ...action.payload.stock,
      },
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
