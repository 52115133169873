import { ErrorMessage, Field } from 'formik';
import errorStyles from '../css/Error.module.css';
import propTypes from 'prop-types';
import React from 'react';
import { Select, CreatableSelect } from '../../../../../components/Select';
import { Checkbox } from '../../../../../components/Checkbox';
import { LabeledInput } from '../../../../../components/Input';

export const LabeledInputField = ({ name, handleInputChange, ...rest }) => (
  <Field name={name}>
    {({ field, form }) => (
      <div>
        <LabeledInput
          {...field}
          {...rest}
          onChange={(option) => {
            if (handleInputChange) {
              handleInputChange({
                target: {
                  name,
                  value: option.target.value ? option.target.value : '',
                },
              });
            } else {
              const value = option.target.value ? option.target.value : '';
              form.setFieldValue(name, value);
              if (name === 'units_multiplier' && value === '1') {
                form.setFieldValue('single_unit_measure_name', null);
              }
            }
          }}
        />
        <ErrorMessage
          name={name}
          component='span'
          className={errorStyles.error}
        />
      </div>
    )}
  </Field>
);

export const CreatableSelectField = ({
  name,
  is_bulk,
  units_multiplier,
  ...rest
}) => (
  <Field name={name}>
    {({ field, form }) => (
      <div>
        <CreatableSelect
          {...field}
          {...rest}
          onChange={(option) => {
            const value = option.target.value ? option.target.value : '';
            form.setFieldValue(name, value);
          }}
          onBlur={() => form.setFieldTouched(name, true)}
        />
        {name === 'unit_type' && !is_bulk && (
          <ErrorMessage
            name={name}
            component='span'
            className={errorStyles.error}
          />
        )}
      </div>
    )}
  </Field>
);

export const SelectField = ({ name, onChange, ...rest }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <div>
          <Select
            {...field}
            {...rest}
            onChange={(option) => {
              const value = option.target.value ? option.target.value : '';
              form.setFieldValue(name, value);
              if (onChange) onChange(value ? value : '');
            }}
            onBlur={() => form.setFieldTouched(name, true)}
          />
          <ErrorMessage
            name={name}
            component='span'
            className={errorStyles.error}
          />
        </div>
      )}
    </Field>
  );
};

export const CheckboxField = ({ name, handleInputChange, ...rest }) => (
  <Field name={name} type={'checkbox'}>
    {({ field, form }) => (
      <Checkbox
        {...field}
        {...rest}
        onChange={(checked) => {
          const value = checked ? checked : false;
          form.setFieldValue(name, value);
          if (name === 'is_bulk' && value === true) {
            form.setFieldValue('unit_type', null);
            form.setFieldValue('single_unit_measure_name', null);
            form.setFieldValue('units_multiplier', '1');
            handleInputChange(null);
          }
        }}
      />
    )}
  </Field>
);

LabeledInputField.propTypes = {
  name: propTypes.string.isRequired,
  handleInputChange: propTypes.func.isRequired,
};

CreatableSelectField.propTypes = {
  name: propTypes.string.isRequired,
  is_bulk: propTypes.string.isRequired,
  units_multiplier: propTypes.string.isRequired,
};

SelectField.propTypes = {
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

CheckboxField.propTypes = {
  name: propTypes.string.isRequired,
  handleInputChange: propTypes.func.isRequired,
};
