import makeActionCreator from 'make-action-creator';
import { NAMESPACE } from '../config/constants';

export const fetchAll = makeActionCreator(`${NAMESPACE}/FETCH_ALL`);
export const fetchOfferableStock = makeActionCreator(
  `${NAMESPACE}/FETCH_OFFERABLE_STOCK`
);
export const fetchOne = makeActionCreator(`${NAMESPACE}/FETCH_ONE`);
export const update = makeActionCreator(`${NAMESPACE}/UPDATE`);
export const remove = makeActionCreator(`${NAMESPACE}/REMOVE`);
export const create = makeActionCreator(`${NAMESPACE}/CREATE`);
export const editStock = makeActionCreator(`${NAMESPACE}/EDIT_STOCK`);
export const updateStock = makeActionCreator(`${NAMESPACE}/UPDATE_STOCK`);
export const searchStock = makeActionCreator(`${NAMESPACE}/SEARCH_STOCK`);
export const createStock = makeActionCreator(`${NAMESPACE}/CREATE_STOCK`);
export const reviseVariant = makeActionCreator(`${NAMESPACE}/REVISE_VARIANT`);
export const editVariant = makeActionCreator(`${NAMESPACE}/EDIT_VARIANT`);
export const createVariant = makeActionCreator(`${NAMESPACE}/CREATE_VARIANT`);
export const pageChange = makeActionCreator(`${NAMESPACE}/PAGE_CHANGE`);
export const createCombo = makeActionCreator(`${NAMESPACE}/CREATE_COMBO`);
export const editCombo = makeActionCreator(`${NAMESPACE}/EDIT_COMBO`);
export const getAllCombos = makeActionCreator(`${NAMESPACE}/GET_ALL_COMBOS`);
export const fetchAvailableStock = makeActionCreator(
  `${NAMESPACE}/FETCH_AVAILABLE_STOCK`
);
export const fetchOffers = makeActionCreator(`${NAMESPACE}/FETCH_OFFERS`);
export const fetchOffer = makeActionCreator(`${NAMESPACE}/FETCH_OFFER`);
export const clearOffer = makeActionCreator(`${NAMESPACE}/CLEAR_OFFER`);
export const fetchProducts = makeActionCreator(`${NAMESPACE}/FETCH_PRODUCTS`);
export const clearProducts = makeActionCreator(`${NAMESPACE}/CLEAR_PRODUCTS`);

export const createOffer = makeActionCreator(`${NAMESPACE}/CREATE_OFFER`);
export const cancelOffer = makeActionCreator(`${NAMESPACE}/CANCEL_OFFER`);
export const uploadCsv = makeActionCreator(`${NAMESPACE}/UPLOAD_CSV`);

export const csvErrors = makeActionCreator(`${NAMESPACE}/CSV_ERRORS`);
export const productCsvDownload = makeActionCreator(
  `${NAMESPACE}/PRODUCT_CSV_DOWNLOAD`
);

export const stockCsvDownload = makeActionCreator(
  `${NAMESPACE}/STOCK_CSV_DOWNLOAD`
);
export const importTemplateDownload = makeActionCreator(
  `${NAMESPACE}/importTemplateDownload`
);

export const setStockOrigin = makeActionCreator(
  `${NAMESPACE}/SET_STOCK_ORIGIN`
);

export const archiveStock = makeActionCreator(`${NAMESPACE}/ARCHIVE_STOCK`);
