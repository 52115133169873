import React from 'react';
import propTypes from 'prop-types';
import styles from '../css/StockInformation.module.css';
import { SelectField, DatePickerField, LabeledInputField } from './FormHelpers';
import { useTranslation } from 'react-i18next';
import { ContainerRow, ContainerInputs, Space, WholeRow } from '../css/styled';
import { onlyCompanies } from '../../../../../utils/filter_institutions';
import Chexbox from '../../../../../components/Checkbox/Checkbox';
import { CheckLabel, CheckSpace } from '../css/styled';

const StockInformation = ({
  variants,
  onSelectedProductchange,
  institutions,
  handleCheckbox,
  item_checked,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <WholeRow>
        <SelectField
          onSelectedProductchange={onSelectedProductchange}
          name='variant'
          label={t('modals.create_stock.name')}
          placeholder={t('modals.create_stock.name_placeholder')}
          options={variants}
          required
        />
      </WholeRow>
      <ContainerInputs>
        <ContainerRow>
          <Space>
            <DatePickerField
              input='input'
              name='expires_on'
              className='value'
              label={t('modals.create_stock.expiration')}
              required
              isFuture
            />
          </Space>
          <Space>
            <SelectField
              label={t('modals.create_stock.deposit')}
              placeholder={t('modals.create_stock.expiration_placeholder')}
              required
              name='address'
              options={institutions.filter(onlyCompanies)}
            />
          </Space>
        </ContainerRow>
        <ContainerRow>
          <Space>
            <LabeledInputField
              input='input'
              label={t('modals.create_stock.lote')}
              placeholder={t('modals.create_stock.price_placeholder')}
              type='number'
              name='cost'
              required
              min={0}
              step={0.01}
            />
          </Space>
          <Space>
            <LabeledInputField
              input='input'
              label={t('modals.create_stock.stock')}
              placeholder={t('modals.create_stock.stock_placeholder')}
              type='number'
              name='quantity'
              required
              min={0}
            />
          </Space>
        </ContainerRow>
      </ContainerInputs>
      <hr className={styles.wholeRow} />
      <Space>
        <LabeledInputField
          input='input'
          label={t('modals.create_stock.price')}
          placeholder={t('modals.create_stock.price_placeholder')}
          type='number'
          step={0.01}
          min={0}
          name='item_price'
          required
        />
      </Space>
      <CheckSpace>
        <CheckLabel>{t('modals.create_stock.rescued')}</CheckLabel>
        <Chexbox onChange={handleCheckbox} checked={item_checked} />
      </CheckSpace>
    </>
  );
};

StockInformation.propTypes = {
  variants: propTypes.arrayOf([]).isRequired,
  onSelectedProductchange: propTypes.func.isRequired,
  institutions: propTypes.shape({}).isRequired,
  handleCheckbox: propTypes.func.isRequired,
  item_checked: propTypes.bool.isRequired,
};

export default StockInformation;
