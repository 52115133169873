import React from 'react';
import propTypes from 'prop-types';
import Switch from 'react-switch';

const SwitchInput = (props) => {
  const { onChange, checked, className, type, disabled } = props;

  return type === 'bigSwitch' ? (
    <Switch
      className={className}
      onChange={onChange}
      checked={checked}
      onColor='#57c157'
      disabled={disabled}
    />
  ) : (
    <Switch
      className={className}
      onChange={onChange}
      checked={checked}
      onColor='#57c157'
      offColor='#ff4d4a'
      height={15}
      width={30}
      handleDiameter={8}
      uncheckedIcon={false}
      checkedIcon={false}
      disabled={disabled}
    />
  );
};

SwitchInput.propTypes = {
  type: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  checked: propTypes.bool.isRequired,
  className: propTypes.string.isRequired,
  disabled: propTypes.bool.isRequired,
};

export default SwitchInput;
