import makeActionCreator from 'make-action-creator';

import { NAMESPACE } from '../configs/constants';

export const fetchInfoClient = makeActionCreator(
  NAMESPACE + '/FETCH_INFO_CLIENT'
);
export const fetchAll = makeActionCreator(NAMESPACE + '/fetchAll');
export const fetchOne = makeActionCreator(NAMESPACE + '/fetchOne');
export const fetchOneOrder = makeActionCreator(NAMESPACE + '/fetchOneOrder');
export const update = makeActionCreator(NAMESPACE + '/update');
export const remove = makeActionCreator(NAMESPACE + '/remove');
export const create = makeActionCreator(NAMESPACE + '/create');

export const cleanModule = makeActionCreator(NAMESPACE + '/cleanModule');

export const fetchNetworks = makeActionCreator(NAMESPACE + '/fetchNetworks');

export const createAddress = makeActionCreator(NAMESPACE + '/createAddress');
export const updateAddress = makeActionCreator(NAMESPACE + '/updateAddress');
export const updateCoupon = makeActionCreator(NAMESPACE + '/updateCoupon');

export const oneClickUpdateDeliveryAddress = makeActionCreator(
  NAMESPACE + '/updateDeliveryAddress'
);

export const userUpdateResidenceAddress = makeActionCreator(
  NAMESPACE + '/userUpdateResidenceAddress'
);

export const updateRecurrent = makeActionCreator(
  NAMESPACE + '/updateRecurrent'
);

export const createResidenceAddress = makeActionCreator(
  NAMESPACE + '/createResidenceAddress'
);
export const updateResidenceAddress = makeActionCreator(
  NAMESPACE + '/updateResidenceAddress'
);

export const createUser = makeActionCreator(NAMESPACE + '/createUser');
export const removeUser = makeActionCreator(NAMESPACE + '/removeUser');
export const updateUser = makeActionCreator(NAMESPACE + '/updateUser');

export const fetchDistricts = makeActionCreator(NAMESPACE + '/fetchDistricts');
export const fetchCategories = makeActionCreator(
  NAMESPACE + '/fetchCategories'
);
export const fetchAllCoupons = makeActionCreator(
  NAMESPACE + '/fetchAllCoupons'
);
export const editCoupon = makeActionCreator(NAMESPACE + '/editCoupon');

export const uploadExcelInstitution = makeActionCreator(
  NAMESPACE + '/uploadExcelInstitution'
);
