import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment'
// import { omit } from 'ramda'

import Modal from './Modal';

// import { isValid } from '../helpers'
// import { buildScheduledAt } from 'utils/date'

import i18n from '../../../../../app/configs/i18n';

class ModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };
  }

  componentDidMount() {
    const { email, profile } = this.props.data.user;
    const { first_name, last_name, cellphone } = profile;
    // const user = users.find((user) => user.id == user_id)
    // const { email } = user
    this.setState({
      formData: {
        first_name,
        last_name,
        email,
        cellphone,
      },
    });
  }

  onChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onConfirm = () => {
    const { institution, user } = this.props.data;

    const userData = {
      id: user.id,
      ...this.state.formData,
    };

    this.props.onConfirm(userData, institution);
  };

  render() {
    const { formData } = this.state;

    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.edit_institution_user.success')}
        formData={formData}
        onChange={this.onChange}
        onConfirm={this.onConfirm}
      />
    );
  }
}

ModalContainer.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default ModalContainer;
