import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'modules/modals/components/index';
import Alert from 'modules/alerts/Alert';
import { connect } from 'react-redux';
import { withHooks } from 'hooks/withHooks';
import * as modalSelectors from 'modules/modals/store/selectors';
import * as permissionsSelectors from 'modules/permissions/store/selectors';
import * as permissionsActions from 'modules/permissions/store/actions';
import Navbar from 'components/Navbar';

const MainLayout = ({
  modal,
  modules,
  user,
  loadUser,
  onChangeNetwork,
  activeNetwork,
  children,
}) => {
  return (
    <>
      <Navbar
        routes={modules}
        user={user}
        loadUser={loadUser}
        onChangeNetwork={onChangeNetwork}
        activeNetwork={activeNetwork}
      />
      {children}
      <Modal show={modal} />
      <Alert />
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.shape({}),
  loadUser: PropTypes.func.isRequired,
  modules: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.string),
    pathname: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
  modal: PropTypes.string.isRequired,
  onChangeNetwork: PropTypes.func.isRequired,
  activeNetwork: PropTypes.string.isRequired,
};

MainLayout.defaultProps = {
  user: {},
  modules: {},
};

const mapStateToProps = (state) => ({
  modal: modalSelectors.type(state),
  modules: permissionsSelectors.modules(state),
  userStatus: permissionsActions.fetchOne.getStatus(state),
  activeNetwork: localStorage.getItem('active_network'),
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: () => dispatch(permissionsActions.fetchOne()),
  onChangeNetwork: (network) => {
    dispatch({ type: permissionsActions.cleanModules });
    localStorage.setItem('active_network', network);
    window.location.reload();
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(MainLayout));
