import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { mountedLogs } from 'utils/mountedLogs';
import rootReducer from './reducers';
import rootSaga from './sagas';

export default function configure() {
  const sagaMiddleware = createSagaMiddleware();

  const reduxMiddleware = mountedLogs([sagaMiddleware], logger);
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...reduxMiddleware))
  );

  sagaMiddleware.run(rootSaga).done.catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e);
    // store.dispatch({ type: permissionsActions.cleanModules });
  });

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(require('./reducers'));
    });
  }

  return store;
}
