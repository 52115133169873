import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from './components/Modal';
import * as modalActions from '../../store/actions';
import * as bankAccountsSelectors from 'modules/bankAccounts/store/selectors';
import i18n from '../../../../app/configs/i18n';

class UpdateBankAccountContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { bankAccount } = this.props;

    this.setState({
      formData: {
        ...bankAccount,
      },
    });
  }

  onChange(e) {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  }

  render() {
    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.edit_bank_account.success')}
        formData={this.state.formData}
        onChange={this.onChange}
      />
    );
  }
}

UpdateBankAccountContainer.propTypes = {
  bankAccount: propTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  bankAccount: bankAccountsSelectors.getBankAccount(state),
  status: bankAccountsSelectors.status(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onConfirm: (payload) => dispatch({ type: props.onConfirm, payload }),
  onCancel: () => {
    dispatch({ type: props.onCancel });
    dispatch(modalActions.closeModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateBankAccountContainer);
