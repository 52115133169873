import { connect } from 'react-redux';

import { withHooks } from 'hooks/withHooks';

import * as modalActions from '../../store/actions';
import * as selectHelpersActions from 'modules/selectHelpers/store/actions';
import * as stockActions from 'modules/stock/store/actions';
import * as stockSelectors from 'modules/stock/store/selectors';

import * as selectHelpersSelectors from 'modules/selectHelpers/store/selectors';

import CreateVariantContainer from './components/CreateVariantContainer';

const mapStateToProps = (state) => ({
  products: selectHelpersSelectors.getItemsList(state)('products'),
  parent_categories: selectHelpersSelectors.parseParentCategories(state)(
    'categories'
  ),
  brands: selectHelpersSelectors.getItemsList(state)('brands'),
  unitTypes: selectHelpersSelectors.getItemsList(state)('unit_types'),
  measureNames: selectHelpersSelectors.getItemsList(state)('measure_names'),
  revisedStatus: stockActions.reviseVariant.getStatus(state),
  confirmStatus: stockSelectors.status(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onMount: () => {
    dispatch(
      selectHelpersActions.fetchAll({
        types: [
          'products',
          'parent_categories',
          'brands',
          'variant/unit_types',
          'variant/measure_names',
        ],
      })
    );
  },
  onConfirm: (body) =>
    dispatch({
      type: props.onConfirm,
      payload: { body, variant: props.data.variant, type: props.data.type },
    }),
  onCancel: () => {
    dispatch({ type: props.onCancel });
    dispatch(modalActions.closeModal());
  },
  onUnmount: () => dispatch(stockActions.reviseVariant.clearStatus),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(CreateVariantContainer));
