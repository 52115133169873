import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as permissionsActions from '../../permissions/store/actions';

const initialState = {
  status: 'init',
  error: '',
  countries: [],
};

const reducer = handleActions(
  {
    [actions.fetchAllCountries.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.fetchAllCountries.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      countries: action.payload.countries,
    }),
    [actions.fetchAllCountries.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.addCountry.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.addCountry.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),
    [actions.addCountry.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.updateCountry.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.updateCountry.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      countries: {
        ...state.countries,
        [action.payload.countries.id]: action.payload.countries,
      },
    }),
    [actions.updateCountry.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.deleteCountry.START]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [actions.deleteCountry.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),
    [actions.deleteCountry.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
