import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as permissionsActions from 'modules/permissions/store/actions';

const initialState = {
  status: 'init',
  error: '',
};

const reducer = handleActions(
  {
    [actions.create.START]: (state) => ({
      ...state,
      status: 'pending',
    }),

    [actions.create.FAILURE]: (state, action) => ({
      ...state,
      status: 'failure',
      error: action.payload.error,
    }),

    [actions.create.SUCCESS]: (state) => ({
      ...state,
      status: 'success',
    }),

    [permissionsActions.cleanModules]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
