import envUrls from './envUrls';
import ConstructorApi from './ConstructorApi';
import { AddingColectorErrors } from './utils';

class UserApi extends ConstructorApi {
  login = async ({ email, password }) => {
    return await this.api.post('authenticate', { email, password });
  };

  createNotification = async (notification) => {
    return await this.api.post('notification', notification);
  };

  fetchNotifications = async () => {
    return await this.api.get('notification');
  };

  fetchAudiences = async () => {
    return await this.api.get('notification/handlers');
  };

  fetchReceivers = async (audienceType) => {
    return await this.api.get(`/notification/receivers?type=${audienceType}`);
  };

  removeUser = async (institution_id, user_id) => {
    return await this.api.delete(
      `institutions/${institution_id}/users/${user_id}`
    );
  };

  fetchInstitutions = async () => {
    return await this.api.get('institutions');
  };

  fetchInstitution = async (institutionId) => {
    return await this.api.get(`institutions/${institutionId}`);
  };

  fetchDistricts = async () => {
    return await this.api.get('v1/districts');
  };

  fetchNetworks = async () => {
    return await this.api.get('networks');
  };

  fetchBusinessRawData = async () => {
    return await this.api.get('institutions/business_raw_data');
  };

  createInstitution = async (newInstitution) => {
    return await this.api.post('institutions', newInstitution);
  };

  createAddressInstitution = async (institutionId, newAddress) => {
    return await this.api.post(
      `v1/institutions/${institutionId}/addresses`,
      newAddress
    );
  };

  createResidenceAddress = async (institutionId, newResidenceAddress) => {
    return await this.api.post(
      `v1/institutions/${institutionId}/residence`,
      newResidenceAddress
    );
  };

  updateResidenceAddress = async (institutionId, addressId, institution) => {
    return await this.api.put(
      `v1/institutions/${institutionId}/residence/${addressId}`,
      institution
    );
  };

  createUser = async (id, newUser) => {
    return await this.api.post(`institutions/${id}/users`, newUser);
  };

  uploadExcelInstitutions = async (body) => {
    return await this.api.post('institutions/import', body);
  };

  updateAddress = async (institutionId, addressId, newInstitution) => {
    return await this.api.put(
      `v1/institutions/${institutionId}/addresses/${addressId}`,
      newInstitution
    );
  };

  updateInstitution = async (institutionId, newFormat) => {
    return await this.api.put(`institutions/${institutionId}`, newFormat);
  };

  updateRecurrent = async (institutionId, newFormat) => {
    return await this.api.put(
      `institutions/${institutionId}/update_recurrent`,
      newFormat
    );
  };

  updateUser = async (institutionId, userId, newUser) => {
    return await this.api.put(
      `institutions/${institutionId}/users/${userId}`,
      newUser
    );
  };
}

export default AddingColectorErrors(new UserApi(envUrls.user, 'User'));
