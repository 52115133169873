import React, { useEffect } from 'react';
import Dropdown from 'components/DropdownWithButton';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DropdownButton from './DropdownButton';
import NilusLogo from './statics/nilus_logo.svg';
import types from './types';
import { buildDropdownOptions } from 'utils/buildDropdownOptions';
import networksConstants from 'utils/networks';
import * as Styled from './statics/styles';
import OptionsConfig from './OptionsConfig';

const Navbar = ({ user, loadUser, onChangeNetwork, routes, activeNetwork }) => {
  useEffect(() => {
    !user.networks && loadUser();
  }, [user, loadUser]);

  if (!user.networks) return <div />;

  const routesForMapping = Object.keys(routes).filter(
    (item) => routes[item].label !== false
  );

  const dateNow = moment().format('DD-MM-YYYY');

  const availableNetworks = buildDropdownOptions(
    user.networks,
    onChangeNetwork
  );

  return (
    <Styled.NavbarContainer role='navigation'>
      <div className='navbar-left'>
        <Link to={`/trips/${dateNow}`}>
          <img alt='nilus-logo' className='logo' src={NilusLogo} />
        </Link>
      </div>
      <div className='navbar-center'>
        {routesForMapping.map((route) => {
          return route === 'settings' ? null : (
            <Styled.ContainerLinkTo key={route}>
              <Styled.LinkTo
                to={routes[route].pathname}
                activeClassName='active'
              >
                {routes[route].label}
              </Styled.LinkTo>
            </Styled.ContainerLinkTo>
          );
        })}
        <OptionsConfig user={user} />
      </div>
      <div className='navbar-right'>
        <Dropdown
          options={availableNetworks}
          disabled={user.networks.length === 1}
        >
          <DropdownButton text={networksConstants[activeNetwork]} />
        </Dropdown>
      </div>
    </Styled.NavbarContainer>
  );
};

Navbar.propTypes = {
  ...types,
};

export default Navbar;
