import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from './components/Modal';
import * as actions from '../../store/actions';
import { withHooks } from 'hooks/withHooks';
import i18n from '../../../../app/configs/i18n';

class AddOfferNameConatiner extends React.Component {
  onConfirm = (formData) => {
    let offer = this.props.data.offer;
    offer.name = formData.name;
    this.props.onConfirm(offer);
  };

  render() {
    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.offer_name.success')}
        onConfirm={this.onConfirm}
      />
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  onConfirm: (offer) => dispatch({ type: props.onConfirm, payload: { offer } }),
  onCancel: () => dispatch(actions.closeModal()),
});

AddOfferNameConatiner.propTypes = {
  onConfirm: propTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withHooks(AddOfferNameConatiner));
