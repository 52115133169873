import { call, put, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { get, post, del } from 'api-connect/dist/api';

import i18n from '../../../app/configs/i18n';
import history from '../../../app/configs/history';

import { onConfirmation } from '../../modals/store/sagas';

import * as actions from './actions';
import * as modalActions from 'modules/modals/store/actions';
import * as alertActions from 'modules/alerts/store/actions';

//FETCH ALL DISTRICTS
function* fetchAllDistrictsWorker() {
  try {
    yield put(actions.fetchAllDistricts.start());
    const { res, error } = yield call(get, 'districts');
    if (res.status > 400 || error) {
      return yield put(actions.fetchAllDistricts.failure({ error }));
    }
    yield put(
      actions.fetchAllDistricts.success({
        districts: res.data,
      })
    );
  } catch (error) {
    yield put(actions.fetchAllDistricts.failure({ error: 'Server error' }));
    yield put(
      alertActions.openAlert({
        message: 'Uups, ocurrio un error',
        type: 'error',
      })
    );
  }
}

//CREATE DISTRICTS
export function* createDistrictsWorker(action) {
  history.push('/settings/districts');
  const { res, error } = yield call(post, 'districts', action.payload);
  if (res.status > 400 || error) {
    yield put(
      alertActions.openAlert({
        message: 'Uups, ocurrio un error',
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.addDistricts.failure({ error }));
  }
  yield put(actions.addDistricts.success());
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: 'Creada correctamente',
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.fetchAllDistricts());
}

//UPDATE DISTRICTS
function* editDistrictsWorker(action) {
  history.push('/settings/districts');
  const { res, error } = yield call(put, '/districts/id', action.payload);
  if (error || res.status >= 400) {
    yield put(
      alertActions.openAlert({
        message: 'Ups, ocurrio un error',
        type: 'error',
      })
    );
    return yield put(actions.updateDistricts.failure({ error }));
  }
  yield put(actions.updateDistricts.success({ res }));
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: 'Actualizado correctamente',
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.fetchAllDistricts());
}

//DELETE DISTRICTS
function* deleteDistrictsWorker(action) {
  const message = i18n.t('districts.sagas.delete_message');
  const operationName = i18n.t('districts.sagas.delete_network');
  const confirmed = yield call(onConfirmation, operationName, message);
  if (!confirmed) {
    return;
  }
  yield put(actions.deleteDistricts.start());
  const { res, error } = yield call(del, '/districts/id', action.payload);
  if (!res || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('districts.sagas.delete_error'),
        type: 'error',
        delay: 2000,
      })
    );
    yield put(modalActions.closeModal());
    return yield put(actions.deleteDistricts.failure({ error }));
  }
  yield put(
    alertActions.openAlert({
      message: i18n.t('districts.sagas.delete_success'),
      type: 'success',
      delay: 2000,
    })
  );
  yield put(actions.deleteDistricts.success());
  yield delay(2000);
  yield put(modalActions.closeModal());
  yield put(actions.fetchAllDistricts());
}

//WATCHERS
export function* fetchAllDistrictsWatcher() {
  yield takeEvery(actions.fetchAllDistricts.type, fetchAllDistrictsWorker);
}
export function* createDistrictsWatcher() {
  yield takeEvery(actions.addDistricts.type, createDistrictsWorker);
}
export function* updateDistrictsWatcher() {
  yield takeEvery(actions.updateDistricts.type, editDistrictsWorker);
}
export function* deleteDistrictsWatcher() {
  yield takeEvery(actions.deleteDistricts.type, deleteDistrictsWorker);
}

//EXPORT WATCHERS
export default [
  fetchAllDistrictsWatcher,
  createDistrictsWatcher,
  updateDistrictsWatcher,
  deleteDistrictsWatcher,
];
