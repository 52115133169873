import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'components/ButtonWithSpinner/ButtonWithSpinnerContainer';

const ErrorList = ({ errors }) => {
  const redFont = { color: 'red' };
  const centerText = { textAlign: 'center' };
  const list = errors.map((x) => (
    <li key={x} style={redFont}>
      {x}
    </li>
  ));
  return <ol style={centerText}>{list}</ol>;
};

ErrorList.propTypes = {
  errors: propTypes.arrayOf([]),
};

ErrorList.defaultProps = {
  errors: [],
};

const Modal = (props) => {
  const { t } = useTranslation();
  const {
    className,
    classNameBackground,
    title,
    children,
    onCancel,
    onSave,
    asyncStatus,
    canSave,
    errors,
  } = props;
  return (
    <div className={`${cn('modal__background')} ${classNameBackground}`}>
      <div className={cn('modal__container', className)}>
        <div className='modal__header'>{title}</div>
        <div className='modal__body'>
          <ErrorList errors={errors} />
          {children}
        </div>
        <div className='modal__buttons'>
          {onCancel && (
            <Button outline className='modal__btn' onClick={onCancel}>
              {t('close')}
            </Button>
          )}
          {onSave && (
            <Button
              primary
              className='modal__btn'
              status={asyncStatus}
              disabled={!canSave}
              onClick={onSave}
            >
              {t('submit')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: propTypes.oneOfType([propTypes.string, propTypes.object]).isRequired,
  className: propTypes.string.isRequired,
  asyncStatus: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
  onCancel: propTypes.func.isRequired,
  onSave: propTypes.func.isRequired,
  canSave: propTypes.bool,
  errors: propTypes.arrayOf([]),
  classNameBackground: propTypes.string.isRequired,
};

Modal.defaultProps = {
  canSave: true,
  errors: [],
};

export default Modal;
