import { put, takeEvery, select, call } from 'redux-saga/effects';
import { post } from 'api-connect/dist/api';
import * as actions from './actions';
import * as modalsSelectors from 'modules/modals/store/selectors';
import LogisticApi from '../../../app/api/LogisticApi';
import * as alertActions from 'modules/alerts/store/actions';
import * as modalActions from 'modules/modals/store/actions';
import { onConfirmation } from '../../modals/store/sagas';
import i18n from '../../../app/configs/i18n';

export function* createWorker(action) {
  yield put(actions.create.start());

  const { method } = action.payload;
  const { type, id, tripId } = yield select(modalsSelectors.data);

  const { res, error } = yield call(post, `${type}/${id}/payments/`, {
    payment_type_id: method,
  });

  if (error) {
    return put(actions.create.failure({ error: error }));
  }

  yield put(
    actions.create.success({ payments: res.payment, type, id, tripId })
  );
}

export function* fetchBalancesWorker(action) {
  yield put(actions.fetchBalances.start());

  const { network } = action.payload;
  try {
    const { res } = yield call(LogisticApi.fetchBalances, network);
    const normalizedByInstitution = res.data.balances.reduce(
      (result, current) => {
        return { ...result, [current.institution_id]: current };
      }
    );
    return yield put(
      actions.fetchBalances.success({
        balancesNormalizedByInstitution: normalizedByInstitution,
      })
    );
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.payments_detail.sagas.get_balance_error'),
        type: 'error',
      })
    );
    return yield put(actions.fetchBalances.failure({ error: error }));
  }
}

export function* fetchPayeeWorker(action) {
  yield put(actions.fetchPayee.start());
  try {
    const { res } = yield call(LogisticApi.fetchPayee, action.payload);
    return yield put(
      actions.fetchPayee.success({
        payee_email: res.data.email,
        payee_name: res.data.payee_name,
      })
    );
  } catch (e) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.payments_detail.sagas.get_payee_error'),
        type: 'error',
      })
    );
    return yield put(actions.fetchPayee.failure({ error: e }));
  }
}

export function* fetchPaymentsOfWorker(action) {
  yield put(actions.fetchPaymentsOf.start());
  const { institutionId } = action.payload;
  try {
    const { res } = yield call(LogisticApi.fetchPaymentsOf, institutionId);
    return yield put(
      actions.fetchPaymentsOf.success({ payments: res.data.payments })
    );
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t(
          'institutions.payments_detail.sagas.get_payments_error'
        ),
        type: 'error',
      })
    );
    return yield put(actions.fetchPaymentsOf.failure({ error: error }));
  }
}

export function* createUntrackedActivityPaymentWorker(action) {
  yield put(actions.createUntrackedActivityPayment.start());

  try {
    const { res } = yield call(
      LogisticApi.createUntrackedActivityPayment,
      action.payload
    );
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.payments_detail.sagas.create_success'),
        type: 'success',
        delay: 5000,
      })
    );
    yield put(modalActions.closeModal());
    return yield put(
      actions.createUntrackedActivityPayment.success({
        payment: res.data.payment,
      })
    );
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.payments_detail.sagas.create_error'),
        type: 'error',
        delay: 5000,
      })
    );
    yield put(modalActions.closeModal());
    return yield put(
      actions.createUntrackedActivityPayment.failure({ error: error })
    );
  }
}

export function* obsolesceWorker(action) {
  const { paymentId } = action.payload;
  const message = i18n.t('institutions.payments_detail.sagas.obsolete_message');
  const operationName = i18n.t('institutions.payments_detail.sagas.obsolete');
  const confirmed = yield call(onConfirmation, operationName, message);
  if (!confirmed) {
    return;
  }
  yield put(actions.obsolesce.start());
  try {
    const { res } = yield call(LogisticApi.obsolescePayment, paymentId);
    yield put(actions.setPayment({ payment: res.data.payment }));
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.payments_detail.sagas.obsolete_success'),
        type: 'success',
        delay: 5000,
      })
    );
    yield put(modalActions.closeModal());
    yield put(actions.resetPayee());
    yield put(actions.obsolesce.success());
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('institutions.payments_detail.sagas.obsolete_error'),
        type: 'error',
        delay: 5000,
      })
    );
    yield put(modalActions.closeModal());
    return yield put(actions.obsolesce.failure({ error }));
  }
}

export function* createWatcher() {
  yield takeEvery(actions.create.type, createWorker);
}

export function* fetchPayeeWatcher() {
  yield takeEvery(actions.fetchPayee.type, fetchPayeeWorker);
}

export function* fetchPaymentsOfWatcher() {
  yield takeEvery(actions.fetchPaymentsOf.type, fetchPaymentsOfWorker);
}

export function* obsolesceWatcher() {
  yield takeEvery(actions.obsolesce.type, obsolesceWorker);
}

export function* createUntrackedActivityPaymentWatcher() {
  yield takeEvery(
    actions.createUntrackedActivityPayment.type,
    createUntrackedActivityPaymentWorker
  );
}

export function* fetchBalancesWatcher() {
  yield takeEvery(actions.fetchBalances.type, fetchBalancesWorker);
}

export default [
  createWatcher,
  fetchPayeeWatcher,
  obsolesceWatcher,
  fetchPaymentsOfWatcher,
  fetchBalancesWatcher,
  createUntrackedActivityPaymentWatcher,
];
