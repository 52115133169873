import React from 'react';
import propTypes from 'prop-types';

import YearPicker from 'components/YearPicker';
import { useTranslation } from 'react-i18next';

const ModalForm = (props) => {
  const { onChange, onChecked, formData } = props;
  const { t } = useTranslation();

  return (
    <span>
      <div className='row'>
        <div className='form-group col-md-12'>
          <input
            onChange={onChange}
            value={formData.model}
            type='text'
            className='form-control'
            name='model'
            placeholder={t('modals.create_vehicle.model_placeholder')}
            autoComplete='off'
          />
        </div>
      </div>
      <div className='row'>
        <div className='form-group col-md-6'>
          <input
            onChange={onChange}
            value={formData.brand}
            type='text'
            className='form-control'
            name='brand'
            placeholder={t('modals.create_vehicle.brand_placeholder')}
            autoComplete='off'
          />
        </div>
        <div className='form-group col-md-6'>
          <YearPicker name='year' value={formData.year} onChange={onChange} />
        </div>
      </div>
      <div className='row'>
        <div
          className='checkbox-custom checkbox-primary form-group col-md-12'
          style={{ marginLeft: '15px' }}
        >
          <input
            name='refrigerated'
            type='checkbox'
            id='refrigerated'
            checked={formData['refrigerated']}
            onClick={onChecked}
          />
          <label htmlFor='refrigerated'>
            {t('modals.create_vehicle.refrigerated')}
          </label>
        </div>
      </div>
    </span>
  );
};

ModalForm.propTypes = {
  onChange: propTypes.func.isRequired,
  onChecked: propTypes.func.isRequired,
  formData: propTypes.shape({}).isRequired,
};

export default ModalForm;
