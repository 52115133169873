import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import TripSummarySlider from './TripSummarySlider';
import Slider from 'components/Slider/SliderContainer';

import * as modalActions from 'modules/modals/store/actions';
import * as paymentActions from 'modules/payments/store/actions';

class TripSummarySliderContainer extends React.Component {
  render() {
    return (
      <Slider slides={this.props.trip.orders}>
        <TripSummarySlider {...this.props} />
      </Slider>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openCreatePaymentCouponModal: (type, id, tripId) =>
    dispatch(
      modalActions.openModal({
        type: 'createPaymentCoupon',
        data: { type, id, tripId },
        onConfirm: paymentActions.create.type,
        onCancel: paymentActions.cleanStatus.type,
      })
    ),
});

TripSummarySliderContainer.propTypes = {
  trip: propTypes.shape({}).isRequired,
};

export default connect(null, mapDispatchToProps)(TripSummarySliderContainer);
