export const createProducts = (products, combo) =>
	combo.items.combo_product_items.reduce((acc, product) => {
		const productInfo = products.filter(
			(produc) => produc.id === product.product_variant_id,
		)[0];
		const singleProduct = {
			id: product.product_variant_id,
			product: productInfo,
			app_name: product.app_name,
			quantity: product.quantity,
		};
		return [...acc, singleProduct];
	}, []);
