import React from 'react';
import propTypes from 'prop-types';

import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';

import Button from 'components/Button';
import { Formik } from 'formik';
import { validationSchema } from '../validations';
import { Label } from 'components/Select/Label';
import { Input } from 'components/Input';

import './statics/style.css';
import { useTranslation } from 'react-i18next';

const AddOfferName = (props) => {
  const { onCancel, onConfirm } = props;
  const { t } = useTranslation();

  const initialValues = {
    name: '',
  };

  return (
    <Modal
      className='edit_date__modal'
      title={t('modals.offer_name.title')}
      onCancel={() => onCancel()}
      {...props}
    >
      <ModalBody className='modal-detail__body'>
        <Formik
          initialValues={initialValues}
          onSubmit={onConfirm}
          validationSchema={validationSchema()}
        >
          {({ values, handleChange, handleSubmit, isValid, errors }) => (
            <>
              <div className='inputContainer'>
                <p className='info'>{t('modals.offer_name.subtitle')}</p>
                <div>
                  <Label label={t('modals.offer_name.name')} />
                  <Input
                    placeholder={t('modals.offer_name.name_placeholder')}
                    name='name'
                    type='text'
                    onChange={handleChange}
                    value={values.name}
                    required
                  />
                  {errors.name ? (
                    <div className='errorMessage'>{errors.name}</div>
                  ) : null}
                </div>
              </div>
              <div className='buttons'>
                <Button className='btn btn-cancel' onClick={onCancel} outline>
                  {t('modals.offer_name.close')}
                </Button>
                <Button
                  className='btn btn-save'
                  onClick={handleSubmit}
                  disabled={!isValid}
                  primary
                >
                  {t('modals.offer_name.submit')}
                </Button>
              </div>
            </>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

AddOfferName.propTypes = {
  onCancel: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
  status: propTypes.string.isRequired,
};

export default AddOfferName;
