import { call, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { get, patch, put as _put, post } from 'api-connect/dist/marketplace';
import { normalize } from '../../../utils';
import { isEmpty } from 'ramda';
import * as actions from './actions';
import {
  getFileNameFromServerOrBackup,
  getURLForFetch,
} from '../helpers/helpers';
import history from '../../../app/configs/history';
import fileDownload from 'js-file-download';
import MarketplaceApi from '../../../app/api/MarketplaceApi';

import * as alertActions from 'modules/alerts/store/actions';
import * as modalActions from 'modules/modals/store/actions';
import * as selectors from './selectors';

import {
  STOCK_CREATE,
  STOCK_OVERWRITE,
} from '../../modals/components/UploadCsv/constants';

import i18n from '../../../app/configs/i18n';

export function* downloadProductCsv() {
  yield put(actions.productCsvDownload.start());
  try {
    const res = yield call(MarketplaceApi.getProductCsv);
    yield put(actions.productCsvDownload.success());
    fileDownload(res, 'productos.csv');
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.export_csv_error'),
        type: 'error',
      })
    );
    yield put(actions.productCsvDownload.failure({ error }));
  }
}

export function* fetchOfferableStock() {
  yield put(actions.fetchOfferableStock.start());
  try {
    const { stocks } = yield call(MarketplaceApi.fetchOfferableStock);
    yield put(
      actions.fetchOfferableStock.success({ stock: normalize(stocks) })
    );
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.offer_error'),
        type: 'error',
      })
    );
    yield put(actions.fetchOfferableStock.failure({ error }));
  }
}

export function* downloadStockCsv() {
  yield put(actions.stockCsvDownload.start());
  try {
    const res = yield call(MarketplaceApi.getStockCsv);
    yield put(actions.stockCsvDownload.success());
    fileDownload(res, 'stock.csv');
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.export_csv_download_error'),
        type: 'error',
      })
    );
    yield put(actions.stockCsvDownload.failure({ error }));
  }
}

export function* downloadImportTemplate() {
  yield put(actions.importTemplateDownload.start());
  try {
    const res = yield call(MarketplaceApi.getImportTemplate);
    const { data } = res;
    const fileName = getFileNameFromServerOrBackup(
      res.headers['Content-Disposition'],
      'planilla_importacion.xlsx'
    );
    yield put(actions.importTemplateDownload.success());
    console.log(data, fileName, 'YESSS');
    fileDownload(data, fileName);
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.import_error'),
        type: 'error',
      })
    );
    yield put(actions.importTemplateDownload.failure({ error }));
  }
}

function* makeUploadCsvRequest(action) {
  const formData = new FormData();
  formData.append('file', action.payload.file);
  console.log(
    action,
    action.payload,
    action.payload.file,
    '-------------------------------->',
    formData.append('file', action.payload.file)
  );
  const { option, validate } = action.payload;
  return yield call(MarketplaceApi.uploadCsv, option, validate, formData);
}

export function* uploadCsv(action) {
  yield put(actions.uploadCsv.start());
  try {
    const res = yield makeUploadCsvRequest(action);
    console.log(res, res.result, 'RESULT STOCK ');
    if (res.result === undefined) {
      yield put(actions.uploadCsv.success());
      yield put(modalActions.closeModal());
      yield put(
        modalActions.openModal({
          type: 'csvErrors',
          data: { ...action.payload, messages: res.messages },
        })
      );
    } else {
      yield put(actions.uploadCsv.success());
      yield put(
        alertActions.openAlert({
          message: `${i18n.t('stock.sagas.imported')} ${res.result} ${i18n.t(
            'stock.sagas.elements'
          )}`,
          type: 'success',
        })
      );
      if (
        action.payload.option === STOCK_OVERWRITE ||
        action.payload.option === STOCK_CREATE
      ) {
        yield put(actions.fetchAll({ type: 'available' }));
      }
      return yield put(modalActions.closeModal());
    }
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.import_csv_error'),
        type: 'error',
      })
    );
  }
}

//this is the function bringing the stock!
export function* fetchAll(action) {
  // yield put(actions.fetchAll.start());

  const type = action.payload.type;
  const page = yield select(selectors.page);

  try {
    const { res } = yield call(
      get,
      `v2/categories/stocks${getURLForFetch(type, page)}`
    );
    // TODO: this needs refactor, the idea would be to replace all tables with react tables and its own pagination but backend is prepared for pagination too
    if (type === 'historic') {
      yield put(
        actions.fetchAll.success({
          stock: type === 'historic' ? res.data.items : res.data,
          type: type,
          page: page,
          totalPages: res.data.total_pages,
        })
      );
    } else if (type === 'unrevised') {
      yield put(
        actions.fetchAll.success({
          stock: type === 'unrevised' ? res.data.items : res.data,
          type: type,
          page: page,
          totalPages: res.data.total_pages,
        })
      );
    } else {
      yield put(actions.fetchAll.success({ stock: res.data, type: type }));
    }
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.get_stock_error'),
        type: 'error',
        delay: 5000,
      })
    );
    yield put(actions.fetchAll.failure({ error }));
  }
}

export function* update(action) {
  yield put(actions.update.start());

  const { variant, body } = action.payload;

  //* CRUD action
  const { res, error } = yield call(
    patch,
    `variants/${variant.variant_id}/stocks/${variant.id}`,
    body
  );

  if (res.status > 400 || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.update_product_error'),
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.update.failure({ error }));
  }

  yield put(actions.update.success());
  yield put(
    alertActions.openAlert({
      message: i18n.t('stock.sagas.update_product_success'),
      type: 'success',
      delay: 2000,
    })
  );

  yield fetchAll(action);
}

export function* reviseVariant(action) {
  yield put(actions.reviseVariant.start());

  const { variant, body } = action.payload;

  //* CRUD action
  const { res, error } = yield call(
    _put,
    `variants/${variant.variant_id}/normalize`,
    body
  );

  if (res.status > 400 || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.revise_product_error_error'),
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.reviseVariant.failure({ error }));
  }
  yield put(actions.reviseVariant.success());
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('stock.sagas.revise_product_success'),
      type: 'success',
      delay: 2000,
    })
  );

  yield fetchAll(action);
}

export function* editVariant(action) {
  yield put(actions.editVariant.start());

  const { variant, variant_id } = action.payload.data;

  //* CRUD action
  const { res, error } = yield call(_put, `variants/${variant_id}`, {
    variant,
  });

  if (res.status > 400 || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.revise_product_error_error'),
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.editVariant.failure({ error }));
  }

  yield put(actions.editVariant.success());
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('stock.sagas.revise_product_success'),
      type: 'success',
      delay: 2000,
    })
  );

  //yield fetchAll(action);
  yield fetchProducts();
}

export function* createVariant(action) {
  yield put(actions.createVariant.start());

  const { body } = action.payload;

  //* CRUD action
  const { res, error } = yield call(post, 'variants', body);
  if (res.status > 400 || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.create_product_error'),
        type: 'error',
        delay: 5000,
      })
    );

    return yield put(actions.createVariant.failure({ error }));
  }

  yield put(actions.createVariant.success());
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('stock.sagas.create_product_success'),
      type: 'success',
      delay: 2000,
    })
  );
  yield fetchProducts();
}

//**************************************************************//
//FETCH AVAILABLE STOCK

//this saga is specific for Create Stock modal, fetching only one particular state of stock which is available
//this is very important when creating  stock for a combo.
export function* fetchAvailableStock(action) {
  yield put(actions.fetchAll.start());
  const type = action.payload.type;

  //* CRUD action
  const { res, error } = yield call(
    get,
    `v2/categories/stocks?filter[where][has_stock]=true&filter[where][revised]=true`
  );

  if (res.status > 400 || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.get_stock_error'),
        type: 'error',
        delay: 5000,
      })
    );

    return yield put(actions.fetchAll.failure({ error }));
  } else {
    yield put(actions.fetchAll.success({ stock: res.data, type: type }));
  }
}
//**************************************************************//
//COMBO CREATION CRUD MODEL

//CREATE COMBO
export function* createCombo(action) {
  yield put(actions.createCombo.start());
  const { body } = action.payload;
  const { res, error } = yield call(post, 'variants/create_combo', body);
  if (res.status > 400 || error) {
    yield put(
      alertActions.openAlert({
        message: 'Error creando combo',
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.createCombo.failure({ error }));
  }

  yield put(actions.createCombo.success());
  yield put(modalActions.closeModal());

  yield put(
    alertActions.openAlert({
      message: 'Combo creado correctamente',
      type: 'success',
      delay: 2000,
    })
  );

  yield getAllCombosWorker();
}

//DONE REFACTOR
export function* getAllCombosWorker() {
  try {
    const { res } = yield call(get, 'combos');
    yield put(
      actions.getAllCombos.success({ combos: res.data.combo_variants })
    );
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: 'Error buscando combos',
        type: 'error',
        delay: 5000,
      })
    );
    yield put(actions.getAllCombos.failure({ error }));
  }
}

//TODO: editCombo incomplete, endpoint edit request
export function* editCombo() {
  history.push('/stock/combos');
  yield put(actions.editCombo.success());

  yield put(
    alertActions.openAlert({
      message: 'Combo editado correctamente',
      type: 'success',
      delay: 2000,
    })
  );
  yield delay(2000);
  yield put(modalActions.closeModal());
}

export function* searchStock(action) {
  yield put(actions.searchStock.start());

  const { search, type } = action.payload;

  if (isEmpty(search)) {
    return yield fetchAll(action);
  }

  if (type === 'available') {
    yield put(actions.searchStock.success({ search, type }));
  }
  // const { res, error } = yield call(
  //   get,
  //   `v2/categories/stocks?filter[where][query]=${search}`
  // );

  // if (res.status > 400 || error) {
  //   yield put(
  //     alertActions.openAlert({
  //       message: i18n.t('stock.sagas.get_stock_error'),
  //       type: 'error',
  //       delay: 5000,
  //     })
  //   );
  //   return yield put(actions.searchStock.failure({ error }));
  // }

  // yield put(actions.searchStock.success({ stock: res.data, type: type }));
}

export function* createStock(action) {
  yield put(actions.createStock.start());

  const { body } = action.payload;

  //* CRUD action
  const { res, error } = yield call(
    post,
    `variants/${body.stock.variant_id}/stocks/`,
    body
  );

  if (res.status > 400 || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.create_stock_error'),
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.createStock.failure({ error }));
  }

  yield put(actions.createStock.success());
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('stock.sagas.create_stock_success'),
      type: 'success',
      delay: 2000,
    })
  );

  yield fetchAll(action);
}

export function* updateStock(action) {
  yield put(actions.updateStock.start());
  const stock = action.payload.stock;
  const dto = {
    item_price: stock.item_price,
    quantity: stock.quantity,
    expires_on: stock.expires_on,
    item_discount: stock.item_discount,
    cost: stock.cost,
    groups_availability: stock.groups_availability,
    rescued: stock.rescued,
  };

  try {
    yield call(
      MarketplaceApi.updateStock,
      stock.variant_id,
      dto,
      action.stock_id
    );
    yield put(actions.updateStock.success());
    yield put(modalActions.closeModal());
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.update_variant_success'),
        type: 'success',
        delay: 5000,
      })
    );
    yield fetchAll({ payload: { type: 'available' } });
  } catch (e) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.create_stock_error'),
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.createStock.failure({ e }));
  }
}

export function* fetchOffers() {
  yield put(actions.fetchOffers.start());

  //todo: Add integration with server
  //* CRUD action
  const { res, error } = yield call(get, `v2/offers`);

  if (res.status > 400 || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.get_offer_error'),
        type: 'error',
        delay: 5000,
      })
    );
    return yield put(actions.fetchOffers.failure({ error }));
  }

  yield put(actions.fetchOffers.success({ offers: res.data.offers }));
}

export function* fetchProducts() {
  yield put(actions.fetchProducts.start());

  const { res, error } = yield call(get, `products`);

  if (res.status > 400 || error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.get_products_error'),
        type: 'error',
        delay: 5000,
      })
    );

    return yield put(actions.fetchProducts.failure({ error }));
  }

  yield put(actions.fetchProducts.success({ products: res.data }));
}

export function* editStock(action) {
  yield put(actions.editStock.start());
  const { body } = action.payload;

  if (isEmpty(body.offer.price_list)) {
    return yield createOffer(action);
  }

  const keys = Object.keys(body.offer.price_list);
  const values = Object.values(body.offer.price_list);

  for (let i = 0; i < keys.length; i++) {
    for (let k = 0; i < values.length; k++) {
      const { res, error } = yield call(
        _put,
        `/variants/${values[k].variant_id}/stocks/${keys[i]}`,
        values[k]
      );

      if (error || res.status > 201) {
        yield put(alertActions.openAlert({ createVariant }));
        return put(actions.editStock.failure({ error }));
      } else {
        yield put(actions.editStock.success());
        yield createOffer(action);
      }
    }
  }
}

export function* createOffer(action) {
  yield put(actions.createOffer.start());

  const { offer } = action.payload;

  try {
    yield call(MarketplaceApi.createOffer, { offer });
    yield put(actions.createOffer.success());
    yield put(modalActions.closeModal());
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.create_offer_success'),
        type: 'success',
        delay: 2000,
      })
    );
    window.location.replace('/stock/offers');

    yield fetchOffers();
  } catch (error) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.create_offer_error'),
        type: 'error',
      })
    );
    yield put(modalActions.closeModal());
    return yield put(actions.createOffer.failure({ error }));
  }
}

export function* pageChange(action) {
  yield put(actions.pageChange.start());

  yield put(actions.pageChange.success(action.payload));
  yield fetchAll(action);
}

export function* cancelOffer(action) {
  yield put(actions.cancelOffer.start());

  const { offerId, body } = action.payload;

  // * CRUD action
  const { res, error } = yield call(_put, `v2/offers/${offerId}`, body);

  if (error || res.status > 400) {
    yield put(
      alertActions.openAlert({
        message: i18n.t('stock.sagas.cancel_offer_error'),
        type: 'error',
      })
    );
    return yield put(actions.cancelOffer.failure({ error }));
  }

  yield put(actions.cancelOffer.success());
  yield put(
    alertActions.openAlert({
      message: i18n.t('stock.sagas.cancel_offer_success'),
      type: 'success',
      delay: 2000,
    })
  );

  yield fetchOffers();
}

export function* fetchOfferWorker(action) {
  yield put(actions.fetchOffer.start());

  const offerId = action.payload.id;

  try {
    const res = yield call(MarketplaceApi.getOffer, offerId);
    yield put(actions.fetchOffer.success(res.data.offer));
  } catch (e) {
    alert(e);
    yield put(actions.fetchOffer.error(e));
  }
}

export function* archiveStockWorker(action) {
  const {
    payload: { variant_id, product_id, product },
  } = action;

  try {
    yield call(_put, `variants/${variant_id}/stocks/${product_id}`, product);
    yield put(actions.archiveStock.success());
    yield put(actions.fetchAll({ type: 'available' }));
    yield put(
      alertActions.openAlert({
        message: 'Stock Archivado con éxito',
        type: 'success',
        delay: 2000,
      })
    );
  } catch (error) {
    yield put(actions.archiveStock.failure());
    yield put(
      alertActions.openAlert({
        message: 'Error al archivar el stock',
        type: 'error',
        delay: 2000,
      })
    );
  }
}

export function* archiveStockWatcher() {
  yield takeEvery(actions.archiveStock.type, archiveStockWorker);
}

export function* editComboWatcher() {
  yield takeEvery(actions.editCombo.type, editCombo);
}

export function* fetchAllWatcher() {
  yield takeEvery(actions.fetchAll.type, fetchAll);
}

export function* fetchOfferWatcher() {
  yield takeEvery(actions.fetchOffer.type, fetchOfferWorker);
}

export function* updateWatcher() {
  yield takeEvery(actions.update.type, update);
}

export function* reviseVariantWatcher() {
  yield takeEvery(actions.reviseVariant.type, reviseVariant);
}

export function* editVariantWatcher() {
  yield takeEvery(actions.editVariant.type, editVariant);
}

export function* createVariantWatcher() {
  yield takeEvery(actions.createVariant.type, createVariant);
}

export function* fetchAvailableStockWatcher() {
  yield takeEvery(actions.fetchAvailableStock.type, fetchAvailableStock);
}

export function* createComboWatcher() {
  yield takeEvery(actions.createCombo.type, createCombo);
}
export function* getAllCombosWatcher() {
  yield takeEvery(actions.getAllCombos.type, getAllCombosWorker);
}

export function* createStockWatcher() {
  yield takeEvery(actions.createStock.type, createStock);
}

export function* updateStockWatcher() {
  yield takeEvery(actions.updateStock.type, updateStock);
}

export function* editStockWatcher() {
  yield takeEvery(actions.editStock.type, editStock);
}

export function* searchStockWatcher() {
  yield takeEvery(actions.searchStock.type, searchStock);
}

export function* createOfferWatcher() {
  yield takeEvery(actions.createOffer.type, createOffer);
}

export function* pageChangeWatcher() {
  yield takeEvery(actions.pageChange.type, pageChange);
}

export function* fetchOfferableStockWatcher() {
  yield takeEvery(actions.fetchOfferableStock.type, fetchOfferableStock);
}

export function* fetchOffersWatcher() {
  yield takeEvery(actions.fetchOffers.type, fetchOffers);
}

export function* cancelOfferWatcher() {
  yield takeEvery(actions.cancelOffer.type, cancelOffer);
}

export function* fetchProductsWatcher() {
  yield takeEvery(actions.fetchProducts.type, fetchProducts);
}

export function* uploadCsvWatcher() {
  yield takeEvery(actions.uploadCsv.type, uploadCsv);
}

export function* downloadProductCsvWatcher() {
  yield takeLatest(actions.productCsvDownload.type, downloadProductCsv);
}

export function* downloadStocksCsvWatcher() {
  yield takeLatest(actions.stockCsvDownload.type, downloadStockCsv);
}

export function* downloadImportTemplateWatcher() {
  yield takeLatest(actions.importTemplateDownload.type, downloadImportTemplate);
}

export default [
  fetchAllWatcher,
  updateWatcher,
  reviseVariantWatcher,
  editVariantWatcher,
  createVariantWatcher,
  createComboWatcher,
  getAllCombosWatcher,
  createStockWatcher,
  editStockWatcher,
  updateStockWatcher,
  searchStockWatcher,
  pageChangeWatcher,
  fetchOffersWatcher,
  fetchOfferWatcher,
  fetchProductsWatcher,
  createOfferWatcher,
  cancelOfferWatcher,
  uploadCsvWatcher,
  downloadProductCsvWatcher,
  downloadStocksCsvWatcher,
  downloadImportTemplateWatcher,
  fetchOfferableStockWatcher,
  editComboWatcher,
  archiveStockWatcher,
];
