export default {
  translation: {
    date_format: 'MM/DD/YYYY',
    date_time_format: 'MM/DD/YYYY hh:mm',
    map_language: 'es',
    loading: 'Cargando...',
    today: 'Hoy',
    select_file: 'SELECCIONAR ARCHIVO',
    to: 'a',
    of: 'de',
    search: 'Buscar',
    close: 'CERRAR',
    submit: 'GUARDAR',
    adder: {
      add_product: 'Agregar otro producto',
      select: 'Seleccionar',
    },
    search_address: 'Buscá la dirección',
    navbar: {
      trips: 'Viajes',
      orders: 'Órdenes',
      shippers: 'Choferes',
      institutions: 'Instituciones',
      stock: 'Stock',
      reports: 'Reportes',
      notifications: 'Notificaciones',
      settings: 'Configuración',
      sign_off: 'Cerrar sesión',
    },
    alert: {
      error: 'Hubo un error!',
    },
    login: {
      sign_in: 'Iniciar sesión',
      brand: 'Nilus',
      description:
        'Facilitamos el rescate de alimentos que están por ser desperdiciados y los distribuimos entre comedores sociales, escuelas y todo aquel que lo necesite.',
      credentials_subtitle:
        'Ingresá tu e-mail y tu contraseña de Nilus para ingresar.',
      no_account: 'No tenés cuenta?',
      create: 'Crear usuario',

      error: 'Hubo un error',
      report: '¿Te gustaría reportarlo?',

      mail: 'E-mail',
      mail_placeholder: 'Email',
      password: 'Contraseña',
      password_placeholder: 'Password',
      invalid_credentials: 'Credenciales invalidas',
      remember_me: 'Recordarme',
      forgot_password: 'Olvidé mi contraseña',
      submit: 'Iniciar sesión',
    },
    map_with_search: {
      msg: 'Locación ajustada. Usar Latitud y Longitud para dirección exacta.',
    },
    modals: {
      add_product: {
        title: 'Agregar producto',
        subtitle:
          'Tené en cuenta que agregar productos a la orden modificará el importe de la misma.',
        origin: 'Origen de artículos',
        origin_placeholder: 'Seleccionar depósito',
      },
      create_cupon: {
        reason: 'Motivo',
        reason_placeholder: 'Seleccionar',
        payment_method: 'Forma de pago',
        payment_method_placeholder: 'Seleccionar',
        amount: 'Monto',
        amount_placeholder: '$',
        close: 'Cancelar',
        submit: 'Crear',
        subtitle: ' Elija motivo y medio de pago para ver el destinatario',
        title: 'Crear cupón de pago',
        receiver: 'Receiver:',
        name: 'Nombre:',
        mail: 'Email:',

        rapi_pago: 'Rapi pago',
        pago_facil: 'Pago facil',
        other: 'Otro',

        food_reason: 'Contribución por entrega de alimentos',
        trip_reason: 'Contribución por servicio de flete',
      },
      create_driver: {
        title: 'Crear chofer',
        close: 'Cerrar',
        submit: 'Crear chofer',
        name: 'Nombre: ',
        name_placeholder: 'Nombre del chofer',
        surname: 'Apellido: ',
        surname_placeholder: 'Apellido del chofer',
        email: 'Email:',
        email_placeholder: 'Email',
        password: 'Contraseña:',
        password_placeholder: 'Contraseña',
        success: 'Bien! El chofer se creó correctamente 🎉',
      },
      edit_driver: {
        title: 'Actualizá la información de este conductor',
        close: 'Cancelar',
        submit: 'Guardar cambios',
        first_name: 'Nombre',
        first_name_placeholder: 'Nombre del chofer',
        last_name: 'Apellido',
        last_name_placeholder: 'Apellido del chofer',
        phone_number: 'Teléfono:',
        phone_number_placeholder: 'Número de teléfono',
        email: 'Email:',
        email_placeholder: 'Email',
        birth_date: 'Fecha de nacimiento:',
        gender: 'Género:',
        password: 'Cambiar contraseña:',
        password_placeholder: 'Nueva contraseña',
        new_password: 'Nueva contraseña',
        new_password_placeholder: 'Confirmar contraseña',
        enable: 'Habilitar',
        disable: 'Deshabilitar',
        success:
          'Bien! La información de este conductor se actualizó correctamente 🎉',
      },
      create_vehicle: {
        title: 'Carga nuevo vehículo',
        subtitle:
          'Completá los siguientes campos para cargar un nuevo vehículo a este chofer:',
        close: 'Cancelar',
        submit: 'Cargar nuevo vehículo',
        brand_placeholder: 'Marca',
        model_placeholder: 'Modelo del vehículo',
        refrigerated: 'Tiene carga de frío',
        success: 'Bien! El vehículo se creó correctamente 🎉',
      },
      edit_vehicle: {
        title: 'Actualizá este vehículo',
        close: 'Cancelar',
        submit: 'Guardar cambios en vehículo',
        model: 'Modelo del vehículo:',
        model_placeholder: 'Modelo del vehículo',
        brand: 'Marca:',
        brand_placeholder: 'Marca',
        year: 'Año:',
        refrigerated: 'Tiene carga de frío',
        success: 'Bien! El vehículo se actualizó correctamente 🎉',
      },
      create_bank_account: {
        title: 'Crear cuenta bancaria',
        subtitle:
          'Completá los siguientes campos para agregar una cuenta bancaria a este chofer:',
        close: 'Cancelar',
        submit: 'Crear cuenta bancaria',
        success: 'Bien! La cuenta bancaria se creó correctamente 🎉',
        bank_name_placeholder: 'Nombre del banco',
        number_placeholder: 'Número de cuenta',
        cbu_placeholder: 'CBU',
        savings_bank: 'Caja de Ahorro',
        current_account: 'Cuenta Corriente',
      },
      edit_bank_account: {
        title: 'Actualizar cuenta bancaria',
        close: 'Cancelar',
        submit: ' Guardar cambios en cuenta bancaria',
        success: 'Bien! La cuenta bancaria se actualizó correctamente 🎉',
        bank_name: 'Nombre del banco:',
        bank_name_placeholder: 'Nombre del banco',
        type: 'Tipo de cuenta:',
        savings_bank: 'Caja de Ahorro',
        current_account: 'Cuenta Corriente',
        number: 'Número de cuenta:',
        number_placeholder: 'Número de cuenta',
        cbu: 'CBU:',
        cbu_placeholder: 'CBU',
      },
      create_trip: {
        title: 'Confirmar Viaje',
        step: 'Paso',
        from: 'de',
        subtitle: 'Crear viaje',
        close: 'Cancelar',
        next: 'Siguiente',
        submit: 'Crear viaje',
        step_1: {
          date: 'Fecha:',
          time: 'Hora:',
          notes: 'Notas:',
        },
        step_2: {
          total_weight: 'Peso total:',
          distance: 'Distancia:',
          time: 'Tiempo aproximado:',
          driver_pay: 'Monto de pago al chofer:',
        },
        step_3: {
          broadcast: 'Hacer broadcasting',
          assign: 'Asignar chofer',
          message:
            'Podes asignar un chofer o broadcastearlo para que lo vean todos:',
          select: 'Seleccioná una opción',
        },
        multi_origin_step: {
          message:
            'Las órdenes seleccionadas tienen distinto punto de depósito. Por favor seleccioná cuál debe ser el origen del viaje:',
        },
      },
      edit_trip: {
        title: 'Editar viaje',
        driver_pay: 'Monto de pago al chofer:',
        driver_pay_placeholder: 'Monto de pago al chofer',
        date: 'Fecha',
        time: 'Hora',
        notes: 'Notas:',
        submit: 'Guardar cambios:',
        close: 'Cerrar',
        success: 'Bien! El viaje se actualizó correctamente 🎉',
        validations: {
          amount_must_be_numeric: 'El monto debe ser un número',
          amount_must_be_positive: 'El monto debe ser positivo',
          amount_must_exist: 'Se debe especificar un monto',
          schedule_date_must_exist: 'Se debe especificar una fecha',
          schedule_time_must_exist: 'Se debe especificar una hora',
        },
      },
      view_trip: {
        title: 'Detalle del viaje',
        close: 'Cerrar',
      },

      create_institution_user: {
        success: 'Bien! El usuario se creó correctamente 🎉',

        title: 'Crear Usuario',
        close: 'Cancelar',
        submit: 'Crear Usuario',

        name: 'Nombre:',
        name_placeholder: 'Nombre del usuario',
        surname: 'Apellido:',
        surname_placeholder: 'Apellido del usuario',
        email: 'Email:',
        email_placeholder: 'Email',
        phone: 'Cellphone:',
        phone_placeholder: 'Celular',
        password: 'Contraseña:',
        password_placeholder: 'Contraseña',
      },

      create_payment: {
        rapi_pago: 'Rapi pago',
        pago_facil: 'Pago facil',
        other: 'Otro',

        food_reason: 'Contribución por entrega de alimentos',
        trip_reason: 'Contribución por servicio de flete',

        validations: {
          required_reason: 'Debe ingresar un motivo para crear el cupón',
          required_amount: 'Debe ingresar un número',
          positive_amount: 'El número debe ser positivo',
          numeric_amount: 'Debe ingresar un monto',
          required_payment_method: 'Debe ingresar un medio de pago',
        },
      },

      edit_order_date: {
        title: 'Editar fecha de entrega',
        date: 'Fecha',
        start_time: 'Hora inicio',
        close: 'CANCELAR',
        submit: 'GUARDAR',
        success: 'Se modificó la fecha correctamente',
        validations: {
          delivery_date: 'Se debe especificar una fecha',
          delivery_time: 'Se debe especificar una hora',
        },
      },

      edit_order: {
        product_adder: {
          product: 'Producto:',
          product_placeholder: 'Seleccionar producto',
          quantity: 'Cantidad',
          close: 'CANCELAR',
          submit: 'GUARDAR',
          validations: {
            product: 'Producto',
            quantity: 'Cantidad',
            available_quantity: 'Debe ingresar una cantidad disponible',
          },
        },
        success: 'Se modificó la fecha correctamente',
        validations: {
          delivery_date: 'Se debe especificar una fecha',
          delivery_time: 'Se debe especificar una hora',
        },
      },

      view_order: {
        afternoon: 'Tarde',
        morning: 'Mañana',
        bank_id: 'ID Banco',
        product: 'Producto',
        description: 'Descripción',
        brand: 'Marca',
        expiration: 'Vencimiento',
        quantity: 'Cantidad',
        total_weight: 'Peso total',
        total_price: 'Costo total',

        title: 'Detalle de la orden',
        loading: 'Cargando orden',
        institution: 'Institución:',
        address: 'Dirección:',
        date: 'Fecha de entrega:',
        schedule: 'Turno:',
        order_payment: 'Número de cupón de envío:',
        no_order_payment: 'No hay cupones generados',
        delivery_payment: 'Número de cupón de alimentos:',
        no_delivery_payment: 'No hay cupones generados',
        products: 'Productos:',
        no_data: 'Sin data',
      },

      view_stock_product: {
        product: 'Producto: ',
        stock: 'Stock: ',
        units: 'unidades',
        no_units: 'Sin data',
        category: 'Categoría:',
        min: 'Mínimo:',
        max: 'Máximo:',
      },

      upload_csv: {
        create_product: 'Crear nuevos productos',
        create_stock: 'Agregar stock',
        replace_stock: 'Reemplazar stock existente',

        title: 'Import desde archivo csv',
        subtitle:
          'Porfavor seleccioná el tipo de contenido que querés actualizar',
        action: 'Acción:',
        action_placeholder: 'Seleccionar',
        download_template: 'DESCARGAR PLANILLA',
        close: 'Cancelar',
        submit: 'Aceptar',
      },

      update_stock: {
        units: 'unidades',
        no_units: 'Sin data',

        close: 'Cancelar',
        submit: 'Guardar',
        previous: 'Anterior',
        next: 'Siguiente',

        category: 'Categoría:',
        min: 'Mínimo:',
        max: 'Máximo:',

        name: 'Nombre del producto',
        name_placeholder: 'Nombre del producto',
        variant: 'Lote',
        variant_placeholder: 'Nº',
        deposit: 'Depósito',
        deposit_placeholder: 'Seleccionar',
        expiration: 'Vencimiento',
        stock: 'Stock',
        stock_placeholder: 'Nº',
        price: 'Precio por pack',
        cost: 'Costo del lote',
        price_placeholder: 'Nº',
        discount: 'Descuento',
        discount_placeholder: '0%',

        validations: {
          at_least_one_greater: 'Debe haber al menos un máximo mayor a 0',
          only_numbers: 'Solo se admiten números',
          zero_or_more: 'Todos los campos deben tener valor 0 o mayor',
          only_positive: 'Solo números positivos',
          greater_or_equal_than: 'Debe ser mayor o igual a ',
        },
      },

      revise_product: {
        loading: 'CARGANDO PRODUCTO',
        title: 'Editar Producto',
        name: 'Nombre del producto',
        name_placeholder: 'Nombre del producto',
        description: 'Descripción',
        description_placeholder: 'Descripción',
        brand: 'Marca',
        brand_placeholder: 'Marca',
        category: 'Categoría',
        category_placeholder: 'Categoría',
        subcategory: 'Subcategoría',
        subcategory_placeholder: 'Subcategoría',
        presentation: 'Presentación',
        presentation_placeholder: 'Bolsita',
        units_multiplier: 'Multiplicador',
        units_multiplier_placeholder: 'Nº',
        units_per_pack: 'Unid. por pack',
        units_per_pack_placeholder: 'Nº',
        weight: 'Peso',
        weight_placeholder: 'Nº',
        unit_measure_name: 'Unidad de medida',
        unit_measure_name_placeholder: 'Unidad de medida',
      },

      edit_payment_status: {
        success: 'Bien! El comentario se ha actualizado 🎉',
        cupon: 'Cupon',
        mark_as_payed: 'Marcar como pagado',

        paid_at: 'Fecha',
        amount: 'Monto',
        comment: 'Agregar comentario',

        close: 'Cancelar',
        submit: 'Guardar Cambios',
      },

      edit_institution_user: {
        success: 'Bien! El usuario se ha actualizado 🎉',

        title: 'Editar Usuario',
        close: 'Cancelar',
        submit: 'Guardar Cambios',

        name: 'Nombre:',
        name_placeholder: 'Nombre del usuario',
        surname: 'Apellido:',
        surname_placeholder: 'Apellido del usuario',
        email: 'Email:',
        email_placeholder: 'Email',
        phone: 'Cellphone:',
        phone_placeholder: 'Celular',
        password: 'Cambiar Contraseña:',
        password_placeholder: 'Nueva Contraseña',
        confirm_password: 'Confirmar Contraseña:',
        confirm_password_placeholder: 'Confirmar Contraseña',
      },

      edit_cupon_comment: {
        success: 'Bien! El comentario se ha actualizado 🎉',
        cupon: 'Cupón',
        comment: 'Comentario',
        comment_placeholder: 'Agregar comentario',
        close: 'Cancelar',
        submit: 'Guardar Cambios',
      },

      csv_errors: {
        cancel_option: 'Cancelar el proceso y descartar todos los registros ',
        continue_option: 'Continuar solo con los registros correctos',

        title: 'Problemas importando archivo csv',
        subtitle: 'Encontramos errores en los siguientes registros:',
        accept: 'Aceptar',
      },

      create_variant: {
        close: 'Cancelar',
        submit: 'Guardar',
        add: 'Agregar Producto',

        by_bulk: 'por bulto',
        presentation_by_bulk: '[Presentación] por bulto',
        is_bulk: 'Es producto a granel / por peso',
        weight_by: 'Peso por',
        weight_by_presentation: 'Peso por [Presentación]',
        unit_by: 'Unidad por',
        unit_by_presentation: 'Unidad por [Presentación]',
        unit_name: 'Nombre de Unidad',
        unit_name_placeholder: 'Seleccionar',

        name: 'Nombre del producto',
        name_placeholder: 'Producto',
        description: 'Descripción',
        description_placeholder: 'Descripción',
        brand: 'Marca',
        brand_placeholder: 'Marca',
        category: 'Categoría',
        category_placeholder: 'Categoría',
        subcategory: 'Subcategoría',
        subcategory_placeholder: 'Subcategoría',
        presentation: 'Presentación',
        presentation_placeholder: 'Presentación',
        unit_multiplier: 'Multiplicador',
        unit_multiplier_placeholder: 'Nº',
        units_per_pack: 'Unid. por pack',
        units_per_pack_placeholder: 'Nº',
        unit_measure: 'Peso por unidad final',
        unit_measure_placeholder: 'Nº',
        unit_measure_name: 'Unidad de peso',
        unit_measure_name_placeholder: 'Unid. peso',
        weight: 'Peso final por pack',
        weight_placeholder: 'Nº',
        is_lightweight: 'Es liviano',
        needs_cooling: 'Necesita carga de frío',
        preview: {
          title: 'Vista Previa, aplicación comedores',
          de: 'de',
          en: 'en',
          con: 'con',
        },
        tooltips: {
          external_reference:
            ' Identificación alfanumérica, puede corresponderse con ' +
            'el sistema de gestión si es que lo hay. ej.: 00012',
          product:
            'Es la identificación del producto que será visible en la tienda. ' +
            'Idealmente usa el mismo nombre que muestra el envase. ej.: Agua Mineral',
          description:
            'No es obligatorio completar. Agrega detalles sobre el producto. ej.: Sin Gas',
          brand:
            'Si aparece en el envase. Es importante este dato para facilitar la trazabilidad de los productos.',
          category_id: 'Grupo de productos al que pertenece.',
          unit_type:
            'Hace referencia a la unidad de consumo. ej.: botellas, vasitos (de yogur), paquetes (de yerba)',
          units_multiplier:
            'En la app lo que ofrecemos son unidades comercializables pero expresadas en unidades de consumo. ' +
            'Pero no decimos 1 pack de botellas, 2 packs de botellas, ' +
            '3 packs de botellas sino que decimos 6 botellas, 12, 18 (este sería el multiplicador).',
          units_per_pack:
            'Hace referencia a la unidad de comercialización o unidad de venta, es decir la unidad que no es divisible para ofrecer.\n' +
            'ej.: en un pack de 6 botellas hay 6 unidades por bulto. ' +
            'Si hablamos de un cajón de frutas o un bolsón de arroz las unidades serían: 1.',
          unit_measure:
            'Se refiere al peso dentro de cada unidad de consumo que está en la unidad de comercialización (o pack).',
          unit_measure_name:
            'Corresponde a la unidad con que se mide el peso de cada unidad de consumo. ' +
            'Se asume que la densidad es igual a 1 para poder calcular el precio por kg si es que aplica.',
          weight:
            'Se calcula automáticamente en función del peso por unidad final y la cantidad de unidades por pack.',
          light_weight:
            'Define si el bulto es liviano en relación a su volúmen, por ejemplo un paquete de snacks. ' +
            'Por defecto es no.',
          need_cooling:
            'Define si el producto debe conservarse a bajas temperaturas. Por defecto es no.',
        },
        validations: {
          required_product: 'Falta el producto',
          required_brand: 'Falta la marca',
          required_category: 'Falta la categoría',
          required_subcategory: 'Falta la subcategoría',
          numeric_units_multiplier: 'Debe ser número',
          positive_units_multiplier: 'Debe ser mayor a 0',
          integer_units_multiplier: 'Debe ser entero',
          required_unit_type: 'Debe especificar una presentación',
          numeric_units_per_pack: 'Debe ser número',
          required_units_per_pack: 'Debe especificar unidades por pack',
          positive_units_per_pack: 'Debe ser mayor a 0',
          integer_units_per_pack: 'Debe ser entero',
          numeric_unit_measure: 'Debe ser número',
          positive_unit_measure: 'Debe ser mayor a 0',
          required_unit_measure_name: 'Debe elegir unidad de medida',
          numeric_weight: 'Debe ser número',
          positive_weight: 'Debe ser mayor a 0',
          required_single_unit_measure_name:
            'Debe especificar un Nombre de la Unidad.',
        },
      },
      edit_variant: {
        title: 'Editar Producto',
      },

      create_stock: {
        step: 'Paso',
        of: 'de',
        create: 'Crear Lote',
        units: 'unidades',
        no_units: 'Sin data',

        cancel: 'Cancelar',
        previous: 'Anterior',
        next: 'Siguiente',
        submit: 'Guardar',

        category: 'Categoría:',
        min: 'Mínimo:',
        max: 'Máximo:',

        deposit: 'Depósito',
        name: 'Nombre del producto',
        name_placeholder: 'Nombre del producto',
        expiration: 'Vencimiento',
        expiration_placeholder: 'Seleccionar',
        stock: 'Stock',
        lote: 'Costo del lote',
        stock_placeholder: 'Nº',
        price: 'Precio por pack',
        price_placeholder: 'Nº',
        pickStock: {
          exp: 'Venc.',
          stock: 'Stock',
          stock_number: 'Lote #',
        },
        titles: {
          variant: 'Producto',
          quantity: 'Cantidad',
          stock: 'Lote',
        },
        validations: {
          not_zero: 'Debe haber al menos un máximo mayor a 0',
          only_numbers: 'Solo se admiten números',
          valid_fields: 'Todos los campos deben tener valor 0 o mayor',
          only_positive: 'Solo números positivos',
          required_product: 'Falta el producto',
          required_address: 'Falta el depósito',
          numeric_quantity: 'La cantidad debe ser un número',
          required_quantity: 'Falta la cantidad de lote',
          positive_quantity: 'La cantidad debe ser mayor a 0',
          integer_quantity: 'La cantidad debe ser entero',
          numeric_price: 'El precio debe ser un número',
          min_price: 'El precio debe ser mayor a 0',
          required_price: 'Falta el precio',
          required_groups: 'Se debe especificar al menos un máximo',
          required_date: 'Requiere fecha de expiración',
        },
        rescued: 'Producto rescatado',
      },
      create_payment_cupon: {
        title: 'Crear un cupón de pago',
        cancel: 'Cancelar',
        submit: 'Generar cupón de pago',
        close: 'Cerrar',

        subtitle:
          'Seleccioná qué método de pago querés que el comedor utilice para este cupón',
        rapi_pago: 'Rapipago',
        pago_facil: 'Pago Fácil',
        number: 'Número de cupón:',
      },

      confirmation: {
        close: 'CANCELAR',
        submit: 'CONFIRMAR',
      },

      assign_driver: {
        assign: 'Asigná un conductor a este viaje',
        close: 'Cancelar',
        submit: 'Confirmar conductor',
        success: 'Bien! El chofer se asignó correctamente 🎉',

        drivers: 'Choferes:',
        tooltip: 'Este/a conductor/a tiene un vehículo con carga de frío',
      },

      offer_name: {
        title: 'Guardar Ofrecimiento',
        subtitle:
          'Asigná un nombre al ofrecimiento para poder identificarlo en el listado de la aplicación.',
        name: 'Título del ofrecimiento',
        name_placeholder: 'Máximo 40 caracteres',
        close: 'CANCELAR',
        submit: 'CONFIRMAR',

        success: 'Se creó la oferta correctamente',
        validations: {
          max_name: 'Solo se pueden ingresar hasta 40 caracteres',
          required_name: 'Se debe escribir un título',
        },
      },
      edit_combo: {
        title: 'Ver Combo',
      },
      create_combo: {
        title: 'Crear Combo',
        identification: 'Identificación',
        composition: 'Composición Del Combo',
        add: 'Agregar otro producto',
        summary: 'Resumen del Combo',
        name: 'Nombre del Combo',
        description: 'Descripción',
        dense: 'Es denso',
        cooling: 'Require Frío',
        product: 'Seleccionar Producto',
        product_label: 'Producto',
        appName: 'Nombre a Mostrar',
        box: 'Caja',
        appName_label: 'Nombre en App',
        present_label: 'Pres.',
        dishes: 'Platos',
        presentation: 'Presentación',
        price: 'Precio de Venta',
        numberOfProducts: 'productos',
      },
      validation: {
        product: 'Requiere Producto',
        appName: 'Requiere Nombre a Mostrar',
        quantity: 'Requiere Cantidad',
        name: 'Requiere Nombre',
      },
      setting: {
        setting: 'Configuración',
        networks: 'Redes',
        users: 'Usuarios',
        country: 'País',
        districts: 'Distritos',
        reports: 'Reportes',
        traceability: 'Trazabilidad',
        institutionAndDonors: 'Instituciones y donantes',
        coupons: 'Cupones',
        orders: 'Órdenes',
        institutions: 'Instituciones',
        customerCategory: 'Categoría de clientes',
        typesOfBusiness: 'Tipos de negocio',
        channels: 'Canales',
      },
    },

    trips: {
      loading: 'Cargando viajes',
      no_trips: 'No hay viajes para este día.',
      all: 'Todos los viajes',
      origin: 'Origen/es:',
      destination: 'Destino/s:',

      types: {
        searching: 'Buscando chofer',
        confirmed: 'Confirmado',
        confirmed_subtitle: 'Chofer confirmado',
        cancelled: 'Cancelado',
        on_going: 'En viaje',
        completed: 'Finalizado',
        assigned: 'Chofer asignado',
        assigned_subtitle: 'Esperando confirmación',
        confirmed_driver: 'Confirmado',
        driver: 'Chofer: ',
      },

      prev: ' - Valor anterior: ',
      edited_by: '. Editado por ',
      at: 'el',

      constants: {
        fruits_or_vegetables: 'Frutas o verduras',
        non_perishable: 'No perecederos',
        fresh: 'Frescos y congelados',
      },

      sagas: {
        create_success: 'El viaje se creó exitosamente! 🎉',
        create_error: 'Error al crear el viaje',
        delete_success: 'El viaje se eliminó exitosamente',
        load_error: 'Hubo un error al cargar los viajes',
        server_error: 'Upss! Hubo un error en el servidor 😓.',
      },

      summary: {
        origin: 'Origen/es:',
        date: 'Día:',
        schedule: 'Horario:',
        destination: 'Destino/s:',
        has_multiple_destinations: 'Tiene múltiples destinos',
        driver: 'Chofer:',
        unassigned: 'Sin asignar',
        driver_pay: 'Cobro del chofer:',
        delivery_total: 'Valor del envío:',
        create_payment: 'Generar cupón de pago',
        pending_payment: 'Este cupón se encuentra pendiente de pago',
        payment_error: 'Ocurrio un error en el pago',
        payment_success: 'El pago fue realizado',
        payment_in_process: 'El pago esta en proceso',
        order: 'Pedido:',
        order_total: 'Total:',
        order_amount: 'Valor del pedido:',
        trip_id: 'Id del viaje:',
      },

      item_dropdown: {
        details: 'Ver detalle',
        edit: 'Editar viaje',
        delete: 'Eliminar viaje',
      },
      status: {
        pause: 'Pausar broadcasting',
        processing: 'Procensando viaje',
        assign_driver: 'Asignar chofer',
        broadcast: 'Hacer broadcast',
      },
    },

    drivers: {
      sagas: {
        error: 'Upss! Hubo un error 😓.',
        create_success: 'El chofer se ha creado correctamente 🎉',
        server_error: 'Upss! Hubo un error en el servidor 😓.',
        update_success: 'El chofer se ha actualizado correctamente 🎉',
        create_account_success: 'Se ha creado la cuenta correctamente 🎉',
        create_vehicle_success: 'El vehículo se ha creado correctamente 🎉',
      },

      go_back: '< Volver a choferes',
      loading: 'Cargando...',

      table: {
        name: 'Nombre',
        mail: 'E-mail',
        phone_number: 'Teléfono',
        verified: 'Activado',
        no_title: 'No hay choferes creados.',
        no_subtitle: 'Para crear uno nuevo tenés que hacerlo',
        no_moment: '(momentáneamente) desde Shippify',
        no_data: 'Sin data',
      },

      info: {
        enabled: 'Habilitado',
        disabled: 'No habilitado',
        no_email: 'Sin email',
        no_phone_number: 'Sin número',
        gender: 'Género:',
        birth_date: 'Fecha de nacimiento:',
        edit: 'Edit',
      },

      list: {
        drivers: 'Choferes',
        filters: 'Filtros',
        list: 'Listado de choferes',
        add: '+ Agregar nuevo chofer',
      },

      filter_list: {
        all: 'Todos',
        enabled: 'Habilitados',
        disabled: 'No habilitados',
      },

      detail: {
        vehicles: {
          sagas: {
            error: 'Upss! Hubo un error 😓.',
            create_success: 'El vehículo se ha creado correctamente 🎉',
            update_success: 'El vehículo se ha actualizado correctamente 🎉',
          },

          title: 'Vehículos',
          add: '+ Agregar nuevo vehículo',
          no: 'No hay vehículos para este chofer',
          create: 'Crear vehículo',
          edit: 'Editar',

          brand: 'Marca:',
          model: 'Modelo:',
          year: 'Año:',
          refrigerated: 'Carga de frío:',
          refrigerated_yes: 'Si',
          refrigerated_no: 'No',
          reviews_title: 'Habilitaciones',
          reviews: 'Ver las habilitaciones',
          has_all_reviews: 'Tiene todas las habilitaciones',
          does_not_have_all_reviews: 'No tiene todas las habilitaciones',
        },
        bank_accounts: {
          sagas: {
            error: 'Upss! Hubo un error 😓.',
            update_success: 'Se ha actualizado la cuenta correctamente 🎉',
          },
          title: 'Cuentas bancarias',
          add: '+ Agregar nueva cuenta bancaria',
          no: 'No hay cuentas para este chofer',
          create: 'Crear cuenta bancaria',
          edit: 'Editar',
          bank: 'Banco:',
          type: 'Tipo de cuenta:',
          number: 'Número de cuenta:',
          cbu: 'CBU:',
        },
        verifications: {
          title: 'Habilitaciones',
          driving_license: 'Tiene licencia de conducir',
          is_monotributista: 'Es monotributista',
          has_cuit_or_cuil: 'Tiene CUIT o CUIL',
          has_banking_account: 'Tiene cuenta bancaria',
          has_paypal_account: 'Tiene cuenta en PayPal',
          habilitation_transport_food: 'Puede transportar comida',
          sanitary_notepad: 'Tiene libreta sanitaria',
        },
      },
    },

    reports: {
      title: 'Reportes',
      from: 'Desde:',
      to: 'Hasta:',
      download: 'Descargar reporte',
    },

    notifications: {
      close: 'Cancelar',
      submit: 'Guardar',
      previous: 'Anterior',
      next: 'Siguiente',
      sagas: {
        aprox: 'Aproximadamente',
        users: ' usuarios serán notificados',
        get_error: 'Error al buscar notificaciones',
        confirm: '¿Enviar mensaje?',
        sent: 'Se enviaron ',
        notifications: ' notificaciones!',
        create_error: 'Hubo un error al crear la notificación',
        get_audience_type_error:
          'Hubo un error al buscar los tipos de destinatarios',
        get_audience_error: 'Hubo un error al buscar los destinatarios',
        excel_error: 'Ups ocurrió un error',
      },
      message_title: 'Mensaje',
      receivers_title: 'Destinatarios',
      step: 'Paso',
      of: 'de',
      notifications_title: 'Notificaciones',

      audience: 'Audiencia',
      audience_placeholder: 'Seleccionar aplicación',

      empty_notifications_message: 'Sin notificaciones para mostrar.',

      message_type: 'Mensaje en la aplicación',
      notification_message_type: 'Notificación',
      receivers: 'Destinatarios',

      empty_institutions_notifications: 'No hay notificaciones para mostrar.',

      soup_kitchen_app_label: 'App Comedores',
      drivers_app_label: 'App Choferes',

      title: 'Notificaciones',
      send_notification: 'Enviar Notificación',

      message: {
        title: 'Título de la notificación:',
        title_placeholder: 'máximo 1024 caracteres',
        text: 'Texto de la notificación:',
        text_placeholder: 'máximo 1024 caracteres',
        includes_in_app_message: 'Incluir un mensaje dentro de la aplicación',
        in_app_title: 'Título del mensaje:',
        in_app_title_placeholder: 'máximo 1024 caracteres',
        in_app_text: 'Texto del mensaje:',
        in_app_text_placeholder: 'máximo 1024 caracteres',
      },

      audience_table: {
        receivers_label: 'Destinatarios',
        text: 'Seleccioná la audiencia para que podamos mostrarte los usuarios de cada aplicación',
        user_column: 'Usuario',
        user_column_placeholder: 'Nombre...',
      },
      validations: {
        blank_title: 'Se debe escribir un título',
        max_title_length: 'Solo se pueden ingresar hasta 1024 caracteres',
        blank_body: 'Se debe escribir un mensaje',
        max_body_length: 'Solo se pueden ingresar hasta 1024 caracteres',
        in_app_title_max_length:
          'Solo se pueden ingresar hasta 1024 caracteres',
        in_app_body_max_length: 'Solo se pueden ingresar hasta 1024 caracteres',
        blank_receiver: 'Falta elegir destinatarios',
        min_receiver: 'Debe haber al menos un destinatario',
        blank_service: 'Falta el destino',
      },
    },

    orders: {
      sagas: {
        delivery_error:
          'La institución destinataria no está habilitada para el servicio de flete',
        create_error: 'Hubo un error al crear la orden. ',
        create_success: 'La orden se creó exitosamente! 🎉',
        download_error: 'Hubo un error al descargar el detalle de productos',
        server_error: 'Upss! Hubo un error en el servidor 😓.',
        delete_message:
          'Se eliminarán todos los datos asociados a esta orden. La acción no se puede deshacer.',
        delete_order: 'Eliminar Orden',
        delete_error: 'No es posible eliminar la orden',
        delete_success: 'La orden se eliminó exitosamente! 🎉',
        edit_error:
          'No es posible procesar los cambios, puede ser que la orden haya sido eliminada.',
        date_success: 'La fecha se cambió exitosamente',
        edit_payment_message:
          'Esta acción modificará el importe de la orden. ¿Desea continuar de todas formas?',
        edit_payment: 'Guardar Cambios',
        edit_order_error: 'Hubo un error al editar la orden: ',
        edit_order_success: 'La orden se edito exitosamente! 🎉',
        obsolete: 'Marcar Cupón como Obsoleto ',
        obsolete_message:
          ' ¿Confirmar acción? El cupón será eliminado permanentemente. ',
        delete_payment_error: '✋ Hubo un error al eliminar el pago',
        delete_payment_success: 'El pago se eliminó corrrectamente!🎉🎉🎉🎉',
        create_payment_error: '✋ Hubo un error al crear el pago',
        create_payment_success: 'El pago se creó corrrectamente!🎉🎉🎉🎉',
        upload_error: 'Debe ser un archivo Excel',
        upload_excel_success: 'Se importó con éxito',
        upload_excel_error: 'Ups, ocurrió un error en el servidor',
      },

      manual: {
        destination: 'Destino',
        goods: 'Mercadería',
        morning: 'Mañana',
        afternoon: 'Tarde',
        fruits_or_vegetables: 'Frutas o verduras',
        non_perishable: 'No perecederos',
        fresh: 'Frescos y congelados',

        step: 'Paso ',
        from: 'de',
        close: 'Cancelar',
        next: 'Siguiente',
        previous: 'Anterior',
        submit: 'Confirmar',

        step_1: {
          date: 'Fecha de entrega',
          time: 'Horario',
          time_placeholder: 'Turno',
          giver: 'Punto de recolección',
          giver_placeholder: 'Seleccionar depósito',
          receiver: 'Punto de entrega',
          receiver_placeholder: 'Seleccionar institución',
        },
        step_2: {
          needs_refrigeration: 'Necesita carga de frío',
          total_weight: 'Peso total:',
          order_value: 'Valor del pedido:',
          discount: 'Descuento asignado: ',
          total_price: 'Precio total: ',
          delivery_value: 'Valor del envío:',
        },
      },
      from_stock: {
        morning: 'Mañana',
        afternoon: 'Tarde',
        no_delivery: 'Sin entrega',
        paypal: 'Paypal',
        rapi_pago: 'Rapi pago',
        pago_facil: 'Pago facil',
        other: 'Otro',

        weight_by_unit: 'Peso por unidad',
        total_weight: 'Peso total',
        price_per_unit: 'Precio por unidad',
        total_price: 'Precio total',

        step_1: {
          step: 'Paso',
          from: 'de',
          close: 'Cancelar',
          next: 'Siguiente',
          destination: 'Destino',
          delivery_date: 'Fecha de entrega',
          schedule: 'Horario',
          schedule_placeholder: 'Turno',
          receiver: 'Beneficiario',
          receiver_placeholder: 'Seleccionar institución',

          validations: {
            date: 'Fecha de entrega',
            required_delivery_preference:
              'Se debe especificar un turno horario',
            required_delivery_preference_label: 'Turno',
            required_receiver:
              'Se debe especificar una institución que reciba la orden',
            required_receiver_label: 'Punto de recolección',
          },
        },
        step_2: {
          step: 'Paso',
          title: 'Seleccionar mercaderia',
          from: 'de',
          close: 'Atras',
          submit: 'Crear órden',
          next: 'Siguiente',
          origin: 'Origen de artículos',
          origin_placeholder: 'Seleccionar depósito',
          payment_method_label: 'Método de pago',
          payment_method: 'Seleccionar Metodo de pago',
          order_value: 'Valor pedido:',
          discount: 'Descuento asignado: ',
          total_price: 'Precio total: ',
          delivery_value: 'Valor envio: ',
          notify_institution: 'Notificar a la institución',

          validations: {
            payment_method: 'Metodo de pago',
            numeric_delivery_amount: 'El precio debe ser un número',
            min_delivery_amount: 'El precio debe ser mayor a 0',
            required_delivery_amount: 'Falta el precio',
            delivery_amount: 'Valor envio: ',
          },
        },

        selected: {
          no: 'Ningun producto agregado',
          product: 'Producto',
          quantity: 'Cantidad',
          added: 'Productos agregados a la orden',
        },

        product_adder: {
          product: 'Producto:',
          product_placeholder: 'Seleccionar producto',
          add: 'Agregar',
          quantity: 'Cantidad',

          validations: {
            product: 'Producto',
            quantity: 'Cantidad',
            max_stock: 'Debe ingresar una cantidad disponible',
          },
        },
      },

      tooltip: {
        product: 'Producto',
        quantity: 'Cantidad',
        preview: 'Vista rápida',
        delivery_date: 'Fecha de entrega:',
        schedule: 'Turno:',
        origin: 'Origen',
        loading: 'Cargando...',
        error: 'Se produjo un error al cargar los productos',
      },

      list: {
        morning: 'Turno Mañana',
        afternoon: 'Turno Tarde',
        without_delivery: 'Sin Flete',
        client: 'Cliente:',
        loading: 'Actualizando órdenes',
        manual_order: 'Asignar kg manualmente',
        from_stock: 'Seleccionar productos del stock',
        title: 'Órdenes',
        add: 'AGREGAR ÓRDEN',
        import: 'IMPORTAR ÓRDENES',
        export: 'EXPORTAR DETALLE',
        new_trip: 'Nuevo viaje',
        new_trip_subtitle:
          ' Agregá las órdenes que quieras para crear un viaje.',
        refrigerated: 'Necesita carga de frío',
        create_trip: 'Crear viaje',

        origin: 'Origen: ',
        no: 'No hay órdenes',
        deleted: 'Eliminadas',
      },

      detail: {
        turn: 'Schedule',
        product: 'Producto',
        description: 'Descripción',
        brand: 'Marca',
        variant: 'Lote',
        expiration: 'Vencimiento',
        presentation: 'Presentación',
        unit: 'Unidad',
        ordered: 'Pedido',
        morning: 'Turno Mañana',
        afternoon: 'Turno Tarde',
        no: 'No hay productos en esta orden',
        edit: 'EDITAR',
        date: 'Fecha',
        products: 'producto/s',
        close: 'CANCELAR',
        submit: 'GUARDAR CAMBIOS',
        created_by: 'Creada por:',
        delivery_date: 'Fecha de Entrega:',
        delivery_preference: 'Turno:',
        origin: 'Orígen',
        origins: 'es',
        address: 'Dirección',
        addresses: 'es',
        institution: 'Institución:',
        order: 'Pedido:',
        no_payment: 'No hay cupon de compra',
        delivery: 'Flete:',
        no_delivery: 'No hay cupon de viaje',
        delete_order: 'ELIMINAR ORDEN',
        deleted_order: 'Orden Eliminada',
        by: 'por',
        edited: 'Editado',
        previous: 'Anterior:',
        total_price: 'Costo total',
        discount: 'Descuento del producto',
      },

      edit_payment: {
        delete: 'Eliminar cupon',
        create: 'Generar cupon',
      },
      edit_product: {
        add: 'AGREGAR PRODUCTO',
        validations: {
          number: 'Debe ser número',
          positive_number: 'Debe ser mayor a 0',
          integer_number: 'Debe ser entero',
        },
      },
    },

    institutions: {
      loading: 'Cargando institución',
      sagas: {
        server_error: 'Upss! Hubo un error en el servidor 😓.',
        get_institution_error: 'Hubo un error al cargar la institución ✋',
        create_success: 'La institución ha sido creada con éxito! 🎉',
        create_error: 'Hubo un error al crear la institución ✋',
        update_error: 'Hubo un error al actualizar la institución 😓',
        update_success: 'La institución ha sido actualizada con éxito! 🎉',
        delete_user_success: 'El usuario se eliminó exitosamente',
        get_districts_error: 'Upss! Hubo un error al cargar los distritos 😓.',
        get_categories_error:
          'Upss! Hubo un error al cargar las categorias 😓.',
        update_payment_error: 'Hubo un error al actualizar el cupón ✋',
        update_payment_success: 'El cupón se ha actualizado correctamente 🎉',
        excel_error: 'Ups ocurrió un error en el servidor',
        upload_excel_success: 'Se importó con éxito',
        upload_excel_error: 'Debe ser un archivo Excel',
      },

      titles: {
        data: 'Datos de la institución',
        location: 'Ubicación',
        contact: 'Contacto',
        services: 'Servicio',
      },
      days: {
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
        sunday: 'Domingo',
      },
      services: {
        lunch: 'Almuerzo',
        breakfast: 'Desayuno',
        snack: 'Merienda',
        dinner: 'Cena',
        foodBag: 'Bolsa de alimentos',
      },
      payment_methods: {
        rapi_pago: 'Rapi pago',
        pago_facil: 'Pago facil',
        other: 'Otro',
        no: 'Ninguno',
      },
      payment_status: {
        obsolete: 'Obsoleto',
        cancelled: 'Cancelado',
        expired: 'Vencido',
        pending: 'Pendiente',
        approved: 'Pagado',
      },
      organization: 'Beneficiario',
      company: 'Donante',
      no_blank: 'No puede estar vacio',
      already_used: 'Ya está en uso.',

      create: {
        title: 'Agregar nueva institución',
        step: 'Paso',
        of: 'de',
        close: 'Cancelar',
        next: 'Siguiente',
        previous: 'Anterior',
        submit: 'Confirmar',
        required: '* campos obligatorios',
        map_comment:
          'Para marcar un punto exacto, podés hacer click en el mapa',
        titles: {
          data: 'Datos de la institución',
          address: 'Dirección de entrega',
          residence: 'Dirección de residencia',
          contact: 'Contacto',
          service: 'Servicio',
        },
        step_1: {
          category: 'Tipo de entidad',
          category_placeholder: 'Seleccionar',
          name: 'Nombre',
          name_placeholder: 'Identificación',
          number: 'Número',
          number_placeholder: 'Nº',
          legal_name: 'Nombre Legal',
          legal_name_placeholder: 'Razón Social',
          cuit: 'CUIT',
          cuit_placeholder: 'Sin espacios ni guiones',
        },
        step_2: {
          address: 'Dirección: *',
          country: 'País',
          country_placeholder: 'País',
          state: 'Provincia',
          state_placeholder: 'Provincia',
          city: 'Ciudad',
          city_placeholder: 'Ciudad',
          zip_code: 'Código Postal',
          zip_code_placeholder: 'Código Postal',
          district: 'Distrito',
          district_placeholder: 'Seleccionar',
          notes: 'Notas',
          notes_placeholder: 'Comentarios sobre la ubicación',
          delivery_rate: 'Costo del flete:*',
          delivery_rate_placeholder: '$',
          payment_method: 'Forma de pago preferida:',
          payment_method_placeholder: 'Seleccionar',
          min_ticket_price: 'Ticket minimo de compra',
        },
        step_3: {
          address: 'Dirección: *',
          country: 'País',
          country_placeholder: 'País',
          state: 'Provincia',
          state_placeholder: 'Provincia',
          city: 'Ciudad',
          city_placeholder: 'Ciudad',
          zip_code: 'Código Postal',
          zip_code_placeholder: 'Código Postal',
          notes: 'Notas',
          notes_placeholder: 'Comentarios sobre la ubicación',
        },
        step_4: {
          contact: 'Persona de Contacto',
          contact_placeholder: 'Nombre y Apellido',
          phone: 'Teléfono',
          phone_placeholder: 'Número de contacto',
          mail: 'Email',
          mail_placeholder: 'Correo electrónico',
          responsible: 'Responsable Legal',
          responsible_name: 'Nombre Completo',
          responsible_name_placeholder: 'Nombre y Apellido',
          dni: 'DNI',
          dni_placeholder: 'Sin espacios ni puntos',
          responsible_phone: 'Teléfono',
          responsible_phone_placeholder: 'Número de contacto',
          responsible_mail: 'Email',
          responsible_mail_placeholder: 'Correo electrónico',
        },
        step_5: {
          order_days: 'Día en que realiza el pedido',
          order_days_placeholder: 'Seleccionar',
          open_hours: 'Horario',
          beneficiaries: 'Beneficiarios',
          beneficiaries_placeholder: 'Nº',
          services: 'Servicios',
          category: 'Categoría',
          category_placeholder: 'Seleccionar',
        },
      },

      payments_detail: {
        no: 'No hay cupones de pago generados',
        sagas: {
          get_balance_error: 'Hubo un error al traer las deudas',
          get_payee_error:
            'Hubo un error al traer la información del cobrador del pago',
          get_payments_error: 'Hubo un error al traer los cupones',
          create_success: 'El cupón se creo exitosamente',
          create_error: 'Hubo un error al crear el cupón',
          obsolete: 'Obsolescer pago',
          obsolete_message: 'Estás seguro que querés volver el pago obsoleto?',
          obsolete_success: 'El pago se ha obsoleto exitosamente ',
          obsolete_error: 'Hubo un error al hacer el pago obsoleto',
        },
        table: {
          date: 'Fecha',
          payment: 'Cupón',
          system: 'Sistema',
          amount: 'Importe',
          status: 'Estado',
          payment_date: 'Fecha de Pago',
          payment_code: 'Código de Pago',
          collected_amount: 'Monto Abonado',
          comments: 'Comentarios',
          mark_as_payed: 'Marcar como pagado',
          edit_comment: 'Editar Comentario',
          mark_obsolete: 'Hacer cupón obsoleto',
        },
        payment_status: {
          pending: 'Pendiente',
          approved: 'Pagado',
          rejected: 'Rechazado',
          in_process: 'En proceso',
          error: 'Error',
        },
      },

      info_detail: {
        name: 'Nombre',
        surname: 'Apellido',
        mail: 'Email',
        phone: 'Teléfono',
        app: 'Aplicación',
        edit_user: 'Editar usuario',
        delete_user: 'Eliminar usuario',
        coming_soon: '(Próximamente)',

        general_info: {
          type: 'Tipo:',
          legal_name: 'Nombre legal:',
          cuit: 'CUIT',
          contact: 'Contacto:',
          phone: 'Teléfono:',
          mail: 'Email:',
          order_date: 'Día en que realiza el pedido:',
          last_order: 'Fecha último pedido:',
          schedule: 'Horario:',
          residence: 'Dirección de residencia:',
          notes: 'Notas:',
          total_debt: 'Balance:',
          client_business_category: 'Categoria de negocio del cliente',
          client_business_channel: 'Canal de negocio del cliente',
          client_business_type: 'Tipo de negocio del cliente',
        },

        location: {
          no: 'Ninguno',
          notes: 'Notas:',
          address: 'Dirección de entrega:',
          district: 'Distrito:',
          delivery_rate: 'Costo del flete:',
          payment_method: 'Forma de pago preferida:',
          residence: 'Forma de pago preferida:',
          residence_address: 'Dirección de residencia:',
        },

        specific_info: {
          beneficiaries: 'Beneficiarios',
          services: 'Servicios:',
          weekly_rations: 'Raciones x semana:',
          coming_soon: '(Próximamente)',
          category: 'Categoría:',
        },

        users: {
          no: 'No hay usuarios para la aplicación',
          user: 'usuario',
          users: 'usuarios',
          add: 'Agregar Usuario',
        },
      },

      detail: {
        info: 'Información',
        payments: 'Cupones',
        loading: 'Cargando detalle',
        back: 'Volver a Instituciones',
        edit: 'Editar Información',
      },

      edit: {
        back: 'Volver a la institución',
        close: 'Cancelar',
        save: 'Guardar',

        loading: 'Cargando datos',
        data: 'Datos de la entidad',

        category: 'Tipo de entidad',
        category_placeholder: 'Seleccionar',
        name: 'Nombre',
        name_placeholder: 'Identificación',
        number: 'Número',
        number_placeholder: 'Nº',
        legal_name: 'Nombre Legal',
        legal_name_placeholder: 'Razón Social',
        cuit: 'CUIT',
        cuit_placeholder: 'Sin espacios ni guiones',

        location: 'Ubicación',
        ticket_min_placeholder: 'Ticket mínimo de compra',
        min_ticket_price: 'Ticket mínimo de compra',
        delivery_address: 'Dirección de entrega: *',
        district: 'Distrito',
        district_placeholder: 'Seleccionar',
        notes: 'Notas',
        notes_placeholder: 'Comentarios sobre la ubicación',
        delivery_rate: 'Costo del flete:*',
        delivery_rate_placeholder: '$',
        payment_method: 'Forma de pago preferida:',
        payment_method_placeholder: 'Seleccionar',

        residence: 'Dirección de residencia: *',
        residence_notes: 'Notas',
        residence_notes_placeholder: 'Comentarios sobre la ubicación',

        contact_title: 'Contacto',

        contact: 'Persona de Contacto',
        contact_placeholder: 'Nombre y Apellido',
        phone: 'Teléfono',
        phone_placeholder: 'Número de contacto',
        mail: 'Email',
        mail_placeholder: 'Correo electrónico',

        service: 'Servicio',

        order_days: 'Día en que realiza el pedido',
        order_days_placeholder: 'Seleccionar',
        open_hours: 'Horario',
        beneficiaries: 'Beneficiarios',
        beneficiaries_placeholder: 'Nº',
        services: 'Servicios',
        service_category: 'Categoría',
        service_category_placeholder: 'Seleccionar',

        required: '* campos obligatorios',
      },

      list: {
        number: 'N°',
        name: 'Nombre',
        no_name: 'Sin nombre',
        district: 'Distrito',
        address: 'Dirección',
        no_address: 'Sin info',
        users: 'Usuarios',
        last_order: 'Último Pedido',
        debt: 'Adeudado',
        with_delivery: 'Con Flete',
        without_delivery: 'Sin Flete',
        recurrent: 'Comedor recurrente',
        excelInstitution: 'importar instituciones',
        beneficiaries: 'Beneficiarios',
        companies: 'Donantes',
        loading: 'Cargando instituciones',

        no: 'No hay instituciones disponibles',
        institution: 'institución',
        institutions: 'instituciones',

        no_created: 'No hay instituciones creadas.',
        no_created_subtitle: 'Podés crear una nueva desde',
        no_here: 'acá',

        search: 'Buscar',
        add: 'Agregar institución',

        no_data: 'Sin data',
        filters: {
          show: 'Mostrar Filtros',
          hide: 'Ocultar Filtros',
          enable: 'Habilitada',
          pending: 'En Revisión',
          disabled: 'No Habilitada',
          week: 'Última Semana',
          month: 'Último Mes',
          last6Months: 'Últimos 6 Meses',
          specific: 'Fecha Específica',
          status: 'Estado',
          order: 'Fecha Última Orden',
          placeholder: 'Todos',
          start: 'Desde',
          end: 'Hasta',
        },
      },
      mapCard: {
        beneficiaries: 'Beneficiarios',
        status: 'Estado',
        last_order: 'Última Orden',
        created_at: 'Alta',
      },

      cupons: {
        cupon: 'cupón',
        cupons: 'cupons',
        create: 'Crear cupón',
        no: 'No hay cupones generados',
        loading: 'Cargando cupones',
      },
    },

    stock: {
      product: 'Producto',
      stock: 'Lote',

      archiveStock: 'Archivar Stock',

      sagas: {
        export_csv_error: 'Hubo un error al descargar el csv de productos',
        offer_error: 'Hubo un error al buscar los artículos ofertables',
        csv_download_error: 'Hubo un error al descargar el csv de lotes',
        import_error: 'Hubo un error al descargar la planilla de importacion',
        imported: 'Se importaron',
        elements: 'elementos',
        import_csv_error: 'Hubo un error al importar el csv',
        get_stock_error: 'Hubo un error al traer el stock ✋',
        update_product_error: 'Hubo un error al actualizar el producto ✋',
        update_product_success: 'El producto se actualizó correctamente',
        revise_product_error: 'Hubo un error al revisar el producto ✋',
        revise_product_success: 'El producto se pudo revisar correctamente',
        create_product_error: 'Hubo un error al crear el producto ✋',
        create_product_success: 'El producto se pudo agregar correctamente',
        create_stock_error: 'Hubo un error al crear el stock ✋',
        create_stock_success: 'El stock se pudo agregar correctamente',
        update_variant_success: 'El lote se actualizó correctamente',
        get_offers_error: 'Hubo un error al traer las ofertas ✋',
        get_products_error: 'Hubo un error al traer los productos ✋',
        create_offer_error: 'Hubo un error al crear la oferta ✋',
        create_offer_success: 'Se pudo crear la oferta correctamente',
        cancel_offer_error: 'Hubo un error al cancelar la oferta ✋',
        cancel_offer_success: 'Se pudo cancelar la oferta correctamente',
      },

      add_button: {
        product: 'Producto',
        stock: 'Lote',
        import: 'Importar csv',
        add: 'AGREGAR',
      },

      catalog: {
        id: 'Cod',
        name: 'Nombre',
        description: 'Descripción',
        brand: 'Marca',
        category: 'Categoría',
        sub_category: 'Sub-Categoría',
        presentation: 'Presentación',
        weight: 'Peso final (por pack)',
        unit: 'Unidad de peso',
        add: 'Agregar producto',
        combo: 'Crear Combo',
      },
      combos: {
        add: 'Crear Combo',
        table: {
          code: 'Código',
          active: 'Activo',
          name: 'Nombre',
          description: 'Descripción',
          items: '# Variants',
          totalWeight: 'Peso Total',
          dishesPerCombo: 'Platos/Combo',
          unit_type: 'Presentación',
          stock: 'Stock Actual',
          price: 'Precio',
          requiresCold: 'Require Frío',
        },
      },

      list: {
        actual: 'Stock Actual',
        offers: 'Ofrecimientos',
        historic: 'Historico',
        combos: 'Combos',
        catalog: 'Catalogo',
        loading: 'Cargando stock',

        product: 'Producto',
        description: 'Descripción',
        brand: 'Marca',
        reference: 'Lote',
        expiration: 'Vencimiento',
        presentation: 'Presentación',
        unit: 'Unidad',
        origin: 'Depósito',
        rescued: 'Rescatado',
        stock: 'Stock',
        price: 'Precio',
        price_lote: 'Costo del lote',
        edit_reference: 'Editar Lote',
        revise_product: 'Editar Producto',
        released_product: 'Ver Productos Liberados',
      },

      tabs: {
        no: 'No hay productos disponibles',
        product: 'producto',
        products: 'productos',
      },

      export_button: {
        product: 'Productos',
        stock: 'Lotes',
        export: 'EXPORTAR',
      },

      offers: {
        create: 'CREAR OFRECIMIENTO',
        no: 'No hay ofertas disponibles',
        product: 'producto',
        products: 'productos',
        offer: 'oferta',
        offers: 'ofertas',
        loading: 'Cargando...',
        orders: 'Ordenes',
        back: 'Volver a Ofrecimientos',

        table: {
          product: 'Producto',
          stock: 'Stock',
          from_to: 'Entrega desde/hasta',
          remaining: 'Tiempo Restante',
          expiration: 'Vence:',
          price: 'Precio:',
          orders: 'ÓRDENES',
          details: 'Ver Detalle',
          cancel: 'Cancelar Ofrecimiento',
        },
      },

      create_offer: {
        close: 'Cancelar',
        previous: 'Anterior',
        next: 'Siguiente',
        submit: 'Guardar',

        step: 'Paso',
        of: 'de',

        outside: 'Fuera de depósito',
        inside: 'Depósito red',

        titles: {
          select_products: 'Seleccionar productos',
          select_destination: 'Seleccionar destinatarios',
          end: 'Fin de la Oferta',
          deliveries: 'Entrega de los pedidos',
        },

        select_products: {
          origin: 'Origen',
          origin_placeholder: 'Origen de artículos',
          product: 'Producto',
          price: 'Precio x kilo',
          no_stock: 'No hay stocks para ofertar',
          search: 'Buscar',
          add_product: '+ AGREGAR PRODUCTO',
        },

        delivery_date: {
          date: 'Fecha',
          start_time: 'Hora inicio',
          end_time: 'Hora fin',
        },

        end: {
          date: 'Fecha',
          time: 'Hora',
        },

        institution: {
          institution: 'Institución',
          institution_placeholder: 'Nombre...',
          district: 'Distrito',
          district_placeholder: 'Distrito...',
          order: 'Pedido',
          order_placeholder: 'Día...',
        },

        validations: {
          required_origin: 'Debe especificar origen de artículos',
          min_stocks: 'Debe especificar al menos 1 artículo',
          required_stocks: 'Debe especificar artículos',
          empty_stocks: 'El selector no puede quedar vacío',
          numeric_price: 'Debe ser un número',
          min_price: 'La cantidad debe ser mayor o igual a 0',
          min_institution:
            'Debe haber al menos 1 institución que pueda aplicar',
          required_institution: 'Debe especificar instituciones',
          valid_end_date: 'La fecha debe ser posterior o igual a la de hoy',

          required_end_date: 'Debe especificar una fecha de fin de oferta',
          required_end_hour: 'Seleccione una hora posterior a ',
          valid_start_date: 'La fecha debe ser posterior o igual a la de hoy',
          required_start_date:
            'Debe especificar una fecha de entrega de pedido',
          required_start_time: 'Debe especificar una hora de entrega de pedido',
          valid_start_time: 'Seleccione una hora posterior a ',
          valid_end_time: 'La hora debe ser posterior a la de comienzo',
          required_end_time: 'Debe especificar una hora de entrega de pedido',
        },
      },
    },
    users: {
      sagas: {
        delete_message:
          'Se eliminará todos los datos asociados a este usuario. La acción no se puede deshacer.',
        delete_network: 'Eliminar usuario',
        delete_error: 'No es posible eliminar el usuario',
        delete_success: 'Se eliminó correctamente',
        server_error: 'Upss! Hubo un error en el servidor 😓.',
        edit_message:
          'Èsta accion editará el usuario, desea continuar de todas formas?',
        edit: 'Guardar cambios',
        edit_user_error: 'Ocurrió un error al editar el usuario',
        edit_user_success: 'el usuario se edito exitosamente! 🎉',
      },
      users: {
        title: 'Usuarios',
        create: 'Crear nuevo usuario',
        no_data: 'No hay data',
      },
      column: {
        name: 'Nombre de usuario',
        email: 'Email',
        networks: 'Redes',
        roles_mask: 'Rol del usuario',
        edit: 'Editar',
        delete: 'Eliminar',
      },
      create_users: {
        username: 'Nombre de usuario',
        email: 'Email',
        password: 'Contraseña',
        confirm_password: 'Confirmar contraseña',
        networks: 'Redes',
        user_role: 'Rol del usuario',
        user_admin: 'Usuario admin',
        user_no_admin: 'Usuario no admin',
        save: 'Guardar',
        username_error: 'Es requerido el nombre del usuario',
        email_error: 'Es requerido el Email',
        password_error: 'Es requerida la contraseña',
        confirm_password_errors: 'Debes confirmar la contraseña',
        networks_options_error: 'Es requerida una red',
        checked_password: 'Las contraseñas deben coincidir',
        role_error: 'Es requerido un rol',
        back: 'Volver a usuarios',
        create: 'Crear usuario',
      },
      update_users: {
        back: 'Volver a usuarios',
        title: 'Editar usuario',
        change_password: 'Cambiar contraseña (opcional)',
      },
    },
    networks: {
      sagas: {
        delete_message:
          'Se eliminará todos los datos asociados a esta red. La acción no se puede deshacer.',
        delete_network: 'Eliminar red',
        delete_error: 'No es posible eliminar la red',
        delete_success: 'Se eliminó correctamente',
        server_error: 'Upss! Hubo un error en el servidor 😓.',
        edit_message:
          'Èsta accion editará la red, desea continuar de todas formas?',
        edit: 'Guardar cambios',
        edit_net_error: 'Ocurrió un error al editar la red',
        edit_net_success: 'La red se edito exitosamente! 🎉',
        create: 'Creada correctamente 🎉!!',
        update: 'Actualizada correctamente 🎉!!',
      },
      networks: {
        no_data: 'No hay data',
        no_title: 'No hay redes creadas',
        no_subtitle: 'Debes crear una nueva red',
        create_network: 'Crear nueva red',
        title: 'Redes',
        edit: 'Editar red',
      },
      column: {
        name: 'Nombre',
        country_code: 'Código del país',
        active: 'Activo',
        visible: 'Visible',
        min_ticket: 'Precio mínimo de ticket',
        phone_country_code: 'Código de teléfono',
        lat: 'Latitud',
        lng: 'Longitud',
        created: 'Creado',
        update: 'Actualizado',
        edit: 'Editar',
        delete: 'Eliminar ',
      },
      create_networks: {
        active: 'Activo',
        status: 'Estatus:',
        visible: 'Visible',
        save: 'Guardar',
        network_name: 'Nombre de la red',
        country_code: 'Id de la red',
        country: 'País',
        min_ticket_price: 'Precio mínimo del boleto',
        code_phone: 'Código de teléfono',
        lat_lng: 'Latitud -34.4714027, Longitud 58.8950115',
        back: 'Volver a redes',
        create: 'Crear red',
        name_error: 'El nombre de la red es requerido',
        network_id_error: 'El id de la red es requerido',
        price_error: 'El precio del ticket es requerido',
        country_error: 'País es requerido',
      },
    },
    districts: {
      create_districts: {
        title: 'Distritos',
        name: 'Nombre del distrito',
        code: 'Código',
        save: 'Guardar',
        create: 'Crear distritos',
        back: 'Volver a distritos',
        edit: 'Editar distrito',
      },
    },
    country: {
      sagas: {
        delete_message:
          'Se eliminarán todos los datos asociados a este país. La acción no se puede deshacer.',
        delete_country: 'Eliminar País',
        error_message: 'Uups, ha ocurrio un error',
        create: 'Creado correctamente!!',
        update: 'Actualizado correctamente!!',
        delete: 'Se eliminó correctamente!!',
      },
      country: {
        title: 'Países',
        create: 'Crear nuevo país',
        edit: 'Editar país',
      },
      column: {
        name: 'Nombre',
        currency: 'Moneda',
        gateway: 'Vía',
        phone_country_code: 'Código de teléfono',
        preferred_payment_method: 'Método de pago',
        created: 'Creado',
        update: 'Actualizado',
      },
      create_country: {
        title: 'Crear país',
        name: 'Nombre del país',
        currency: 'Moneda',
        back: 'Volver a países',
        payment_method: {
          payment: 'Métodos de pago:',
          rapipago: 'rapipago',
          pagofacil: 'pagofacil',
          other: 'otro',
        },
        code_phone: 'Código telefónico (Ej: 54)',
        gateway_method: {
          gateway: 'Vía',
          paypal: 'paypal',
          mercadopago: 'Mercadopago',
        },
        save: 'Guardar',
        delete: 'Eliminar',
        edit: 'Editar',
        name_error: 'Nombre de país es requerido',
        currency_error: 'La moneda es requerida',
        phone_error: 'Código de teléfono es requerido',
        gateway_error: 'Vía es requerido',
      },
    },
    orden_create: 'Crear orden',
    upload_order: 'Porfavor seleccioná qué tipo de acción querés realizar',
    import_excel: 'Importar desde archivo excel',
  },
};
