import { call, put, takeEvery, select } from 'redux-saga/effects';

import { put as _put, post } from 'api-connect/dist/api';

import * as actions from './actions';
import * as alertActions from 'modules/alerts/store/actions';
import * as modalSelectors from 'modules/modals/store/selectors';
import * as modalActions from 'modules/modals/store/actions';

import { normalize } from 'utils';

import { buildVehicle } from '../helpers/helpers';
import i18n from '../../../app/configs/i18n';

export function* createWorker(action) {
  yield put(actions.create.start());

  const vehicle = buildVehicle(action.payload);
  const { shipperId: shipper_id } = yield select(modalSelectors.data);

  const { res, error } = yield call(post, 'vehicles', {
    ...vehicle,
    shipper_id,
  });

  if (error) {
    yield put(modalActions.closeModal());
    yield put(
      alertActions.openAlert({
        message: i18n.t('drivers.detail.vehicles.sagas.error'),
        type: 'error',
      })
    );
    yield put(actions.create.failure({ error }));
  }

  yield put(actions.create.success({ vehicle: res.vehicle }));
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('drivers.detail.vehicles.sagas.create_success'),
      type: 'success',
      delay: 2000,
    })
  );
}

export function* updateWorker(action) {
  yield put(actions.update.start());

  const vehicle = buildVehicle(action.payload);
  const { res, error } = yield call(_put, `vehicles/${vehicle.id}`, vehicle);

  if (error) {
    yield put(modalActions.closeModal());
    yield put(
      alertActions.openAlert({
        message: i18n.t('drivers.detail.vehicles.sagas.error'),
        type: 'error',
      })
    );
    yield put(actions.update.failure({ error }));
  }

  yield put(actions.update.success({ vehicle: res.vehicle }));
  yield put(modalActions.closeModal());
  yield put(
    alertActions.openAlert({
      message: i18n.t('drivers.detail.vehicles.sagas.udpate_success'),
      type: 'success',
      delay: 2000,
    })
  );
}

export function* updateListWorker(action) {
  const { vehicles } = action.payload;

  yield put({
    type: actions.updateList,
    payload: { items: normalize(vehicles) },
  });
}

export function* createWatcher() {
  yield takeEvery(actions.create.type, createWorker);
}

export function* updateWatcher() {
  yield takeEvery(actions.update.type, updateWorker);
}

export default [createWatcher, updateWatcher];
