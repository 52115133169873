import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import styles from '../css/Grid.module.css';
import addProductStyles from '../css/AddNewProduct.module.css';

import {
  LabeledInputField,
  CheckboxField,
  CreatableSelectField,
} from './FormHelpers';
import AddNewProduct from './AddNewProduct';
import i18n from '../../../../../app/configs/i18n';

const Loading = () => <div className={styles.fullRow}>LOADING PRODUCTS...</div>;

export const EditCombo = (props) => {
  const separatorStyles = { margin: '20px 0px 20px 0px' };
  const {
    products,
    selectedProducts,
    addNewProduct,
    onAddProductChange,
    removeSelectedProduct,
    values,
    loadingProducts,
  } = props;

  const { name, description, dishes } = values;

  const weight = useMemo(() => {
    return (
      selectedProducts.length &&
      Math.round(
        selectedProducts.reduce((acc, value) => {
          if (value.product && value.quantity) {
            const totalWeight = parseFloat(value.product.unit_measure);
            const quantity = parseFloat(value.quantity);
            return (acc += totalWeight * quantity);
          } else {
            return acc;
          }
        }, 0) * 10
      ) / 10
    );
  }, [selectedProducts]);

  const noCombosProducts = useMemo(() => {
    return products.filter(
      (product) => product.combo_product_items.length === 0
    );
  }, [products]);

  const numberOfProducts = selectedProducts.length;
  const textStyle = { fontFamily: 'Poppins-Bold', color: '#000' };

  return (
    <div className={styles.grid}>
      <div className={styles.fullRow}>
        <h4 style={textStyle}>
          {i18n.t('modals.create_combo.identification')}
        </h4>
      </div>
      <div className={styles.fullRow}>
        <LabeledInputField
          name='name'
          label={i18n.t('modals.create_combo.name')}
          value={name}
        />
      </div>
      <div className={styles.fullRow}>
        <LabeledInputField
          name='description'
          label={i18n.t('modals.create_combo.description')}
          value={description}
        />
      </div>
      <hr className={styles.fullRow} style={separatorStyles} />
      <div className={styles.fullRow}>
        <h4 style={textStyle}>{i18n.t('modals.create_combo.composition')}</h4>
      </div>
      {!loadingProducts && (
        <>
          <div className={`${styles.selectProductRow} ${styles.labelText}`}>
            {i18n.t('modals.create_combo.product_label')}:
          </div>
          <div
            className={`${styles.selectProductDisplayRow} ${styles.labelText}`}
          >
            {i18n.t('modals.create_combo.appName_label')}:
          </div>
          <div
            className={`${styles.selectProductQuantityRow} ${styles.labelText}`}
          >
            {i18n.t('modals.create_combo.present_label')}:
          </div>
        </>
      )}
      {loadingProducts ? (
        <Loading />
      ) : (
        selectedProducts.map((product, index) => (
          <AddNewProduct
            key={product.id}
            id={product.id}
            product={product.product}
            app_name={product.app_name}
            quantity={product.quantity}
            products={noCombosProducts}
            position={index}
            onAddProductChange={onAddProductChange}
            removeSelectedProduct={removeSelectedProduct}
          />
        ))
      )}

      <div
        className={`${styles.fullRow} ${styles.inline}`}
        style={separatorStyles}
      >
        <button
          className={addProductStyles.addButton}
          onClick={(event) => addNewProduct(event)}
        >
          {'+ '}
          {i18n.t('modals.create_combo.add')}
        </button>
      </div>
      <hr className={styles.fullRow} style={separatorStyles} />
      <div className={styles.fullRow}>
        <h4 style={textStyle}>{i18n.t('modals.create_combo.summary')}</h4>
      </div>
      <div className={styles.numberOfProducts} style={textStyle}>
        {numberOfProducts} {i18n.t('modals.create_combo.numberOfProducts')} -{' '}
        {weight} Kg.
      </div>
      <div className={styles.dishes}>
        <LabeledInputField
          value={dishes}
          type='number'
          name='dishes'
          label={i18n.t('modals.create_combo.dishes')}
          step={1}
          min={0}
        />
      </div>
      <div className={styles.presentation}>
        <CreatableSelectField
          disabled
          options={[{ label: i18n.t('modals.create_combo.box'), value: 'box' }]}
          name='presentation'
          placeholder={i18n.t('modals.create_combo.presentation')}
          label={i18n.t('modals.create_combo.presentation')}
        />
      </div>
      <div className={styles.price}>
        <LabeledInputField
          name='price'
          placeholder='$'
          label={i18n.t('modals.create_combo.price')}
        />
      </div>
      <div
        className={`${styles.fullRow} ${styles.inline}`}
        style={separatorStyles}
      >
        <CheckboxField
          disabled
          name='is_dense'
          label={i18n.t('modals.create_combo.dense')}
        />
        <CheckboxField
          disabled
          name='need_cooling'
          label={i18n.t('modals.create_combo.cooling')}
        />
      </div>
    </div>
  );
};

EditCombo.propTypes = {
  products: propTypes.arrayOf([]).isRequired,
  selectedProducts: propTypes.arrayOf([]).isRequired,
  loadingProducts: propTypes.bool.isRequired,
  values: propTypes.shape({
    name: propTypes.string.isRequired,
    description: propTypes.string.isRequired,
    dishes: propTypes.number.isRequired,
  }).isRequired,
  addNewProduct: propTypes.func.isRequired,
  onAddProductChange: propTypes.func.isRequired,
  removeSelectedProduct: propTypes.func.isRequired,
};

export default EditCombo;
