import makeActionCreator from 'make-action-creator';

import { NAMESPACE } from '../config/constants';

export const fetchAll = makeActionCreator(NAMESPACE + '/fetchAll');
export const fetchAddresses = makeActionCreator(NAMESPACE + '/fetchAddresses');
export const fetchCategories = makeActionCreator(
  NAMESPACE + '/fetchCategories'
);
export const fetchParentCategories = makeActionCreator(
  NAMESPACE + '/fetchParentCategories'
);
export const fetchOfferStock = makeActionCreator(NAMESPACE + 'fetchOfferStock');
export const fetchVariants = makeActionCreator(NAMESPACE + 'fetchVariants');
export const fetchAvailableStock = makeActionCreator(
  NAMESPACE + '/fetchAvailableStock'
);
