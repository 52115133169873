import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import ModalFooter from 'components/ModalCenter/components/ModalFooter';
import Button from 'components/ButtonWithSpinner/ButtonWithSpinnerContainer';
import { useTranslation } from 'react-i18next';
import './statics/styles.css';

const EditInstitutionUser = (props) => {
  const { onCancel, onConfirm, formData, onChange, status } = props;
  const { t } = useTranslation();

  const Title = () => (
    <div>
      <div className='edit-institution-modal__title'>
        {t('modals.edit_institution_user.title')}
      </div>
    </div>
  );

  return (
    <Modal
      className='edit-institution-modal'
      title={<Title />}
      onCancel={() => onCancel()}
      {...props}
    >
      <ModalBody className='modal-detail__body'>
        <div className='edit-institution_detail__body'>
          <div className='edit-institution_detail__body__grid'>
            <div>
              <label className='edit-institution_detail__body__label'>
                {t('modals.edit_institution_user.name')}
              </label>
              <input
                onChange={onChange}
                value={formData.first_name}
                type='text'
                datacy='first_name'
                className='form-control edit-institution_detail__body__input'
                name='first_name'
                placeholder={t('modals.edit_institution_user.name_placeholder')}
              />
            </div>
            <div>
              <label className='edit-institution_detail__body__label'>
                {t('modals.edit_institution_user.surname')}
              </label>
              <input
                onChange={onChange}
                value={formData.last_name}
                datacy='last_name'
                type='text'
                className='form-control edit-institution_detail__body__input'
                name='last_name'
                placeholder={t(
                  'modals.edit_institution_user.surname_placeholder'
                )}
              />
            </div>
            <div>
              <label className='edit-institution_detail__body__label'>
                {t('modals.edit_institution_user.email')}
              </label>
              <input
                onChange={onChange}
                value={formData.email}
                type='email'
                datacy='email'
                className='form-control edit-institution_detail__body__input'
                name='email'
                placeholder={t(
                  'modals.edit_institution_user.email_placeholder'
                )}
              />
            </div>
            <div>
              <label className='edit-institution_detail__body__label'>
                {t('modals.edit_institution_user.phone')}
              </label>
              <input
                onChange={onChange}
                value={formData.cellphone}
                type='text'
                datacy='cellphone'
                className='form-control edit-institution_detail__body__input'
                name='cellphone'
                placeholder={t(
                  'modals.edit_institution_user.phone_placeholder'
                )}
              />
            </div>
            <div>
              <label className='edit-institution_detail__body__label'>
                {t('modals.edit_institution_user.password')}
              </label>
              <input
                onChange={onChange}
                value={formData.password}
                type='text'
                datacy='password'
                className='form-control edit-institution_detail__body__input'
                name='password'
                placeholder={t(
                  'modals.edit_institution_user.password_placeholder'
                )}
              />
            </div>
            <div>
              <label className='edit-institution_detail__body__label'>
                {t('modals.edit_institution_user.confirm_password')}
              </label>
              <input
                onChange={onChange}
                value={formData.password_confirmation}
                type='text'
                datacy='password_confirmation'
                className='form-control edit-institution_detail__body__input'
                name='password_confirmation'
                placeholder={t(
                  'modals.edit_institution_user.confirm_password_placeholder'
                )}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button outline datacy='btnCanc' onClick={onCancel}>
          {t('modals.edit_institution_user.close')}
        </Button>
        <Button primary datacy='btnSave' status={status} onClick={onConfirm}>
          {t('modals.edit_institution_user.submit')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

EditInstitutionUser.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}).isRequired,
  status: PropTypes.string.isRequired,
};

export default EditInstitutionUser;
