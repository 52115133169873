import React from 'react';
import propTypes from 'prop-types';

const ModalFooter = (props) => {
  return <div className='modal-footer'>{props.children}</div>;
};

ModalFooter.propTypes = {
  children: propTypes.node.isRequired,
};

export default ModalFooter;
