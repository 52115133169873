import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';

const ModalBody = (props) => {
  return (
    <div className={cn('modal-body', props.className)}>{props.children}</div>
  );
};

ModalBody.propTypes = {
  children: propTypes.oneOfType([propTypes.array, propTypes.object]).isRequired,
  className: propTypes.string.isRequired,
};

export default ModalBody;
