import { range } from 'ramda'
import numeral from 'numeral'

export const buildScheduledAt = (date, time) => {
  const hour = time.format('HH')
  const minute = time.format('mm')
  return date.set({
    hour,
    minute,
    seconds: 0,
    millisecond: 0
  }).toISOString()
}

export const buildDayTimes = () => range(0, 24)
  .map(num => String(num))
  .map(num => num.length === 1 ? `0${num}` : num)
  .map(num => `${num}:00`);

export const buildDateString = (date) => {
  const dd = numeral(date.getDate()).format('00');
  const mm = numeral(date.getMonth() + 1).format('00');
  const yy = date.getFullYear();

  const hh = numeral(date.getHours()).format('00');
  const mins = numeral(date.getMinutes()).format('00');
  const ss = numeral(date.getSeconds()).format('00');

  return `${dd}/${mm}/${yy} ${hh}:${mins}:${ss}`
}