import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Loading from 'components/Loading';
import { connect } from 'react-redux';

const ValidatorRoutes = ({ component, path, allowedModules, ...props }) => {
  const Component = component;

  if (JSON.stringify(allowedModules).length < 2) {
    return <Loading />;
  }

  return (
    <Route
      exact
      path={path}
      render={(renderProps) => {
        return localStorage.getItem('token') ? (
          <Component {...{ ...renderProps, ...props }} />
        ) : (
          <Redirect to='/login' />
        );
      }}
    />
  );
};

const modulePropType = PropTypes.shape({
  pathname: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  actions: PropTypes.arrayOf(PropTypes.string),
});

ValidatorRoutes.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  allowedModules: PropTypes.shape({
    index: modulePropType,
    institutions: modulePropType,
    notifications: modulePropType,
    orders: modulePropType,
    settings: modulePropType,
    shippers: modulePropType,
    stock: modulePropType,
    trips: modulePropType,
  }),
  path: PropTypes.string.isRequired,
};

ValidatorRoutes.defaultProps = {
  component: () => <div />,
  allowedModules: [],
};

const mapStateToProps = (state) => ({
  allowedModules: state.permissions.modules,
});

export default connect(mapStateToProps, null)(ValidatorRoutes);
