import React from 'react';
import propTypes from 'prop-types';

import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import ModalFooter from 'components/ModalCenter/components/ModalFooter';

import ModalForm from './ModalForm';
import { useTranslation } from 'react-i18next';

const CreateVehicle = (props) => {
  const { onCancel, onConfirm, formData, status } = props;
  const { t } = useTranslation();
  return (
    <Modal
      title={t('modals.create_vehicle.title')}
      onCancel={() => onCancel()}
      {...props}
    >
      <ModalBody>
        <p>{t('modals.create_vehicle.subtitle')}</p>
        <ModalForm {...props} />
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-default' onClick={onCancel}>
          {t('modals.create_vehicle.close')}
        </button>
        <button
          className='btn btn-primary'
          onClick={() => onConfirm({ ...formData })}
          disabled={status === 'pending'}
        >
          {t('modals.create_vehicle.submit')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

CreateVehicle.propTypes = {
  onCancel: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
  formData: propTypes.shape({}).isRequired,
  status: propTypes.string.isRequired,
};

export default CreateVehicle;
