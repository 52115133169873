import modules from '../modules';

export default [
  {
    name: '/',
    path: '/',
    component: modules.trips.scenes.list,
  },
  {
    name: 'trips',
    path: '/trips/:date',
    component: modules.trips.scenes.list,
  },
  {
    name: 'orders',
    path: '/orders/createOrder',
    component: modules.orders.scenes.createOrder,
  },
  {
    name: 'orders',
    path: '/orders',
    component: modules.orders.scenes.list,
  },
  {
    name: 'orders',
    path: '/orders/create',
    component: modules.orders.scenes.create,
  },
  {
    name: 'orders',
    path: '/orders/detail/:id',
    component: modules.orders.scenes.detail,
  },
  {
    name: 'shippers',
    path: '/shippers',
    component: modules.shippers.scenes.list,
  },
  {
    name: 'shippers',
    path: '/shippers/:shipperId',
    component: modules.shippers.scenes.detail,
  },
  {
    name: 'institutions',
    path: '/institutions/list/:type',
    component: modules.institutions.scenes.list,
  },
  {
    name: 'institutions',
    path: '/institutions/create',
    component: modules.institutions.scenes.create,
  },
  {
    name: 'institutions',
    path: '/institutions/list/:institutionId/detail/:type',
    component: modules.institutions.scenes.detail,
  },
  {
    name: 'institutions',
    path: '/institutions/:institutionId/edit',
    component: modules.institutions.scenes.edit,
  },
  {
    name: 'stock',
    path: '/stock',
    component: modules.stock.scenes.list,
  },
  {
    name: 'stock',
    path: '/stock/:type/create',
    component: modules.stock.scenes.create,
  },
  {
    name: 'stock',
    path: '/stock/offers/:id/detail',
    component: modules.stock.scenes.offerDetail,
  },
  {
    name: 'stock',
    path: '/stock/:type',
    component: modules.stock.scenes.list,
  },
  // {
  //   name: 'reports',
  //   path: '/reports',
  //   component: modules.reports.scenes.list,
  // },
  {
    name: 'notifications',
    path: '/notifications',
    component: modules.notification.scenes.main,
  },
  {
    name: 'notifications',
    path: '/notifications/create',
    component: modules.notification.scenes.create,
  },
  {
    name: 'notifications',
    path: '/notifications/list/:type',
    component: modules.notification.scenes.main,
  },
  {
    name: 'settings',
    path: '/settings/networks',
    component: modules.network.scenes.network,
  },

  {
    name: 'settings',
    path: '/settings/networks/create',
    component: modules.network.scenes.createNetwork,
  },
  {
    name: 'settings',
    path: '/settings/networks/update/:id',
    component: modules.network.scenes.updateNetwork,
  },
  {
    name: 'settings',
    path: '/settings/users',
    component: modules.user.scenes.user,
  },
  {
    name: 'settings',
    path: '/settings/users/create',
    component: modules.user.scenes.createUser,
  },
  {
    name: 'settings',
    path: '/settings/users/update/:id',
    component: modules.user.scenes.updateUser,
  },
  {
    name: 'settings',
    path: '/settings/districts',
    component: modules.districts.scenes.districts,
  },
  {
    name: 'settings',
    path: '/settings/districts/create',
    component: modules.districts.scenes.createDistricts,
  },
  {
    name: 'settings',
    path: '/settings/districts/update',
    component: modules.districts.scenes.updateDistricts,
  },
  {
    name: 'settings',
    path: '/settings/country',
    component: modules.country.scenes.country,
  },
  {
    name: 'settings',
    path: '/settings/country/create',
    component: modules.country.scenes.createCountry,
  },
  {
    name: 'settings',
    path: '/settings/country/update/:id',
    component: modules.country.scenes.updateCountry,
  },

  {
    name: '404',
    path: '/404',
    component: () => {}, // TODO
  },
];
