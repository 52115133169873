import React from 'react';
import propTypes from 'prop-types';
import TimePickerRn from 'rc-time-picker';

import './styles.css';

const TimePicker = (props) => {
  const { onChange, format, time, disabled } = props;

  return (
    <TimePickerRn
      showSecond={false}
      defaultValue={time}
      value={time}
      onChange={onChange}
      format={format}
      inputReadOnly
      popupClassName='time-picker__popup'
      className='time-picker_style'
      disabled={disabled}
    />
  );
};

TimePicker.propTypes = {
  onChange: propTypes.func.isRequired,
  format: propTypes.string.isRequired,
  time: propTypes.oneOfType([propTypes.string, propTypes.object]).isRequired,
  disabled: propTypes.bool.isRequired,
};

export default TimePicker;
