import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import ModalFooter from 'components/ModalCenter/components/ModalFooter';
import Button from 'components/ButtonWithSpinner/ButtonWithSpinnerContainer';

import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3Container';
import MultiOriginStep from './MultiOriginStep/MultiOriginStep';

import i18n from '../../../../../app/configs/i18n';

import './statics/styles.css';

class CreateTrip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      hasErrors: false,
    };
  }

  onValidation = (errors) => {
    this.setState({ errors });
    this.setState({ hasErrors: errors.length > 0 });
  };

  render() {
    const {
      status,
      step,
      isValid,
      onCancel,
      onConfirm,
      onNextStep,
    } = this.props;
    const redText = { color: 'red' };
    const centerAlign = { textAlign: 'center' };
    const errors = this.state.errors.map((x, i) => (
      <li key={i} style={redText}>
        {x}
      </li>
    ));
    const Title = () =>
      step === -1 ? (
        <div>
          <div className='create-trip-modal__title'>
            {i18n.t('modals.create_trip.title')}
          </div>
        </div>
      ) : (
        <div>
          <div className='create-trip-modal__steps'>
            {i18n.t('modals.create_trip.step')} {step + 1}{' '}
            {i18n.t('modals.create_trip.from')} 3
          </div>
          <div className='create-trip-modal__title'>
            {i18n.t('modals.create_trip.subtitle')}
          </div>
        </div>
      );
    return (
      <Modal
        className='create-trip-modal'
        title={<Title />}
        onCancel={() => onCancel()}
        {...this.props}
      >
        <ModalBody className='modal-detail__body'>
          <ol style={centerAlign}>{errors}</ol>
          {step === -1 && <MultiOriginStep {...this.props} />}
          {step === 0 && <Step1 {...this.props} />}
          {step === 1 && (
            <Step2 {...this.props} onValidation={this.onValidation} />
          )}
          {step === 2 && <Step3 {...this.props} />}
        </ModalBody>
        <ModalFooter>
          <Button datacy={'btnCanc'} outline onClick={onCancel}>
            {i18n.t('modals.create_trip.close')}
          </Button>
          {step < 2 && (
            <Button
              primary
              datacy={'btnNext'}
              disabled={!isValid || this.state.hasErrors}
              rerender={step}
              onClick={onNextStep}
            >
              {i18n.t('modals.create_trip.next')}
            </Button>
          )}
          {step === 2 && (
            <Button
              primary
              datacy={'btnSave'}
              status={status}
              disabled={!isValid}
              onClick={onConfirm}
            >
              {i18n.t('modals.create_trip.submit')}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

CreateTrip.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default CreateTrip;
