import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  adaptToOutputDto,
  initialValues,
  makeCategorySubcategoryBrand,
} from '../helpers';
import Modal from 'components/ModalCenter';
import ModalBody from 'components/ModalCenter/components/ModalBody';
import styles from '../css/Modal.module.css';
import { validationSchema } from '../validations';
import { Formik } from 'formik';
import Button from 'components/Button';
import ButtonWithSpinner from 'components/ButtonWithSpinner/ButtonWithSpinner';
import EditVariant from './EditVariant';
import Loader from 'components/Loader/components/Loader';
import i18n from '../../../../../app/configs/i18n';

function ModalContainer(props) {
  const [units_per_pack, setUnits_per_pack] = useState(
    Number(props.data.variant.units_per_pack)
  );
  const [unit_measure, setUnit_measure] = useState(
    Number(props.data.variant.unit_measure)
  );
  const [weight, setWeight] = useState(Number(props.data.variant.weight));
  const [category_id, setCategory_id] = useState(undefined);
  const [category, setCategory] = useState(undefined);

  const { onCancel } = props;

  const selectHelpersReducer = useSelector((state) => state.select_helpers);
  const { items } = selectHelpersReducer;

  const {
    simple_products,
    categories,
    brands,
    unit_types,
    measure_names,
  } = items;
  let pending = true;
  let initValues = initialValues(props.data.variant);
  let categ;
  let subCategory;
  let brand;
  if (Object.keys(items).length >= 5) {
    pending = false;

    const aux = makeCategorySubcategoryBrand(
      props.data.variant,
      categories,
      brands
    );
    categ = aux.category;
    subCategory = aux.subCategory;
    brand = aux.brand;

    initValues.brand = brand ? brand : undefined;
    initValues.parent_category_id = categ ? categ : undefined;
    initValues.category_id = subCategory ? subCategory : undefined;
    initValues.description = props.data.variant.description;
    initValues.product = simple_products.find(
      (product) => product.id === props.data.variant.product_id
    );
    initValues.unit_measure = Number(props.data.variant.unit_measure);
    initValues.unit_measure_name = measure_names.find(
      (mn) => mn.value === props.data.variant.unit_measure_name
    );
    initValues.unit_type = initValues.is_bulk
      ? null
      : unit_types.find((ut) => ut.value === props.data.variant.unit_type);
    initValues.units_multiplier = props.data.variant.units_multiplier
      ? props.data.variant.units_multiplier
      : 1;
    initValues.units_per_pack = props.data.variant.units_per_pack;

    initValues.single_unit_measure_name =
      initValues.single_unit_measure_name && initValues.units_multiplier > 1
        ? {
            label: initValues.single_unit_measure_name,
            value: initValues.single_unit_measure_name,
          }
        : null;
  }

  useEffect(() => {
    setCategory(categ);
    setCategory_id(subCategory);
  }, [categ, subCategory]);

  const handleInputChange = async (event) => {
    if (!event) {
      setUnit_measure(1);
    } else {
      switch (event.target.name) {
        case 'units_per_pack':
          setUnits_per_pack(event.target.value);
          break;
        case 'unit_measure':
          setUnit_measure(event.target.value);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    setWeight(units_per_pack * unit_measure);
  }, [units_per_pack, unit_measure]);

  const handleCategoryChange = async (event) => {
    setCategory(event);
    setCategory_id(null);
  };

  const handleChildrenCategoryChange = async (event) => {
    setCategory_id(event);
  };

  const onConfirm = (values) => {
    values.units_per_pack = units_per_pack;
    values.unit_measure = unit_measure;
    values.weight = weight;
    values.category_id = category_id.id;

    let original_variant = props.data.variant;
    let id = original_variant.brand_name
      ? original_variant.variant_id
      : original_variant.id;
    let dto = adaptToOutputDto(values);

    props.onConfirm({ variant: dto.variant, variant_id: id });
  };

  return pending ? (
    <div>
      <Loader title='CARGANDO PRODUCTO' />
    </div>
  ) : (
    <Modal
      className='create-variant-modal'
      title={<Title step={1} />}
      onCancel={onCancel}
      {...props}
    >
      <ModalBody className='modal-detail__body'>
        <Formik
          initialValues={initValues}
          validationSchema={validationSchema}
          onSubmit={onConfirm}
          isInitialValid
          enableReinitialize
        >
          {({ isSubmitting, handleSubmit, isValid, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <EditVariant
                  products={simple_products}
                  categories={categories}
                  brands={brands}
                  variant={props.data.variant}
                  handleChildrenCategoryChange={(c) =>
                    handleChildrenCategoryChange(c)
                  }
                  handleCategoryChange={(event) => handleCategoryChange(event)}
                  unitTypes={unit_types}
                  measureNames={measure_names}
                  handleInputChange={(event) => handleInputChange(event)}
                  units_per_pack={units_per_pack}
                  unit_measure={unit_measure}
                  weight={weight}
                  category={category}
                  category_id={category_id}
                  values={values}
                />
                <div className={styles.buttons}>
                  <Button
                    type='button'
                    datacy='btnCancel'
                    onClick={onCancel}
                    outline
                  >
                    Cancelar
                  </Button>
                  <ButtonWithSpinner
                    type='submit'
                    datacy='btnSave'
                    disabled={isSubmitting || !isValid}
                    primary
                  >
                    Guardar
                  </ButtonWithSpinner>
                </div>
              </form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
}

const Title = () => (
  <div>
    <div className={styles.title}>{i18n.t('modals.edit_variant.title')}</div>
  </div>
);

ModalContainer.propTypes = {
  data: propTypes.arrayOf({}).isRequired,
};

export default ModalContainer;
