import styled from 'styled-components';

export const InputStyled = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
`;

export const LabelStyled = styled.label`
  padding: 3px 15px 3.5px;
  background-color: #37afff;
  border-radius: 50px;
  font-family: Poppins;
  letter-spacing: 1.3px;
  text-align: center;
  font-weight: 400;
  outline: 0;
  cursor: pointer;
  border: none;
  color: #ffffff;
  align-items: center;
  width: 240px;
`;

export const MarginStyles = styled.div`
  margin-left: 4px;
`;
