import i18n from '../../../app/configs/i18n';

export const NAMESPACE = 'trips';
export const productsList = [
  {
    name: 'frescos-congelados',
    label: i18n.t('trips.constants.fresh'),
    unit: 'Kg.',
    cooling: true,
  },
  {
    name: 'no-perecederos',
    label: i18n.t('trips.constants.non_perishable'),
    unit: 'Kg.',
    cooling: false,
  },
  {
    name: 'frutas-verduras',
    label: i18n.t('trips.constants.fruits_or_vegetables'),
    unit: 'Kg.',
    cooling: false,
  },
];
