import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn:
    'https://2e315d1cad0a4df58723da6092e65bf9@o158763.ingest.sentry.io/1831260',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.ENV_NAME,
});
