import React from 'react';
import propTypes from 'prop-types';
import CheckBoxList from './components/CheckBoxList';

import { parseServices, parseInputs } from './helpers';

class CheckBoxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: [],
      list: [],
    };
  }

  componentDidMount() {
    const { inputs, list } = this.props;
    const services = parseServices(inputs, list);

    this.setState({
      inputs: services,
      list,
    });
  }

  componentDidUpdate(oldProps, oldState) {
    const { inputs } = this.state;
    const newList = parseInputs(inputs);

    if (oldState.inputs !== this.state.inputs) {
      this.setState(
        {
          inputs: inputs,
          list: newList,
        },
        () => {
          if (this.props.hasOwnProperty('onChange')) {
            this.props.onChange({
              target: { name: this.props.name, value: newList },
            });
          }
        }
      );
    }
  }

  onCheck = (e) => {
    const { inputs } = this.state;
    const { value, checked } = e.target;

    this.setState({
      inputs: inputs.map((input) => ({
        ...input,
        checked: input.value === value ? checked : input.checked,
      })),
    });
  };

  render() {
    const { inputs } = this.state;

    return (
      <CheckBoxList {...this.props} inputs={inputs} onCheck={this.onCheck} />
    );
  }
}

CheckBoxContainer.propTypes = {
  list: propTypes.arrayOf([]).isRequired,
  inputs: propTypes.arrayOf([]).isRequired,
};

export default CheckBoxContainer;
