import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from './components/Modal';

import * as actions from '../../store/actions';

import { withHooks } from 'hooks/withHooks';
import i18n from 'app/configs/i18n';

class CreateShipperContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };
  }

  onChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onConfirm = () => {
    const { formData } = this.state;
    const { institution } = this.props.data;

    this.props.onConfirm(formData, institution);
  };

  render() {
    return (
      <Modal
        {...this.props}
        modalSuccess={i18n.t('modals.create_institution_user.success')}
        onConfirm={this.onConfirm}
        onChange={this.onChange}
        formData={this.state.formData}
      />
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  onConfirm: (user, institution) =>
    dispatch({ type: props.onConfirm, payload: { user, institution } }),
  onCancel: () => dispatch(actions.closeModal()),
});

CreateShipperContainer.propTypes = {
  onConfirm: propTypes.func.isRequired,
  data: propTypes.shape({}).isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withHooks(CreateShipperContainer));
