import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment-timezone';
import Modal from 'components/ModalFullScreen';
import Table from 'components/Table';
import Loader from 'components/Loader';
import schedule from '../constants';
import { isEmpty, isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import './statics/styles.css';

const ViewOrder = (props) => {
  const { t } = useTranslation();
  const { onCancel, data, columns, order, status } = props;
  return (
    <Modal title={t('modals.view_order.title')} onCancel={onCancel}>
      {status === 'pending' && (
        <Loader title={t('modals.view_order.loading')} />
      )}
      <div className='view-order'>
        <div className='view-order__details'>
          <div className='view-order__item'>
            <label className='view-order__label'>
              {t('modals.view_order.institution')}
            </label>
            <p className='view-order__text'>{data.order.receiver.name}</p>
          </div>
          <div className='view-order__item'>
            <label className='view-order__label'>
              {t('modals.view_order.address')}
            </label>
            <p className='view-order__text'>
              {data.order.receiver.addresses[0].street_1}
            </p>
          </div>
          <div className='view-order__item'>
            <label className='view-order__label'>
              {t('modals.view_order.date')}
            </label>
            <p className='view-order__text'>
              {moment(data.order.delivery_preference.day)
                .utc()
                .format(t('date_format'))}
            </p>
          </div>
          <div className='view-order__item'>
            <label className='view-order__label'>
              {t('modals.view_order.schedule')}
            </label>
            <p className='view-order__text'>
              {schedule[data.order.delivery_preference.time]}
            </p>
          </div>
          <div className='view-order__item'>
            <label className='view-order__label'>
              {t('modals.view_order.order_payment')}
            </label>
            {!isEmpty(data.order.payments) &&
              data.order.payments.map((payment, i) => (
                <p key={i} className='view-order__text'>
                  {payment.gateway_info.id}
                </p>
              ))}
            {isEmpty(data.order.payments) && (
              <p className='view-order__text'>
                {t('modals.view_order.no_order_payment')}
              </p>
            )}
          </div>
          <div className='view-order__item'>
            <label className='view-order__label'>
              {t('modals.view_order.delivery_payment')}
            </label>
            {!isEmpty(data.order.deliveries[0].payments) &&
              data.order.deliveries[0].payments.map((payment, i) => (
                <p key={i} className='view-order__text'>
                  {payment.gateway_info.id}
                </p>
              ))}
            {isEmpty(data.order.deliveries[0].payments) && (
              <p className='view-order__text'>
                {t('modals.view_order.no_delivery_payment')}
              </p>
            )}
          </div>
        </div>
        <div className='view-order__products'>
          <label className='view-order__label'>
            {t('modals.view_order.products')}
          </label>
          {!isNil(order.order_items) && (
            <Table items={order.order_items} columns={columns}>
              <tbody className='stock-table__body'>
                {order.order_items.map((item, key) => (
                  <tr key={key}>
                    {Object.keys(columns).map((key, i) =>
                      columns[key].children ? (
                        columns[key].children({ item, ...props })
                      ) : (
                        <td key={i}>
                          {item[key]
                            ? item[key]
                            : t('modals.view_order.no_data')}
                        </td>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </Modal>
  );
};

ViewOrder.propTypes = {
  onCancel: propTypes.func.isRequired,
  data: propTypes.shape({}).isRequired,
  order: propTypes.shape({}).isRequired,
  columns: propTypes.shape({}).isRequired,
  status: propTypes.string.isRequired,
};

export default ViewOrder;
