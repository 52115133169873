export default {
  translation: {
    date_format: 'MM/DD/YYYY',
    date_time_format: 'MM/DD/YYYY LT',
    map_language: 'en',
    loading: 'Loading...',
    today: 'Today',
    select_file: 'SELECT FILE',
    to: 'to',
    of: 'of',
    search: 'Search',
    close: 'CLOSE',
    submit: 'SUBMIT',
    adder: {
      add_product: 'Add another product',
      select: 'Select',
    },
    search_address: 'Search address',
    navbar: {
      trips: 'Trips',
      orders: 'Orders',
      shippers: 'Drivers',
      institutions: 'Institutions',
      stock: 'Stock',
      reports: 'Reports',
      notifications: 'Notifications',
      settings: 'Settings',
      sign_off: 'Sing off',
    },
    alert: {
      error: 'An error occurred!',
    },
    login: {
      sign_in: 'Log in',
      brand: 'Nilus',
      description:
        'We facilitate the rescue of food that is about to be thrown and distribute it across social soup kitchens, schools and everyone who needs it',
      credentials_subtitle: 'Enter your e-mail and your password to log in.',
      no_account: "Don't have an account",
      create: 'Create user',

      error: 'An error occurred',
      report: 'Would you like to report it?',

      mail: 'E-mail',
      mail_placeholder: 'Email',
      password: 'Password',
      password_placeholder: 'Password',
      invalid_credentials: 'Invalid credentials',
      remember_me: 'Remember me',
      forgot_password: 'Forgot my password',
      submit: 'Log in',
    },
    map_with_search: {
      msg: 'Adjusted Location. Use Lat and Lng for exact location.',
    },
    modals: {
      add_product: {
        title: 'Add a product',
        subtitle: 'Adding a product to the order will modify its amount',
        origin: 'Origin',
        origin_placeholder: 'Select deposit',
      },
      create_cupon: {
        reason: 'Reason',
        reason_placeholder: 'Select',
        payment_method: 'Payment method',
        payment_method_placeholder: 'Select',
        amount: 'Amount',
        amount_placeholder: '$',
        close: 'Close',
        submit: 'Submit',
        subtitle: 'Select the reason and payment method to see the receiver',
        title: 'Create payment cupon',
        receiver: 'Receiver:',
        name: 'Name:',
        mail: 'Email:',

        rapi_pago: 'Rapi pago',
        pago_facil: 'Pago facil',
        other: 'Other',

        food_reason: 'Food contribution',
        trip_reason: 'Delivery contribution',
      },

      create_driver: {
        title: 'Create driver',
        close: 'Close',
        submit: 'Create driver',
        name: 'Name: ',
        name_placeholder: "Driver's name",
        surname: 'Surname: ',
        surname_placeholder: "Driver's surname",
        email: 'Email:',
        email_placeholder: 'Email',
        password: 'Password:',
        password_placeholder: 'Password',
        success: 'Successfully created the driver 🎉',
      },
      edit_driver: {
        title: "Update the driver's information",
        close: 'Cancel',
        submit: 'Save changes',
        first_name: 'Name',
        first_name_placeholder: "Driver's name",
        last_name: 'Last name',
        last_name_placeholder: "Driver's last name",
        phone_number: 'Phone number:',
        phone_number_placeholder: 'Phone number',
        email: 'Email:',
        email_placeholder: 'Email',
        birth_date: 'Birth date',
        gender: 'Gender:',
        password: 'Change password:',
        password_placeholder: 'New password',
        new_password: 'New password',
        new_password_placeholder: 'Confirm password',
        enable: 'Enable',
        disable: 'Disable',
        success: "Successfully updated this driver's information 🎉",
      },
      create_vehicle: {
        title: 'Add a new vehicle',
        subtitle:
          'Complete the following fields to create a new vehicle for the driver',
        close: 'Cancel',
        submit: 'Add new vehicle',
        brand_placeholder: 'Brand',
        model_placeholder: 'Vehicle model',
        refrigerated: 'Has refrigeration',
        success: 'Successfully created the vehicle🎉',
      },
      edit_vehicle: {
        title: 'Update vehicle',
        close: 'Cancel',
        submit: 'Save changes en vehicle',
        model: "Vehicle's model:",
        model_placeholder: "Vehicle's model",
        brand: 'Brand:',
        brand_placeholder: 'Brand',
        year: 'Year:',
        refrigerated: 'Has refrigeration',
        success: 'Successfully updated the vehicle🎉',
      },
      create_bank_account: {
        title: 'Create bank account',
        subtitle:
          'Complete the following fields to create a new bank account for the driver',
        close: 'Cancel',
        submit: 'Create bank account',
        success: 'Successfully created the bank account',
        bank_name_placeholder: 'Bank name',
        number_placeholder: 'Account number',
        cbu_placeholder: 'CBU',
        savings_bank: 'Savings account',
        current_account: 'Current account',
      },
      edit_bank_account: {
        title: 'Update bank account',
        close: 'Cancel',
        submit: ' Save changes',
        success: 'Successfully updated the bank account🎉',
        bank_name: 'Bank name:',
        bank_name_placeholder: 'Bank name',
        type: 'Account type:',
        savings_bank: 'Savings account',
        current_account: 'Current account',
        number: 'Account number:',
        number_placeholder: 'Account number',
        cbu: 'CBU:',
        cbu_placeholder: 'CBU',
      },
      create_trip: {
        title: 'Confirm trip',
        step: 'Step',
        from: 'of',
        subtitle: 'Create trip',
        close: 'Cancel',
        next: 'Next',
        submit: 'Create trip',
        step_1: {
          date: 'Date:',
          time: 'Time:',
          notes: 'Notes:',
        },
        step_2: {
          total_weight: 'Total weight:',
          distance: 'Distance:',
          time: 'Approximate duration:',
          driver_pay: "Driver's pay:",
        },
        step_3: {
          broadcast: 'Broadcast',
          assign: 'Assign driver',
          message:
            'You can assign a driver or broadcast the trip for everyone to see',
          select: 'Select an option',
        },
        multi_origin_step: {
          message:
            'The selected orders have different origins. Please select the first origin fot the trip',
        },
      },
      edit_trip: {
        title: 'Edit trip',
        driver_pay: "Driver's pay:",
        driver_pay_placeholder: "Driver's pay",
        date: 'Date',
        time: 'Time',
        notes: 'Notes:',
        submit: 'Save changes:',
        close: 'Close',
        success: 'Successfully edited the trip',
        validations: {
          amount_must_be_numeric: 'The amount must be a number',
          amount_must_be_positive: 'The amount must be a positive number',
          amount_must_exist: 'Amount is required',
          schedule_date_must_exist: 'Date is required',
          schedule_time_must_exist: 'Time is required',
        },
      },
      view_trip: {
        title: 'Trip details',
        close: 'Close',
      },

      create_institution_user: {
        success: 'Successfully created the user🎉',

        title: 'Create user',
        close: 'Cancel',
        submit: 'Create user',

        name: 'Name:',
        name_placeholder: "User's name",
        surname: 'Surname:',
        surname_placeholder: "User's surname",
        email: 'Email:',
        email_placeholder: 'Email',
        phone: 'Cellphone:',
        phone_placeholder: 'Cellphone',
        password: 'Password:',
        password_placeholder: 'Password',
      },

      create_payment: {
        rapi_pago: 'Rapi pago',
        pago_facil: 'Pago facil',
        other: 'Other',

        food_reason: 'Food contribution',
        trip_reason: 'Delivery contribution',

        validations: {
          required_reason: 'The reason is required',
          required_amount: 'Amount must be a number',
          positive_amount: 'The number must be positive',
          numeric_amount: 'Amount is required',
          required_payment_method: 'Payment method is required',
        },
      },

      edit_order_date: {
        title: 'Edit order date',
        date: 'Date',
        start_time: 'Start time',
        close: 'CANCEL',
        submit: 'SUBMIT',
        success: 'Successfully updated the date',
        validations: {
          delivery_date: 'Date is required',
          delivery_time: 'Time is required',
        },
      },

      edit_order: {
        product_adder: {
          product: 'Product:',
          product_placeholder: 'Select product',
          quantity: 'Quantity',
          close: 'CANCEL',
          submit: 'SUBMIT',
          validations: {
            product: 'Product',
            quantity: 'Quantity',
            available_quantity: 'You must enter a valid stock quantity',
          },
        },
        success: 'Successfully updated the date',
        validations: {
          delivery_date: 'Date is required',
          delivery_time: 'Time is required',
        },
      },

      view_order: {
        afternoon: 'Afternoon',
        morning: 'Morning',
        bank_id: 'Bank ID',
        product: 'Product',
        description: 'Description',
        brand: 'Brand',
        expiration: 'Expiration',
        quantity: 'Quantity',
        total_weight: 'Total weight',
        total_price: 'Total cost',

        title: 'Order detail',
        loading: 'Loading order',
        institution: 'Institution:',
        address: 'Address:',
        date: 'Delivery date:',
        schedule: 'Schedule:',
        order_payment: 'Order cupon number:',
        no_order_payment: 'There are no generated cupons',
        delivery_payment: 'Delivery cupon number:',
        no_delivery_payment: 'There are no generated cupons',
        products: 'Products:',
        no_data: 'No data',
      },

      view_stock_product: {
        product: 'Product: ',
        stock: 'Stock: ',
        units: 'units',
        no_units: 'No data',
        category: 'Category:',
        min: 'Minimum:',
        max: 'Maximum:',
      },

      upload_csv: {
        create_product: 'Create a new product',
        create_stock: 'Add stock',
        replace_stock: 'Replace existing stock',

        title: 'Import from csv',
        subtitle: 'Please select the field you want to update',
        action: 'Action:',
        action_placeholder: 'Select',
        download_template: 'DOWNLOAD TEMPLATE',
        close: 'Cancel',
        submit: 'Submit',
      },

      update_stock: {
        units: 'units',
        no_units: 'No data',

        close: 'Close',
        submit: 'Submit',
        previous: 'Previous',
        next: 'Next',

        category: 'Category:',
        min: 'Minimum:',
        max: 'Maximum:',

        name: 'Product name',
        name_placeholder: 'Product name',
        variant: 'Stock',
        variant_placeholder: 'Nº',
        deposit: 'Deposit',
        deposit_placeholder: 'Select',
        expiration: 'Expiration',
        stock: 'Stock',
        stock_placeholder: 'Nº',
        price: 'Price per pack',
        cost: 'Lot cost',
        price_placeholder: 'Nº',
        discount: 'Discount',
        discount_placeholder: '0%',

        validations: {
          at_least_one_greater: 'At least one must be greater than zero',
          only_numbers: 'Only numbers are allowed',
          zero_or_more: 'All fields must have a value or zero or more',
          only_positive: 'Only positive numbers are allowed',
          greater_or_equal_than: 'Must be grater than or equal to',
        },
      },

      revise_product: {
        loading: 'LOADING PRODUCT',
        title: 'Edit Product',
        name: 'Product name',
        name_placeholder: 'Product name',
        description: 'Description',
        description_placeholder: 'Description',
        brand: 'Brand',
        brand_placeholder: 'Brand',
        category: 'Category',
        category_placeholder: 'Category',
        subcategory: 'Subcategory',
        subcategory_placeholder: 'Subcategory',
        presentation: 'Presentation',
        presentation_placeholder: 'Food bag',
        units_multiplier: 'Unit multiplier',
        units_multiplier_placeholder: 'Nº',
        units_per_pack: 'Units per pack',
        units_per_pack_placeholder: 'Nº',
        weight: 'Weight',
        weight_placeholder: 'Nº',
        unit_measure_name: 'Unit measure',
        unit_measure_name_placeholder: 'Unit measure',
      },

      edit_payment_status: {
        success: "Successfully updated the payment's comment🎉",
        cupon: 'Payment',
        mark_as_payed: 'Mark as payed',

        paid_at: 'Date',
        amount: 'Amount',
        comment: 'Add comment',

        close: 'Cancel',
        submit: 'Submit',
      },

      edit_institution_user: {
        success: 'Successfully updated the user',

        title: 'Edit User',
        close: 'Cancel',
        submit: 'Submit',

        name: 'Name:',
        name_placeholder: "User's name",
        surname: 'Surname:',
        surname_placeholder: "User's surname",
        email: 'Email:',
        email_placeholder: 'Email',
        phone: 'Cellphone:',
        phone_placeholder: 'Cellphone',
        password: 'Change Password:',
        password_placeholder: 'New password',
        confirm_password: 'Confirm Password:',
        confirm_password_placeholder: 'Confirm Password',
      },

      edit_cupon_comment: {
        success: 'Successfully updated the comment',
        cupon: 'Cupon',
        comment: 'Comment',
        comment_placeholder: 'Add comment',
        close: 'Cancel',
        submit: 'Submit',
      },

      csv_errors: {
        cancel_option: 'Quit the process and discard all registers',
        continue_option: 'Continue only with the valid registers',

        title: 'Problems importing the csv file',
        subtitle: 'We found error in the following registers',
        accept: 'Accept',
      },

      create_variant: {
        close: 'Cancel',
        submit: 'Submit',
        add: 'Add Product',

        by_bulk: 'by bulk',
        presentation_by_bulk: '[Presentation] by bulk',
        is_bulk: 'Is by bulk / by weight',
        weight_by: 'Weight by',
        weight_by_presentation: 'Weight by [Presentation]',
        unit_by: 'Unit by',
        unit_by_presentation: 'Unit by [Presentation]',
        unit_name: 'Unit name',
        unit_name_placeholder: 'Select',

        name: 'Product name',
        name_placeholder: 'Product',
        description: 'Description',
        description_placeholder: 'Description',
        brand: 'Brand',
        brand_placeholder: 'Brand',
        category: 'Category',
        category_placeholder: 'Category',
        subcategory: 'Subcategory',
        subcategory_placeholder: 'Subcategory',
        presentation: 'Presentation',
        presentation_placeholder: 'Presentation',
        unit_multiplier: 'Unit multiplier',
        unit_multiplier_placeholder: 'Nº',
        units_per_pack: 'Units per pack',
        units_per_pack_placeholder: 'Nº',
        unit_measure: 'Final weight per unit',
        unit_measure_placeholder: 'Nº',
        unit_measure_name: 'Weight unit name',
        unit_measure_name_placeholder: 'Weight unit',
        weight: 'Final weight per pack',
        weight_placeholder: 'Nº',
        is_lightweight: 'Is lightweight',
        needs_cooling: 'Needs refrigeration',
        preview: {
          title: 'Food shelter App Preview',
          de: 'of',
          en: 'in',
          con: 'with',
        },
        tooltips: {
          external_reference:
            'Alphanumeric identification can corresponds to' +
            'the management system if there is one. Example: 00012',
          product:
            'Product identification that will be visible in the shop' +
            'ideally use the same nae that appears in the package. Example.: Mineral water',
          description:
            'Is not a required field. Adds detail to the product. Example.: Without Gas',
          brand:
            'If its present in the package. Very important to facilitate the traceability of the products',
          category_id: 'The group of product to which it belongs to.',
          units_multiplier:
            'In the app we offer commercialize units expressed in consume units. ' +
            'We do not say 1 pack of bottles ' +
            'Instead ww say 6 bottles (this is the multiplier).',
          units_per_pack:
            'References the commercial unit, the unit that is not divisible to offer.\n' +
            'Example.: In a 6 bottle pack there are 6 units per bulk. ' +
            'If we refer to a fruit crate or a bag of rice the units would be: 1.',
          measure:
            'The weight inside each consume unit that belongs to the commercialization unit (or pack).',

          weight:
            'Automatically calculated based on the final unit weight and the amount of units per pack.',
          light_weight:
            'Specified if the bulk is lightweight related to its volume, such as a snack. ' +
            'Defaults to false',
          need_cooling:
            'Defines if the product needs cooling. Defaults to false.',
        },
        validations: {
          required_product: 'Product is required',
          required_brand: 'Brand is required',
          required_category: 'Category is required',
          required_subcategory: 'Subcategory is required',
          numeric_units_multiplier: 'Must be a number',
          positive_units_multiplier: 'Must be greater than zero',
          integer_units_multiplier: 'Must be an integer',
          required_unit_type: 'Presentation is required',
          numeric_units_per_pack: 'Must be a number',
          required_units_per_pack: 'Units per pack is required',
          positive_units_per_pack: 'Must be greater than zero',
          integer_units_per_pack: 'Must be an integer',
          numeric_unit_measure: 'Must be a number',
          positive_unit_measure: 'Must be greater than zero',
          required_unit_measure_name: 'Unit measure is required',
          numeric_weight: 'Must be a number',
          positive_weight: 'Must be greater than zero',
          required_single_unit_measure_name: 'Unit name is required',
        },
      },
      edit_variant: {
        title: 'Edit Product',
      },

      create_stock: {
        step: 'Step',
        of: 'of',
        create: 'Create Stock',
        units: 'units',
        no_units: 'No data',

        cancel: 'Cancel',
        previous: 'Previous',
        next: 'Next',
        submit: 'Submit',

        category: 'Category:',
        min: 'Minimum:',
        max: 'Maximum:',

        deposit: 'Deposit',
        name: 'Product name',
        name_placeholder: 'Product name',
        expiration: 'Expiration',
        expiration_placeholder: 'Select',
        stock: 'Stock',
        lote: 'Lot cost',
        stock_placeholder: 'Nº',
        price: 'Price per pack',
        price_placeholder: 'Nº',
        pickStock: {
          exp: 'Exp.',
          stock: 'Stock',
          stock_number: 'Stock #',
        },
        titles: {
          variant: 'Variant',
          quantity: 'Quantity',
          stock: 'Stock',
        },

        validations: {
          not_zero: 'At least one must be greater than zero',
          only_numbers: 'Only numbers',
          valid_fields: 'All fields must be greater than zero',
          only_positive: 'Only positive numbers are allowed',
          required_product: 'Product is required',
          required_address: 'Deposit is required',
          numeric_quantity: 'Quantity must be numeric',
          required_quantity: 'Quantity is required',
          positive_quantity: 'Quantity must be greater than zero',
          integer_quantity: 'Quantity must be an integer',
          numeric_price: 'Price must be a number',
          min_price: 'Price must be greater than zero',
          required_price: 'Price is required',
          required_groups: 'Groups is required',
          required_date: 'Requires expiration date',
        },
        rescued: 'rescued product',
      },
      create_payment_cupon: {
        title: 'Create payment cupon',
        cancel: 'Cancel',
        submit: 'Create',
        close: 'Close',

        subtitle:
          'Select the payment method you want the soup kitchen to use for this cupon',
        rapi_pago: 'Rapipago',
        pago_facil: 'Pago Fácil',
        number: 'Cupon number:',
      },

      confirmation: {
        close: 'CANCEL',
        submit: 'SUBMIT',
      },

      assign_driver: {
        assign: 'Assign a driver to the trip',
        close: 'Cancel',
        submit: 'Confirm driver',
        success: 'Successfully assigned the driver to the trip',

        drivers: 'Drivers:',
        tooltip: 'This driver has a vehicle with refrigeration',
      },

      offer_name: {
        title: 'Submit Offer',
        subtitle: 'Assign a name to the offer to identify it in the app',
        name: 'Offer title',
        name_placeholder: 'Maximum 40 characters',
        close: 'CANCEL',
        submit: 'SUBMIT',

        success: 'Successfully created the offer',
        validations: {
          max_name: 'Only 40 characters are allowed',
          required_name: 'Name is required',
        },
      },
      edit_combo: {
        title: 'View Combo',
      },
      create_combo: {
        title: 'Create Combo',
        identification: 'Identification',
        composition: 'Combo Composition',
        add: 'Add another product',
        summary: 'Combo Summary',
        name: "Combo's name",
        description: 'Description',
        dense: 'Is Dense',
        cooling: 'Requires Cooling',
        product: 'Select Product',
        product_label: 'Product',
        appName: 'Name',
        box: 'Box',
        appName_label: 'Name in App',
        present_label: 'Pres.',
        dishes: 'Dishes',
        presentation: 'Presentation',
        price: 'Price',
        numberOfProducts: 'products',
        validation: {
          product: 'Requires Product',
          appName: 'Requires Name in App',
          quantity: 'Requires Quantity',
          name: 'Requires Name',
        },
      },
      setting: {
        setting: 'Settings',
        networks: 'Networks',
        users: 'Users',
        country: 'Country',
        districts: 'Districts',
        reports: 'Reports',
        traceability: 'Traceability',
        institutionAndDonors: 'Institutions and donors',
        coupons: 'Coupons',
        orders: 'Orders',
        institutions: 'Institutions',
        customerCategory: 'Customer category',
        typesOfBusiness: 'Type of business',
        channels: 'channels',
      },
    },

    trips: {
      loading: 'Loading trips',
      no_trips: 'There are no trips for the selected date',
      all: 'All trips',
      origin: 'Origin/s:',
      destination: 'Destination/s:',

      types: {
        searching: 'Seaching for a driver',
        confirmed: 'Confirmed',
        confirmed_subtitle: 'Confirmed driver',
        cancelled: 'Cancelled',
        on_going: 'Ongoing',
        completed: 'Completed',
        assigned: 'Assigned driver',
        assigned_subtitle: 'Awaiting confirmation',
        confirmed_driver: 'Confirmed driver',
        driver: 'Driver: ',
      },

      prev: ' - Previous value ',
      edited_by: '. Edited by ',
      at: 'on',

      constants: {
        fruits_or_vegetables: 'Fruits or vegetables',
        non_perishable: 'Not perishable',
        fresh: 'Fresh and frozen',
      },

      sagas: {
        create_success: 'Successfully created the trip🎉',
        create_error: 'Error creating the trip',
        delete_success: 'Successfully deleted the trip',
        load_error: 'There was an error loading the trips',
        server_error: 'Upss! There was an error on the server 😓.',
      },

      summary: {
        origin: 'Origin/s:',
        date: 'Date:',
        schedule: 'Schedule:',
        destination: 'Destination/s:',
        has_multiple_destinations: 'Has multiple destinations',
        driver: 'Driver:',
        unassigned: 'Unassigned',
        driver_pay: "Driver's pay",
        delivery_total: 'Delivery cost:',
        create_payment: 'Generate payment cupon',
        pending_payment: 'This cupon is pending',
        payment_error: 'An error occurred with the payment',
        payment_success: 'The payment was made',
        payment_in_process: 'The payment is in process',
        order: 'Order:',
        order_total: 'Total:',
        order_amount: 'Order cost:',
        trip_id: 'Trip id:',
      },

      item_dropdown: {
        details: 'View details',
        edit: 'Edit trip',
        delete: 'Delete trip',
      },
      status: {
        pause: 'Pause broadcast',
        processing: 'Processing trip',
        assign_driver: 'Assign driver',
        broadcast: 'Broadcast',
      },
    },

    drivers: {
      sagas: {
        error: 'Upss! There was an error 😓.',
        create_success: 'Successfully created the driver🎉',
        server_error: 'Upss! There was an error on the server 😓.',
        update_success: 'Successfully updated the driver',
        create_account_success:
          'Successfully created the bank account for the driver',
        create_vehicle_success:
          'Successfully created the vehicle for the driver',
      },

      go_back: '< Back to drivers',
      loading: 'Loading...',

      table: {
        name: 'Name',
        mail: 'E-mail',
        phone_number: 'Phone number',
        verified: 'Verified',
        no_title: 'No drivers.',
        no_subtitle: 'To create a new one',
        no_moment: 'go to Shippify momentarily',
        no_data: 'No data',
      },

      info: {
        enabled: 'Enabled',
        disabled: 'Disabled',
        no_email: 'No email',
        no_phone_number: 'No number',
        gender: 'Gender:',
        birth_date: 'Birth date',
        edit: 'Edit',
      },

      list: {
        drivers: 'Drivers',
        filters: 'Filters',
        list: 'Drivers list',
        add: '+ Add driver',
      },

      filter_list: {
        all: 'All',
        enabled: 'Enabled',
        disabled: 'Disabled',
      },

      detail: {
        vehicles: {
          sagas: {
            error: 'Upss! There was an error 😓.',
            create_success: 'Successfully created the vehicle',
            update_success: 'Successfully updated the vehicle',
          },

          title: 'Vehicles',
          add: '+ Add new vehicle',
          no: 'There are no vehicles for this driver',
          create: 'Create vehicle',
          edit: 'Edit',

          brand: 'Brand:',
          model: 'Model:',
          year: 'Year:',
          refrigerated: 'Refrigerated:',
          refrigerated_yes: 'Yes',
          refrigerated_no: 'No',
          reviews: 'Reviews',
          reviews_title: 'Reviews',
          has_all_reviews: 'Has all qualifications',
          does_not_have_all_reviews: 'Does not have all qualifications',
        },
        bank_accounts: {
          sagas: {
            error: 'Upss! There was an error 😓.',
            update_success: 'Successfully updated the bank account',
          },
          title: 'Bank accounts',
          add: '+ Add bank account',
          no: 'There are no bank accounts for this driver',
          create: 'Create a bank account',
          edit: 'Edit',
          bank: 'Bank:',
          type: 'Account type:',
          number: 'Account number:',
          cbu: 'CBU:',
        },
        verifications: {
          title: 'Qualifications',
          driving_license: 'Has drivers license',
          is_monotributista: 'Is single-tax',
          has_cuit_or_cuil: 'Has CUIT or CUIL',
          has_banking_account: 'Has a bank account',
          has_paypal_account: 'Has paypal account',
          habilitation_transport_food: 'Can transport food',
          sanitary_notepad: 'Has sanitary notepad',
        },
      },
    },

    reports: {
      title: 'Reports',
      from: 'From:',
      to: 'To:',
      download: 'Download report',
    },

    notifications: {
      close: 'Close',
      submit: 'Submit',
      previous: 'Back',
      next: 'Next',
      sagas: {
        aprox: 'Approximately',
        users: ' users will be notified',
        get_error: 'Error loading the notifications',
        confirm: 'Send message?',
        sent: '',
        notifications: ' notifications were sent!',
        create_error: 'There was an error while creating the notification',
        get_audience_type_error:
          'There was an error loading the types of recipients',
        get_audience_error: 'There was an error loading the recipients',
        excel_error: 'Ups an error occurred',
      },
      message_title: 'Message',
      receivers_title: 'Receivers',
      step: 'Step',
      of: 'of',
      notifications_title: 'Notifications',

      audience: 'Audience',
      audience_placeholder: 'Select app',

      empty_notifications_message: 'There are no notifications to show.',

      message_type: 'App message',
      notification_message_type: 'Notification',
      receivers: 'Receivers',

      empty_institutions_notifications: 'There are no notifications to show.',

      soup_kitchen_app_label: 'Soup Kitchen App',
      drivers_app_label: 'Drivers App',

      title: 'Notifications',
      send_notification: 'Send Notification',

      message: {
        title: 'Notification title:',
        title_placeholder: '1024 characters maximum',
        text: 'Notification text:',
        text_placeholder: '1024 characters maximum',
        includes_in_app_message: 'Include an in app message',
        in_app_title: 'Message title:',
        in_app_title_placeholder: '1024 characters maximum',
        in_app_text: 'Message text:',
        in_app_text_placeholder: '1024 characters maximum',
      },

      audience_table: {
        receivers_label: 'Receivers',
        text: 'Select the audience to see the receivers of each app',
        user_column: 'User',
        user_column_placeholder: 'Name...',
      },
      validations: {
        blank_title: 'Title is required',
        max_title_length: 'Only 140 characters maximum',
        blank_body: 'Message is required',
        max_body_length: 'Only 140 characters maximum',
        in_app_title_max_length: 'Only 140 characters maximum',
        in_app_body_max_length: 'Only 140 characters maximum',
        blank_receiver: 'Missing receiver',
        min_receiver: 'Must have at least one receiver',
        blank_service: 'Destination is required',
      },
    },

    orders: {
      sagas: {
        delivery_error:
          'The receiver institution is not enabled to receive with delivery',
        create_error: 'There was an error creating the order. ',
        create_success: 'Successfully created the order🎉',
        download_error: 'There was an error loading the product detail',
        server_error: 'Upss! There was an error on the server 😓.',
        delete_message:
          'All data associated with this order will be deleted, this can not be undone',
        delete_order: 'Delete Order',
        delete_error: 'Can not delete the order',
        delete_success: 'Successfully deleted the order',
        edit_error: 'The order might have ben deleted',
        date_success: 'Successfully updated the date',
        edit_payment_message:
          'This will modify the order amount. Do you want to continue anyway?',
        edit_payment: 'Submit',
        edit_order_error: 'There was an error editing the order ',
        edit_order_success: 'Successfully edited the order',
        obsolete: 'Mask as obsolete',
        obsolete_message:
          'Confirm action? The cupon will be marked as obsolete. ',
        delete_payment_error:
          '✋ There was an error marking th payment as obsolete',
        delete_payment_success: 'Successfully marked payment as obsolete',
        create_payment_error: '✋ There was an error creating the payment',
        create_payment_success: 'Successfully created the payment',
        upload_excel_error: 'It must be an Excel file',
        upload_excel_success: 'Was imported successfully',
        total_price: 'Total cost',
      },

      manual: {
        destination: 'Destination',
        goods: 'Goods',
        morning: 'Morning',
        afternoon: 'Afternoon',
        fruits_or_vegetables: 'Fruits or vegetables',
        non_perishable: 'Not perishable',
        fresh: 'Fresh and frozen',

        step: 'Step ',
        from: 'of',
        close: 'Cancel',
        next: 'Next',
        previous: 'Previous',
        submit: 'Confirm',

        step_1: {
          date: 'Delivery date',
          time: 'Schedule',
          time_placeholder: 'Schedule',
          giver: 'Pickup point',
          giver_placeholder: 'Select deposit',
          receiver: 'Delivery point',
          receiver_placeholder: 'Select institution',
        },
        step_2: {
          needs_refrigeration: 'Needs refrigeration',
          total_weight: 'Total weight:',
          order_value: 'Order cost:',
          delivery_value: 'Delivery cost:',
        },
      },
      from_stock: {
        morning: 'Morning',
        afternoon: 'Afternoon',
        no_delivery: 'No delivery',
        paypal: 'Paypal',
        rapi_pago: 'Rapi pago',
        pago_facil: 'Pago facil',
        other: 'Other',

        weight_by_unit: 'Weight by unit',
        total_weight: 'Total weight',
        price_per_unit: 'Price by unit',
        total_price: 'Total price',

        step_1: {
          step: 'Step',
          from: 'of',
          close: 'Cancel',
          next: 'Next',
          destination: 'Destination',
          delivery_date: 'Delivery date',
          schedule: 'Schedule',
          schedule_placeholder: 'Schedule',
          receiver: 'Beneficiary',
          receiver_placeholder: 'Select institution',

          validations: {
            date: 'Delivery date',
            required_delivery_preference: 'Delivery preference is required',
            required_delivery_preference_label: 'Schedule',
            required_receiver: 'Receiver institution is required',
            required_receiver_label: 'Delivery point is required',
          },
        },
        step_2: {
          step: 'Step',
          title: 'Select goods',
          from: 'of',
          close: 'Back',
          submit: 'Create order',
          next: 'Next',
          origin: 'Origin',
          origin_placeholder: 'Select deposit',
          payment_method_label: 'Payment method',
          payment_method: 'Select Payment method',
          order_value: 'Order amount:',
          discount: 'Assigned discount: ',
          total_price: 'Total price: ',
          delivery_value: 'Delivery amount: ',
          notify_institution: 'Notify institution',

          validations: {
            payment_method: 'Payment method',
            numeric_delivery_amount: 'The price must be a number',
            min_delivery_amount: 'The price must be greater than zero',
            required_delivery_amount: 'Price is required',
            delivery_amount: 'Delivery amount: ',
          },
        },

        selected: {
          no: 'No added product',
          product: 'Product',
          quantity: 'Quantity',
          added: 'Added products to the order',
        },

        product_adder: {
          product: 'Product:',
          product_placeholder: 'Select product',
          add: 'Add',
          quantity: 'Quantity',

          validations: {
            product: 'Product',
            quantity: 'Quantity',
            max_stock: 'Must select an available amount',
          },
        },
      },

      tooltip: {
        product: 'Product',
        quantity: 'Quantity',
        preview: 'Preview',
        delivery_date: 'Delivery date:',
        schedule: 'Schedule:',
        origin: 'Origin',
        loading: 'Loading...',
        error: 'There was an error loading the products',
      },

      list: {
        morning: 'Morning',
        afternoon: 'Afternoon',
        without_delivery: 'Without delivery',
        client: 'Client:',
        loading: 'Updating orders',
        manual_order: 'Manually assign Kg',
        from_stock: 'Select products from stock',
        title: 'Orders',
        add: 'ADD ORDER',
        import: 'IMPORT ORDERS',
        export: 'EXPORT DETAIL',
        new_trip: 'New trip',
        new_trip_subtitle: 'Add the orders to create a trip',
        refrigerated: 'Needs refrigeration',
        create_trip: 'Create trip',

        origin: 'Origin: ',
        no: 'There are no orders',
        deleted: 'Deleted',
      },

      detail: {
        turn: 'Schedule',
        product: 'Product',
        description: 'Description',
        brand: 'Brand',
        variant: 'Stock',
        expiration: 'Expiration',
        presentation: 'Presentation',
        unit: 'Unit',
        ordered: 'Order',
        morning: 'Morning',
        afternoon: 'Afternoon',
        no: 'There are no products in this order',
        edit: 'EDIT',
        date: 'Date',
        products: 'product/s',
        close: 'CANCEL',
        submit: 'SUBMIT CHANGES',
        created_by: 'Created by:',
        delivery_date: 'Delivery date:',
        delivery_preference: 'Schedule:',
        origin: 'Origin',
        origins: 's',
        address: 'Address',
        addresses: 'es',
        institution: 'Institution:',
        order: 'Order:',
        no_payment: 'There are no order cupons',
        delivery: 'Delivery:',
        no_delivery: 'There are no delivery cupons',
        delete_order: 'DELETE ORDER',
        deleted_order: 'Deleted order',
        by: 'by',
        edited: 'Edited',
        previous: 'Previous:',
        total_price: 'Total cost',
        discount: 'Product discount',
      },

      edit_payment: {
        delete: 'Delete cupon',
        create: 'Generate cupon',
      },
      edit_product: {
        add: 'ADD PRODUCT',
        validations: {
          number: 'Must be a number',
          positive_number: 'Must be greater than zero',
          integer_number: 'Must be an integer',
        },
      },
    },

    institutions: {
      loading: 'Loading institution',
      sagas: {
        server_error: 'Upss! There was an error on the server 😓.',
        get_institution_error: 'There was an error loading the institution ✋',
        create_success: 'Successfully created the institution',
        create_error: 'There was an error creating the institution ✋',
        update_error: 'There was an error updating the institution 😓',
        update_success: 'Successfully updated the institution',
        delete_user_success: 'Successfully deleted the user',
        get_districts_error:
          'Upss! There was an error loading the districts 😓.',
        get_categories_error:
          'Upss! There was an error loading the categories 😓.',
        update_payment_error: 'There was an error updating the cupon ✋',
        update_payment_success: 'Successfully updated the cupon',
        excel_error: 'Ups an error occurred in the server',
        upload_excel_success: 'Was imported successfully',
        upload_excel_error: 'It must be an Excel file',
      },

      titles: {
        data: 'Institution data',
        location: 'Location',
        contact: 'Contact',
        services: 'Service',
      },
      days: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
      },
      services: {
        lunch: 'Lunch',
        breakfast: 'Breakfast',
        snack: 'Snack',
        dinner: 'Dinner',
        foodBag: 'Food bag',
      },
      payment_methods: {
        rapi_pago: 'Rapi pago',
        pago_facil: 'Pago facil',
        other: 'Other',
        no: 'None',
      },
      payment_status: {
        obsolete: 'Obsolete',
        cancelled: 'Cancelled',
        expired: 'Expired',
        pending: 'Pending',
        approved: 'Payed',
      },
      organization: 'Beneficiary',
      company: 'Donor',
      no_blank: 'Is required',
      already_used: 'Already in use',

      create: {
        title: 'Add new institution',
        step: 'Step',
        of: 'of',
        close: 'Cancel',
        next: 'Next',
        previous: 'Previous',
        submit: 'Confirm',
        required: '* required',
        map_comment: 'Click on the map to select a specific point in the map.',
        titles: {
          data: 'Institution data',
          address: 'Delivery address',
          residence: 'Residence address',
          contact: 'Contact',
          service: 'Service',
        },
        step_1: {
          category: 'Entity type',
          category_placeholder: 'Select',
          name: 'Name',
          name_placeholder: 'Identification',
          number: 'Number',
          number_placeholder: 'Nº',
          legal_name: 'Legal name',
          legal_name_placeholder: 'Business name',
          cuit: 'CUIT',
          cuit_placeholder: 'Without spaces or dashes',
        },
        step_2: {
          address: 'Address: *',
          country: 'Country',
          country_placeholder: 'Country',
          state: 'State',
          state_placeholder: 'State',
          city: 'City',
          city_placeholder: 'City',
          zip_code: 'Zip code',
          zip_code_placeholder: 'Zip code',
          district: 'District',
          district_placeholder: 'Select',
          notes: 'Notes',
          notes_placeholder: 'Address comments',
          delivery_rate: 'Delivery cost:*',
          delivery_rate_placeholder: '$',
          payment_method: 'Preferred payment method:',
          payment_method_placeholder: 'Select',
          min_ticket_price: 'Minimum purchase ticket',
        },
        step_3: {
          address: 'Address: *',
          country: 'Country',
          country_placeholder: 'Country',
          state: 'State',
          state_placeholder: 'State',
          city: 'City',
          city_placeholder: 'City',
          zip_code: 'Zip code',
          zip_code_placeholder: 'Zip code',
          notes: 'Notes',
          notes_placeholder: 'Address comments',
        },
        step_4: {
          contact: 'Contact',
          contact_placeholder: 'Name y Surname',
          phone: 'Phone number',
          phone_placeholder: 'Contact number',
          mail: 'Email',
          mail_placeholder: 'Email',
          responsible: 'Legal responsible',
          responsible_name: 'Full name',
          responsible_name_placeholder: 'Name y Surname',
          dni: 'DNI',
          dni_placeholder: 'Without spaces or dots',
          responsible_phone: 'Phone number',
          responsible_phone_placeholder: 'Contact number',
          responsible_mail: 'Email',
          responsible_mail_placeholder: 'Email',
        },
        step_5: {
          order_days: 'Order day',
          order_days_placeholder: 'Select',
          open_hours: 'Schedule',
          beneficiaries: 'Beneficiaries',
          beneficiaries_placeholder: 'Nº',
          services: 'Services',
          category: 'Category',
          category_placeholder: 'Select',
        },
      },

      payments_detail: {
        no: 'There are no payment cupons',
        sagas: {
          get_balance_error: 'There was an error loading the balances',
          get_payee_error: 'There was an error loading the payee information',
          get_payments_error: 'There was an error loading the cupons',
          create_success: 'Successfully created the cupon',
          create_error: 'There was an error creating the cupon',
          obsolete: 'Mark as obsolete',
          obsolete_message:
            'Are you sure you want to mark this cupon as obsolete?',
          obsolete_success: 'Successfully marked cupon as obsolete',
          obsolete_error: 'There was an error marking the cupon as obsolete',
        },
        table: {
          date: 'Date',
          payment: 'Cupon',
          system: 'System',
          amount: 'Amount',
          status: 'Status',
          payment_date: 'Payment date',
          payment_code: 'Payment code',
          collected_amount: 'Collected amount',
          comments: 'Comments',
          mark_as_payed: 'Mark as payed',
          edit_comment: 'Edit Comment',
          mark_obsolete: 'Mark as obsolete',
        },
        payment_status: {
          pending: 'Pending',
          approved: 'Approved',
          rejected: 'Rejected',
          in_process: 'In process',
          error: 'Error',
        },
      },

      info_detail: {
        name: 'Name',
        surname: 'Surname',
        mail: 'Email',
        phone: 'Phone number',
        app: 'App',
        edit_user: 'Edit user',
        delete_user: 'Delete user',
        coming_soon: '(Coming soon)',

        general_info: {
          type: 'Type:',
          legal_name: 'Name legal:',
          cuit: 'CUIT',
          contact: 'Contact:',
          phone: 'Phone number:',
          mail: 'Email:',
          order_date: 'Order date:',
          last_order: 'Last order date:',
          schedule: 'Schedule:',
          residence: 'Residence address:',
          notes: 'Notes:',
          total_debt: 'Balance:',
          client_business_category: 'Client business category',
          client_business_channel: 'Client business channel',
          client_business_type: 'Client business type',
        },

        location: {
          no: 'None',
          notes: 'Notes:',
          address: 'Delivery address:',
          district: 'District:',
          delivery_rate: 'Delivery cost:',
          payment_method: 'Preferred payment method:',
          residence: 'Preferred payment method:',
          residence_address: 'Residence address:',
        },

        specific_info: {
          beneficiaries: 'Beneficiaries',
          services: 'Services:',
          weekly_rations: 'Weekly rations:',
          coming_soon: '(Coming soon)',
          category: 'Category:',
        },

        users: {
          no: 'There are no users',
          user: 'user',
          users: 'users',
          add: 'Add User',
        },
      },

      detail: {
        info: 'Information',
        payments: 'Payments',
        loading: 'Loading detail',
        back: 'Back to Institutions',
        edit: 'Edit Information',
      },

      edit: {
        back: 'Back to the institution',
        close: 'Cancel',
        save: 'Submit',

        loading: 'Loading data',
        data: 'Entity details',

        category: 'Entity type',
        category_placeholder: 'Select',
        name: 'Name',
        name_placeholder: 'Identification',
        number: 'Number',
        number_placeholder: 'Nº',
        legal_name: 'Legal name',
        legal_name_placeholder: 'Business name',
        cuit: 'CUIT',
        cuit_placeholder: 'Without spaces or dashes',

        location: 'Location',

        delivery_address: 'Delivery address: *',
        district: 'District',
        district_placeholder: 'Select',
        notes: 'Notes',
        notes_placeholder: 'Address comments',
        delivery_rate: 'Delivery cost:*',
        delivery_rate_placeholder: '$',
        payment_method: 'Preferred payment method:',
        payment_method_placeholder: 'Select',

        residence: 'Residence address: *',
        residence_notes: 'Notes',
        residence_notes_placeholder: 'Address comments',
        ticket_min_placeholder: 'Minimun purchase ticket',
        min_ticket_price: 'Minimum purchase ticket',
        contact_title: 'Contact',

        contact: 'Contact',
        contact_placeholder: 'Name y Surname',
        phone: 'Phone number',
        phone_placeholder: 'Contact number',
        mail: 'Email',
        mail_placeholder: 'Email',

        service: 'Service',

        order_days: 'Order date',
        order_days_placeholder: 'Select',
        open_hours: 'Schedule',
        beneficiaries: 'Beneficiaries',
        beneficiaries_placeholder: 'Nº',
        services: 'Services',
        service_category: 'Category',
        service_category_placeholder: 'Select',

        required: '* required',
      },

      list: {
        number: 'N°',
        name: 'Name',
        no_name: 'No name',
        district: 'District',
        address: 'Address',
        no_address: 'No info',
        users: 'Users',
        last_order: 'Last order',
        debt: 'Owed',
        with_delivery: 'With Delivery',
        without_delivery: 'Without delivery',
        recurrent: 'recurring dining room',
        excelInstitution: 'import institutions',
        beneficiaries: 'Beneficiaries',
        companies: 'Donors',
        loading: 'Loading institutions',

        no: 'No available institutions',
        institution: 'institution',
        institutions: 'institutions',

        no_created: 'There are no created institutions',
        no_created_subtitle: 'You can create a new one from',
        no_here: 'here',

        search: 'Search',
        add: 'Add institution',

        no_data: 'No data',
        filters: {
          show: 'Show Filters',
          hide: 'Hide Filters',
          enable: 'Enabled',
          pending: 'Pending',
          disabled: 'Disabled',
          week: 'Last Week',
          month: 'Last Month',
          last6Months: 'Last 6 Months',
          specific: 'Specific Date',
          status: 'Status',
          order: 'Last Order Date',
          placeholder: 'All',
          start: 'From',
          end: 'To',
        },
      },
      mapCard: {
        beneficiaries: 'Beneficiaries',
        status: 'Status',
        last_order: 'Last Order',
        created_at: 'Created At',
      },

      cupons: {
        cupon: 'cupon',
        cupons: 'cupons',
        create: 'Create cupon',
        no: 'There are no generated cupons',
        loading: 'Loading cupons',
      },
    },

    stock: {
      product: 'Product',
      stock: 'Stock',

      archiveStock: 'Archive Stock',
      sagas: {
        export_csv_error: 'There was an error downloading the product csv',
        offer_error: 'There was an error loading the offerable articles',
        csv_download_error: 'There was an error downloading the articles csv',
        import_error: 'There was an error downloading the import table',
        imported: 'Imported',
        elements: 'elements',
        import_csv_error: 'There was an error importing the csv',
        get_stock_error: 'There was an error loading the stock ✋',
        update_product_error: 'There was an error updating the product ✋',
        update_product_success: 'Successfully updated the product',
        revise_product_error: 'There was an error revising the product ✋',
        revise_product_success: 'Successfully revised the product',
        create_product_error: 'There was an error creating the product ✋',
        create_product_success: 'Successfully added the product',
        create_stock_error: 'There was an error creating the stock ✋',
        create_stock_success: 'Successfully added the stock',
        update_variant_success: 'Successfully updated the variant',
        get_offers_error: 'There was an error loading the offers ✋',
        get_products_error: 'There was an error loading the products ✋',
        create_offer_error: 'There was an error creating the offer ✋',
        create_offer_success: 'Successfully created the offer',
        cancel_offer_error: 'There was an error cancelling the offer ✋',
        cancel_offer_success: 'Successfully cancelled the offer',
      },

      add_button: {
        product: 'Product',
        stock: 'Stock',
        import: 'Import csv',
        add: 'ADD',
      },

      catalog: {
        id: 'Code',
        name: 'Name',
        description: 'Description',
        brand: 'Brand',
        category: 'Category',
        sub_category: 'Sub-Category',
        presentation: 'Presentation',
        weight: 'Final weight (per pack)',
        unit: 'Weight unit name',
        add: 'Add product',
      },
      combos: {
        add: 'Create Combo',
        table: {
          code: 'Code',
          active: 'Active',
          name: 'Name',
          description: 'Description',
          items: '# Variants',
          totalWeight: 'Total Weight',
          dishesPerCombo: 'Dishes/Combo',
          unit_type: 'Format',
          stock: 'Stock',
          price: 'Price',
          requiresCold: 'Requires Cold',
        },
      },

      list: {
        actual: 'Actual Stock',
        offers: 'Offers',
        historic: 'Historic',
        combos: 'Combos',
        catalog: 'Catalog',
        loading: 'Loading stock',
        product: 'Product',
        description: 'Description',
        brand: 'Brand',
        reference: 'Code',
        expiration: 'Expiration',
        presentation: 'Presentation',
        unit: 'Unit',
        origin: 'Warehouse',
        rescued: 'Rescued',
        stock: 'Stock',
        price: 'Price',
        price_lote: 'Lot cost',
        edit_reference: 'Edit Stock',
        revise_product: 'Edit product',
        released_product: 'View released products',
      },

      tabs: {
        no: 'There are no available products',
        product: 'product',
        products: 'products',
      },

      export_button: {
        product: 'Products',
        stock: 'Stocks',
        export: 'EXPORT',
      },

      offers: {
        create: 'CREATE OFFER',
        no: 'There are no available offers',
        product: 'product',
        products: 'products',
        offer: 'offer',
        offers: 'offers',
        loading: 'Loading...',
        orders: 'Orders',
        back: 'Back to offers',

        table: {
          product: 'Product',
          stock: 'Stock',
          from_to: 'Delivery from/to',
          remaining: 'Remaining time',
          expiration: 'Expires:',
          price: 'Price:',
          orders: 'ORDERS',
          details: 'View details',
          cancel: 'Cancel Offer',
        },
      },

      create_offer: {
        close: 'Cancel',
        previous: 'Previous',
        next: 'Next',
        submit: 'Submit',

        step: 'Step',
        of: 'of',

        outside: 'Outside deposit',
        inside: 'Network deposit',

        titles: {
          select_products: 'Select products',
          select_destination: 'Select receivers',
          end: 'End of offer',
          deliveries: 'Order delivery',
        },

        select_products: {
          origin: 'Origin',
          origin_placeholder: 'Origin',
          product: 'Product',
          price: 'Price per kilo',
          no_stock: 'There are no stocks to offer',
          search: 'Search',
          add_product: '+ ADD PRODUCT',
        },

        delivery_date: {
          date: 'Date',
          start_time: 'Start time',
          end_time: 'End time',
        },

        end: {
          date: 'Date',
          time: 'Time',
        },

        institution: {
          institution: 'Institution',
          institution_placeholder: 'Name...',
          district: 'District',
          district_placeholder: 'District...',
          order: 'Order',
          order_placeholder: 'Date...',
        },

        validations: {
          required_origin: 'Stock origin is required',
          min_stocks: 'At least one product is required',
          required_stocks: 'Stocks are require',
          empty_stocks: 'The selector can not be left blank',
          numeric_price: 'Must be a number',
          min_price: 'The amount must be greater than o equal to zero',
          min_institution: 'At least one institution is required',
          required_institution: 'An institution is required',
          valid_end_date: 'The end date must be before the start date',

          required_end_date: 'End date is required',
          required_end_hour: 'Select a time before',
          valid_start_date: 'The end date must be before the start date',
          required_start_date: 'Start date is required',
          required_start_time: 'Start time is required',
          valid_start_time: 'Select a start time before',
          valid_end_time: 'The end time must be before the start time',
          required_end_time: 'End time is required',
        },
      },
    },
    users: {
      sagas: {
        delete_message:
          'All data associated with this user will be deleted. The action cannot be undone. ',
        delete_network: 'Delete user',
        delete_error: 'Unable to delete user',
        delete_success: 'was removed successfully',
        server_error: ' Oops! There was an error on the server😓.',
        edit_message:
          ' This action will edit the user, do you want to continue anyway?',
        edit: 'save changes',
        edit_user_error: 'An error occurred while editing the user',
        edit_user_success: ' the user was successfully edited!🎉',
      },
      users: {
        title: 'Users',
        create: 'Create new user',
        no_data: 'No data',
      },
      column: {
        name: 'Username',
        email: 'Email',
        networks: 'networks',
        roles_mask: 'User role',
        edit: 'Edit',
        delete: 'Delete',
      },
      create_users: {
        username: 'Username',
        email: 'Email',
        password: 'Password',
        confirm_password: 'Confirm password',
        networks: 'Networks',
        user_role: 'User role',
        user_admin: 'User admin',
        user_no_admin: 'User no admin',
        save: 'Save',
        username_error: 'Username is requiered',
        email_error: 'Email is requiered',
        password_error: 'Password is requiered',
        confirm_password_errors: 'You must confirm the password',
        networks_options_error: 'Networks is requiered',
        checked_password: 'Passwords must match',
        role_error: 'Role is requiered',
        back: 'Back to users',
        create: 'Create user',
      },
      update_users: {
        back: 'back to users',
        title: 'Edit user',
        change_password: 'Change password (optional)',
      },
    },
    networks: {
      sagas: {
        delete_message:
          'All data associated with this network will be deleted. The action cannot be undone. ',
        delete_network: 'Delete network',
        delete_error: 'Unable to delete network',
        delete_success: 'was removed successfully',
        server_error: 'Oops! There was an error on the server 😓. ',
        edit_message:
          'This action will edit the network, do you want to continue anyway?',
        edit: 'Save changes',
        edit_net_error: 'An error occurred while editing the network',
        edit_net_success: 'The network was edited successfully! 🎉 ',
        create: 'Created successfully 🎉 !!',
        update: 'Updated successfully 🎉 !!',
      },
      networks: {
        no_data: 'There is no data',
        no_title: 'No networks created',
        no_subtitle: 'You must create a new network',
        create_network: 'Create new network',
        title: 'Networks',
        edit: 'Edit red',
      },
      column: {
        name: 'Name',
        country_code: 'Network id',
        active: 'Active',
        visible: 'Visible',
        min_ticket: 'Min price ticket',
        phone_country_code: 'Phone country code',
        lat: 'Latitude',
        lng: 'Longitude',
        created: 'Created at',
        update: 'Update at',
        edit: 'Edit',
        delete: 'Delete ',
      },
      create_networks: {
        active: 'Active',
        status: 'Status:',
        visible: 'Visible',
        save: 'Save',
        network_name: 'Network name',
        country_code: 'Network id',
        country: 'Country',
        min_ticket_price: 'Min ticket price',
        code_phone: 'Code phone',
        lat_lng: 'Latitude -34.4714027, Longitude 58.8950115',
        back: 'Go back to networks',
        create: 'Create network',
        name_error: 'the name of the network is required',
        network_id_error: 'the code country is requiered',
        price_error: 'ticket price is requiered',
        country_error: 'Country is requiered',
      },
    },
    districts: {
      create_districts: {
        title: 'Districts',
        name: 'District name',
        code: 'Code',
        save: 'Save',
        create: 'Create districts',
        back: 'Back to districts',
        edit: 'Edit districts',
      },
    },
    country: {
      sagas: {
        delete_message:
          'All data associated with this country will be deleted. The action cannot be undone.',
        delete_country: 'Delete country',
        error_message: 'Uups, an error occurred',
        create: 'Created successfully!!',
        update: 'Update successfully!!',
        delete: 'It was removed successfully !!',
      },
      country: {
        title: 'Countries',
        create: 'Create new country',
        edit: 'Edit country',
      },
      column: {
        name: 'Name',
        currency: 'Currency',
        gateway: 'Gateway',
        phone_country_code: 'Phone country code',
        preferred_payment_method: 'Payment method',
        created: 'Created at',
        update: 'Update at',
      },
      create_country: {
        title: 'Country',
        name: 'Country name',
        currency: 'Currency',
        back: 'Go back to countries',
        payment_method: {
          payment: 'Payment methods: ',
          rapipago: 'rapipago',
          pagofacil: 'pagofacil',
          other: 'other',
        },
        code_phone: 'Phone code (Ex: 54)',
        gateway_method: {
          gateway: 'Gateway',
          paypal: 'paypal',
          mercadopago: 'Mercadopago',
        },
        save: 'Save',
        delete: 'Delete',
        edit: 'Edit',
        name_error: 'the country name is required',
        currency_error: 'the currency is requiered',
        phone_error: 'Phone country is requiered',
        gateway_error: 'gateway is requiered',
      },
    },
    orden_create: 'Create orden',
    upload_order: 'Please select what type of action you want to perform',
    import_excel: 'Import from excel file',
  },
};
